import servicesUtils from '../utils/service'

export const teamServices = {
    async getTeamInfo(id) {
        try {
            const url = `${servicesUtils.resourceUrl('getTeam')}`
                .replace(':id', id);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in teamServices.getTeamInfo, err: ${err}`)
            throw err
        }
    },
    async cancelTeamPlan(id) {
        try {
            const url = `${servicesUtils.resourceUrl('cancelTeamPlan')}`
                .replace(':teamHistoryId', id);
            const res = await servicesUtils.post(url);
            return res.data;
        } catch (err) {
            console.log(`failed in teamServices.cancelTeamPlan, err: ${err}`)
            throw err
        }
    },
    async getDiaryHomeworkUserWithUsage(query) {
        try {
            const url = `${servicesUtils.resourceUrl('getDiaryHomeworkUserWithUsage')}`
                + '?'
                + servicesUtils.queryParams(query);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in teamServices.getDiaryHomeworkUserWithUsage, err: ${err}`)
            throw err
        }
    },
    async checkTeamMember(id, query) {
        try {
            const url = `${servicesUtils.resourceUrl('checkTeamMember')}`
                .replace(':teamId', id) + '?' + servicesUtils.queryParams(query);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in teamServices.getDiaryHomeworkUserWithUsage, err: ${err}`)
            throw err
        }
    },
    async addTeamMember(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('addTeamMember')}`
                .replace(':teamId', id);
            const res = await servicesUtils.post(url, payload);
            return res.data;
        } catch (err) {
            console.log(`failed in teamServices.addTeamMember, err: ${err}`)
            throw err
        }
    },
    async changeMemberRole(teamId, userId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('changeMemberRole')}`
                .replace(':teamId', teamId)
                .replace(':userId', userId);
            const res = await servicesUtils.post(url, payload);
            return res.data;
        } catch (err) {
            console.log(`failed in teamServices.changeMemberRole, err: ${err}`)
            throw err
        }
    },
    async removeTeamMember(teamId, userId) {
        try {
            const url = `${servicesUtils.resourceUrl('removeTeamMember')}`
                .replace(':teamId', teamId)
                .replace(':userId', userId);
            const res = await servicesUtils.post(url);
            return res.data;
        } catch (err) {
            console.log(`failed in teamServices.removeTeamMember, err: ${err}`)
            throw err
        }
    }
}