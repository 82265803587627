import servicesUtils from '../utils/service'

export const bookServices = {
    async getBookList(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getBookList')}`
                + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getBookList err: ${err}`)
            throw err
        }
    },
}