<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-card outlined>
            <PayFunctionArea v-bind:isPay="payfunctionMap[functionId]"></PayFunctionArea>
            <v-card-title>
                <v-text-field v-model="searchUserName" :label="$t('student_list.search_student_label')" single-line
                    append-icon="mdi-magnify" 
                    @click:append="searchStudent"
                    clearable @click:clear="clearSearchUserName"
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
            </v-card-title>
            <div class="mx-4">
                <v-divider></v-divider>
            </div>
            <v-subheader>{{ formatStudents() }}</v-subheader>

            <v-data-table :headers="headers" :items="students" :server-items-length="totalStudents" :loading="loading"
                :page.sync="studentPage" :options.sync="studentOptions" :items-per-page="10" hide-default-footer
                disable-sort class="mx-4">

                <template v-slot:item.unread="{ item }">
                    <v-icon v-if="item.unread" color="deep-orange darken-4">
                        mdi-circle-medium </v-icon>
                </template>
                <template v-slot:item.role="{ item }">
                    <v-img :src="item.role" class="role-thumbnail  mx-auto my-2">
                    </v-img>
                </template>
                <template v-slot:item.nameAndAccount="{ item }">
                    <span>{{ item.name }}</span>
                    <br />
                    <span class="font-weight-thin">{{ item.account }}</span>
                </template>
                <template v-slot:item.lastPost="{ item }">
                    <div v-if="item.lastPost">
                        <span>{{ item.lastPost.title }}</span>
                        <br />
                        <span class="red--text text-caption">
                            {{ `#${formatEmotions(item.lastPost.emotions)}` }}
                        </span>
                        <span class="blue--text text-caption">
                            {{ `#${formatBehavior(item.lastPost.behavior)}` }}</span>
                    </div>
                    <span v-else>{{ $t('general.diary.no_data') }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn small class="mr-2 mt-1 normal-case" outlined @click="goToStudentDetail(item.id)">
                        <v-icon small class="mr-1"> mdi-eye </v-icon>
                        {{ $t('button.detail') }}
                    </v-btn>
                </template>
                <template v-slot:item.watching="{ item }">
                    <div class="checkbox-container">
                        <v-checkbox v-model="item.watching" @change="addOrRemoveCase(item)"></v-checkbox>
                    </div>
                </template>
            </v-data-table>
            <div class="text-center my-4">
                <v-pagination v-model="studentPage" :length="studentPageCount" 
                :total-visible="11" circle color="orange accent-2">
                </v-pagination>
            </div>
        </v-card>
    </v-container>
</template>

<script>

import { studentServices } from '@/services/student.js';
import PayFunctionArea from '@/components/console/diary/component/PayFunctionArea.vue'
import {mapState } from "vuex";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getItem } from '@/utils/storageManager';

export default {
    name: 'studentList',
    components: { PayFunctionArea },
    data() {
        return {
            functionId: 'f3',
            payfunctionMap: JSON.parse(getItem('payfunctionMap')),
            breadcrumbsItems: [],
            headers: [
                {
                    text: '',
                    value: 'unread',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.role'),
                    value: 'role',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                },
                {
                    text: this.$t('table.name_account'),
                    value: 'nameAndAccount',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.latest_post_brief'),
                    value: 'lastPost',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                },
                {
                    text: this.$t('table.last_login'),
                    value: 'lastLoginTime',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                },
                {
                    text: this.$t('table.function'), value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                },
                {
                    text: this.$t('table.case'), value: 'watching', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                },
            ],
            students: [
                {
                    role: '',
                    name: '',
                    studentId: '',
                    lastTitle: '',
                    emotions: '',
                    action: '',
                    lastLoginTime: '3/3/2020 7:29:16 PM',
                    watching: true,
                },
            ],
            studentPage: 1,
            studentPageCount: 1,
            totalStudents: 0,
            loading: false,
            studentOptions: {},
            homeWorkId: null,
            searchUserName: null,
        }
    },
    async created() {
        this.homeWorkId = this.$route.params.id;
        this.breadcrumbsItems.push({
            text: this.$t('index.title'),
            disabled: false,
            href: `/diary`,
        }, {
            text: this.homeworkName,
            disabled: false,
            href: `/diary/homework/${this.homeWorkId}`,
        }, {
            text: this.$t('menu.diary_student'),
            disabled: true,
            href: `/diary/${this.homeWorkId}`,
        }
        );
        if (!this.payfunctionMap[this.functionId]) {
            return;
        }
        this.searchUserName = '';

    },
    watch: {
        studentOptions: {
            handler() {
                this.getStudentData()
            },
            deep: true,
        },
        homeworkName() {
            this.breadcrumbsItems[1].text = this.homeworkName;
        },
    },
    computed: {
        ...mapState(["homeworkName"]),
    },
    methods: {
        getStudentData() {
            if (this.homeWorkId == null)
                return;
            this.loading = true;
            this.getStudentList().then(data => {
                this.students = data.items;
                this.totalStudents = data.total;
                this.studentPageCount = data.pageCount;
                this.loading = false;
                this.studentPage = data.page;
            })
        },
        searchStudent() {
            if (this.homeWorkId == null)
                return;
            this.studentOptions.page = 1;
            this.getStudentData();
        },
        async getStudentList() {
            const { page } = this.studentOptions;
            const query = {
                page: page,
                user_name: this.searchUserName,
            };
            const res = await studentServices.getStudents(this.homeWorkId, query);
            dayjs.extend(utc)
            dayjs.extend(timezone)
            const tz = dayjs.tz.guess()
            const items = res.students.list.map(function (d) {
                return {
                    id: d.user_id,
                    name: d.user_name,
                    role: d.avatar_url,
                    account: d.account,
                    lastLoginTime: dayjs.utc(d.last_login).tz(tz).format('YYYY-MM-DD HH:mm:ss'),
                    unread: true,
                    lastPost: d.last_post,
                    watching: d.watching,
                };
            });
            const total = res.students.total;
            const pageCount = res.students.totalPage;
            return { items, total, pageCount, page }
        },
        goToStudentDetail(id) {
            // console.log('id:', id)
            this.$router.push({
                name: 'studentDetail', params: {
                    id: this.homeWorkId,
                    sid: id
                }
            });
        },
        formatEmotions(emotions) {
            if (Array.isArray(emotions) && typeof emotions[0] === 'string') {
                let result = '';
                for (let emotion of emotions) {
                    const a = this.$t(`emotion.type.${emotion}`);
                    if (a) {
                        if (result.length > 0) {
                            result += ', ';
                        }
                        result += a;
                    }
                }
                return result;
            }
            return emotions;
        },
        formatBehavior(behavior) {
            if (behavior == null || behavior == '') {
                return '';
            }
            const a = this.$t(`behavior.type.${behavior}`);
            if (a) {
                return a;
            }
            return '';
        },
        async addOrRemoveCase(student) {
            // console.log('student:', student)
            const homeWorkId = parseInt(this.homeWorkId);
            if (student.watching) {
                //Add case
                await studentServices.addStudentCase(homeWorkId, student.id);
            } else {
                //Remove case
                await studentServices.deleteStudentCase(homeWorkId, student.id);
            }
        },
        clearSearchUserName() {
            this.searchUserName = '';
            this.getStudentData();
        },
        formatStudents() {
            return this.$t('student_list.students').replace('${num}', this.totalStudents);
        }
    },
}
</script>

<style scoped>
.role-thumbnail {
    width: 50px;
    align-items: center;
}

.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>