<template>
   <v-container>
        <v-row style="height: 20vh;"></v-row>
        <v-row justify="center" align="center">
            <v-img :src="loadingImage" contain style="height: 40vh;"></v-img>
        </v-row>
        <v-row justify="center" class="mt-10">
            <v-progress-linear :color="loadingColor" indeterminate height="15"
                style="border-radius: 10px; width: 75vw;"></v-progress-linear>
        </v-row>
    </v-container>
</template>

<script>
import { ebookLoginServices } from "@/services/ebookLogin.js";
import { getItem } from '@/utils/storageManager';

export default {
    name: "EBookLoginCheck",
    data() {
        return {
            isStudioUser: false,
            loadingColor: 'amber darken-3',
            loadingImage: require(`@/assets/ebook/loading_console.svg`)
        };
    },
    async created() {
        const userName = getItem('userName');
        const ebookUserName = getItem('ebookUserName');

        let isStudent = false;
        if (location.origin.indexOf('ebook.mangachat') != -1) {
            isStudent = true;
        }
        if (isStudent) {
            this.isStudioUser = true;
            this.loadingColor = '#F3C250';
            this.loadingImage = require(`@/assets/ebook/loading_studio.svg`)
        } 
        // else if (!userName){
        //     // 如果是後台進來但沒有userName就叫他去日記本
        //     this.$router.push('/diary');
        // }

        if (ebookUserName) {
            let toRedirectPath = sessionStorage.getItem('redirect_path');
            sessionStorage.removeItem('redirect_path');
            this.$router.push(toRedirectPath);
        } else if (userName) {
            this.$router.push('/ebook/redirect?console=1');
        } else {
            ebookLoginServices.oauthLogin();
        }
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if(facebookChat)
            facebookChat.style.display = "none";
    },
};
</script>

<style scoped>
.vh-100 {
    height: 100vh;
}
</style>