<template>
    <v-container fluid class="ma-0 pa-0" v-if="invalid">
        <v-row class="blue-grey lighten-1 py-4">
            <v-btn text plain class="white--text text-h5 pa-0 ma-0 normal-case text-center mx-auto"
                :to="getConsoleIndexUrl">MangaChat</v-btn>
        </v-row>
        <v-row>
            <v-spacer />
            <v-icon class="mx-auto my-2" color="blue-grey darken-2" large>mdi-emoticon-cry-outline</v-icon>
            <v-spacer />
        </v-row>
        <div class="text-center my-4">{{ $t('reset_password.invalid') }}</div>
    </v-container>
    <v-container fluid class="ma-0 pa-0" v-else>
        <v-row class="blue-grey lighten-1 py-4 mb-2">
            <v-btn text plain class="white--text text-h5 pa-0 ma-0 normal-case text-center mx-auto"
                :to="getConsoleIndexUrl">MangaChat</v-btn>
        </v-row>
        <v-card class="mx-auto mt-10" width="500px" outlined>
            <v-sheet color="grey lighten-2" class="pt-2 px-2 pb-1 mb-2">
                <div class="text-body-1 py-2">{{ $t('reset_password.greeting') }}</div>
                <p class="text-body-2">{{ $t('reset_password.title') }}</p>
            </v-sheet>
            <v-text class="px-2 text-body-2">
                {{ $t('reset_password.info') }}
            </v-text>
            <v-card-subtitle>
                <v-row class="mx-2">
                    <v-col cols="4" sm="3">{{ $t('login.password') }}</v-col>
                    <v-form ref="passwordForm">
                        <v-col cols="8" sm="9">
                            <v-row>
                                <v-text-field :placeholder="$t('user_setting.new_password_placeholder')"
                                    outlined dense hide-details="auto"
                                    v-model="newPassword"
                                    :rules="[rules.required]"
                                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showNewPassword ? 'text' : 'password'"
                                    @click:append="showNewPassword = !showNewPassword"
                                    @change="checkPassword" class="mb-2">
                                </v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field :placeholder="$t('login.passwor_repeat')"
                                    outlined dense hide-details="auto"
                                    v-model="passwordRepeat"
                                    :rules="[rules.required]"
                                    :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="showPasswordRepeat ? 'text' : 'password'"
                                    :error-messages="passwordErrorMessage"
                                    @click:append="showPasswordRepeat = !showPasswordRepeat"
                                    @change="checkPassword" class="pt-2">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-form>
                </v-row>
            </v-card-subtitle>
            <v-card-action>
                <v-row class="my-2">
                    <v-spacer />
                    <v-btn class="normal-case" rounded @click="savePWD()" :disabled="passwordErrorMessage">
                        {{ $t('reset_password.submit') }}</v-btn>
                    <v-spacer />
                </v-row>
            </v-card-action>
        </v-card>
        <v-dialog v-model="loginDialog" persistent max-width="400">
            <v-card class="pb-2">
                <v-card-title class="my-2">{{ $t('reset_passord.dialog.title') }}</v-card-title>
                <v-card-subtitle class="my-4 text-center text-body-1">
                    {{ $t('reset_passord.dialog.content') }}
                </v-card-subtitle>
                <v-card-actions class="py-2">
                    <v-spacer />
                    <v-btn rounded @click="toLogin()"
                    class="deep-orange lighten-2 normal-case white--text mx-2">{{ $t('reset_password.cancel') }}</v-btn>
                    <v-btn rounded @click="login()"
                    class="deep-orange lighten-2 normal-case white--text mx-2">{{ $t('reset_password.ok') }}</v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { userServices } from '@/services/user';
import { mapGetters } from "vuex";
import { loginServices } from '@/services/login';

export default {
    name: 'ResetPwd',
    data() {
        return {
            newPassword: '',
            showNewPassword: false,
            passwordErrorMessage: '',
            passwordRepeat: '',
            showPasswordRepeat: false,
            code: '',
            rules: {
                required: value => !!value || this.$t('post_revise.comment_required'),
            },
            invalid: true,
            loginDialog: false,
            loginCode: '',
        };
    },
    async created() {
        this.code = this.$route.params.code;
        await this.checkCode();
    },
    methods: {
        async checkCode() {
            const result = await userServices.checkResetPwd(this.code);
            this.invalid = !result.data.valid;
        },
        checkPassword() {
            if (this.passwordRepeat && this.newPassword && this.newPassword !== this.passwordRepeat) {
                this.passwordErrorMessage = this.$t('reset_password.not_match');
            } else {
                this.passwordErrorMessage = null;
            }
        },
        async savePWD() {
            if (!this.$refs.passwordForm.validate()) {
                return;
            }
            this.checkPassword();
            if (this.passwordErrorMessage) {
                return;
            }

            const keyResult = await userServices.getKey();
            if (keyResult.data.key) {
                const key = keyResult.data.key;
                const encryptedData = userServices.encryptData(this.newPassword, key);
                const payload = {
                    password: encryptedData,
                    code: this.code
                }
                const saveResult = await userServices.resetPwd(payload);
                if (saveResult.data.code) {
                    this.loginCode = saveResult.data.code;
                    this.loginDialog = true;
                }
            }
        },
        async login() {
            const payload = {
                code: this.loginCode,
            }
            await loginServices.accountLogin(payload);
            location.href = '/login/check';
        },
        toLogin() {
            this.$router.push('/login');
        }
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    },
    computed: {
        ...mapGetters(["getConsoleIndexUrl"]),
    }
}
</script>
<style scoped></style>