import Vue from 'vue'
import Vuex from 'vuex'

import { state } from './state'
import { mutations } from './mutation'
import { actions } from './action'
import { getters } from './getter'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
    },
    state,
    mutations,
    actions,
    getters
})