<template>
    <v-container>
        <v-row class="vh-100 align-center align-self-center">
            <v-col>
                <v-progress-linear color="amber darken-3" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { loginServices } from '@/services/login.js';
import { setItem } from '@/utils/storageManager.js';

export default {
    name: "loginRedirect",
    components: {},
    data() {
        return {
            code: '',
            overlay: true,
        };
    },
    async created() {
        this.code = this.$route.query.code;
        await this.getUserInfo();
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    },
    methods: {
        async getUserInfo() {
            const data = {
                code: this.code,
            }
            const res = await loginServices.getUserInfo(data);
            if (res.success) {
                setItem("userName", res.user.name);
                setItem('locale', res.user.locale);
                const toRedirectPath = sessionStorage.getItem('redirect_path');
                if (!toRedirectPath) {
                    sessionStorage.setItem('redirect_path', '/diary');
                }
                sessionStorage.setItem('isHomeworkLock', res.chargePlan.isHomeworkLock);
                sessionStorage.setItem('planName', res.chargePlan.planName);
                sessionStorage.setItem('studentLimit', res.chargePlan.studentLimit);
                sessionStorage.setItem('locale', res.user.locale);
                sessionStorage.setItem('mode', res.mode);
                sessionStorage.setItem('teamModeAvailable', res.teamModeAvailable);
                location.href = `/login/check`;
            }
        }
    },
};
</script>

<style scoped>
.vh-100 {
    height: 100vh;
}
</style>