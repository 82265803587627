import fetch from 'node-fetch'
import router from '@/router'
import { clearStorage } from '@/utils/storageManager';

const httpHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
}

const checkStatus = (res) => {
    if (res.status >= 200 && res.status < 300) {
        return res
    } else {
        let error = new Error(res.statusText)
        let path = '/error';
        switch (res.status) {
            case 404: path = '/404'; break;
            case 401:
                if (res.url.indexOf('book') != -1) {
                    path = '/ebook/login';
                } else {
                    path = '/login/check';
                    clearStorage();
                }
                sessionStorage.setItem('redirect_path', window.location.pathname);
                break;
        }
        router.push(path);
        throw error;
    }
}

export default {
    async get(url) {
        try {
            const res = await fetch(url, {
                method: 'get',
                headers: httpHeaders,
                credentials: 'include'
            })
            const data = checkStatus(res)
            return data.json()
        } catch (err) {
            console.log(`client failed to get ${url}, err: ${err}`)
            throw err
        }
    },
    async getBinary(url) {
        try {
            const res = await fetch(url, {
                method: 'get',
                headers: httpHeaders,
                credentials: 'include',
                dataType: 'binary',
                xhrFields: {
                    responseType: 'blob'
                },
            })
            //const data = checkStatus(res)
            return res.blob()
        } catch (err) {
            console.log(`client failed to get ${url}, err: ${err}`)
            throw err
        }
    },
    async post(url, d) {
        try {
            return fetch(url, {
                method: 'post',
                keepalive: true,
                body: JSON.stringify(d),
                headers: httpHeaders,
                credentials: 'include'
            }).then(res => {
                const data = checkStatus(res)
                return data.json()
            })

        } catch (err) {
            console.log(`client failed to post ${url}, err: ${err}`)
            throw err
        }
    },

    async postFormData(url, d) {
        try {
            const headers = Object.assign({}, httpHeaders)
            delete headers['Content-Type'];

            const formData = new FormData();
            for (let key in d) {
                formData.append(key, d[key]);
            }
            return fetch(url, {
                method: 'post',
                body: formData,
                headers: headers,
                credentials: 'include'
            }).then(res => {
                const data = checkStatus(res)
                return data.json()
            })

        } catch (err) {
            console.log(`client failed to postFormData ${url}, err: ${err}`)
            throw err
        }
    },
    async delete(url) {
        try {
            const res = await fetch(url, {
                method: 'delete',
                headers: httpHeaders,
                credentials: 'include'
            })
            const data = checkStatus(res)
            return data.json()
        } catch (err) {
            console.log(`client failed to delete ${url}, err: ${err}`)
            throw err
        }
    },
    queryParams(params) {
        return Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    },

    resourceUrl(r) {
        const resource = {
            // consoleLogin: '/console/signIn',
            // consoleLogout: '/console/logout',
            // consoleAdminName: '/console/my',
            // consoleChangePwd: '/console/changePwd',
            // consoleSysFunction: '/console/sysFunction',
            // consoleAdminUser: '/console/adminUser',
            // consoleAdminUserRoleList: '/console/adminUser/roles',
            // consoleContestStat: '/console/contest/stat',
            // consoleUserList: '/console/users',
            // consoleUserCheckSchool: '/console/users/:id',
            // consoleContestRoundPostList: '/console/contest/:round/post',
            // consoleContesPostScoring: '/console/contest/post/:id/score',
            // consoleContesPost: '/console/contest/post/:id',

            loginLink: '/login',
            logoutLink: '/logout',
            login: '/console/signIn',
            dcLogin: '/console/dcSignIn',
            myInfo: '/console/my',
            userInfo: '/user/info',
            userMenu: '/console/system/menu',
            userLogout: '/user/logout',
            editAccountLink: '/account',
            refreshUserInfo: '/refresh',
            changeMode: '/changeMode',
            accountLogin: '/console/accountLogin',
            userBasicInfoSetting: '/user/setting/basic',
            emailValidate: '/user/email/validate',
            emailCodeValidate: '/user/email/validate/code',
            userSchoolSetting: '/user/setting/school',
            changeLanguage: '/user/changeLang/:lang',
            getKey: '/user/key',
            changePwd: '/user/pwd',
            bindingCheck: '/user/bindingCheck',
            forgetPWD: '/user/forget/pwd/:validateWay',
            resetPwd: '/user/pwd/reset',
            checkResetPwd: '/user/pwd/reset/:code',
            updateAvatar: '/user/avatar',
            addNotifyToken: '/user/notifyToken',
            removeNotifyToken: '/user/notifyToken/remove',

            checkAccount: '/register/check',
            register: '/register/',
            get3rdOAuthLink: '/oAuth/:platform',
            get3rdOAuthBindLink: '/oAuth/:platform/bind',
            oAuthLogin3rd: '/oAuth/login/:platform',
            bind3rdAccount: '/register/bind',

            getTeam: '/team/:id',
            cancelTeamPlan: '/team/plan/:teamHistoryId/cancel',
            checkTeamMember: '/team/:teamId/teamMemver/check',
            addTeamMember: '/team/:teamId/teamMember',
            changeMemberRole: '/team/:teamId/teamMember/:userId/role',
            removeTeamMember: '/team/:teamId/teamMember/:userId/remove',

            getMyVouchers: '/voucher/my',
            redeemVoucher: '/voucher/redeem',
            useVoucher: '/voucher/use/:id',

            getMyInviteCode: '/my/inviteCode',
            getMyInvitedFriends: '/my/invited',

            getDiaryFunctionPrivilege: '/console/diary/funcPrivilege',
            getTrendData: '/console/diary/homework/:id/statistic/trend',
            getTrendDataPost: '/console/diary/homework/:id/statistic/trend/post',
            getEmotion4Data: '/console/diary/homework/:id/statistic/emotion4',
            getEmotion4DataPost: '/console/diary/homework/:id/statistic/emotion4/post',
            getEmotionsData: '/console/diary/homework/:id/statistic/emotions',
            getEmotionsDataPost: '/console/diary/homework/:id/statistic/emotions/post',
            getBehaviorsData: '/console/diary/homework/:id/statistic/behaviors',
            getBehaviorsDataPost: '/console/diary/homework/:id/statistic/behaviors/post',

            getHomeworkPost: '/console/diary/post/:id',

            createHomework: '/console/diary/homework/:id',
            getHomeworkDetail: '/console/diary/homework/:id/detail',
            getHomeworkName: '/console/diary/homework/:id',
            getHomeworkStudentName: '/console/diary/homework/:id/users',
            getHomeworkPostPagingData: '/console/diary/homework/:id/posts',
            getHomeworkRecentPostUser: '/console/diary/homework/:id/recent',
            getHomewrokInviteCode: '/homework/:diaryHomeworkId/inviteCode',
            checkHomewrokInviteCode: '/homework/:diaryHomeworkId/check/inviteCode',
            checkHomeworkTeacherInviteCode: '/homework/:diaryHomeworkId/check/teacherInviteCode',
            getHomeworkTeacherInvitation: '/homework/:diaryHomeworkId/invitationLetter',
            getHomeworkTeachers: '/homework/:diaryHomeworkId/privilege',
            getBadMoodStudents: '/homework/:diaryHomeworkId/badMood/member',
            acceptHomeworkPrivilege: '/homework/:diaryHomeworkId/privilege/accept',
            rejectHomeworkPrivilege: '/homework/:diaryHomeworkId/privilege/reject',
            deleteHomeworkPrivilege: '/homework/:diaryHomeworkId/privilege/delete',
            checkJoinedHomework: '/console/diary/homework/:diaryHomeworkId/isJoined',
            getDiaryHomeworkUserWithUsage: '/homework/student/active/usage',
            applyInvitationCode: '/drpb/:code',

            getDiaryPostComment: '/console/diary/post/:id/comment',
            createDiaryPostComment: '/console/diary/post/:id/comment',
            updateDiaryPostComment: '/console/diary/post/comment/:id',
            deleteDiaryPostComment: '/console/diary/post/comment/:id',
            // testDiary: '/ajax/diary',

            exportRaw: '/export/raw',
            exportBCard: '/ai/multipleResult',

            allStudentList: '/student/',
            getBasicInfo: '/student/basicInfo',
            uploadStudents: '/student/upload',
            uploadStudentsCheck: '/student/upload/check',
            uploadStudentsSubmit: '/student/upload/submit',
            deleteStudent: '/student/delete',
            students: '/student/list/:id',
            studentCase: '/student/:homeworkId/case/:studentId',
            studentDetail: '/student/info/:id',
            studentPosts: '/student/diaryPostHistory',
            studentCaseList: '/student/:homeworkId/case',
            archiveStudentFromHomework: '/student/:homeworkId/archiveStudent',

            sendBackPosts: '/student/:homeworkId/sendback',

            postCollectionUpdate: '/post/:diaryPostId/collect',
            postCollectionList: '/student/:diaryHomeworkId/collection',
            sendBackDiaryPost: '/post/:diaryPostId/sendback',
            getDiaryPostImage: '/post/:diaryPostId/composeAll',

            getAIStrategy: '/ai/simpleReply/:id',

            getDiaryHomework: '/diary/homework',
            getMyHomeworkList: '/diary/homework/my',
            copyDiaryHomework: '/homework/:diaryHomeworkId/copy',
            archiveDiaryHomework: '/homework/:diaryHomeworkId/archive',
            getJoinedDiaryHomework: '/diary/homework/others',
            joinDiaryHomework: '/diary/homework/join',
            getArchivedDiaryHomework: '/diary/homework/archive',
            getDiaryHomeworkCase: '/diary/homework/case',

            getPublishSampleHomework: '/diary/homework/publishSample',
            getPublishSampleHomeworkWithSample: '/diary/homework/publishSample/resourcesBook',
            getPublishSampleHomeworkResources: '/diary/homework/publishSample/:diaryHomeworkId/resources',
            takePublishSampleHomeworkPlan: '/diary/homework/publishSample/:id/takePlan',
            getHomeworkResource: '/homework/:diaryHomeworkId/resources',

            getSampleQuest: '/diary/:diaryHomeworkId/sampleQuest',
            getSampleQuestDetail: '/diary/:diaryHomeworkId/sampleQuest/:questId',
            getQusetDiary: '/diary/questSample',

            takeCourseBook: '/homework/takeCourseBook',

            getPostRevise: '/post/:diaryPostId/revise',
            editPostRevise: '/post/:diaryPostId/revise/:commentId',
            getPostReviseLibrary: '/post/revise/library',
            editReviseLibraryComment: '/post/revise/library/:id',

            // getDiaryQuestList: '/homeworkQuest/:diaryHomeworkId/questList',
            getHomeworkQuestDetail: '/homework/:id/quest/:qid/',
            getQuestPostPaging: '/homeworkQuest/:diaryHomeworkId/quest/:questId/posts',
            getQusetSelection: '/homeworkQuest/:diaryHomeworkId/questSelection',
            getQuestInfo: '/homework/:id/quest/info',

            takeContestBook: '/contest/takeHomework',
            getContestHomeworkInfo: '/contest/:id/info',
            contestInviteCoderVerify: '/contest/inviteCodeVerify',
            checkHomeworkContest: '/diary/:diaryHomeworkId/contest',
            getTeamRanking: '/contest/:id/ranking',
            getStudentRanking: '/contest/:id/studentRanking',
            getTeamInfo: '/contest/team/:id/info',

            getNotificationIndex: '/wn/',
            notificationRead: '/wn/notification/:id',
            getUnreadNotification: '/wn/unnotify',
            clearNotification: '/wn/clear',

            getMyOwnChargePlanList: '/ucp',
            soldUserChargePlan: '/ucp/:id/sold',
            releaseUserChargePlan: '/ucp/:id/release',
            getUnsoldUserChargePlanCount: '/ucp/unsold',
            userChargePlanSetNewCode: '/ucp/:id/code',
            userChargePlanAddComment: '/ucp/:id/comment',
            renewUserChargePlan: '/ucp/:id/renew',
            getHomeworkNonUseUserChargePlanCount: '/ucp/unuse/homeworkPlan',

            getEBook: '/book/homework/:diaryHomeworkId/user/:userId',
            getStudentEBook: '/book/diary/:diaryId',
            getEBookPageList: '/book/:diaryPrintId/pages',
            getEBookPageTemplate: '/book/template/:diaryPrintTemplateId',
            saveEBookPageTemplate: '/book/template/:diaryPrintTemplateId',
            saveEBookPageTemplateImage: '/book/template/:diaryPrintId/image',
            getEBookTemplateList: '/book/template',
            getEBookPDF: '/book/:diaryPrintId/ebook',
            generateEBookPDF: '/book/:diaryPrintId/generate',
            loginLinkEBook: '/loginLink/ebook',
            eBookUserInfo: '/ebookUser/info',
            getBookList: '/book/',
            getEBookDiaryPosts: '/book/:diaryPrintId/posts',
            getEBookSelectedPosts: '/book/:diaryPrintId/pages',
            getEBookPostDetail: '/book/post/:id',
            updateEBookPosts: '/book/:diaryPrintId',
            addEBookPage: '/book/:diaryPrintId/page',
            getEBookBasicInfo: '/book/:diaryPrintId/basicInfo',
            saveEBookBasicInfo: '/book/:diaryPrintId/basicInfo',
            getEBookLink: '/book/:diaryPrintId/link',

        }
        
        let domainName = process.env.VUE_APP_API_URL;
        if (location.origin.indexOf('ebook.mangachat') != -1) {
            domainName = process.env.VUE_APP_EBOOK_API_URL
        }
        
        let url = domainName ? `${domainName}${resource[r]}` : resource[r]
        return url
    }
}