<template>
    <v-container fluid class="ma-0 pa-0 d-flex flex-column" style="min-height: 100vh;">
        <div class="black py-4 text-center">
            <span class="white--text text-h4 text-center mx-auto">{{ $t('info_setting.title') }}</span>
        </div>
        <div class="flex-grow-1 align-content-center justify-center">
            <div class="mx-auto" style="width: 600px;">
                <p class="text-h6">{{ $t('info_setting.email_verify.title') }}</p>
                <v-form ref="form">
                    <v-text-field outlined dense class="mt-3"
                        :placeholder="$t('info_setting.email_verify.placeholder')"
                        v-model="email" :rules="[rules.required, rules.email]"
                        :error-messages="errorMessage"
                        :success="mailSent"
                        :success-messages="mailSent ? $t('info_setting.email_verify.sent') : ''"></v-text-field>
                </v-form>
                <v-form ref="code">
                    <v-text-field v-if="mailSent" outlined dense class="mt-3"
                        :placeholder="$t('info_setting.email_verify_code.placeholder')"
                        v-model="code" :rules="[rules.required]"
                        :error-messages="codeErrorMessage"></v-text-field>
                </v-form>
                <p class="text-body-2 text-right">{{ $t('info_setting.email_verify.details') }}</p>

            </div>
        </div>
        <div v-if="mailSent" class="d-flex justify-end grey lighten-2 py-4 ">
            <v-spacer />
            <v-btn rounded class="black--text mr-4 normal-case" @click=sendMail>
                {{ $t('info_setting.email_verify.resend') }}</v-btn>
            <v-btn rounded class="black--text mr-4 normal-case" @click=validation>
                {{ $t('info_setting.email_verify.validate') }}</v-btn>
        </div>
        <div v-else class="d-flex justify-end grey lighten-2 py-4">
            <v-spacer />
            <v-btn rounded class="black--text mr-4 normal-case" @click=sendMail>
                {{ $t('info_setting.email_verify.send') }}</v-btn>
        </div>

    </v-container>
</template>

<script>
import { registerServices } from '@/services/register';
import { removeItem } from '@/utils/storageManager';

export default {
    name: "BasicInfoSetting",
    data() {
        return {
            email: '',
            rules: {
                required: value => !!value || this.$t('post_revise.comment_required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('info_setting.email_verify.invalid_email')
                },

            },
            errorMessage: '',
            mailSent: false,
            code: '',
            codeErrorMessage: '',
        };
    },
    created() {

    },
    methods: {
        async validation() {
            if (this.$refs.code.validate()) {
                const payload ={
                    email: this.email,
                    code: this.code,
                }
                const codeResult = await registerServices.emailCodeValidate(payload);
                if (codeResult.data.error === '-2') {
                    this.codeErrorMessage = this.$t('info_setting.email_verify.code_not_valid');
                } else if (codeResult.data.error === '-1') {
                    this.errorMessage = this.$t('info_setting.email_verify.wrong_email');
                } else {
                    /// go to name setting
                    removeItem('emailVerified');
                    this.$router.push('/setting/basicInfo');
                }
            }
        },
        async sendMail() {
            this.errorMessage = '';
            if (this.$refs.form.validate()) {
                const payload = {
                    email: this.email,
                };
                const mailResult = await registerServices.emailValidate(payload);
                if (mailResult.data.returnCode === '-1') {
                    this.errorMessage = this.$t('info_setting.email_verify.duplicated');
                } else if (mailResult.data.returnCode === '-2') {
                    this.errorMessage = this.$t('info_setting.email_verify.wrong_email');
                }else {
                    this.mailSent = true;
                }
            }
        },
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    },
}
</script>

<style scoped>
.fix-height-container {
    height: calc(100vh - 500px);
    margin-top: calc((100vh - 480px) / 2);
}

.fix-height-container span {
    vertical-align: middle;
}
</style>