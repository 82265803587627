<template>
    <v-dialog v-model="dialog" width="500px">
        <v-sheet color="grey lighten-3" class="text-right">

            <v-btn icon @click="dialog = false"
                class="normal-case">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-sheet>
        <Post v-bind:postItem="openedPostItem" v-bind:commentList="commentList" v-bind:homeworkId="homeworkId"
            v-bind:key="postId" />
    </v-dialog>
</template>

<script>
import Post from '@/components/console/diary/component/Post.vue'
import { diaryPostServices } from '@/services/diaryPost.js';
export default {
    name: "postDialog",
    components: { Post },
    props: ['postId', 'homeworkId', 'postDialogOpenCount'],
    data() {
        return {
            dialog: false,
            openedPostItem: {},
            commentList: [],
        };
    },
    async created() {
        this.getDiaryPost();
    },
    watch: {
        async postDialogOpenCount() {
            this.getDiaryPost();
        },
    },
    mounted() {

    },
    methods: {
        async getDiaryPost() {
            if (this.postId == this.openedPostItem.diary_post_id) {
                this.dialog = true;
                return;
            }
            const postRes = await diaryPostServices.getHomeworkPost(this.postId);
            this.openedPostItem = postRes.data.post;
            this.commentList = postRes.data.comment;
            this.dialog = true;
        }
    },
};
</script>
<style scoped></style>

