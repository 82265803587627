<template>
    <v-container>
        <v-row style="height: 20vh;"></v-row>
        <v-row justify="center" align="center">
            <v-img :src="loadingImage" contain style="height: 40vh;"></v-img>
        </v-row>
        <v-row justify="center" class="mt-10">
            <v-progress-linear :color="loadingColor" indeterminate height="15"
                style="border-radius: 10px; width: 75vw;"></v-progress-linear>
        </v-row>
    </v-container>
</template>

<script>
import { ebookLoginServices } from "@/services/ebookLogin.js";
import { setItem } from '@/utils/storageManager';

export default {
    name: "loginRedirect",
    components: {},
    data() {
        return {
            code: '',
            overlay: true,
            isConsoleUser: false,
            loadingColor: '#F3C250',
            loadingImage: require(`@/assets/ebook/loading_studio.svg`)
        };
    },
    async created() {
        this.code = this.$route.query.code;
        console.log('code in bookredirect: ', this.code)
        console.log('this.$route.query in bookredirect: ', this.$route.query)
        if (this.$route.query.console == 1) {
            this.isConsoleUser = true;
            this.loadingColor = 'amber darken-3';
            this.loadingImage = require(`@/assets/ebook/loading_console.svg`)
        }
        await this.getUserInfo();
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if(facebookChat)
            facebookChat.style.display = "none";
    },
    methods: {
        async getUserInfo() {
            const data = {
                code: this.code,
                web: this.isConsoleUser
            }
            const res = await ebookLoginServices.getUserInfo(data);
            if (res.success) {
                setItem("ebookUserName", res.user.name);
                setItem('locale', res.user.locale);
                const toRedirectPath = sessionStorage.getItem('redirect_path');
                if (!toRedirectPath) {
                    sessionStorage.setItem('redirect_path', '/ebook');
                }
                sessionStorage.setItem('locale', res.user.locale);
                location.href = `/ebook/login`;
            }
        }
    },
};
</script>

<style scoped>
.vh-100 {
    height: 100vh;
}
</style>