<template>
    <v-container fluid pa-0>
        <div class="d-flex book-procedure">
            <v-btn small text class="pl-0 basic-text normal-case" @click="goBack()">
                <v-icon class="mr-2">mdi-arrow-left-thick</v-icon>
                {{ $t('post_revise.back') }}
            </v-btn>
            <div class="ml-auto">
                <v-btn small outlined class="mr-2 rounded-pill normal-case" :disabled="!isEdit" :loading="loading"
                    @click="reload()">{{ $t('button.cancel') }}</v-btn>
                <v-btn small depressed color="deep-orange darken-1" class="rounded-pill white--text normal-case"
                    :disabled="!isEdit" :loading="loading" @click="savePage()">{{ $t('button.update') }}</v-btn>
            </div>
        </div>
        <div class="d-flex">
            <v-card class="show-area" flat tile>
                <div class="overflow-auto">
                    <div class="canva">
                        <div class="canva-outer" @click="elementEdit(null, -1)"></div>
                        <div ref="canvaContent" class="canva-content">
                            <div v-for="(item, index) in canvaTemplate.items" :key="item.id"
                                :class="checkContentElementClass(item.id, item.is_editable)" :style="item.style"
                                @click="elementEdit(item, index)">{{ item.text }}</div>

                        </div>
                    </div>
                </div>
                <div class="post-list">
                    <div class="post-list-element mx-2" v-for="post in postList" :key="post.id"
                        :class="editingPageId == post.id ? 'selected' : ''" @click="getPageTemplate(post.id)">
                        <v-img lazy-src="https://picsum.photos/id/40/10/6" :src="post.thumbnailUrl" class="border"></v-img>
                        <span>{{ getPostTypeName(post.type) }}</span>
                    </div>
                </div>
                <div class="zoom-function">
                    <div class="d-flex">
                        <v-slider
                            hide-details
                            hint="zoom"
                            max="15"
                            min="5"
                            class="ml-auto"
                            style="max-width: 150px"
                            v-model="displayZoom"></v-slider>
                        <span>{{ displayZoom * 10 }}%</span>
                    </div>
                </div>
            </v-card>
            <v-card flat tile class="elem-options">
                <div class="px-3 my-3">{{ $t('book.content.format_setting') }}</div>
                <div class="nav nav-tabs">
                    <div v-for="item in items" :key="item.icon" class="nav-item" v-show="item.type == editElem.type"
                        :class="item.type == editElem.type ? 'selected' : ''">
                        <v-icon>mdi-{{ item.icon }}</v-icon>
                    </div>
                </div>
                <div>
                    <v-list v-show="editElem.type == 'template'" class="tab-content">
                        <v-list-group :value="true">
                            <template v-slot:activator>
                                <v-list-item-title>{{ $t('book.content.format_layout') }}</v-list-item-title>
                            </template>
                            <div class="template-list">
                                <v-list-item two-line v-for="templateItem in templateList" :key="templateItem.id"
                                    @click="changeTemplate(templateItem.id)">
                                    <v-list-item-content class="pb-2">
                                        <v-img :src="templateItem.thumbnailUrl"></v-img>
                                        <v-list-item-subtitle class="mt-1">{{ templateItem.name }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </div>

                        </v-list-group>
                    </v-list>
                    <v-list v-show="editElem.type == 'text'" class="tab-content">
                        <v-list-group :value="true">
                            <template v-slot:activator>
                                <v-list-item-title>{{ $t('book.content.format_text') }}</v-list-item-title>
                            </template>

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ $t('book.content.format_text_content')
                                    }}</v-list-item-subtitle>
                                    <v-textarea v-model="editText" :label="$t('book.content.format_text_content')"
                                        class="mt-1" background-color="white"
                                        single-line outlined dense></v-textarea>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>
                    </v-list>
                    <v-list v-show="editElem.type == 'pic'" class="tab-content">
                        <v-list-group :value="true">
                            <template v-slot:activator>
                                <v-list-item-title>{{ $t('book.content.format_image') }}</v-list-item-title>
                            </template>

                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle>{{ $t('book.content.format_image_file') }}</v-list-item-subtitle>
                                    <v-btn small depressed color="secondary" class="mt-1 normal-case"
                                        @click="openEditRoleThumbnailDialog">
                                        {{ $t('book.content.format_upload') }}</v-btn>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>
                    </v-list>
                </div>
            </v-card>
        </div>
        <v-dialog v-model="editThumbnailDialog" max-width="300px" persistent>
            <v-card dense>
                <v-card-title>
                    <span class="text-h5">{{ $t('book.content.format_edit_photo') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form ref="thumbnailForm" lazy-validation>
                            <v-img class="book-img-block" :src="thumbnail.imgUrl"
                                v-if="thumbnail.imgUrl && !editedThumbnail.thumbnailFiles"></v-img>
                            <div class="book-img-block mx-auto" v-if="editedThumbnail.thumbnailFiles"
                                :style="`width: ${thumbnailOption.autoCropWidth}px; height: ${thumbnailOption.autoCropHeight}px`">
                                <div ref="thumbnailBlock">
                                    <vueCropper ref="thumbnailCropper" :img="thumbnailOption.img"
                                        :mode="option.mode" :canScale="option.canScale" :output-size="option.size"
                                        :output-type="option.outputType" :info="false" :full="option.full"
                                        :fixed="option.fixed" :fixed-number="thumbnailOption.fixedNumber"
                                        :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                        :fixed-box="option.fixedBox" :original="option.original"
                                        :auto-crop="option.autoCrop"
                                        :auto-crop-width="thumbnailOption.autoCropWidth"
                                        :auto-crop-height="thumbnailOption.autoCropHeight"
                                        :center-box="option.centerBox">
                                    </vueCropper>
                                </div>
                                <!--
                                <v-slider v-model="scale" :max="20" :min="0" step="1" hide-details class="align-center"
                                    @input="scaleImg()"></v-slider>
                                -->
                            </div>
                            <v-file-input v-model="editedThumbnail.thumbnailFiles" :label="$t('book.content.format_upload')"
                                accept="image/png, image/jpeg" prepend-icon="mdi-camera"
                                @change="$uploadImage(editedThumbnail.thumbnailFiles, thumbnailOption)"></v-file-input>

                        </v-form>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="editThumbnailDialog = false" :disabled="buttonLoading" class="normal-case">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="light-green darken-2 white--text" @click="saveImage" :loading="buttonLoading"
                        :disabled="buttonLoading" class="normal-case">
                        {{ $t('button.upload') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>

import { VueCropper } from 'vue-cropper';
import { ebookServices } from "@/services/ebook.js";

export default {
    name: 'pageLayoutEdit',
    components: {
        VueCropper
    },
    data() {
        return {
            loading: false,
            buttonLoading: false,
            displayZoom: 10,
            isEdit: false,
            editingPageId: '',
            diaryPrintId: '',
            option: this.$cropperOption,
            editThumbnailDialog: false,
            thumbnail: {
                editOptionFab: false,
                imgUrl: '',
            },
            editedThumbnail: {
                imgUrl: '',
                thumbnailFiles: '',
                displayHeight: 0,
                displayWidth: 0,
            },
            thumbnailOption: {
                img: '',
                fixedNumber: [1, 1],
                autoCropHeight: 200,
                autoCropWidth: 200,
            },

            canvaTemplate: {},
            templateList: [],
            postList: [],
            editElem: {
                index: '',
                type: 'template',
            },
            editText: '',
            tab: null,
            items: [{
                icon: 'keyboard',
                type: 'text',
            }, {
                icon: 'newspaper-variant',
                type: 'template',
            }
            ],
        }
    },
    watch: {
        editText(val) {
            const $elem = this.canvaTemplate.items[this.editElem.index];
            if ($elem.text != val) {
                this.isEdit = true;
            }
            this.canvaTemplate.items[this.editElem.index].text = val;
        },
        displayZoom(val) {
            this.$refs.canvaContent.style.transform = 'translate(-50%, -50%) scale(' + (val * 10) + '%)';
        }
    },
    async created() {
        this.diaryPrintId = this.$route.params.diaryPrintId;

        const postRes = await ebookServices.getEBookPageList(this.diaryPrintId, {});
        postRes.data.forEach(page => {
            this.postList.push({
                "id": page.id,
                "postId": page.post_id,
                "thumbnailUrl": `${page.thumbnail_url}?${this._uuid()}`,
                "type": page.type,
            })
        });

        let pageId = this.$route.query.qpage_id;
        if (pageId == null) {
            pageId = this.postList[0].id;
        }
        await this.getPageTemplate(pageId);

    },
    methods: {
        getPostTypeName(type) {
            if (type == 1) {
                return this.$t('book.content.cover');
            } else if (type == 3) {
                return this.$t('book.content.author');
            } else if (type == 4) {
                return this.$t('book.content.epilogue');
            } else {
                return this.$t('book.basic_info.pages');
            }
        },
        checkContentElementClass(elementId, editable) {
            let pageClass = '';

            if (this.editElem.id == elementId) {
                pageClass += ' selected';
            }
            if (editable) {
                pageClass += ' editable';
            }
            return pageClass;
        },
        goBack() {
            if (this.isEdit) {
                if (!confirm(this.$t('book.content.exit_msg'))) {
                    return;
                }
                this.$router.go(-1)
            } else {
                this.$router.go(-1)
            }
        },
        async reload() {
            const currentPageId = this.editingPageId;
            this.editingPageId = '';
            await this.getPageTemplate(currentPageId);
        },
        async getPageTemplate(pageId) {
            if (this.editingPageId == pageId) {
                return;
            }
            if (this.isEdit) {
                if (!confirm(this.$t('book.content.exit_msg'))) {
                    return;
                }
            }
            this.loading = true;

            const templateRes = await ebookServices.getEBookPageTemplate(pageId, {});

            this.canvaTemplate = templateRes.data;

            let postBlockNum = 0;
            this.canvaTemplate.items.forEach(item => {
                item.style = this.getPageElementStyle(item);

                if (item['fill_data'] == 'story') {
                    postBlockNum++;
                }
            });
            let page = this.postList.find(post => post.id == pageId);

            //要看看這個類型的template有沒有讀取過
            let needLoadTemplateList = true;
            let oldPage = null;
            if (this.editingPageId) {
                oldPage = this.postList.find(post => post.id == this.editingPageId);
            }

            if (oldPage != null && oldPage.type == page.type) {
                needLoadTemplateList = false;
            }

            this.editingPageId = pageId;

            if (needLoadTemplateList) {
                await this.getTemplateListByType(page.type, postBlockNum);
            }

            this.isEdit = false;
            this.initialOption();

            this.loading = false;

            let lastPageId = this.$route.query.qpage_id;
            if (lastPageId != pageId) {
                window.history.replaceState({}, "", "?qpage_id=" + pageId);
            }

        },
        async getTemplateListByType(type, postBlockNum) {
            const templateRes = await ebookServices.getEBookTemplateList({
                type: type,
                post_block_num: postBlockNum
            });
            this.templateList.length = 0;
            templateRes.data.forEach(template => {
                this.templateList.push({
                    "id": template.id,
                    "name": template.name,
                    "thumbnailUrl": template.thumbnail_url,
                })
            });

        },
        getPageElementStyle(elem, index) {
            const templateWidth = this.canvaTemplate.width;
            const canvasRatio = 600 / templateWidth;

            let style = '';
            style += 'top: ' + Math.round(elem['y'] * canvasRatio) + 'px;';
            style += 'left: ' + Math.round(elem['x'] * canvasRatio) + 'px;';
            if (elem['type'] == 'text' && elem['text_align'] == null) {
                style += 'max-width: ' + Math.round(elem['width'] * canvasRatio) + 'px;';
            } else {
                //realWidth = elem['width'];
                style += 'width: ' + Math.round(elem['width'] * canvasRatio) + 'px;';
            }

            if (elem['text_align'] != null) {
                style += 'text-align: ' + (elem['text_align'] == 'c' ? 'center' : 'right') + ';';
            }

            if (elem['height'] != null) {
                //realHeight = elem['height'];
                style += 'height: ' + Math.round(elem['height'] * canvasRatio) + 'px;';
            }

            style += 'z-index: ' + (elem.z_index ? elem.z_index + 2 : (index + 2)) + ';';

            if (elem['background_color'] != null) {
                style += 'background-color:' + elem['background_color'] + ';';
            }
            if (elem['img_url'] != null) {
                let imgUrl = elem['img_url'];
                if (imgUrl.indexOf('data:') != 0) {
                    imgUrl += '?' + this._uuid();
                }
                style += 'background-image: url(' + imgUrl + '); background-size: cover;background-position: center;';
            }
            if (elem['color'] != null) {
                style += 'color:' + elem['color'] + ';';
            }
            if (elem['shape'] == 'circle') {
                style += 'border-radius:50%;';
            }


            if (elem['font_size'] != null) {
                style += 'font-size:' + Math.round(elem['font_size'] * canvasRatio) + 'px;';
            }
            if (elem['font_family'] != null) {
                style += 'font-family:' + elem['font_family'] + ';';
            }
            return style;
        },
        elementEdit($elem, index) {
            if (this.editElem.index != '') {
                this.editElem.index = '';
                this.editElem.type = '';
                this.editElem.id = '';
            }
            if ($elem == null) {
                this.editElem.index = '';
                this.editElem.type = 'template';
                return;
            }

            let type = $elem.type;
            let isEditable = $elem.is_editable;

            if (isEditable == true) {
                this.editElem.id = $elem.id;
                if (type == 'text') {
                    this.editElem.index = index;
                    this.editText = $elem.text;
                    this.editElem.type = 'text';
                } else if (type == 'pic') {
                    this.editElem.index = index;
                    this.editText = '';
                    this.editElem.type = 'pic';
                }
                // $('.elem-options .nav-item').addClass('d-none');
                // if (isMovable) {
                //     $('[href="#sizePosTab"]').parent().removeClass('d-none');
                // }

                // let properties = propertyMap[type];

                // if (properties != null) {
                //     for (let i = 0; i < properties.length; i++) {
                //         $('[href="#'+properties[i]+'Tab"]').parent().removeClass('d-none');
                //     }
                // }

                // $('.elem-options').removeClass('d-none');

                // //$('[data-toggle="collapse"').collapse('hide');

                // $('.elem-options .nav-item:not(.d-none)').first().find('.nav-link').tab('show');
            } else {
                // $('#canvasOuter').click();
                this.initialOption();
            }

        },
        async savePage() {
            this.loading = true;

            let saveCanvaTemplate = JSON.parse(JSON.stringify(this.canvaTemplate));
            saveCanvaTemplate.items.forEach(item => {
                item.style = null;
            });

            const resultRes = await ebookServices.updateEBookTemplate(
                this.editingPageId, {
                template: JSON.stringify(saveCanvaTemplate)
            }
            )

            let page = this.postList.find(post => post.id == this.editingPageId);
            page.thumbnailUrl = resultRes.data.thumbnail_url + '?' + this._uuid();

            this.editingPageId = null;
            this.isEdit = false;

            this.getPageTemplate(page.id);
            this.loading = false;
        },
        initialOption() {
            this.editElem.type = 'template';
        },
        async changeTemplate(templateId) {
            this.loading = true;

            this.isEdit = true;

            const templateRes = await ebookServices.getEBookPageTemplate(this.editingPageId, {
                template_id: templateId
            });

            this.canvaTemplate = templateRes.data;

            this.canvaTemplate.items.forEach(item => {
                item.style = this.getPageElementStyle(item)
            });

            this.loading = false;

        },
        openEditRoleThumbnailDialog() {
            const $elem = this.canvaTemplate.items[this.editElem.index];
            const ratio = $elem.height / $elem.width;
            this.thumbnailOption.autoCropHeight = this.thumbnailOption.autoCropWidth * ratio;


            this.thumbnailOption.fixedNumber = [this.thumbnailOption.autoCropWidth, this.thumbnailOption.autoCropHeight];
            this.editedThumbnail.thumbnailFiles = null;
            this.editThumbnailDialog = true;

            //this.$refs.thumbnailCropper.refresh();
        },
        reloadCropper() {
            this.$refs.thumbnailCropper.refresh();
        },
        saveImage() {
            //Get the cropped image data and use artistServices to update the thumbnail
            if (this.$refs.thumbnailForm.validate()) {
                // Promise.all([this.$getCropperImg(this.$refs.thumbnailCropper)])
                this.buttonLoading = true;
                Promise.all([this.$getCropperData(this.$refs.thumbnailCropper)])
                    .then(async result => {
                        if (result[0].status == 1) {

                            const imageRes = await ebookServices.saveEBookTemplateImage(
                                this.diaryPrintId,
                                {
                                    id: this._uuid(),
                                    file: result[0].data
                                }
                            );
                            const $elem = this.canvaTemplate.items[this.editElem.index];

                            $elem.img_url = imageRes.data.url;

                            $elem.style = this.getPageElementStyle($elem, this.editElem.index)
                            this.isEdit = true;
                            this.buttonLoading = false;
                            this.editThumbnailDialog = false;
                        }
                    });
            }
            // this.editThumbnailDialog = false;
        },
        _uuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8; return v.toString(16); });
        }
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    }
}
</script>
<style scoped>
.post-list {
    height: 150px;
    border-top: 1px solid #ddd;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
    padding: 10px 15px;
    background-color: #fafafa;
}

.post-list-element {
    height: 100%;
    text-align: center;
    display: inline-block;
}

.post-list-element>div {
    width: 145px;
}

.post-list-element.selected>div {
    border: 2px solid #de724b;
}

.zoom-function {
    height: 35px;
    line-height: 35px;
    border-top: 1px solid #ddd;
    text-align: right;
    padding: 0 10px;
    width: 100%;
    background-color: #fafafa;
}

.elem-options {
    width: 250px;
    border-left: 1px solid #c0bfbf;
    z-index: 3;
    background-color: #fafafa;
}

.canva {
    height: calc(100vh - 288px);
    min-height: 470px;
    position: relative;
}

.canva-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #fafafa;
}

.show-area {
    /* flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; */
    width: calc(100% - 250px);
}

.canva-content {
    height: 424px;
    transform: translate(-50%, -50%) scale(0.9);
    position: relative;
    background-color: #fff;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 2;
}

.canva-content>* {
    position: absolute;
}

.canva-content>div.editable {
    cursor: pointer;
}

.canva-content>div.selected::before {
    border: 3px dashed #ddd;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: .5rem;
    padding-right: .5rem;
    margin-bottom: 0;
    margin-top: .5rem;
    list-style: none;
}

.nav-tabs>.nav-item {
    margin-bottom: -1px;
    text-align: center;
    padding: 0.5rem 1rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs>.nav-item.selected {
    color: #495057;
    background-color: #ebebeb;
    border-color: #dee2e6 #dee2e6 #ebebeb;
}

.tab-content {
    padding-top: 7px;
    background-color: #ebebeb;
}

::v-deep .v-list .v-list-item--active {
    background-color: #d9d8d8;
    color: #444;
}

.template-list {
    overflow-y: auto;
    max-height: 70vh;
    padding: 10px;
    text-align: center;
}

@media (max-width: 575.9px) {

    .canva-content {
        width: 600px;
        max-height: 350px;
    }
}

@media (min-width: 576px) and (max-width: 767.9px) {

    .canva-content {
        width: 600px;
        max-height: 450px;
    }
}

@media (min-width: 768px) {
    .canva-content {
        width: 600px;
        max-height: 450px;
    }
}

@media (min-width: 1100px) {
    .canva-content {
        width: 600px;
        max-height: 500px;
    }
}

@media (max-width: 767.9px) {
    .show-area {
        width: 100%;
    }

    .elem-options {
        display: block;
        position: absolute;
        right: 0px;
        min-height: 100%;
    }
}


.book-img-block {
    width: 60px;
}

.book-img-block>div {
    width: 100%;
    height: 100%;
}

@media (min-width: 600px) {
    .book-img-block {
        width: 100px;
    }
}

@media (min-width: 960px) {
    .book-img-block {
        width: 110px;
    }
}

@media (min-width: 1264px) {

    .book-img-block {
        width: 150px;
    }
}
</style>
