<template>
    <v-app-bar id="app-bar" app
        elevate-on-scroll :color="bgColor" flat clipped-left class="text-white">

        <v-toolbar-title>
            <span class="white--text text-h5 pa-0 ma-0 normal-case">MangaChat</span>

        </v-toolbar-title>
        <v-chip outlined small color="white" class="ml-2">E-Book</v-chip>
        <v-spacer />

        <v-menu
            bottom
            left
            offset-y
            origin="top right"
            transition="scale-transition">
            <template v-slot:activator="{ attrs, on }">
                <v-btn
                    class="ml-2 white--text normal-case"
                    min-width="0"
                    text
                    v-bind="attrs"
                    v-on="on">
                    <v-icon>mdi-account</v-icon> <span class="d-done d-md-flex">{{ userName }}</span>
                </v-btn>
            </template>
            <v-list :tile="false" nav dense class="text-center">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            <v-btn class="normal-case" block text href="/ebook/logout">{{ $t('app_bar.logout') }}</v-btn>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
import { getItem } from '@/utils/storageManager';
export default {
    name: "AppBar",
    components: {},
    props: ['showMenu'],

    data: () => ({
        userName: getItem("ebookUserName"),
        bgColor: "grey darken-3",
    }),
    async created() {
        this.setColor();
    },
    computed: {
    },
    methods: {
        setColor() {
            const consoleUser = getItem("userName");
            if (!consoleUser) {
                this.bgColor = '#F3C250';
            } else {
                this.bgColor = 'grey darken-3';
            }
        }
    },
};
</script>

<style scoped></style>