<template>
    <v-container fluid>
        <v-row class="mx-1 mb-2">
            <div class="text-h6">{{ $t('ebook_list.export') }}</div>
            <v-spacer></v-spacer>
            <v-btn icon dark color="deep-orange darken-2">
                <v-icon dark large>mdi-plus-circle</v-icon>
            </v-btn>
        </v-row>
        <v-card outlined>
            <v-card-title>
                <v-text-field v-model="searchAuthorName" :label="$t('ebook_list.author')" single-line
                    append-icon="mdi-magnify"
                    @click:append="searchAuthor"
                    clearable @click:clear="clearSearchAuthorName"
                    hide-details></v-text-field>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-subheader>{{ formatBooks() }}</v-subheader>
            <v-data-table
                class="mx-4"
                :headers="headers"
                :items="books"
                :server-items-length="totalBooks"
                :loading="loading"
                :options.sync="options"
                :items-per-page="10"
                :page.sync="currentPage"
                hide-default-footer>
                <template v-slot:item.actions="{ item }">
                    <v-btn small class="mr-2 mt-1 normal-case" outlined
                        @click="viewDetail(item.id)">
                        <v-icon small class="mr-1"> mdi-eye </v-icon>
                        <!-- {{ $t('button.detail') }} -->
                        {{ $t('ebook_list.view') }}
                    </v-btn>
                </template>
            </v-data-table>
            <div class="text-center my-4">
                <v-pagination v-model="currentPage" :length="bookPageCount"
                    :total-visible="11" circle color="orange accent-2">
                </v-pagination>
            </div>
        </v-card>
        <v-dialog v-model="chooseDiaryDialog" width="600" persistent>
            <v-card class="pb-2">
                <v-card-title>{{ $t('ebook_list.title') }}
                    <v-spacer></v-spacer>
                    <v-btn icon medium @click="chooseDiaryDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="text-body-1">{{ $t('ebook_list.journals') }}</v-card-subtitle>
                <v-card>

                </v-card>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { bookServices } from '@/services/book.js';

export default {
    data() {
        return {
            books: [],
            totalBooks: 0,
            loading: false,
            options: {},
            headers: [
                {
                    text: this.$t('ebook_list.book'),
                    value: 'name',
                    sortable: false,
                    align: 'left',
                    class: 'grey lighten-4'
                },
                {
                    text: this.$t('ebook_list.author'),
                    value: 'author_name',
                    sortable: false,
                    align: 'left',
                    class: 'grey lighten-4'
                },
                {
                    text: this.$t('ebook_list.type'),
                    value: 'type',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4'
                },
                {
                    text: this.$t('ebook_list.last_edit_time'),
                    value: 'update_date',
                    sortable: false,
                    align: 'left',
                    class: 'grey lighten-4'
                },
                {
                    text: this.$t('ebook_list.last_export_time'),
                    value: 'last_generate_date',
                    sortable: false,
                    align: 'left',
                    class: 'grey lighten-4'
                },
                {
                    text: this.$t('ebook_list.function'),
                    value: 'actions',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4'
                },
            ],
            currentPage: 1,
            searchAuthorName: '',
            bookPageCount: 0,
            chooseDiaryDialog: false,
        };
    },
    async created() {

    },
    methods: {
        getBookData() {
            this.loading = true;
            this.getBookList().then((res) => {
                this.books = res.items;
                this.totalBooks = res.total;
                this.bookPageCount = res.pageCount;
                this.currentPage = res.page;
                this.loading = false;
            });
        },
        async getBookList() {
            const { page } = this.options;
            const query = {
                page: page,
                author_name: this.searchAuthorName,
            };
            const res = await bookServices.getBookList(query);
            console.log('res', res);
            if (res.data.list) {
                return {
                    items: res.data.list,
                    total: res.data.total,
                    pageCount: res.data.total_page,
                    page
                };
            } else {
                return {
                    items: [],
                    total: 0,
                    pageCount: 0,
                    page
                };
            }
        },
        searchAuthor() {
            this.options.page = 1;
            this.getBookData();
        },
        clearSearchAuthorName() {
            this.searchAuthorName = '';
            this.getBookData();
        },
        formatBooks() {
            return this.$t('ebook_list.counts')
                .replace('{count}', this.totalBooks);
        },
        viewDetail(id) {
            console.log('viewDetail with id:', id);
        },
    },
    watch: {
        options: {
            handler() {
                this.getBookData();
            },
            deep: true
        },
    },
};
</script>

<style scoped></style>
