<template>
    <div>
        <Steps />
        <v-container class="mt-4">
            <v-form ref="basicInfoForm" lazy-validation>
                <div>
                    <div class="text-h6">{{ $t('book.basic_info.title') }}</div>
                    <v-card class="pa-4" outlined>
                        <div>
                            <label>{{ $t('book.basic_info.book_name') }}</label>
                            <v-text-field v-model="bookName.value" :rules="bookName.rules" class="mt-2" :label="$t('book.basic_info.book_name')" single-line outlined dense></v-text-field>
                        </div>
                        <div class="mt-3">
                            <label> {{ $t('book.basic_info.content_title') }}</label>
                            <div>
                                <v-checkbox v-model="defaultDisplay" class="d-inline-block mr-2" selected :label="$t('book.basic_info.front_cover')"
                                    readonly single-line dense hide-details></v-checkbox>
                                <v-checkbox v-model="defaultDisplay" class="d-inline-block mr-2" :label="$t('book.basic_info.back_cover')" readonly
                                    single-line dense hide-details></v-checkbox>
                                <v-checkbox v-model="defaultDisplay" class="d-inline-block mr-2" :label="$t('book.basic_info.pages')" readonly
                                    single-line dense hide-details></v-checkbox>
                                <v-checkbox v-model="aboutAuthor.display" class="d-inline-block mr-2" :label="$t('book.basic_info.author_intro')"
                                    single-line dense hide-details></v-checkbox>
                                <v-checkbox v-model="thanks.display" class="d-inline-block mr-2" :label="$t('book.basic_info.acknowledgement')" single-line
                                    dense hide-details></v-checkbox>
                                <v-checkbox v-model="needMsgBoard.display" class="d-inline-block mr-2" :label="$t('book.basic_info.board')"
                                    single-line dense hide-details></v-checkbox>
                            </div>
                        </div>
                    </v-card>
                </div>
                <div v-show="aboutAuthor.display" class="mt-5">
                    <div class="text-h6">{{ $t('book.basic_info.author_intro') }}</div>
                    <v-card class="pa-4" outlined>
                        <div>
                            <label>{{ $t('book.basic_info.author_info_content') }}</label>
                            <v-textarea :rules="aboutAuthor.rules" v-model="aboutAuthor.value" class="mt-2" :label="$t('book.basic_info.author_info_content')"
                                counter="100" single-line outlined dense></v-textarea>
                        </div>
                    </v-card>
                </div>
                <div v-show="thanks.display" class="mt-5">
                    <div class="text-h6">{{ $t('book.basic_info.acknowledgement') }}</div>
                    <v-card class="pa-4" outlined>
                        <div>
                            <label>{{ $t('book.basic_info.book.basic_info.acknowledgement_thank') }}</label>
                            <div class="body-2">{{$t('book.basic_info.book.basic_info.acknowledgement_hint')}}</div>
                            <v-textarea :rules="thanks.rules" v-model="thanks.value" class="mt-2" :label="$t('book.basic_info.book.basic_info.acknowledgement_thank_content')" counter="100"
                                single-line outlined dense></v-textarea>
                        </div>
                        <div>
                            <label>{{ $t('book.basic_info.book.basic_info.acknowledgement_postscript') }}</label>
                            <div class="body-2">{{ $t('book.basic_info.book.basic_info.acknowledgement_postscript_hint') }}</div>
                            <v-textarea :rules="postscript.rules" v-model="postscript.value" class="mt-2" :label="$t('book.basic_info.book.basic_info.acknowledgement_postscript_content')"
                                counter="100" single-line outlined dense></v-textarea>
                        </div>
                    </v-card>
                </div>
            </v-form>
            <div class="d-flex my-5">
                <v-btn class="normal-case" outlined depressed @click="goPreStep()">
                    {{ $t('button.previous_step') }}</v-btn>
                <v-btn color="#DE714B" class="ml-auto white--text normal-case"  depressed
                    @click="saveBasicInfo()" :loading="loading">
                    {{ $t('button.next_step') }}</v-btn>
            </div>
        </v-container>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import { ebookServices } from "@/services/ebook.js";
import Steps from "@/components/ebook/Steps.vue";

export default {
    name: 'ebookBasicInfo',
    components: {
        Steps
    },
    data() {
        return {
            loading: false,
            diaryPrintId: '',
            defaultDisplay: true,
            bookName: {
                value: '',
                rules: [v => !!v || '不得為空'],
            },
            aboutAuthor: {
                display: false,
                value: '',
                rules: [v => (!this.aboutAuthor.display || (this.aboutAuthor.display && !!v)) || '不得為空',
                v => (!this.aboutAuthor.display || (this.aboutAuthor.display && v && v.length <= 100)) || '最多100字']
            },
            thanks: {
                display: false,
                value: '',
                rules: [v => (!this.thanks.display || (this.thanks.display && !!v)) || '不得為空',
                v => (!this.thanks.display || (this.thanks.display && v && v.length <= 100)) || '最多100字']
            },
            postscript: {
                value: '',
                rules: [v => (!this.thanks.display || (this.thanks.display && !!v)) || '不得為空',
                v => (!this.thanks.display || (this.thanks.display && v && v.length <= 100)) || '最多100字']
            },
            needMsgBoard: {
                display: false
            }
        }
    },
    watch: {

    },
    async created() {
        this.diaryPrintId = this.$route.params.diaryPrintId;
        const basicInfoRes = await ebookServices.getEBookBasicInfo(this.diaryPrintId, {});
        const basicinfoData = basicInfoRes.data;
        this.bookName.value = basicinfoData.book_name;
        this.aboutAuthor.value = basicinfoData.about_author;
        this.aboutAuthor.display = basicinfoData.about_author != null;

        this.thanks.value = basicinfoData.thanks;
        this.postscript.value = basicinfoData.postscript;
        this.thanks.display = basicinfoData.thanks != null;

        this.needMsgBoard.display = basicinfoData.need_msg_board != null && basicinfoData.need_msg_board == '1';
    },
    methods: {
        async saveBasicInfo() {
            if (this.$refs.basicInfoForm.validate()) {
                this.loading = true;
                const payload = {
                    'book_name': this.bookName.value,
                    'about_author': this.aboutAuthor.display ? this.aboutAuthor.value: '',
                    'postscript': this.thanks.display ? this.postscript.value: '',
                    'thanks': this.thanks.display ? this.thanks.value: '',
                    'msg_board': this.needMsgBoard.display ? '1': '0'
                }
                await ebookServices.saveEBookBasicInfo(this.diaryPrintId, payload);
                this.$router.push(`/ebook/${this.diaryPrintId}/list`);
            }
        },
        goPreStep() {
            this.$router.push(`/ebook/${this.diaryPrintId}/posts`);
        },
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if(facebookChat)
            facebookChat.style.display = "none";
    }
}
</script>
<style scoped></style>
