import servicesUtils from '../utils/service'

export const diaryQuestServices = {
    // async getDiaryQuestList(diaryHomeworkId) {
    //     try {
    //         const url = `${servicesUtils.resourceUrl('getDiaryQuestList')}`
    //             .replace(':diaryHomeworkId', diaryHomeworkId);
    //         const data = await servicesUtils.get(url);
    //         return data;
    //     } catch (err) {
    //         console.log(`failed to getDiaryQuestList err: ${err}`)
    //         throw err
    //     }
    // },
    async getQuestPostPaging(diaryHomeworkId, questId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getQuestPostPaging')}`
                .replace(':diaryHomeworkId', diaryHomeworkId)
                .replace(':questId', questId)
                + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to getDiaryQuestList err: ${err}`)
            throw err
        }
    },
    async getHomeworkQuestDetail(diaryHomeworkId, questId) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkQuestDetail')}`
                .replace(':id', diaryHomeworkId)
                .replace(':qid', questId);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to getDiaryQuestList err: ${err}`)
            throw err
        }
    },
    async getHomeworkQuestSelection(diaryHomeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('getQusetSelection')}`
                .replace(':diaryHomeworkId', diaryHomeworkId);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to getQusetSelection err: ${err}`)
            throw err
        }
    },
    async addHomeworkQuestSelection(diaryHomeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getQusetSelection')}`
                .replace(':diaryHomeworkId', diaryHomeworkId);
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to getQusetSelection err: ${err}`)
            throw err
        }
    },
    async getQuestInfo(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getQuestInfo')}`
                .replace(':id', id) + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getHomeworkInfo err: ${err}`)
            throw err
        }
    }
}