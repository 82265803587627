<template>
    <v-container fluid>
        <div class="text-h5">
            {{ $t('main_menu.team_management') }}
        </div>
        <v-alert v-if="planExpired" text prominent type="error" class="mt-2">
            {{ $t('my_team.plan_expired_msg') }}
        </v-alert>
        <v-row>
            <v-col cols="auto">
                <v-tabs v-model="selectedTab">
                    <v-tabs-slider color="deep-orange darken-1"></v-tabs-slider>
                    <v-tab
                        class="grey--text text--darken-2 subtitle-1">
                        {{ $t('my_team.tabs.members') }}
                    </v-tab>
                    <v-tab
                        class="grey--text text--darken-2 subtitle-1">
                        {{ $t('my_team.tabs.info') }}
                    </v-tab>
                    <v-tab class="grey--text text--darken-2 subtitle-1">
                        {{ $t('my_team.tabs.students') }}
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="canEditRole" cols="auto" class="my-auto">
                <v-btn small rounded color="#DE714B" class="white--text normal-case"
                    @click="addMemberDialog = true">
                    <v-icon class="mr-1">mdi-account-plus</v-icon>
                    {{ $t('my_team.button.add_member') }}
                </v-btn>
            </v-col>
        </v-row>
        <div class="my-2"></div>
        <v-tabs-items v-model="selectedTab">
            <v-tab-item>
                <v-card outlined>
                    <v-card-title>
                        {{ teamMemberTitle }}
                    </v-card-title>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th v-for="header in memberTableHeaders"
                                        :key="header.value"
                                        class="text-left text-subtitle-1">
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="teamMember.length == 0">
                                    <td class="text-body-1" :colspan="memberTableHeaders.length">
                                        {{ $t('my_team.no_member') }}
                                    </td>
                                </tr>
                                <tr
                                    v-for="member in teamMember"
                                    :key="member.id">
                                    <td class="text-body-1">{{ member.name }}</td>
                                    <td class="text-body-1">{{ member.account }}</td>
                                    <td style="width: 180px;">
                                        <div v-if="member.is_user">
                                            {{ member.role_name }}
                                        </div>
                                        <div v-else-if="canEditRole">
                                            <v-select
                                                v-model="member.select_role_id" :items="roleListWithRemove"
                                                item-text="name" item-value="id"
                                                dense hide-details>
                                                <template v-slot:item="{ item }">
                                                    <div v-if="item.id == -1"
                                                        class="red--text"
                                                        @click="showRemoveMemberDialog(member)">
                                                        {{ item.name }}
                                                    </div>
                                                    <div v-else
                                                        @click="changeMemberRole(member, item)">
                                                        {{ item.name }}
                                                    </div>
                                                </template>
                                            </v-select>
                                        </div>
                                        <div v-else>
                                            {{ member.role_name }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card outlined>
                    <v-card-title>
                        {{ $t('my_team.tabs.info') }}
                    </v-card-title>
                    <v-divider class="mx-2"></v-divider>
                    <v-card-subtitle>
                        <v-row>
                            <v-col cols="2" class="text-body-1">
                                {{ $t('my_team.group_name') }}
                            </v-col>
                            <v-col cols="10" class="text-body-1">
                                {{ teamName }}
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-card-title>
                        {{ $t('my_team.plan_record') }}
                    </v-card-title>
                    <v-divider class="mx-2"></v-divider>
                    <v-data-table :headers="planRecordHeader" :items="planRecords"
                        :items-per-page="planRecords.length" hide-default-footer>
                        <template v-slot:item.status="{ item }">
                            <v-icon v-if="item.status == 1" color="success" large>
                                mdi-circle-medium </v-icon>
                            <v-icon v-else-if="item.status == -1" color="error" large>
                                mdi-circle-medium </v-icon>
                            <v-icon v-else color="gray" large>
                                mdi-circle-medium </v-icon>
                        </template>
                        <template v-slot:item.statusStr="{ item }">
                            <div v-if="item.status == 1" class="green--text">
                                {{ item.statusStr }}
                            </div>
                            <span v-else class="grey--text text--darken-2">
                                {{ item.statusStr }}
                            </span>
                        </template>
                        <template v-slot:item.function="{ item }">
                            <v-btn v-if="item.status == 1 && canEditRole" class="error normal-case" small
                                @click="showCancelPlanDialog(item)">
                                {{ $t('button.cancel') }}
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card outlined>
                    <v-card-title>
                        {{ $t('my_team.student_number') }}{{ ` (${allStudents.length} / ${studentLimit})` }}
                    </v-card-title>
                    <v-card-subtitle>
                        <v-row>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="3">
                                <v-select
                                    v-model="selectedHomework"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    :items="diaryHomeworks"
                                    dense
                                    outlined></v-select>
                            </v-col>
                            <v-col
                                class="d-flex"
                                cols="12"
                                sm="6">
                                <v-text-field
                                    class="pill-shaped-textfield"
                                    :label="$t('my_team.filter')"
                                    outlined dense rounded
                                    append-icon="mdi-magnify"
                                    @click:append="filterStudentByName"
                                    v-model="studentFilter"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-simple-table class="my-2">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th v-for="header in studentTableHeaders"
                                        :key="header"
                                        class="text-left text-subtitle-1">
                                        {{ header }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="student in students"
                                    :key="student.id">
                                    <td class="text-body-1">
                                        <span>{{ student.name }}</span><br>
                                        {{ student.email }}
                                    </td>
                                    <td class="text-body-1">{{ student.lastLoginTime }}</td>
                                    <td class="text-body-1">{{ student.diaryName }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="cancelPlanDialog" max-width="400">
            <v-card>
                <v-card-title>
                    {{ $t('my_team.cancel_plan_dialog.title') }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="cancelPlanDialog = false" class="normal-case">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" @click="cancelPlan" class="normal-case">
                        {{ $t('button.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="addMemberDialog" max-width="500">
            <v-card>
                <v-card-title>
                    {{ $t('my_team.add_member_dialog.title') }}
                    <span class="deep-orange--text text--darken-2">{{ teamName }}</span>
                </v-card-title>
                <v-card-subtitle class="text-h6 mt-1">
                    {{ $t('my_team.member_table.role') }}
                </v-card-subtitle>
                <v-card-subtitle class="mb-0 pb-0">
                    <v-select
                        v-model="selectedRole" :items="roleList"
                        item-text="name" item-value="id"
                        dense outlined>
                    </v-select>
                </v-card-subtitle>
                <v-card-subtitle class="text-h6 mt-0">
                    {{ $t('my_team.add_member_dialog.search') }}
                </v-card-subtitle>
                <v-card-subtitle>
                    <v-row no-gutters>
                        <v-col cols="12" sm="5" md="4">
                            <v-select
                                width="100px"
                                v-model="selectedSearchType"
                                item-text="text"
                                item-value="id"
                                :items="searchType"
                                :lable="$t('my_team.add_member_dialog.search_label')"
                                dense
                                outlined>
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="7" md="8">
                            <v-text-field
                                outlined dense
                                append-icon="mdi-magnify"
                                @click:append="searchMember"
                                v-model="searchStr"></v-text-field>
                        </v-col>
                    </v-row>
                    <div v-if="showUser">
                        <span>{{ $t('my_team.add_member_dialog.name') }}{{ userResult.user_name }}</span><br>
                        <span>{{ $t('my_team.add_member_dialog.account') }}{{ userResult.user_account }}</span><br>
                        <span>{{ $t('my_team.add_member_dialog.mail') }}{{ userResult.email }}</span>
                    </div>
                    <div v-if="showErrorMsg" class="red--text">
                        {{ errorMsg }}
                    </div>
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="addMemberDialog = false" class="normal-case">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="#DE714B" class="white--text normal-case" @click="addMember">
                        {{ $t('my_team.add_member_dialog.add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="removeMemberDialog" width="400px">
            <v-card>
                <v-card-title>
                    {{ $t('my_team.remove_member_dialog.title') }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="hideRemoveMemberDialog" class="normal-case">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" @click="removeMember" class="normal-case">
                        {{ $t('button.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="errorDialog">
            <v-card>
                <v-card-title>
                    {{ errorDialogMsg }}
                </v-card-title>
                <v-card-actions>
                    <v-btn color="error" @click="errorDialog = false" class="normal-case">
                        {{ $t('button.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import { teamServices } from '@/services/team.js';

export default {
    name: 'MyTeam',
    data() {
        return {
            loading: false,
            selectedTab: 0,
            planExpired: false,
            teamMemberTitle: '',
            memberTableHeaders: [
                {
                    text: this.$t('my_team.member_table.name'),
                    value: 'name',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('my_team.member_table.account'),
                    value: 'account',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('my_team.member_table.role'),
                    value: 'role',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
            ],
            teamMember: [],
            roleMap: '',
            roleList: [],
            teamName: '',
            teamId: '',
            planRecordHeader: [
                {
                    text: '',
                    value: 'status',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('charge_plan.name'),
                    value: 'name',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('charge_plan.startDate'),
                    value: 'plan_start_date',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('charge_plan.planExpiredDate'),
                    value: 'plan_end_date',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('charge_plan.planDays'),
                    value: 'days',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('charge_plan.peopleNum'),
                    value: 'number_limit',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                // {
                //     text: this.$t('charge_plan.studentNum'),
                //     value: 'student_limit',
                //     align: 'center',
                //     class: 'grey lighten-4',
                //     filterable: false,
                //     sortable: false,
                // },
                {
                    text: this.$t('charge_plan.status'),
                    value: 'statusStr',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('table.function'),
                    value: 'function',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
            ],
            planRecords: [],
            cancelPlanDialog: false,
            planHistoryIdToCancel: '',
            studentLimit: 0,
            students: [],
            allStudents: [],
            studentTableHeaders: [
                this.$t('my_team.student_table_title.name'),
                this.$t('my_team.student_table_title.last_login'),
                this.$t('my_team.student_table_title.diary')
            ],
            diaryHomeworks: [{
                id: -1,
                name: this.$t('my_team.all_journal')
            }],
            selectedHomework: {
                id: -1,
                name: this.$t('my_team.all_journal')
            },
            studentFilter: '',
            addMemberDialog: false,
            selectedRole: '',
            searchType: [
                {
                    id: 'account',
                    text: this.$t('my_team.add_member_dialog.search_by_account'),
                },
                {
                    id: 'mail',
                    text: this.$t('my_team.add_member_dialog.search_by_mail')
                }
            ],
            selectedSearchType: {
                id: 'account',
                text: this.$t('my_team.add_member_dialog.search_by_account'),
            },
            searchStr: '',
            showUser: false,
            userResult: {},
            showErrorMsg: false,
            errorMsg: '',
            canAddMember: false,
            canEditRole: false,
            roleListWithRemove: [],
            removeMemberDialog: false,
            memberToRemove: {},
            errorDialog: false,
            errorDialogMsg: '',
        }
    },
    async created() {
        const result = await teamServices.getTeamInfo('my');
        if (!result.tph) {
            this.planExpired = true;
        }
        this.teamMemberTitle = this.$t('my_team.member_count') +
            ` (${result.activeUserIdList.length}/${result.tph?.number_limit ? result.tph.number_limit : 0})`;
        this.canEditRole = result.canEditRole;
        this.roleMap = result.roleMap;
        const members = result.userMap;
        for (const member of members) {
            this.teamMember.push({
                id: member.id,
                name: member['displayName'],
                account: member['account'],
                role_id: member['role_id'],
                role_name: `${this.$t(`my_team.role_list.${this.roleMap[member['role_id']].name}`)}`,
                is_user: member['is_current_user'],
                select_role_id: member['role_id'],
            });
        }
        this.teamName = result.team.team_name;
        this.teamId = result.team.team_id;
        for (const plan of result.historyPlanList) {
            let statusStr = '';
            switch (plan.status) {
                case 0:
                    statusStr = this.$t('my_team.plan_status_list.not_start');
                    break;
                case 1:
                    statusStr = this.$t('my_team.plan_status_list.current');
                    break;
                case -2:
                    statusStr = this.$t('my_team.plan_status_list.end');
                    break;
                case -3:
                    statusStr = this.$t('my_team.plan_status_list.temp_stop');
                    break;
                default:
                    statusStr = this.$t('my_team.plan_status_list.cancel');
            }
            this.planRecords.push({
                id: plan.id,
                name: plan.name,
                plan_start_date: plan.plan_start_date,
                plan_end_date: plan.plan_end_date,
                days: plan.days,
                number_limit: plan.number_limit,
                student_limit: plan.student_limit,
                status: plan.status,
                statusStr: statusStr
            });
        }
        this.studentLimit = result.plan? result.plan.student_limit : 0;
        for (let role of result.roleList) {
            this.roleList.push({
                id: role.id,
                name: this.$t(`my_team.role_list.${role.name}`),
            });
        }
        this.roleListWithRemove = [...this.roleList];
        this.roleListWithRemove.push({
            id: -1,
            name: this.$t('my_team.remove_member'),
        });
        this.selectedRole = this.roleList[0];
        await this.getStudentList();
    },
    methods: {
        async getStudentList() {
            const studentResult = await teamServices.getDiaryHomeworkUserWithUsage({});
            for (const student of studentResult.users) {
                this.students.push({
                    id: student.id,
                    name: student.name,
                    email: student.email,
                    lastLoginTime: student.last_login_time,
                    diaryName: student.diary_name,
                    diaryHomeworkId: student.diary_homework_id,
                });

                let hw = this.diaryHomeworks.find(hw => hw.id == student.diary_homework_id);
                if (!hw) {
                    this.diaryHomeworks.push({
                        id: student.diary_homework_id,
                        name: student.diary_name,
                    });
                }
            }
            this.allStudents = [...this.students];
        },
        async changeMemberRole(memberToChange, role) {
            const payload = {
                role_id: role.id
            }
            const changeRoleResult = await teamServices.changeMemberRole(this.teamId,
                memberToChange.id, payload);
            if (changeRoleResult.error_code == -2) {
                this.errorDialogMsg = this.$t('my_team.change_role_error')
                this.errorDialog = true;
            } else {
                window.location.reload();
            }
        },
        showRemoveMemberDialog(member) {
            this.memberToRemove = member;
            this.removeMemberDialog = true;
        },
        hideRemoveMemberDialog() {
            this.memberToRemove.select_role_id = this.memberToRemove.role_id;
            this.memberToRemove = {};
            this.removeMemberDialog = false;
        },
        async removeMember() {
            this.removeMemberDialog = false;
            this.loading = true;
            const removeResult = await teamServices.removeTeamMember(this.teamId, this.memberToRemove.id);
            this.loading = false;
            if (removeResult.error_code == -2) {
                this.errorDialogMsg = this.$t('my_team.change_role_error')
                this.errorDialog = true;
            } else {
                this.$toastr.Add({
                    msg: this.$t('my_team.remove_member_success'),
                    clickClose: true,
                    timeout: 3000,
                    position: "toast-top-right",
                    classNames: ["animated", "bounceInRight"],
                    onClosed: () => {
                        window.location.reload();
                    }
                });
            }
        },
        showCancelPlanDialog(item) {
            this.planHistoryIdToCancel = item.id;
            this.cancelPlanDialog = true;
        },
        async cancelPlan() {
            this.cancelPlanDialog = false;
            this.loading = true;
            const result = await teamServices.cancelTeamPlan(this.planHistoryIdToCancel);
            this.loading = false;
            if (result.result) {
                this.$toastr.Add({
                    msg: this.$t('my_team.cancel_plan_success'),
                    clickClose: true,
                    timeout: 3000,
                    position: "toast-top-right",
                    classNames: ["animated", "bounceInRight"],
                });
                let plan = this.planRecords.find(plan => plan.id == this.planHistoryIdToCancel);
                plan.status = -1;
                plan.statusStr = this.$t('my_team.plan_status_list.cancel');
            }
        },
        filterStudentByName() {
            if (this.studentFilter == '') {
                this.students = [...this.allStudents];
            } else {
                this.students = this.allStudents.filter(student => student.name.includes(this.studentFilter));
            }
        },
        filterStudentByHomework() {
            if (this.selectedHomework.id == '-1') {
                this.students = [...this.allStudents];
            } else {
                this.students = this.allStudents.filter(student => student.diaryHomeworkId == this.selectedHomework.id);
            }
        },
        async addMember() {
            if (this.canAddMember) {
                this.addMemberDialog = false;
                this.loading = true;
                const addResult = await teamServices.addTeamMember(this.teamId, {
                    user_id: this.userResult.user_id,
                    role_id: this.selectedRole,
                });
                this.showUser = false;
                this.showErrorMsg = false;
                this.errorMsg = '';
                this.userResult = {};
                this.canAddMember = false;
                this.loading = false;
                if (addResult.error_code != null) {
                    let error_msg = this.$t('my_team.add_member_dialog.error_msg.-1');
                    if (addResult.error_code != -1) {
                        error_msg = this.$t(`my_team.add_member_dialog.error_msg.failed`);
                    }
                    this.$toastr.Add({
                        msg: error_msg,
                        type: "error",
                        clickClose: true,
                        timeout: 3000,
                        position: "toast-top-right",
                        classNames: ["animated", "bounceInRight"],
                    });
                } else {
                    this.$toastr.Add({
                        msg: this.$t('my_team.add_member_success'),
                        clickClose: true,
                        timeout: 3000,
                        position: "toast-top-right",
                        classNames: ["animated", "bounceInRight"],
                        onClosed: () => {
                            window.location.reload();
                        }
                    });
                }
            }
        },
        async searchMember() {
            if (this.searchStr == '') {
                this.showUser = false;
                this.showErrorMsg = false;
                this.errorMsg = '';
                this.userResult = {};
                this.canAddMember = false;
                return;
            }
            this.showUser = false;
            this.showErrorMsg = false;
            let query;
            if (this.selectedSearchType.id == 'account') {
                query = {
                    account: this.searchStr
                }
            } else {
                query = {
                    email: this.searchStr
                }
            }
            const result = await teamServices.checkTeamMember(this.teamId, query);
            if (result.error_code) {
                this.errorMsg = this.$t(`my_team.add_member_dialog.error_msg.${result.error_code}`);
                this.canAddMember = false;
                this.showErrorMsg = true;
            } else {
                this.userResult = result;
                this.showUser = true;
                this.canAddMember = true;
            }
        }
    },
    watch: {
        selectedHomework: {
            handler() {
                this.filterStudentByHomework();
            },
            deep: true,
        },
    }
}
</script>

<style scoped>
.pill-shaped-textfield .v-text-field__slot {
    border-radius: 50px;
}
</style>