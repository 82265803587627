<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-3" large></v-breadcrumbs>
        <div>
            <v-row class="text-h6 my-2 mx-1 grey--text text--darken-2">
                {{ title }}
            </v-row>
            <v-row class="text-body-1 my-2 mx-1" color="#4F4F4F">
                <span color="#4F4F4F" class="my-auto grey--text text--darken-3">
                    <v-icon color="#BDBDBD" small class="mb-1">mdi-check-bold</v-icon>
                    {{ `${$t('homeworkQuestList.complete_rate')} ${rate}%` }}
                </span>
                <span color="#4F4F4F" class="my-auto mx-4 grey--text text--darken-3">
                    <v-icon color="#BDBDBD" small class="mb-1">mdi-account</v-icon>
                    {{ `${$t('homeworkQuestList.complete_num')} ${counts}` }}
                </span>
                <span color="#4F4F4F" class="my-auto grey--text text--darken-3">
                    <v-icon color="#BDBDBD" small class="mb-1">mdi-bullseye</v-icon>
                    {{ `${$t('homeworkQuestList.acerage_score')} ${score_ave}` }}
                </span>
            </v-row>
        </div>
        <v-tabs v-model="tab">
            <v-tabs-slider color="deep-orange arken-1"></v-tabs-slider>
            <v-tab
                class="grey--text text--darken-2 subtitle-1">
                {{ $t('homeworkdQuestNews.browse') }}
            </v-tab>
            <v-tab
                class="grey--text text--darken-2 subtitle-1">
                {{ $t('homeworkdQuestNews.stats') }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-row class="my-2 ml-3">
                    <v-select :items="orderList"
                        item-text="text"
                        item-value="value"
                        outlined flat dense solo
                        hide-details="auto"
                        v-model="orderInput"
                        @change="orderChanged"></v-select>
                    <!-- <v-spacer></v-spacer> -->
                    <div style="width: 500px;"></div>
                </v-row>
                <v-row class="ml-1">
                    <v-col cols="12" md="8" lg="8" order="2" order-md="1">
                        <v-skeleton-loader v-if="isPostLoad"
                            type="list-item-avatar, divider, list-item-three-line, card-heading, image"></v-skeleton-loader>
                        <v-card v-if="postList.length == 0 && !isPostLoad" class="text-center pa-5" outlined>{{
                            $t('general.msg.no_diary_data') }}</v-card>
                        <Post v-for="postItem in postList" v-bind:postItem="postItem"
                            v-bind:commentList="commentMap[postItem.diary_post_id]" v-bind:homeworkId="homeworkId"
                            v-bind:key="postItem.id" />
                        <v-sheet class="text-center" v-if="hasMorePost">
                            <v-btn class="normal-case"
                                outlined @click="getPostPagingList">{{ $t('button.load_more') }}</v-btn>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" order="1" order-md="2">
                        <v-card outlined class="px-2 pb-4">
                            <div class="px-4 py-1 text-h6 grey--text text--darken-2 ">{{
                                $t('homeworkdQuestNews.submission_short_label') }}</div>
                            <v-divider></v-divider>
                            <v-card-subtitle>{{ $t('homeworkdQuestNews.submitted') }}</v-card-subtitle>
                            <div v-if="getSubmittedUserList().length == 0" class="text-center">{{
                                $t('general.diary.no_data') }}
                            </div>
                            <v-row class="no-gutters">
                                <v-col class="cursor-pointer text-center mb-2" v-for="(item, i) in getSubmittedUserList()"
                                    :key="i"
                                    cols="3" sm="2" md="4" lg="3" @click="toStudentPage(item.user_id)">
                                    <v-avatar color="grey lighten-2">
                                        <img :src="item.role" :alt="item.name">
                                    </v-avatar>
                                    <v-card-subtitle class="pa-0">{{ item.name }}</v-card-subtitle>
                                </v-col>
                            </v-row>
                            <v-card-subtitle>{{ $t('homeworkdQuestNews.not_submitted') }}</v-card-subtitle>
                            <div v-if="getUnsubmittedUserList().length == 0" class="text-center">{{
                                $t('general.diary.no_data')
                            }}
                            </div>
                            <v-row class="no-gutters">
                                <v-col class="cursor-pointer text-center mb-2" v-for="(item, i) in getUnsubmittedUserList()"
                                    :key="i"
                                    cols="3" sm="2" md="4" lg="3" @click="toStudentPage(item.user_id)">
                                    <v-avatar>
                                        <img :src="item.role" :alt="item.name">
                                    </v-avatar>
                                    <v-card-subtitle class="pa-0">{{ item.name }}</v-card-subtitle>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-card outlined flat class="mt-3">
                    <v-card-actions>
                        <v-row v-if="showAllStudents" class="my-2 mx-2">
                            <v-btn outlined color="#555555" class="normal-case">
                                {{ $t('homeworkdQuestNews.all') }}</v-btn>
                            <v-btn text color="#555555" class="normal-case"
                                @click="showAllStudents = false">{{ $t('homeworkdQuestNews.not_submitted') }}</v-btn>
                        </v-row>
                        <v-row v-else class="my-2 mx-2">
                            <v-btn text color="#555555" class="normal-case"
                                @click="showAllStudents = true">{{ $t('homeworkdQuestNews.all') }}</v-btn>
                            <v-btn outlined color="#555555" class="normal-case">
                                {{ $t('homeworkdQuestNews.not_submitted') }}</v-btn>
                        </v-row>
                    </v-card-actions>
                    <v-card-text>
                        <v-data-table
                            :headers="diaryHeaders"
                            :items="tableUserList"
                            :page.sync="page"
                            hide-default-footer
                            disable-sort
                            :items-per-page="10">
                            <template v-slot:item.role="{ item }">
                                <v-img :src="item.role"
                                    class="role-thumbnail  mx-auto my-2">
                                </v-img>
                            </template>
                            <template v-slot:item.nameAndAccount="{ item }">
                                <span>{{ item.name }}</span>
                                <br />
                                <span class="font-weight-thin">{{ item.account }}</span>
                            </template>
                            <template v-slot:item.score="{ item }">
                                <span v-if="item.score < 1">{{ $t('post.scoring') }}</span>
                                <span v-else>{{ item.score }}</span>
                            </template>
                            <template v-slot:item.update_date="{ item }">
                                {{ item.update_date ? item.update_date : $t('homeworkQuestNews.no_submitted') }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn v-if="item.submitted" small class="mr-2 mt-1 normal-case" outlined
                                    @click="dialogPostId = item.diary_post_id; postDialogOpenCount += 1"
                                    color="#25A2B7">
                                    <v-icon small class="mr-1"> mdi-eye </v-icon>
                                    {{ $t('button.view_post') }}
                                </v-btn>
                            </template>
                        </v-data-table>
                        <div class="text-center my-4">
                            <v-pagination
                                v-model="page"
                                :length="pageCount"
                                circle
                                color="orange accent-2">
                            </v-pagination>
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-overlay absolute :value="dataLoading">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
        <PostDialog v-if="dialogPostId != null" v-bind:postDialogOpenCount="postDialogOpenCount"
            v-bind:postId="dialogPostId" v-bind:homeworkId="homeworkId"></PostDialog>
    </v-container>
</template>

<script>
import Post from '@/components/console/diary/component/Post.vue'
import { diaryServices } from "@/services/diary.js";
import { diaryQuestServices } from "@/services/diaryQuest.js";
import PostDialog from '@/components/console/diary/component/PostDialog.vue'

export default {
    name: 'homeworkQuestNews',
    components: { Post, PostDialog },
    data() {
        return {
            breadcrumbsItems: [],
            homeworkId: '',
            title: '',
            rate: '',
            counts: '',
            score_ave: '',
            questionId: '',
            tab: null,
            orderInput: 'timeDesc',
            orderList: [
                {
                    value: 'timeDesc',
                    text: this.$t('homeworkQuestNews.order.time_desc'),
                },
                {
                    value: 'timeAsc',
                    text: this.$t('homeworkQuestNews.order.time_asc'),
                },
                {
                    value: 'scoreAsc',
                    text: this.$t('homeworkQuestNews.order.score_asc'),
                },
                {
                    value: 'scoreDesc',
                    text: this.$t('homeworkQuestNews.order.score_desc'),
                },
            ],
            allUserList: [],
            showAllStudents: true,
            showNotSubmittedStudents: false,
            diaryHeaders: [
                {
                    text: this.$t('table.role'),
                    sortable: false,
                    value: 'role',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.name_account'),
                    value: 'nameAndAccount',
                    sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.score'),
                    sortable: false,
                    value: 'score',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.submitted_date'),
                    sortable: false,
                    value: 'update_date',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.function'), value: 'actions', sortable: false,
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                },
            ],
            tableUserList: [],
            page: 1,
            pageCount: 1,
            loading: false,
            diaryPostOptions: {},
            postDataPayload: {
                page: 0,
                orderAsc: false,
                orderByScore: false,
            },
            isPostLoad: false,
            postList: [],
            commentMap: {},
            hasMorePost: false,
            dialogPostId: null,
            postDialogOpenCount: 0,
            dataLoading: false,
        }
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        this.questionId = this.$route.params.qid;

        this.dataLoading = true;

        const diaryHomeworkNameRes = await diaryServices.getHomeworkName(this.homeworkId, {});
        this.homeworkName = diaryHomeworkNameRes.data.name;
        const questDetail = await diaryQuestServices.getHomeworkQuestDetail(
            this.homeworkId, this.questionId);
        this.rate = questDetail.data.quest.rate;
        this.counts = questDetail.data.quest.count;
        this.score_ave = questDetail.data.quest.score_ave;
        this.title = questDetail.data.quest.title;
        this.allUserList = questDetail.data.students;
        this.tableUserList = this.allUserList;

        this.breadcrumbsItems.push({
            text: this.$t('index.title'),
            disabled: false,
            href: `/diary`,
        }, {
            text: this.homeworkName,
            disabled: false,
            href: `/diary/homework/${this.homeworkId}`,
        }, {
            text: this.$t('homeworkQuestList.page_title'),
            disabled: false,
            href: `/diary/homework/${this.homeworkId}/questList`,
        }, {
            text: this.title,
            disabled: true,
            href: ``,
        });

        this.dataLoading = false;
        await this.getPostPagingList();
    },
    methods: {
        toStudentPage(studentId) {
            this.$router.push(`/diary/homework/${this.homeworkId}/student/${studentId}`);
        },
        getUnsubmittedUserList() {
            if (!this.allUserList || this.allUserList.length == 0) {
                return [];
            }
            return this.allUserList.filter(item => !item.submitted);
        },
        getSubmittedUserList() {
            if (!this.allUserList || this.allUserList.length == 0) {
                return [];
            }
            return this.allUserList.filter(item => item.submitted);
        },
        displayAllStudents() {
            this.showAllStudents = true;
            this.showNotSubmittedStudents = false;
        },
        displayNotSubmittedStudents() {
            this.showAllStudents = false;
            this.showNotSubmittedStudents = true;
        },
        async getPostPagingList() {
            //記錄一下scrollbar位置，等等要停在這
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

            this.hasMorePost = false;
            this.isPostLoad = true;
            let payload = {
                page: this.postDataPayload.page + 1,
                orderAsc: this.postDataPayload.orderAsc ? 1 : 0,
                orderByScore: this.postDataPayload.orderByScore ? 1 : 0,
            }
            const res = await diaryQuestServices.getQuestPostPaging(this.homeworkId,
                this.questionId, payload);
            if (res.data != null) {
                this.isPostLoad = false;

                for (const [key, value] of Object.entries(res.data.post_comment_map)) {
                    let commentList = [];
                    value.forEach(item => {
                        commentList.push(item);
                    });
                    this.commentMap[key] = commentList;
                }

                res.data.post_list.forEach(item => {
                    this.postList.push(item);
                });

                this.postDataPayload.page = payload.page;
                if (res.data.post_list.length == 0) {
                    this.hasMorePost = false;
                } else {
                    this.hasMorePost = true;

                    //scrollbar要停在這
                    window.scrollTo(0, scrollPosition + 200);
                }
            }
        },
        async orderChanged() {
            const value = this.orderInput;
            switch (value) {
                case 'timeAsc':
                    this.postDataPayload.orderAsc = true;
                    this.postDataPayload.orderByScore = false;
                    break;
                case 'timeDesc':
                    this.postDataPayload.orderAsc = false;
                    this.postDataPayload.orderByScore = false;
                    break;
                case 'scoreAsc':
                    this.postDataPayload.orderAsc = true;
                    this.postDataPayload.orderByScore = true;
                    break;
                case 'scoreDesc':
                    this.postDataPayload.orderAsc = false;
                    this.postDataPayload.orderByScore = true;
                    break;
            }
            this.postDataPayload.page = 0;
            this.postList = [];
            this.commentMap = {};
            await this.getPostPagingList();
        }
    },
    watch: {
        showAllStudents(value) {
            if (value) {
                this.tableUserList = this.allUserList;
            } else {
                this.tableUserList = this.getUnsubmittedUserList();
            }
            if (this.tableUserList) {
                this.pageCount = Math.ceil(this.tableUserList.length / 10);
            } else {
                this.pageCount = 0;
            }
            this.page = 1;
        },
    }
}
</script>

<style scoped>
.role-thumbnail {
    width: 50px;
    align-items: center;
}

.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>