<template>
    <div>
        <div class="main-content mx-auto">
            <div v-if="!homeworkId" class="text-h5 mb-2">
                {{ $t('diary_sample.create_sample.label') }}
            </div>
            <div v-else class="text-h5 mb-2">
                {{ $t('diary_sample.setting_edit') }}
            </div>
            <v-form ref="homeworkForm" v-model="valid" lazy-validation>
                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.diary_name') }}</label>
                    <v-text-field v-model="nameInput" :label="$t('edit.diary_name_label')" outlined flat solo dense
                        hide-details="auto"
                        :rules="nameInputRules"></v-text-field>
                </div>
                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.diary_description') }}</label>
                    <v-textarea v-model="descriptionInput" :label="$t('edit.diary_description_label')" outlined flat solo
                        hide-details="auto"
                        :rules="descriptionInputRules"></v-textarea>
                </div>
                <!-- <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.select_grade') }}</label>
                    <v-row no-gutters>
                        <v-col cols="4" md="2" v-for="(item, ind) in gradeList" :key="`grade${ind}`">
                            <v-checkbox class="mt-0" v-model="gradeInput" :label="item.title" :value="item.value"
                                hide-details="auto" :rules="gradeInputRules"></v-checkbox>
                        </v-col>
                    </v-row>
                </div> -->
                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.user_limit') }}</label>
                    <v-select :items="studentLimitList" :label="$t('edit.user_limit')" outlined flat dense solo
                        hide-details="auto"
                        v-model="studentLimitInput" :rules="studentLimitInputRules"></v-select>
                </div>

                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit.label_color') }}</label>
                    <v-item-group class="d-flex" mandatory v-model="colorInput">
                        <v-item class="mr-2" v-for="item in colorList" :key="item.value" :value="item.value"
                            v-slot="{ active, toggle }">
                            <v-card :color="item.code" class="d-flex align-center text-center" dark height="30" width="30"
                                @click="toggle">
                                <v-scroll-y-transition>
                                    <v-icon v-if="active" class="mx-auto" label>mdi-check</v-icon>
                                </v-scroll-y-transition>
                            </v-card>
                        </v-item>
                    </v-item-group>
                </div>

                <div class="mb-5">
                    <label class="font-weight-medium">{{ $t('edit_pronunciation') }}</label>
                    <v-select :items="speechLangList" item-text="title" item-value="value" v-model="speechLangInput"
                        outlined flat dense solo hide-details="auto"
                        :rules="speechLangInputRules"></v-select>
                </div>
            </v-form>
            <div v-if="speechLangInput == 'en'" class="mb-3">
                <v-checkbox class="my-2 font-weight-medium" v-model="aiScoring" hide-details="auto"
                    :label="$t('edit.ai_scoring_sample')"></v-checkbox>
            </div>
            <div class="mb-3">
                <label class="font-weight-medium">{{ $t('edit.setting_calendar') }}</label>
                <v-radio-group class="mt-0" dense hide-details="auto" v-model="diaryCalendarTypeInput">
                    <v-radio v-for="item in diaryCalendarTypeList" :key="item.value" :label="item.title"
                        :value="item.value" hide-details="auto"></v-radio>
                </v-radio-group>
            </div>
            <v-divider></v-divider>
            <div class="text-right mt-4">
                <v-btn class="mr-2 normal-case" color="secondary" outlined large @click="$router.go(-1)">
                    {{ $t('button.cancel') }}
                </v-btn>
                <v-btn class="normal-case"
                    color="primary" large :loading="buttonLoading" :disabled="buttonLoading" @click="saveHomework">{{
                        $t('button.confirm') }}
                </v-btn>
            </div>
        </div>
        <v-dialog v-model="inviteCodeDialog" persistent width="600">
            <v-card class="pb-2">
                <v-card-title>
                    <span class="text-h6 ml-2" style="color:#D84315">
                        {{ nameInput }}
                    </span>
                    <span class="text-h6">{{ $t('edit.sample.dialog_message') }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle class="mt-4 text-h4 text-center" color="grey darken-2">
                    {{ inviteCode }}
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="goBack" color="#D84315"
                        class="normal-case">ok</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackBar.on"
            :timeout="snackBar.timeout"
            :color="snackBar.type"
            right>
            {{ snackBar.text }}
        </v-snackbar>
    </div>
</template>

<script>
import { diaryServices } from '@/services/diary.js';
export default {
    name: "SampleHomeworkEdit",
    components: {},
    data() {
        return {
            snackBar: {
                on: false,
                text: "My timeout is set to 2000.",
                timeout: 2000,
                type: "info",
            },
            breadcrumbsItems: [],
            buttonLoading: false,
            homeworkId: '',
            nameInput: '',
            nameInputRules: [(v) => !!v || this.$t('general.msg.must_filled')],
            descriptionInput: '',
            descriptionInputRules: [(v) => !!v || this.$t('general.msg.must_filled')],
            gradeInput: [],
            gradeInputRules: [() => this.gradeInput.length != 0 || this.$t('general.msg.must_filled')],
            gradeList: [
                {
                    title: this.$t('edit.grade.1'),
                    value: '1',
                }, {
                    title: this.$t('edit.grade.2'),
                    value: '2',
                }, {
                    title: this.$t('edit.grade.3'),
                    value: '3',
                }, {
                    title: this.$t('edit.grade.4'),
                    value: '4',
                }, {
                    title: this.$t('edit.grade.5'),
                    value: '5',
                }, {
                    title: this.$t('edit.grade.6'),
                    value: '6',
                }, {
                    title: this.$t('edit.grade.7'),
                    value: '7',
                }, {
                    title: this.$t('edit.grade.8'),
                    value: '8',
                }, {
                    title: this.$t('edit.grade.9'),
                    value: '9',
                }
            ],
            studentLimitInput: '',
            studentLimitInputRules: [(v) => !!v || this.$t('general.msg.must_filled')],
            studentLimitList: [
                10, 20, 30, 40, 50, 60, 70, 80
            ],
            colorInput: '',
            colorList: [
                {
                    code: 'red',
                    value: '1',
                }, {
                    code: 'orange',
                    value: '2',
                }, {
                    code: 'yellow darken-1',
                    value: '3',
                }, {
                    code: 'green',
                    value: '4',
                }, {
                    code: 'blue',
                    value: '5',
                }, {
                    code: 'blue darken-4',
                    value: '6',
                }, {
                    code: 'deep-purple ',
                    value: '7',
                }
            ],
            speechLangInput: '',
            speechLangInputRules: [(v) => !!v || this.$t('general.msg.must_filled')],
            speechLangList: [
                {
                    title: this.$t('edit.lang.users_language'),
                    value: 'user'
                },
                {
                    title: this.$t('edit.lang.zh'),
                    value: 'zh'
                }, {
                    title: this.$t('edit.lang.en'),
                    value: 'en'
                }
            ],
            valid: false,
            inviteCode: '',
            inviteCodeDialog: false,
            diaryCalendarTypeInput: '1',
            diaryCalendarTypeList: [{
                title: this.$t('edit.calendar_mode.on'),
                value: '1',
            }, {
                title: this.$t('edit.calendar_mode.off'),
                value: '0',
            }],
            aiScoring: false,
            subType: [],
        };
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        if (typeof this.homeworkId == 'undefined') {
            this.homeworkId = null;
            this.speechLangInput = 'user';
        } else {
            const diaryHomeworkDetailRes = await diaryServices.getHomeworkDetail(this.homeworkId);
            const diaryHomeworkDetailData = diaryHomeworkDetailRes.data;
            this.nameInput = diaryHomeworkDetailData.name;
            this.descriptionInput = diaryHomeworkDetailData.description;
            this.colorInput = diaryHomeworkDetailData.diary_cover_color;
            diaryHomeworkDetailData.user_classes.forEach(userClass => {
                this.gradeInput.push(userClass);
            });
            this.studentLimitInput = diaryHomeworkDetailData.diary_publish_num;
            this.takeEndDateInput = diaryHomeworkDetailData.diary_take_end_date;
            //this.colorList = diaryHomeworkDetailData.label_color;
            if (!diaryHomeworkDetailData.speech_lang) {
                this.speechLangInput = 'user';
            } else {
                this.speechLangInput = diaryHomeworkDetailData.speech_lang;
            }
            if (diaryHomeworkDetailData.sub_type) {
                this.diaryCalendarTypeInput = '0';
                const types = JSON.parse(diaryHomeworkDetailData.sub_type);
                for (const subtype of types) {
                    switch (subtype) {
                        case 0:
                            this.diaryCalendarTypeInput = '1';
                            break;
                        case 2:
                            this.aiScoring = true;
                            break;
                    }
                }
                this.subType = types;
            }
        }
    },
    mounted() {
    },
    computed: {

    },
    watch: {

    },
    methods: {
        showAlertMsg(type, msg) {
            this.snackBar.type = type;
            this.snackBar.text = msg;
            this.snackBar.on = true;
        },
        async saveHomework() {
            if (this.$refs.homeworkForm.validate()) {
                this.subType = [];
                if (this.diaryCalendarTypeInput == '1') {
                    this.subType.push(0);
                }
                if (this.aiScoring) {
                    this.subType.push(2);
                }
                const subTypeStr = JSON.stringify(this.subType);
                let payload = {
                    name: this.nameInput,
                    description: this.descriptionInput,
                    diary_cover_color: this.colorInput,
                    user_classes: this.gradeInput.join(','),
                    diary_publish_num: this.studentLimitInput,
                    label_color: this.colorInput,
                    speech_lang: this.speechLangInput == 'user' ? '' : this.speechLangInput,
                    share_type: 5,
                    sub_type: subTypeStr,
                }
                const res = await diaryServices.createHomework(this.homeworkId, payload);
                if (!this.homeworkId) {
                    this.inviteCode = res.data.invite_code;
                    this.inviteCodeDialog = true;
                } else {
                    this.$router.go(-1);
                }
            } else {
                this.showAlertMsg('red', this.$t('general.msg.input_incomplete'))
            }
        },
        goBack() {
            this.$router.go(-1);
        },
    },
}
</script>
<style scoped>
.main-content {
    max-width: 650px;
}
</style>
