import servicesUtils from '../utils/service'
import { setItem } from '../utils/storageManager'

export const loginServices = {
    async oauthLogin() {
        try {
            const url = `${servicesUtils.resourceUrl('loginLink')}?redirect_uri=${process.env.VUE_APP_LOGIN_REDIRECT_URL}`;
            const response = await servicesUtils.get(url);
            const oauthLink = response.link;
            location.href = oauthLink;
        } catch (err) {
            console.log(`failed to login err: ${err}`);
            throw err
        }
    },
    async oauthLogout() {
        try {
            const url = `${servicesUtils.resourceUrl('logoutLink')}`;
            const response = await servicesUtils.get(url);
            const oauthLink = response.link;
            // return oauthLink;
            location.href = oauthLink;
        } catch (err) {
            console.log(`failed to login err: ${err}`);
            throw err
        }
    },
    async login() {
        try {
            const url = `${servicesUtils.resourceUrl('login')}`
            const data = await servicesUtils.post(url);
            return data;
        } catch (err) {
            console.log(`failed to login err: ${err}`);
            throw err
        }
    },
    async dcLogin() {
        try {
            const url = `${servicesUtils.resourceUrl('dcLogin')}`
            const data = await servicesUtils.post(url);
            return data;
        } catch (err) {
            console.log(`failed to login err: ${err}`);
            throw err
        }
    },
    async logout() {
        const url = `${servicesUtils.resourceUrl('userLogout')}`
        const data = await servicesUtils.get(url)
        return data
    },
    async getMyInfo() {
        const url = `${servicesUtils.resourceUrl('myInfo')}`;
        const response = await servicesUtils.get(url);
        return response.data;
    },
    async getUserInfo(data) {
        const url = `${servicesUtils.resourceUrl('userInfo')}`;
        const response = await servicesUtils.post(url, data);
        return response.data;
    },
    //accountLogin
    async accountLogin(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('accountLogin')}`
            const result = await servicesUtils.post(url, payload);
            const res = result.data;
            if (res.success) {
                setItem("userName", res.user.name);
                setItem('locale', res.user.locale);
                
                sessionStorage.setItem('isHomeworkLock', res.chargePlan.isHomeworkLock);
                sessionStorage.setItem('planName', res.chargePlan.planName);
                sessionStorage.setItem('studentLimit', res.chargePlan.studentLimit);
                sessionStorage.setItem('locale', res.user.locale);
                sessionStorage.setItem('mode', res.mode);
                sessionStorage.setItem('teamModeAvailable', res.teamModeAvailable);
            }
            return result;
        } catch (err) {
            console.log(`failed to accountLogin err: ${err}`);
            throw err
        }
    },
    async get3rdOAuthLink(platform, redirect_uri) {
        try {
            const url = `${servicesUtils.resourceUrl('get3rdOAuthLink')}`
            .replace(':platform', platform) + `?redirect_uri=${redirect_uri}`;
            const response = await servicesUtils.get(url);
            console.log('response', response)
            return response.data.link;
        } catch (err) {
            console.log(`failed to get3rdOAuthLink err: ${err}`);
            throw err
        }
    },
    async get3rdOAuthBindLink(platform, redirect_uri) {
        try {
            const url = `${servicesUtils.resourceUrl('get3rdOAuthBindLink')}`
            .replace(':platform', platform) + `?redirect_uri=${redirect_uri}`;
            const response = await servicesUtils.get(url);
            console.log('response', response)
            return response.data.link;
        } catch (err) {
            console.log(`failed to get3rdOAuthBindLink err: ${err}`);
            throw err
        }
    },
    async oAuthLogin3rd(platform, data) {
        try {
            const url = `${servicesUtils.resourceUrl('oAuthLogin3rd')}`
            .replace(':platform', platform);
            const response = await servicesUtils.post(url, data);
            return response;
        } catch (err) {
            console.log(`failed in oAuthLogin3rd err: ${err}`);
            throw err
        }
    }
    // // async getAdminName() {
    // //     const url = `${servicesUtils.resourceUrl('adminName')}`
    // //     const data = await servicesUtils.get(url, {})
    // //     return data
    // // },
    // async updatePwd(oldPwd, newPwd) {
    //     const url = `${servicesUtils.resourceUrl('changePwd')}`
    //     const data = await servicesUtils.post(url, {
    //         old_pwd: oldPwd,
    //         new_pwd: newPwd,
    //     })
    //     return data
    // },
}