<template>
    <v-dialog v-model="value" persistent>
        <v-card class="mx-40">
            <v-card-title class="ml-0 pl-0 mr-5 pr-0">
                <v-btn text color="grey" @click="show = false"
                    class="normal-case">
                    <v-icon>mdi-arrow-left-thick</v-icon>
                    {{ getTitle() }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn icon @click="show = false"
                    class="normal-case">
                    <v-icon>mdi-close-thick</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="my-3"></v-divider>
            <v-card outlined class="card-no-outline">

                <v-card-text>
                    {{ $t('quest.edit_frame.title_label') }}
                    <v-form ref="titleForm">
                        <v-text-field outlined v-model="frameTitle"
                            :rules="framTitleRule"></v-text-field></v-form>
                </v-card-text>
                <v-card-text v-if="type != 3">
                    {{ $t('quest.edit_frame.example') }}
                    <v-text-field
                        outlined
                        append-icon="mdi-plus"
                        v-model="sample"
                        @click:append="addExample"></v-text-field>
                    <v-divider></v-divider>
                    <v-chip-group column>
                        <v-chip v-for="(item, index) in frameSamples" :key="index" class="ma-2"
                            outlined label color="#828282">
                            <v-icon color="#585858" left small
                                @click="frameSamples.splice(index, 1)">mdi-close-thick</v-icon>
                            {{ item }}
                        </v-chip>
                    </v-chip-group>
                </v-card-text>
                <div v-else>
                    <v-card-text v-for="(samples, index) in frameSamples"
                        :key="index" style="padding-bottom: 5px; padding-top: 5px;">
                        {{ $t(`quest.edit_frame.behavior_examples.behavior${index + 1}`) }}
                        <v-text-field
                            outlined
                            append-icon="mdi-plus"
                            v-model="samples.sampleModel"
                            @click:append="addBehaviorExample(samples)"></v-text-field>
                        <v-divider></v-divider>
                        <v-chip-group column>
                            <v-chip v-for="(item, index) in samples.text" :key="index" class="ma-2"
                                outlined label color="#828282">
                                <v-icon color="#585858" left small
                                    @click="samples.text.splice(index, 1)">mdi-close-thick</v-icon>
                                {{ item }}
                            </v-chip>
                        </v-chip-group>
                    </v-card-text>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="show = false" outlined
                        class="normal-case">
                        {{ $t('button.cancel') }}</v-btn>
                    <v-btn color="#DE714B" class="white--text normal-case" @click="returnData">
                        {{ $t('button.confirm') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-card>
    </v-dialog>
</template>
<script>

export default {
    name: 'QuestEditFrame',
    components: {},
    props: ['value', 'type', 'title', 'samples'],
    data() {
        return {
            homeworkId: null,
            sample: '',
            typeName: '',
            frameTitle: '',
            frameSamples: [],
            framTitleRule: [
                v => !!v || this.$t('quest.edit_frame.rule_label'),
            ],
            localSamples: [],
            // Add default behavior samples 1-10
            defaultBehaviorSamples: [
                {
                    id: 'behavior1',
                    sampleModel: '',
                    text: [],
                },
                {
                    id: 'behavior2',
                    sampleModel: '',
                    text: [],
                },
                {
                    id: 'behavior3',
                    sampleModel: '',
                    text: [],
                },
                {
                    id: 'behavior4',
                    sampleModel: '',
                    text: [],
                },
                {
                    id: 'behavior5',
                    sampleModel: '',
                    text: [],
                },
                {
                    id: 'behavior6',
                    sampleModel: '',
                    text: [],
                },
                {
                    id: 'behavior7',
                    sampleModel: '',
                    text: [],
                },
                {
                    id: 'behavior8',
                    sampleModel: '',
                    text: [],
                },
                {
                    id: 'behavior9',
                    sampleModel: '',
                    text: [],
                },
                {
                    id: 'behavior10',
                    sampleModel: '',
                    text: [],
                },
            ],
        }
    },
    async created() {
        this.value = false;
    },
    methods: {
        addExample() {
            if (this.sample.length > 0) {
                this.frameSamples.push(this.sample);
                this.sample = '';
            }
        },
        addBehaviorExample(item) {
            if (item.sampleModel.length > 0) {
                item.text.push(item.sampleModel);
                item.sampleModel = '';
            }
        },
        getTitle() {
            switch (this.type) {
                case 1:
                    this.typeName = this.$t('quest.main.event_frame');
                    break;
                case 2:
                    this.typeName = this.$t('quest.main.story_frame');
                    break;
                case 3:
                    this.typeName = this.$t('quest.main.behavior_frame');
                    break;
            }
            return `${this.$t('quest.edit.edit_frame')}${this.typeName}`;
        },
        returnData() {
            if (this.$refs.titleForm.validate()) {
                this.$emit('return-data', {
                    title: this.frameTitle,
                    type: this.type,
                    samples: this.frameSamples,
                    typeName: this.typeName,
                });
                this.show = false;
            }
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.save = false;
                this.$emit('input', value)
            }
        }
    },
    watch: {
        show(val) {
            if (val) {
                this.frameTitle = `${this.title}`;
                this.frameSamples = [];
                if (this.type == 3) {
                    this.frameSamples = JSON.parse(JSON.stringify(this.defaultBehaviorSamples));
                    for (let sample of this.samples) {
                        let item = this.frameSamples.find(item => item.id == sample.id);
                        if (item) {
                            item.text = sample.text;
                        }
                    }
                } else {
                    if (this.samples) {
                        for (let sample of this.samples) {
                            this.frameSamples.push(sample);
                        }
                    }
                    this.sample = '';
                }
            }
        },
    }
}
</script>

<style scoped>
.card-no-outline {
    border: none;
}
</style>