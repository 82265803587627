<template>
    <v-container fluid>
        <div class="text-h6 grey--text text--darken-2">
            {{ $t('student_page.title') }}
        </div>
        <v-row class="my-2 mx-1">
            <span class="text-body-1 grey--text text--darken-2">
                {{ $t('student_page.student_account') }}
            </span>
            <v-spacer></v-spacer>
            <v-btn rounded small color="grey darken-4" @click="downloadSampleFile"
                class="mr-2 white--text font-weight-bold normal-case">
                {{ $t('student_page.download_button') }}</v-btn>
            <div>
                <input type="file" ref="fileInput" @change="onFileChange" accept=".csv" style="display: none" />
                <v-btn rounded small color="deep-orange darken-2"
                    @click="openFilePicker"
                    class="white--text font-weight-bold normal-case">
                    <v-icon>mdi-folder-open-outline</v-icon>
                    {{ $t('student_page.upload_button') }}</v-btn>
            </div>
        </v-row>
        <v-card outlined v-if="!previewMode">
            <v-select
                class="mx-6 mt-4"
                v-model="selectedFilter"
                :items="classes"
                dense outlined
                append-outer-icon="mdi-magnify"
                @click:append-outer="filter()"></v-select>
            <div v-if="selectedStudents.length > 0"
                class="mx-6 my-2 pb-1"
                style="background-color: #f8f9fa;">
                <v-chip-group
                    active-class="primary--text"
                    column class="ma-1">
                    <div v-for="student in selectedStudents" :key="student.id"
                        class="tooltip-container">
                        <v-chip label outlined>
                            {{ `${student.class} ${student.name} (${student.account})` }}
                        </v-chip>
                        <span class="tooltiptext" @click="removeStudent(student)">
                            {{ $t("button.cancel") }}</span>
                    </div>
                </v-chip-group>
                <v-divider></v-divider>
                <v-row class="mx-0 my-4 pa-0">
                    <v-spacer></v-spacer>
                    <v-btn small outlined @click="clearSelection"
                        class="normal-case">{{ $t('button.cancel') }}</v-btn>
                    <v-btn small color="error" class="mx-2 normal-case" @click="showDeleteStudentDialog">{{
                $t('post.delete') }}</v-btn>
                </v-row>
            </div>
            <v-data-table :headers="headers" :items="students"
                :server-items-length="totalStudents" :loading="loading"
                :page.sync="currentPage" :options.sync="studentsOptions"
                hide-default-footer class="mx-6 mt-2">
                <template v-slot:item.selected="{ item }">
                    <div class="checkbox-container">
                        <v-checkbox v-model="item.selected" @click.stop
                            @click="selection(item)"></v-checkbox>
                    </div>
                </template>
            </v-data-table>
            <div class="text-center my-4">
                <v-pagination v-model="currentPage" :length="pageCount" circle color="orange accent-2">
                </v-pagination>
            </div>
        </v-card>
        <v-card outlined v-else>
            <v-data-table :headers="previewHeader" :items="previewStudents"
                :items-per-page="previewStudents.length"
                hide-default-footer
                fixed-header>
                <template v-slot:item.year="{ item }">
                    <v-text-field v-model="item.year" outlined dense
                        class="mt-3" :style="{ 'min-width': '60px' }">
                    </v-text-field>
                </template>
                <template v-slot:item.grade="{ item }">
                    <v-text-field v-model="item.grade" outlined dense
                        class="mt-3" :style="{ 'min-width': '40px' }">
                    </v-text-field>
                </template>
                <template v-slot:item.class="{ item }">
                    <v-text-field v-model="item.class" outlined dense
                        class="mt-3" :style="{ 'min-width': '60px' }">
                    </v-text-field>
                </template>
                <template v-slot:item.name="{ item }">
                    <v-text-field v-model="item.name" outlined dense
                        class="mt-3" :style="{ 'min-width': '200px' }">
                    </v-text-field>
                </template>
                <template v-slot:item.account="{ item }">
                    <v-text-field
                        :error="item.account_duplicate"
                        :error-messages="item.account_duplicate ? $t('student_page.account_duplicate') : ''"
                        v-model="item.account" outlined dense
                        class="mt-3" :style="{ 'min-width': '200px' }"
                        @input="duplicateUpdate(item)">
                    </v-text-field>
                </template>
                <template v-slot:item.password="{ item }">
                    <v-text-field v-model="item.password" outlined dense
                        class="mt-3" :style="{ 'min-width': '80px' }">
                    </v-text-field>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn small class="my-auto normal-case" color="error"
                        @click="removePreviewStudent(item.id)">
                        {{ $t('post.delete') }}
                    </v-btn>
                </template>
            </v-data-table>
            <v-divider></v-divider>
            <v-row class="mx-0 my-4 pa-0">
                <v-spacer></v-spacer>
                <v-btn small outlined @click="clearPreview"
                    class="normal-case">{{ $t('button.cancel') }}</v-btn>
                <v-btn small color="error" class="mx-2 normal-case" @click="check">{{ $t('student_page.upload')
                    }}</v-btn>
            </v-row>
        </v-card>
        <v-dialog
            v-model="dialog"
            width="600">
            <v-card>
                <v-card-title>
                    {{ $t("student_page.error_dialog.title") }}
                </v-card-title>
                <v-card-text>
                    {{ errorMessage }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text class="normal-case"
                        @click="dialog = false">
                        {{ $t("button.confirm") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="assignJournalDialog"
            width="600">
            <v-card>
                <v-card-title>
                    {{ $t("student_page.assign_journal_dialog.title") }}
                </v-card-title>
                <v-divider class="mb-3"></v-divider>
                <div v-show="assignedJournal != ''" class="my-4 mx-4 py-4 pl-4"
                    style="border-radius: 5px; background-color: #e2e3e5;">
                    <v-icon color="deep-orange darken-3" class="my-auto">mdi-account-group</v-icon>
                    {{ assignDialogMessage }}
                </div>
                <v-card-subtitle>
                    {{ $t('student_page.assign_journal_dialog.title') }}
                </v-card-subtitle>
                <v-card-text>
                    <v-select
                        v-model="assignedJournal"
                        :items="diaryList"
                        outlined dense>
                    </v-select>
                </v-card-text>
                <v-card-text v-if="assignedJournal == '-1'">
                    {{ $t('student_page.assign_journal_dialog.new_journal') }}
                    <v-form ref="form">
                        <v-text-field
                            v-model="newJounalName"
                            :rules="rules"
                            :label="$t('student_page.assign_journal_dialog.new_journal_placeholder')">
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="normal-case"
                        @click="assignJournalDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn
                        color="deep-orange darken-3"
                        class="white--text normal-case"
                        @click="submit">
                        {{ $t("button.confirm") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="deleteDialog"
            width="600">
            <v-card>
                <v-card-title>
                    {{ $t("student_page.delete_dialog.title") }}
                </v-card-title>
                <v-card-text>
                    {{ $t("student_page.delete_dialog.message") }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text class="normal-case"
                        @click="deleteDialog = false">
                        {{ $t("button.cancel") }}
                    </v-btn>
                    <v-btn
                        color="error"
                        text class="normal-case"
                        @click="deleteStudent">
                        {{ $t("button.confirm") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { studentServices } from '@/services/student.js';
import { userPayServices } from '@/services/userPay.js';

export default {
    name: 'student',
    data() {
        return {
            headers: [{
                text: this.$t('student_page.table.select'),
                value: 'selected',
                align: 'center',
                class: 'grey lighten-4',
                filterable: false,
                sortable: false,
            },
            {
                text: this.$t('student_page.table.year'),
                value: 'schoolYear',
                align: 'center',
                class: 'grey lighten-4',
                filterable: false,
                sortable: false,
            },
            {
                text: this.$t('student_page.table.class'),
                value: 'class',
                align: 'center',
                class: 'grey lighten-4',
                filterable: false,
                sortable: false,
            },
            {
                text: this.$t('student_page.table.name'),
                value: 'name',
                align: 'center',
                class: 'grey lighten-4',
                filterable: false,
                sortable: false,
            },
            {
                text: this.$t('student_page.table.account'),
                value: 'account',
                align: 'left',
                class: 'grey lighten-4',
                filterable: false,
                sortable: false,
            },
            {
                text: '',
                value: 'actions', sortable: false,
                align: 'center',
                class: 'grey lighten-4',
                filterable: false,
            },],
            students: [],
            currentPage: 1,
            pageCount: 1,
            totalStudents: 0,
            loading: false,
            studentsOptions: {},
            selectedStudents: [],
            classes: [{
                text: this.$t('student_page.filter'),
                value: ''
            },],
            selectedFilter: '',
            selectedFile: null,
            previewMode: false,
            previewHeader: [
                {
                    text: '',
                    value: 'id',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('student_page.table.year'),
                    value: 'year',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('student_page.preview_table.grade'),
                    value: 'grade',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('student_page.table.class'),
                    value: 'class',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('student_page.table.name'),
                    value: 'name',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('student_page.table.account'),
                    value: 'account',
                    align: 'left',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: this.$t('student_page.preview_table.password'),
                    value: 'password',
                    align: 'left',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
                {
                    text: '',
                    value: 'actions',
                    align: 'center',
                    class: 'grey lighten-4',
                    filterable: false,
                    sortable: false,
                },
            ],
            previewStudents: [],
            errorMessage: '',
            dialog: false,
            duplicateAccount: [],
            diaryList: [{
                text: this.$t('student_page.assign_journal_dialog.none'),
                value: '',
            },
            {
                text: this.$t('student_page.assign_journal_dialog.new'),
                value: '-1',
            }],
            diary_count: 0,
            assignJournalDialog: false,
            assignDialogMessage: '',
            assignedJournal: '',
            newJounalName: '',
            rules: [(v) => !!v || this.$t('post_revise.comment_required')],
            deleteDialog: false,
            ucpCount: 0,
        };

    },
    watch: {
        studentsOptions: {
            handler() {
                this.getStudents();
            },
            deep: true,
        },
        assignedJournal: {
            handler() {
                this.getHomeworkNonUseUserChargePlanCount();
            },
            deep: true,
        }
    },
    async created() {
        ///Get basic info
        await this.getBasicInfo();
    },
    methods: {
        async getBasicInfo() {
            const res = await studentServices.getBasicInfo();
            res.diary_list.forEach(diary => {
                this.diaryList.push({
                    text: diary.name,
                    value: diary.id,
                });
            });
            this.diary_count = res.diary_count;
            res.class_list.forEach(classInfo => {
                this.classes.push({
                    text: classInfo.school_year + ' ' + this.$t('student_page.grade_class')
                        .replace('$(grade)', classInfo.grade)
                        .replace('$(classNo)', classInfo.class_no),
                    value: classInfo.class_id,
                });
            })
        },
        getStudents() {
            this.loading = true,
                this.getStudentList().then(data => {
                    this.students = data.items;
                    this.totalStudents = data.total;
                    this.pageCount = data.pageCount;
                    this.currentPage = data.page;
                    this.loading = false;
                });
        },
        async getStudentList() {
            const { page, class_id } = this.studentsOptions;
            const payload = {
                page: page,
                class_id: class_id ? class_id : '',
            }

            const res = await studentServices.getAllStudentList(payload);
            let items = [];
            res.list.list.forEach(item => {
                items.push({
                    id: item.id,
                    selected: false,
                    schoolYear: item.schoolYear,
                    class: this.$t('student_page.grade_class')
                        .replace('$(grade)', item.grade)
                        .replace('$(classNo)', item.classNo),
                    name: item.displayName,
                    account: item.account
                });
            });
            const total = res.list.total;
            const pageCount = res.list.totalPage;

            return { items, total, pageCount, page };
        },
        selection(item) {
            if (item.selected) {
                this.selectedStudents.push(item);
            } else {
                const index = this.selectedStudents.findIndex(student => student.id === item.id);
                if (index !== -1) {
                    this.selectedStudents.splice(index, 1);
                }
            }
        },
        removeStudent(student) {
            const index = this.selectedStudents.findIndex(s => s.id === student.id);
            const studentIndex = this.students.findIndex(s => s.id === student.id);
            if (index !== -1 && studentIndex !== -1) {
                this.selectedStudents.splice(index, 1);
                this.students[studentIndex].selected = false;
            }
        },
        clearSelection() {
            this.selectedStudents = [];
            this.students.forEach(student => {
                student.selected = false;
            });
        },
        downloadSampleFile() {
            try {
                const link = document.createElement('a');
                link.href = `/${this.$t('student_page.sample_file')}`;
                link.download = this.$t('student_page.sample_file');
                link.click();
            } catch (error) {
                console.log('error', error)
            }
        },
        async uploadStudents() {
            const payload = {
                file: this.selectedFile
            }
            const res = await studentServices.uploadStudents(payload);
            this.selectedFile = null;

            if (res.return_code) {
                switch (res.return_code) {
                    case '-12002':
                        this.errorMessage = res.message;
                        break;
                    case '-12003':
                        this.errorMessage = this.$t('student_page.error_dialog.value_error');
                        break;
                    default:
                        this.errorMessage = this.$t('student_page.error_dialog.file_error');
                        break;
                }
                this.dialog = true;
            } else {
                for (let student of res.list) {
                    let operatedStudent = {
                        id: this.previewStudents.length + 1,
                        year: student['0'],
                        grade: student['1'],
                        class: student['2'],
                        name: student['4'],
                        account: student['5'],
                        password: student['6']
                    }
                    if (student.is_exist == 1) {
                        operatedStudent.account_duplicate = true;
                    }
                    this.previewStudents.push(operatedStudent);
                }
                this.previewMode = true;
            }
        },
        openFilePicker() {
            this.$refs.fileInput.value = null;
            this.$refs.fileInput.click();
        },
        onFileChange(event) {
            this.selectedFile = event.target.files[0];
            if (this.selectedFile) {
                this.uploadStudents();
            } else {
                console.log('no file');
            }
        },
        removePreviewStudent(id) {
            let studentIndex = this.previewStudents.findIndex(student => student.id === id);
            if (studentIndex !== -1) {
                this.previewStudents.splice(studentIndex, 1);
            }
            if (this.previewStudents.length === 0) {
                this.clearPreview();
            }
        },
        clearPreview() {
            this.previewStudents = [];
            this.previewUnavailableStudents = [];
            this.previewMode = false;
        },
        async check() {
            let studentList = [];
            for (let student of this.previewStudents) {
                studentList.push({
                    0: student.year,
                    1: student.grade,
                    2: student.class,
                    4: student.name,
                    5: student.account,
                    6: student.password
                });
            }
            const payload = {
                student_data: JSON.stringify(studentList)
            }
            const result = await studentServices.uploadStudentsCheck(payload);
            const returnCode = result.return_code;
            if (returnCode) {
                switch (returnCode) {
                    case '-4': {
                        for (let student of this.previewStudents) {
                            student.account_duplicate = false;
                        }
                        const duplicateAccount = result.dup_account_list;
                        for (let account of duplicateAccount) {
                            const index = this.previewStudents.findIndex(student => student.account === account);
                            if (index !== -1) {
                                this.previewStudents[index].account_duplicate = true;
                            }
                        }
                        break;
                    }
                }
            } else {
                /// Choose assigned jornal
                this.assignDialogMessage = this.$t('manage.remain') +
                    this.diary_count + ' / ' + this.ucpCount;
                this.assignJournalDialog = true;
            }
        },
        duplicateUpdate(item) {
            item.account_duplicate = false;
        },
        filter() {
            this.studentsOptions.class_id = this.selectedFilter;
            this.studentsOptions.page = 1;
            this.getStudents();
        },
        async submit() {
            if (this.assignedJournal != '' && this.previewStudents.length > this.ucpCount) {
                alert(this.$t('user_charge_plan.error.unenough'));
                return;
            }
            if ((this.assignedJournal == '-1' && this.$refs.form.validate())
                || this.assignedJournal != '-1') {
                let studentList = [];
                for (let student of this.previewStudents) {
                    studentList.push({
                        0: student.year,
                        1: student.grade,
                        2: student.class,
                        4: student.name,
                        5: student.account,
                        6: student.password
                    });
                }
                let payload = {
                    student_data: JSON.stringify(studentList)
                }
                if (this.assignedJournal != '-1' && this.assignedJournal != '') {
                    payload.hw_id = this.assignedJournal;
                } else if (this.assignedJournal == '-1') {
                    payload.hw_name = this.newJounalName;
                }
                const submitResult = await studentServices.uploadStudentsSubmit(payload);
                if (submitResult.error_code) {
                    switch (submitResult.error_code) {
                        case '-4':
                            this.errorMessage = this.$t('student_page.error_dialog.over_limit');
                            break;
                        case '-2':
                            this.errorMessage = this.$t('student_page.error_dialog.homework_error');
                            break;
                        default:
                            this.errorMessage = submitResult.message;
                            break;
                    }
                    this.dialog = true;
                } else {
                    this.$toastr.Add({
                        msg: this.$t('student_page.import_success'),
                        clickClose: true,
                        timeout: 3000,
                        position: "toast-top-right",
                        classNames: ["animated", "bounceInRight"],
                    });
                    window.location.reload();
                }
            }
        },
        showDeleteStudentDialog() {
            this.deleteDialog = true;
        },
        async deleteStudent() {
            let studentIds = '';
            for (const student of this.selectedStudents) {
                if (studentIds.length > 0) {
                    studentIds += ',';
                }
                studentIds += student.id;
            }
            const payload = {
                user_ids: studentIds
            }
            const deleteResult = await studentServices.deleteStudent(payload);
            if (deleteResult.result) {
                this.$toastr.Add({
                    msg: this.$t('student_page.delete_success'),
                    clickClose: true,
                    timeout: 3000,
                    position: "toast-top-right",
                    classNames: ["animated", "bounceInRight"],
                });
                window.location.reload();
            }
        },
        async getHomeworkNonUseUserChargePlanCount() {
            if (this.assignedJournal == '') {
                this.ucpCount = 0;
                this.assignDialogMessage = '';
                return;
            }
            console.log('this.assignedJournal:', this.assignedJournal)
            if (this.assignedJournal == '-1') {
                this.ucpCount = await userPayServices.getUnsoldUserChargePlanCount();
                this.assignDialogMessage = this.$t('user_charge_plan.ticket_remain_num') +
                this.previewStudents.length + ' / ' + this.ucpCount;
            } else {
                let payload = {
                    homework_id: this.assignedJournal
                };
                this.ucpCount = await userPayServices.getHomeworkNonUseUserChargePlanCount(payload);
                this.assignDialogMessage = this.$t('user_charge_plan.homework_ticket_remain_num') +
                    this.previewStudents.length + ' / ' + this.ucpCount;
            }
            
        }
    },
};
</script>

<style scoped>
.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltiptext {
    visibility: hidden;
    position: absolute;
    background-color: rgba(66, 66, 66, 0.85);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
</style>
