export const state = {
    user: null,
    consoleIndexUrl: null,
    consoleFuncList: [],
    contestIndexUrl: null,
    contestFuncList: [],
    barColor: '#FF7043',
    barImage: '',
    drawer: null,
    hasFinishFirstRound: false,
    homeworkName: '',
}