<template>
  <div class="main-content error-page row no-gutters justify-center mt-4">
    <div
      class="col-xs-12 align-center justify-center row no-gutters text-center"
    >
      <v-alert outlined type="warning" prominent border="left"
        >Oooops~找不到頁面</v-alert
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "NotFound",
};
</script>
