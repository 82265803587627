<template>
    <v-app>
        <AppBar v-bind:showMenu="false"/>
        <v-main>
           <router-view :key="$route.path"></router-view>
        </v-main>
    </v-app>
</template>

<script>
import AppBar from "@/components/ebook/BasicAppBar.vue";

export default {
    name: "EbookLayout",
    components: {
        AppBar,
    },
    data: () => ({
        
    }),
};
</script>
<style>
.book-procedure {
    box-shadow: -1px 2px 5px #e3e0e0;
    z-index: 4;
    position: relative;
    padding: 5px;
}
</style>
