import servicesUtils from '../utils/service'

export const diaryPostReviseServices = {
    async getReviseList(diaryPostId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getPostRevise')}`
                .replace(':diaryPostId', diaryPostId) + '?'
                + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getPostList err: ${err}`)
            throw err
        }
    },
    async addPostRevise(diaryPostId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getPostRevise')}`
                .replace(':diaryPostId', diaryPostId);
            const data = await servicesUtils.post(url, payload);
            return data
        } catch (err) {
            console.log(`failed to get getPostList err: ${err}`)
            throw err
        }
    },
    async editPostRevise(diaryPostId, commentId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('editPostRevise')}`
                .replace(':diaryPostId', diaryPostId)
                .replace(':commentId', commentId);
            const data = await servicesUtils.post(url, payload);
            return data
        } catch (err) {
            console.log(`failed to get getPostList err: ${err}`)
            throw err
        }
    },
    async deletePostRevise(diaryPostId, commentId) {
        try {
            const url = `${servicesUtils.resourceUrl('editPostRevise')}`
                .replace(':diaryPostId', diaryPostId)
                .replace(':commentId', commentId);
            const data = await servicesUtils.delete(url);
            return data
        } catch (err) {
            console.log(`failed to get getPostList err: ${err}`)
            throw err
        }
    },
    async getReviseLibrary(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getPostReviseLibrary')}`
                + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getPostList err: ${err}`)
            throw err
        }
    },
    async deleteReviseLibraryComment(commentId) {
        try {
            const url = `${servicesUtils.resourceUrl('editReviseLibraryComment')}`
                .replace(':id', commentId);
            const data = await servicesUtils.delete(url);
            return data
        } catch (err) {
            console.log(`failed to get getPostList err: ${err}`)
            throw err
        }
    },
    async addReviseLibraryComment(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getPostReviseLibrary')}`;
            const data = await servicesUtils.post(url, payload);
            return data
        } catch (err) {
            console.log(`failed to get getPostList err: ${err}`)
            throw err
        }
    },
}