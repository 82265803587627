<template>
    <v-container fluid>
        <div class="text-h6 grey--text text--darken-2">{{ $t('voucher.title') }}</div>
        <v-tabs class="my-2" v-model="tab" color="#DE714B">
            <v-tab href="#tabUnused">
                {{ $t('voucher.unused') }}
            </v-tab>
            <v-tab href="#tabHistory">
                {{ $t('voucher.history') }}
            </v-tab>
        </v-tabs>
        <v-divider class="my-4 "></v-divider>
        <v-tabs-items class="my-2" v-model="tab" color="#DE714B">
            <v-tab-item value="tabUnused">
                <v-row class="ma-2">
                    <v-form class="my-auto" lazy-validation ref="redeemForm">
                        <v-text-field
                            :rules="redeemCodeRules"
                            v-model="redeemCode"
                            dense
                            :placeholder="voucherInputPlaceholder"
                            outlined></v-text-field>
                    </v-form>
                    <v-btn outlined class="mx-2 mt-2 normal-case" small @click="redeemVoucher">
                        {{ $t('voucher.redeem_button') }}</v-btn>
                </v-row>
                <v-row class="ma-2" v-if="haveMoreUnusedVouchers">
                    <v-btn outlined small @click="getUnusedVouchers"
                        class="normal-case">{{ $t('voucher.refresh_button') }}</v-btn>
                </v-row>
                <v-alert v-model="redeemError" dismissible type="error" max-width="300">
                    {{ redeemErrorMsg }}
                </v-alert>
                <v-alert v-model="redeemSuccess" dismissible type="success" max-width="300">
                    {{ $t('voucher.redeem_success') }}
                </v-alert>
                <div v-if="unusedVouchers.length == 0"
                    class="grey--text text--darken-2 text-center">
                    NO DATA </div>
                <v-alert
                    v-for="(item) in unusedVouchers"
                    border="left"
                    colored-border
                    color="#DE714B"
                    elevation="1"
                    class="mx-1 my-5"
                    :key="item.id">
                    <v-row class="text-h6 my-2 mx-2">
                        <v-col cols="10">
                            <v-row>{{ item.item_name }}</v-row>
                            <v-row class="text-body-1">{{ getExpiredStr(item.expire_date) }}</v-row>
                        </v-col>
                        <v-col col="2" class="ma-auto">
                            <v-row>
                                <v-btn class="dynamic-wrap-button normal-case"
                                    outlined small @click="useVoucher(item.id)">{{
                                        $t('voucher.use_button')
                                    }}</v-btn>
                            </v-row>
                            <v-row v-if="item.code" class="pt-2">
                                <v-btn v-if="!item.clicked" outlined small
                                    @click="copyTextToClipboard(item, item.code)"
                                    class="dynamic-wrap-button normal-case">
                                    {{ $t('voucher.transfer_button') }}</v-btn>
                                <v-btn v-else outlined small color="blue"
                                    class="dynamic-wrap-button normal-case"
                                    @click="copyTextToClipboard(item, item.code)">
                                    {{ $t('voucher.transfer_code') + item.code }}</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-tab-item>
            <v-tab-item value="tabHistory">
                <div v-if="vouchers.length == 0"
                    class="grey--text text--darken-2 text-center"> NO DATA </div>
                <v-alert
                    v-for="(item) in vouchers"
                    border="left"
                    colored-border
                    color="grey lighten-2"
                    elevation="1"
                    class="mx-1 my-5"
                    :key="item.id">
                    <v-row class="text-h6 my-2 mx-2">
                        <v-col>
                            <v-row>{{ item.item_name }}</v-row>
                            <v-row class="text-body-1">{{ getExpiredStr(item.expire_date) }}</v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-btn class="my-auto normal-case" outlined disabled v-if="item.status == 2">
                            {{ $t('voucher.transfered') }}
                        </v-btn>
                        <v-btn class="my-auto normal-case" outlined disabled v-else>{{ $t('voucher.used') }}</v-btn>
                    </v-row>
                </v-alert>
                <v-row class="ma-2" v-if="haveMoreVouchers">
                    <v-btn outlined small @click="getVouchers"
                        class="normal-case">{{ $t('voucher.refresh_button') }}</v-btn>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>
<script>
import { userServices } from "@/services/user.js";

export default {
    name: 'voucher',
    data() {
        return {
            tab: null,
            voucherInputPlaceholder: this.$t('voucher.redeem_instruction'),
            vouchers: [],
            unusedVouchers: [],
            currentVoucherPage: 1,
            haveMoreVouchers: true,
            currentUsedVoucherPage: 1,
            haveMoreUnusedVouchers: true,
            redeemCode: '',
            redeemCodeRules: [
                v => !!v || this.$t('voucher.redeem_code_required'),
            ],
            redeemSuccess: false,
            redeemErrorMsg: '',
            redeemError: false,
        }
    },
    created() {
        this.getUnusedVouchers();
        this.getVouchers();
    },
    methods: {
        async getVouchers() {
            const payload = {
                'page': this.currentUsedVoucherPage
            }
            const result = await userServices.getMyVouchers(payload);

            console.log('voucher result:', result)

            for (let item of result.data.datas) {
                this.vouchers.push(item);
            }
            if (result.data.total_pages == this.currentVoucherPage || result.data.total_pages == 0) {
                this.haveMoreVouchers = false;
            } else {
                this.currentVoucherPage += 1;
            }
        },
        async getUnusedVouchers() {
            const payload = {
                'page': this.currentUsedVoucherPage,
                'item_type': '1'
            }
            const result = await userServices.getMyVouchers(payload);

            console.log('unused result:', result);

            for (let item of result.data.datas) {
                item['clicked'] = false;
                this.unusedVouchers.push(item);
            }
            if (result.data.total_pages == this.currentUsedVoucherPage) {
                this.haveMoreUnusedVouchers = false;
            } else {
                this.currentUsedVoucherPage += 1;
            }
        },
        getExpiredStr(date) {
            if (date) {
                return this.$t('voucher.use_before') + date;
            } else {
                return this.$t('voucher.no_expired_date');
            }
        },
        async redeemVoucher() {
            if (this.$refs.redeemForm.validate()) {
                const payload = {
                    'code': this.redeemCode
                }
                const result = await userServices.redeemVoucher(payload);
                const data = result.data;
                if (data.error_code == '-1') {
                    this.redeemErrorMsg = this.$t('voucher.redeem_error_unavailable');
                    this.redeemError = true;
                } else if (data.error_code == '-2') {
                    this.redeemErrorMsg = this.$t('voucher.redeem_error_self');
                    this.redeemError = true;
                } else if (data.error_code == '-3') {
                    this.redeemErrorMsg = this.$t('voucher.redeem_error_used');
                    this.redeemError = true;
                } else {
                    this.reloadUnusedVoucher();
                    this.redeemSuccess = true;
                }
            }
        },
        reloadUnusedVoucher() {
            this.unusedVouchers = [];
            this.currentUsedVoucherPage = 1;
            this.getUnusedVouchers();
        },
        async useVoucher(id) {
            const result = await userServices.useVoucher(id);
            const data = result.data;

            if (data.error_code == '-2') {
                this.redeemErrorMsg = this.$t('voucher.plan_inuse');
                this.redeemError = true;
            } else if (data.error_code == '-3') {
                this.redeemErrorMsg = this.$t('voucher.expired');
                this.reloadUnusedVoucher();
                this.redeemError = true;
            } else if (data.error_code == '-4') {
                this.redeemErrorMsg = this.$t('voucher.use_error');
                this.redeemError = true;
            } else {
                sessionStorage.setItem('redirect_path', '/voucher');
                this.$toastr.Add({
                    msg: this.$t('voucher.use_successed'),
                    clickClose: true,
                    timeout: 3000,
                    position: "toast-top-right",
                    classNames: ["animated", "bounceInRight"],
                    onClicked: () => {
                        console.log('onClicked on toastr');
                        this.$router.push('/refresh');
                    },
                    onClosed: () => {
                        console.log('onClosed on toastr');
                        this.$router.push('/refresh');
                    },
                });
            }
        },
        async copyTextToClipboard(item, str) {
            if (!item.clicked) {
                item.clicked = true;
            }
            try {
                await navigator.clipboard.writeText(str);
                this.$toastr.Add({
                    msg: this.$t('voucher.code_copied'),
                    clickClose: true,
                    timeout: 3000,
                    position: "toast-top-right",
                    classNames: ["animated", "bounceInRight"],
                });
            } catch (err) {
                console.error("Unable to copy text", err);
            }
        }
    }
}
</script>
<style scoped>
.dynamic-wrap-button .v-btn__content {
    white-space: normal;
    text-align: center;
}
</style>