import servicesUtils from '../utils/service'

export const contestServices = {
    async takeContestBook(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('takeContestBook')}`;
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to get takeContestBook err: ${err}`)
            throw err
        }
    },
    async getHomeworkInfo(id) {
        try {
            const url = `${servicesUtils.resourceUrl('getContestHomeworkInfo')}`
                .replace(':id', id);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getHomeworkInfo err: ${err}`)
            throw err
        }
    },
    async updateHomeworkInfo(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getContestHomeworkInfo')}`
                .replace(':id', id);
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to post updateHomeworkInfo err: ${err}`)
            throw err
        }
    },
    async checkHomeworkContest(id) {
        try {
            const url = `${servicesUtils.resourceUrl('checkHomeworkContest')}`
                .replace(':diaryHomeworkId', id);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`checkHomeworkContest failed with err: ${err}`)
            throw err
        }
    },
    async inviteCodeVerify(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('contestInviteCoderVerify')}`
                + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get inviteCoderVerify err: ${err}`)
            throw err;
        }
    },
    async getTeamRanking(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getTeamRanking')}`
                .replace(':id', id) + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getTeamRanking, err: ${err}`)
            throw err;
        }
    },
    async getStudentRanking(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getStudentRanking')}`
                .replace(':id', id) + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getTeamRanking, err: ${err}`)
            throw err;
        }
    },
    async getTeamInfo(id) {
        try {
            const url = `${servicesUtils.resourceUrl('getTeamInfo')}`
                .replace(':id', id);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getTeamRanking, err: ${err}`)
            throw err;
        }
    },
}