import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import PortalLayout from './layout/PortalLayout.vue'
import DefaultLayout from './layout/DefaultLayout.vue'
import ConsoleLayout from './layout/ConsoleLayout.vue'
import ConsoleMainLayout from './layout/ConsoleMainLayout.vue'
import ConsoleNoMenuLayout from './layout/ConsoleNoMenuLayout.vue'
import EbookLayout from './layout/EbookLayout.vue'
import vuetify from './plugins/vuetify'
import router from './router/index'
import store from './store'
import i18n from './locale/i18n'
import VueToastr from 'vue-toastr'
// import VueFbCustomerChat from 'vue-fb-customer-chat'
import VueGtag from 'vue-gtag';
import '@/assets/font/font.css'
require('./assets/css/animate.css')

Vue.config.productionTip = false

Vue.component('defaultLayout', DefaultLayout)
Vue.component('portalLayout', PortalLayout)
Vue.component('consoleLayout', ConsoleLayout)
Vue.component('consoleMainLayout', ConsoleMainLayout)
Vue.component('consoleNoMenuLayout', ConsoleNoMenuLayout)
Vue.component('EbookLayout', EbookLayout)

// Vue.http.headers.common('X-Frame-Options', 'DENY')
// Vue.http.headers.common('Cache-Control', 'no-cache, no-store, must-revalidate')
// Vue.http.headers.common('Pragma', 'no-cache')
// Vue.http.headers.common('X-Content-Type-Options', 'nosniff')
Vue.use(Vuelidate);
Vue.use(VueToastr);
// Vue.use(VueFbCustomerChat, {
//   page_id: "120523752672170",
//   theme_color: '#FF7043',
//   locale: 'zh_TW',
// });

Vue.use(VueGtag, {
  config: { id: 'UA-161741191-1' },
})

Vue.prototype.$cropperOption = {
  size: 1,
  canScale: false,
  mode: 'cover',
  full: true,
  outputType: 'png',
  canMove: true,
  original: false,
  high: true,
  max: 99999,
  fixed: true,
  canMoveBox: false,
  fixedBox: true,
  centerBox: true,
  autoCrop: true,
},

Vue.prototype.$getCropperImg = function (cropper) {
  return new Promise((resolve) => {
    if (typeof cropper != 'undefined') {
      cropper.getCropBlob(data => {

        console.log('getCropBlob', data);

        resolve({
          status: 1,
          data: data
        })
      })
      ///base64
      // cropper.getCropData(data => {
      //   // do something
      //   // console.log(data) 
      //   resolve({
      //     status: 1,
      //     data: data
      //   })
      // })
    } else {
      resolve({
        status: 0
      })
    }
  });
},

Vue.prototype.$getCropperData = function (cropper) {
  return new Promise((resolve) => {
    if (typeof cropper != 'undefined') {
      ///base64
      cropper.getCropData(data => {
        // do something
        // console.log(data) 
        resolve({
          status: 1,
          data: data
        })
      })
    } else {
      resolve({
        status: 0
      })
    }
  });
},

Vue.prototype.$uploadImage = function (uploadFile, fileOption) {
  //var file = e.target.files[0]
  var reader = new FileReader();
  reader.readAsArrayBuffer(uploadFile)
  reader.onload = () => {
    let data = null;
    if (typeof uploadFile === 'object') {
      // 把Array Buffer转化为blob 如果是base64不需要
      data = window.URL.createObjectURL(new Blob([uploadFile]))
    } else {
      data = uploadFile
    }
    fileOption.img = data;
    this.reloadCropper();
  }
},

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
