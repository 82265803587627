import servicesUtils from '../utils/service'

export const exportServices = {
    async getRawData(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('exportRaw')}`
                + '?'
                + servicesUtils.queryParams(payload);
            const res = await fetch(url, {
                method: 'get',
                responseType: 'blob',
                credentials: 'include'
            });
            return res;
        } catch (err) {
            console.log(`failed in getRawData, err: ${err}`)
            throw err
        }
    },
    async getBCardData(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('exportBCard')}`
                + '?'
                + servicesUtils.queryParams(payload);
            const res = await fetch(url, {
                method: 'get',
                responseType: 'blob',
                credentials: 'include'
            });
            return res;
        } catch (err) {
            console.log(`failed in getRawData, err: ${err}`)
            throw err
        }
    }
}