<template>
    <v-app>
        <AppBar v-bind:showMenu="false"/>
        
        <v-main>
            <v-container fluid class="px-5 py-4">
                <router-view :key="$route.path"></router-view>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import AppBar from "@/components/console/AppBar.vue";

export default {
    name: "ConsoleNoMenuLayout",
    components: {
        AppBar,
    },
    data: () => ({
        
    }),
};
</script>
<style>
.cursor-pointer {
    cursor: pointer;
}
.edit-option-fab {
    position: absolute;
    top: 0;
    right: 0
}
.border-bottom {
    border-bottom: 1px solid #ddd;
}
.border {
    border: 1px solid #ddd;
}

.logo-block {
    width: 180px;
}

.logo-block>div {
    aspect-ratio: 3 / 1;
}

.banner-block {
    width: 240px;
}

.banner-block>div {
    aspect-ratio: 4 / 1;
}

.thumbnail-block {
    width: 60px;
}

.thumbnail-block>div {
    aspect-ratio: 1 / 1;
}

@media (min-width: 600px) {
    .logo-block {
        width: 300px;
    }

    .banner-block {
        width: 400px !important;
    }

    .thumbnail-block {
        width: 100px;
    }
}

@media (min-width: 960px) {
    .logo-block {
        width: 330px;
    }

    .banner-block {
        width: 440px !important;
    }

    .thumbnail-block {
        width: 110px;
    }
}

@media (min-width: 1264px) {
    .logo-block {
        width: 330px;
    }

    .banner-block {
        width: 440px !important;
    }

    .thumbnail-block {
        width: 150px;
    }
}
</style>
