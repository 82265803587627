<template>
    <v-container fluid class="ma-0 pa-0 d-flex flex-column" style="min-height: 100vh;">
        <div class="black py-4 text-center">
            <span class="white--text text-h4 text-center mx-auto">{{ $t('info_setting.title') }}</span>
        </div>
        <div class="flex-grow-1 align-content-center justify-center">
            <div v-if="nameSetting" class="mx-auto" style="width: 500px">
                <v-form ref="name">
                    <span>{{ $t('info_setting.name') }}</span>
                    <v-text-field outlined dense class="mt-3" v-model="name" id="username" ref="username"
                        key="name-field" :rules="[rules.required]"></v-text-field>
                    <span>{{ $t('info_setting.gender') }}</span>
                    <v-select outlined dense v-model="selectedGender" :items="genderOptions" item-text="text"
                        item-value="value" class="mt-3">
                    </v-select>
                </v-form>
            </div>
            <div v-else class="mx-auto" style="width: 500px;">
                <v-form ref="form">
                    <span>{{ $t('info_setting.country') }}</span>
                    <v-text-field outlined dense class="mt-3" v-model="country" :rules="[rules.required]" id="country"
                        key="country-field"></v-text-field>
                    <span>{{ $t('info_setting.school') }}</span>
                    <v-text-field outlined dense class="mt-3" v-model="school" :rules="[rules.required]"></v-text-field>
                    <span>{{ $t('info_setting.position') }}</span>
                    <v-text-field outlined dense class="mt-3" v-model="position"
                        :rules="[rules.required]"></v-text-field>
                    <span>{{ $t('info_setting.invite_code') }}</span>
                    <v-text-field outlined dense class="mt-3" v-model="code"></v-text-field>
                </v-form>
            </div>
        </div>
        <div class="d-flex justify-end grey lighten-2 py-4 fix-y">
            <v-spacer />
            <v-btn rounded class="black--text mr-4 normal-case" @click=updateInfo>
                {{ nameSetting ? $t('info_setting.next') : $t('info.diary.button.done') }}</v-btn>
        </div>
        <v-overlay :value="loadingData">
            <v-progress-circular indeterminate color="amber darken-3"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
/* global google */
import { userServices } from '@/services/user';
import { loginServices } from "@/services/login";
import { getItem, setItem, removeItem } from '@/utils/storageManager';

export default {
    name: "BasicInfoSetting",
    data() {
        return {
            nameSetting: true,
            selectedGender: 'm',
            // selectedGender: { value: 'm', text: this.$t('info_setting.male') },
            genderOptions: [
                { value: 'm', text: this.$t('info_setting.male') },
                { value: 'f', text: this.$t('info_setting.female') },
            ],
            country: '',
            level1: '',
            level2: '',
            level3: '',
            school: '',
            position: '',
            code: '',
            name: '',
            rules: {
                required: value => !!value || this.$t('post_revise.comment_required'),
            },
            loadCountryAddr: false,
            loadingData: false,
        };
    },
    async created() {
        this.loadingData = true;
        const userInfo = await loginServices.getMyInfo();
        this.name = getItem('userName');
        if (this.name === 'null' || this.name === '-1') {
            this.name = '';
        } else if (userInfo.user.gender != null && userInfo.user.gender !== '') {
            this.nameSetting = false;
        }
        this.loadingData = false;
    },

    watch: {
        country() {
            let countryValue = document.getElementById('country').getAttribute('data-input');
            if (this.country != countryValue)
                this.loadCountryAddr = false;
        },
        nameSetting(val) {
            if (val == false) {
                this.loadGoogleMapsScript();
            }
        }
    },
    methods: {
        async updateNameSetting() {
            if (!this.$refs.name.validate()) return;
            setItem('userName', this.name);
            const payload = {
                name: this.name,
                gender: this.selectedGender,
            }
            const response = await userServices.basicInfoSetting(payload);
            if (response.data.returnCode) {
                // some error
            } else {
                this.nameSetting = false;
            }
        },
        updateInfo() {
            if (this.nameSetting) {
                this.updateNameSetting();
            } else {
                this.checkAddress();
            }
        },
        async checkAddress() {
            if (!this.$refs.form.validate()) return;
            try {
                let locale = getItem('locale');
                let language = 'en';
                switch (locale) {
                    case 'zh':
                    case 'zh-tw':
                        language = 'zh-tw';
                        break;
                }
                let checkCountry = this.country;
                if (this.country == 'SG' || this.country == 'sg') {
                    checkCountry = 'Singapore';
                }
                const mapKey = process.env.VUE_APP_MAP_KEY;
                const result = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + checkCountry + `&key=${mapKey}&language=${language}`)
                    .then(response => response.json())
                    .then(data => data)
                    .catch(error => console.log('error in fetch map:', error));

                if (result.status != 'OK') {
                    alert(this.$t('info_setting.no_place_found'));
                    return;
                }
                const acs = result.results[0].address_components;
                if (!this.loadCountryAddr) {
                    if (confirm('Do you mean ' + result.results[0].formatted_address + '?')) {
                        this.parseAddr(acs);
                    }
                } else {
                    this.parseAddr(acs);
                }
                this.updateOtherInfo();
            } catch (error) {
                console.log('error:', error);
            }
        },
        async parseAddr(acs) {
            for (let i = 0; i < acs.length; i++) {
                let ac = acs[i];
                if (ac.types[0] == 'country') {
                    this.country = ac.short_name;
                } else if (ac.types[0] == 'administrative_area_level_1') {
                    //州、直轄市
                    this.level1 = ac.short_name;
                } else if (ac.types[0] == 'administrative_area_level_2') {
                    //州下面的市、縣
                    this.level2 = ac.short_name;
                } else if (ac.types[0] == 'administrative_area_level_3') {
                    //區
                    this.level3 = ac.short_name;
                }
            }
            if (this.level1 == '' && this.level2 != '') {
                /*台灣縣市應該可以放在同一個等級*/
                this.level1 = this.level2;
                this.level2 = '';
            }
        },
        async updateOtherInfo() {
            const payload = {
                country: this.country,
                level1: this.level1,
                level2: this.level2,
                level3: this.level3,
                school: this.school,
                position: this.position,
                invite_code: this.code,
            }
            await userServices.schoolSetting(payload);
            // this.$router.push('/diary');
            removeItem('infoSet');
            this.$router.push('/refresh');
        },
        loadGoogleMapsScript() {
            let locale = getItem('locale');
            let language = 'en';
            switch (locale) {
                case 'zh':
                case 'zh-tw':
                    language = 'zh-tw';
                    break;
            }
            const mapKey = process.env.VUE_APP_MAP_KEY;
            const src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places&language=${language}`;
            // Check if the script is already loaded
            if (!document.querySelector('script[src="https://maps.googleapis.com/maps/api/js"]')) {
                const script = document.createElement('script');
                script.src = src;
                script.async = true;
                script.defer = true;
                document.body.appendChild(script);
                script.onload = () => {
                    this.$nextTick(() => {
                        this.initializeAutocomplete();
                    });
                }
            } else {
                this.$nextTick(() => {
                    this.initializeAutocomplete();
                });
            }
        },
        initializeAutocomplete() {
            const input = document.getElementById('country');
            if (input && input.id === 'country') {
                const options = {
                    types: ['(cities)']
                };
                if (window.google && window.google.maps && window.google.maps.places) {
                    const autocomplete = new google.maps.places.Autocomplete(input, options);
                    autocomplete.addListener('place_changed', () => {
                        const place = autocomplete.getPlace();
                        let formattedAddr = this.formatAddress(place.adr_address);
                        input.value = formattedAddr;
                        input.setAttribute('data-input', formattedAddr);
                        this.country = formattedAddr;
                        this.loadCountryAddr = true;
                    });
                }
            } else {
                console.log('country input not found');
            }
        },
        formatAddress(adrAddress) {
            let parts = adrAddress.split('<span');
            let formattedAddr = '';

            for (let i = 0; i < parts.length; i++) {
                let p = parts[i];
                if (p.includes('span') && !p.includes('postal-code')) {
                    formattedAddr += p.substring(p.indexOf('">') + 2, p.indexOf('</s'));
                    p = p.substring(p.lastIndexOf('>') + 1);

                    if (p != '') {
                        formattedAddr += p;
                    }
                } else if (p != '' && !p.includes('postal-code')) {
                    formattedAddr += p;
                }
            }
            return formattedAddr;
        },
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    },
}
</script>

<style scoped>
.fix-height-container {
    height: calc(100vh - 380px);
    margin-top: calc((100vh - 400px) / 2);
}

.fix-height-container-shorter {
    height: calc(100vh - 280px);
    margin-top: calc((100vh - 500px) / 2);
}

.fix-height-container span {
    vertical-align: middle;
}
</style>