import servicesUtils from '../utils/service'

export const diaryStatisticServices = {
    async getTrendData(homeworkId, payload) {
        try {
            
            const url = `${servicesUtils.resourceUrl('getTrendData')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getTrendData err: ${err}`)
            throw err
        }
    },
    async getTrendPostList(homeworkId, payload) {
        try {
            
            const url = `${servicesUtils.resourceUrl('getTrendDataPost')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getTrendPostList err: ${err}`)
            throw err
        }
    },
    async getEmotion4Data(homeworkId, payload) {
        try {
            
            const url = `${servicesUtils.resourceUrl('getEmotion4Data')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEmotion4Data err: ${err}`)
            throw err
        }
    },

    async getEmotion4PostList(homeworkId, payload) {
        try {
            
            const url = `${servicesUtils.resourceUrl('getEmotion4DataPost')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEmotion4PostList err: ${err}`)
            throw err
        }
    },
    async getEmotionsData(homeworkId, payload) {
        try {
            
            const url = `${servicesUtils.resourceUrl('getEmotionsData')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEmotionsData err: ${err}`)
            throw err
        }
    },

    async getEmotionsDataPostList(homeworkId, payload) {
        try {
            
            const url = `${servicesUtils.resourceUrl('getEmotionsDataPost')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEmotionsDataPostList err: ${err}`)
            throw err
        }
    },
    
    async getBehaviorsData(homeworkId, payload) {
        try {
            
            const url = `${servicesUtils.resourceUrl('getBehaviorsData')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getBehaviorsData err: ${err}`)
            throw err
        }
    },
    async getBehaviorsPostList(homeworkId, payload) {
        try {
            
            const url = `${servicesUtils.resourceUrl('getBehaviorsDataPost')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEmotionsDataPostList err: ${err}`)
            throw err
        }
    },
}