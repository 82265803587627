<template>
    <v-container fluid>
        <v-btn text class="normal-case">
            <v-icon>mdi-arrow-left</v-icon>
            {{ $t('button.back') }}
        </v-btn>
        <div></div>
        <span class="ma-20 pa-20">
            {{ `${$t('menu.mode.personalMode')} - ${$t('manage.title')}` }}
        </span>
        <div></div>
        <div>
            <p v-html="$t('manage.desc')"></p>
            <!-- {{ $t('manage.desc') }} -->
        </div>
        <v-btn class="normal-case">
            {{ $t('manage.btn.upgrade') }}
        </v-btn>
        <v-divider class="my-4 "></v-divider>
        <v-card outlined>
            <v-card-subtitle>
                {{ $t('chargePlan.name.free') }}
            </v-card-subtitle>
            <v-card-text>
                <v-icon color="#DE714B" class="mx-2">mdi-account-group</v-icon>
                {{ `${$t('manage.remain')} ${usedStudent}/${studentLimit}` }}
            </v-card-text>
        </v-card>
        <v-tabs class="my-2" v-model="optionTab" color="#DE714B">
            <v-tab href="#tabJournal">
                {{ $t('manage.option.journals') }}
            </v-tab>
            <v-tab href="#tabStudent">
                {{ $t('manage.option.student') }}
            </v-tab>
        </v-tabs>
        <v-tabs-items class="my-2" v-model="optionTab" color="#DE714B">
            <v-tab-item value="tabJournal">
                <v-card outlined>
                   
                </v-card>
            </v-tab-item>
            <v-tab-item value="tabStudent">
                <v-card outlined>
                    
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-container>
</template>

<script>
export default {
    name: "Manage",
    components: {},
    data() {
        return {
            usedStudent: 15,
            studentLimit: 10,
            optionTab: '',
        };
    },
    async created() { },
    methods: {},
};
</script>

<style scoped></style>