<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <Resource v-bind:resourceRes="resources"></Resource>
    </v-container>
</template>

<script>
import { diaryServices } from "@/services/diary.js";
import Resource from '@/components/console/diary/component/Resource.vue';
import { mapState } from "vuex";

export default {
    name: 'diaryResource',
    components: {
        Resource
    },
    data() {
        return {
            breadcrumbsItems: [],
            homeworkId: '',
            title: '',
            resources: []
        }
    },
    async created() {
        this.homeworkId = this.$route.params.id;

        const resourceRes = await diaryServices.getPublishSampleHomeworkResources(this.homeworkId);
        
        this.breadcrumbsItems.push({
            text: this.$t('menu.diary_resource'),
            disabled: false,
            href: `/diarySample/resourcesBook`,
        }, {
            text: resourceRes.data.info.name,
            disabled: true,
            href: ``,
        });

        this.resources = resourceRes.data.resources;
    },
    computed: {
        ...mapState(["homeworkName"]),
    },
    watch: {
        homeworkName() {
            this.breadcrumbsItems[1].text = this.homeworkName;
        },
    },
    methods: {

    },
}
</script>

<style scoped></style>
