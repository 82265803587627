<template>
    <v-container fluid>
        <div class="text-h6 grey--text text--darken-2">
            {{ $t('menu.diary_resource') }}
        </div>
        <v-card>
            <v-card-text>
                <v-item-group class="mr-4">
                    <v-row>
                        <v-col v-for="item in diarySamples" :key="item.id" cols="12" sm="4" md="3" lg="2">
                            <v-item>
                                <v-card outlined class="mx-auto text-center align-center card-outline"
                                    @click="checkResourcePay(item)">
                                    <v-card-title clas="my-0">
                                        <div v-if="item.diary_cover_img" class="mx-auto diary-icon"
                                            :class="item.has_pay ? '' : 'block'">
                                            <v-img :src="item.diary_cover_img" height="40px" width="40px"></v-img>
                                        </div>
                                        <v-icon class="mx-auto" v-else x-large
                                            :color="item.diary_cover_color ? item.diary_cover_color : '#6aa72c'">
                                            mdi-book-open
                                        </v-icon>
                                    </v-card-title>
                                    <div class="text-body-2 mb-2" style="color: #555555;">{{ item.name }}</div>
                                </v-card>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>
            </v-card-text>
        </v-card>
        <v-dialog v-model="openPlanExchangeCodeDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('menu.diary_resource') }} - {{ selectedHomework.name }}
                </v-card-title>
                <v-card-text class="pb-2">
                    <div class="text-subtitle-1">
                        {{ $t('diary_main.create_from_publish_sample.need_code.message') }}
                    </div>
                    <a :href="selectedHomework.info_url" class="text-subtitle-2 text--grey" target="_blank">
                        <v-icon class="mr-1" small>mdi-help-circle</v-icon>{{
                $t('diary_main.create_from_publish_sample.need_code.info') }}
                    </a>
                </v-card-text>
                <v-card-text class="mt-2">
                    <v-form ref="planExchangeCodeForm" v-model="isPlanExchangeCodeValid" lazy-validation>
                        <v-text-field :label="$t('diary_main.create_from_publish_sample.need_code.input')"
                            :placeholder="$t('diary_main.create_from_publish_sample.need_code.input')"
                            v-model="planExchangeCode" :rules="planExchangeCodeRules" outlined></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn plain class="mb-2 normal-case" @click="openPlanExchangeCodeDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="deep-orange lighten-1" class="mb-2 white--text normal-case" :loading="loading"
                        @click="takePlan">
                        {{ $t('diary_main.create_from_publish_sample.need_code.button.validate') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { diaryServices } from '@/services/diary.js';

export default {
    name: 'diarySampleResource',
    components: {},
    data() {
        return {
            diarySamples: [],
            loading: false,
            openPlanExchangeCodeDialog: false,
            selectedHomework: {},
            planExchangeCode: '',
            planExchangeCodeRules: [
                (v) => !!v || this.$t('general.msg.must_filled'),
                (v) => (!!v && this.planExchangeCodeErrorMsg == '') || this.planExchangeCodeErrorMsg,
            ],
            planExchangeCodeErrorMsg: '',
            isPlanExchangeCodeValid: true,
        }
    },
    async created() {
        await this.fetchDiarySample();
    },

    watch: {
        planExchangeCode() {
            this.planExchangeCodeErrorMsg = '';
        },
    },
    methods: {
        async fetchDiarySample() {
            const result = await diaryServices.getPublishSampleHomeworkWithSample();
            result.data.forEach(item => {
                this.diarySamples.push({
                    id: item.id,
                    name: item.info.name,
                    diary_cover_color: item.info.diary_cover_color,
                    diary_cover_img: item.info.diary_cover_img,
                    info_url: item.info.intro_url,
                    has_pay: item.has_pay
                });
            });

        },
        checkResourcePay(item) {
            this.selectedHomework = item;
            if (!item.has_pay) {
                this.openPlanExchangeCodeDialog = true;
            } else {
                this.goToResource();
            }
        },
        async takePlan() {
            if (this.$refs.planExchangeCodeForm.validate()) {
                this.loading = true;
                const payload = {
                    plan_exchange_code: this.planExchangeCode,
                };
                const takeResult = await diaryServices.takePublishSampleHomeworkPlan(this.selectedHomework.id, payload);
                const data = takeResult.data;
                const returnCode = data.return_code;

                if (returnCode) {
                    switch (returnCode) {
                        case '-01103':
                            this.planExchangeCodeErrorMsg = this.$t('diary_main.book.serial_num_error');
                            break;
                        case '-01104':
                            this.planExchangeCodeErrorMsg = this.$t('diary_main.book.serial_num_error');
                            break;
                        case '-01105':
                            this.planExchangeCodeErrorMsg = this.$t('diary_main.book.serial_num_used');
                            break;
                        default: break;
                    }
                    this.loading = false;
                    this.$refs.planExchangeCodeForm.validate();
                    return;
                }
                this.goToResource();
            }
        },
        goToResource() {
            this.$router.push(`/diarySample/resourcesBook/${this.selectedHomework.id}/resources`);
        }
    },
}
</script>

<style scoped>
.diary-icon.block {
    position: relative;
}

.diary-icon.block:after {
    font: normal normal normal 24px / 1 "Material Design Icons";
    bottom: -10px;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    right: -10px;
    font-size: 1rem;
    color: #FFC107;
}

.diary-icon.block::after {
    content: '\F033E';
}
</style>