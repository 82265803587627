<template>
    <div>
        <v-btn outlined small color="grey darken-1" @click="openDataPickerMenu = true"
            class="normal-case"><v-icon small>mdi-calendar-month</v-icon>{{ $t('button.date_range') }}</v-btn>
        <v-dialog ref="dialog" v-model="openDataPickerMenu" persistent width="330px">
            <v-card>

                <div class="pt-4 pb-2 px-5">
                    <v-card-text class="pa-0 mb-1"><span class="subtitle-1">{{
                        $t('report.query_condition.query_day.value.custom.title') }}</span></v-card-text>
                    <v-row class="no-gutters" align="center">
                        <v-col>
                            <v-btn x-small color="primary" outlined class="mb-3 normal-case"
                                @click="setStartDateAsCreateDate">{{
                                    $t('report.query_condition.query_day.value.custom.btn.homework_create_date') }}</v-btn>
                            <v-text-field ref="dateRangeStart" :background-color="dataRangeStartBg" v-model="dateRangeStart"
                                :rules="dateRangeStartRule"
                                :label="$t('report.query_condition.query_day.value.custom.value.start_date.title')" dense
                                @click="selectDateRangeType = 0" @change="changeDateRange"
                                @focus="focusDateRange(0)"></v-text-field>
                        </v-col>
                        <div class="px-2">-</div>
                        <v-col class="text-right">
                            <v-btn x-small color="primary" outlined class="mb-3 normal-case"
                                @click="setEndDateAsLastPostDate" :disabled="lastPostDate == null">{{
                                    $t('report.query_condition.query_day.value.custom.btn.last_post_date') }}</v-btn>
                            <v-text-field ref="dateRangeEnd" :background-color="dataRangeEndBg" v-model="dateRangeEnd"
                                :rules="dateRangeEndRule"
                                :label="$t('report.query_condition.query_day.value.custom.value.end_date.title')" dense
                                @click="selectDateRangeType = 1" @change="changeDateRange"
                                @focus="focusDateRange(1)"></v-text-field>
                        </v-col>
                    </v-row>
                    <div class="text-center">
                        <v-date-picker v-model="timeRange" locale="zh-cn" :min="homeworkCreateDate" :max="today" no-title
                            scrollable show-adjacent-months @click:date="changeDate" range>

                        </v-date-picker>
                    </div>
                </div>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="openDataPickerMenu = false"
                        class="normal-case">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn class="normal-case"
                        text color="primary" :disabled="disabledApplyBtn" @click="changeCustomDate">
                        {{ $t('button.apply') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "post",
    components: {},
    props: ['homeworkCreateDate', 'lastPostDate'],
    data() {
        return {
            dateRangeText: '',
            openDataPickerMenu: false,
            selectDateRangeType: '0',
            dateRangleValue: [],
            dateRangeStart: '',
            dateRangeStartRule: [
                (v) => (/^((19|2[0-9])[0-9]{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v)) || this.$t('report.query_condition.query_day.value.custom.value.start_date.error.1'),
                (v) => !(this.dateRangeEnd == null || (this.dateRangeEnd != '' && v > this.dateRangeEnd)) || this.$t('report.query_condition.query_day.value.custom.value.start_date.error.2'),
            ],
            dataRangeStartBg: '',
            dateRangeEnd: '',
            dataRangeEndBg: '',
            dateRangeEndRule: [
                (v) => /^((19|2[0-9])[0-9]{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || this.$t('report.query_condition.query_day.value.custom.value.end_date.error.1'),
                (v) => !(this.dateRangeStart == null || (this.dateRangeStart != '' && v < this.dateRangeStart)) || this.$t('report.query_condition.query_day.value.custom.value.end_date.error.2'),
            ],
            oldTimeRange: [],
            timeRange: [],
            queryDate: '',
            today: null,
            disabledApplyBtn: true,
        };
    },
    async created() {
        this.today = new Date().toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');

    },
    watch: {
    },
    mounted() {

    },
    methods: {

        changeCustomDate() {
            if (this.dateRangeStart != '' && this.dateRangeEnd != '') {
                this.dateRangeText = this.dateRangeStart + ' - ' + this.dateRangeEnd
                this.$emit('queryData', this.timeRange);
                this.openDataPickerMenu = false;
            }

        },
        setStartDateAsCreateDate() {
            this.dateRangeStart = this.homeworkCreateDate;
            this.changeDateRange();
            this.selectDateRangeType = 0;
            this.changeDateRangeType();
        },
        setEndDateAsLastPostDate() {
            this.dateRangeEnd = this.lastPostDate;
            this.changeDateRange();
            this.selectDateRangeType = 1;
            this.changeDateRangeType();
        },
        changeDateRange() {
            if (this.dateRangeEnd == '') {
                this.timeRange = [this.dateRangeStart, this.dateRangeStart];
            } else if (this.dateRangeStart == '') {
                this.timeRange = [this.dateRangeEnd, this.dateRangeEnd];
            } else {
                if (this.dateRangeStart > this.dateRangeEnd) {
                    return;
                }
                this.timeRange = [this.dateRangeStart, this.dateRangeEnd];
            }

            if (this.dateRangeStart != '' && this.dateRangeEnd != '') {
                this.disabledApplyBtn = false;
            } else {
                this.disabledApplyBtn = true;
            }
            this.oldTimeRange = this.timeRange;
        },
        changeDate() {
            if (this.selectDateRangeType == 0) {
                let selectVal = this.timeRange[0];
                if (selectVal == null) {
                    selectVal = this.timeRange[1];
                }
                if (this.oldTimeRange[1] == null) {
                    this.oldTimeRange[1] = selectVal;
                }
                if (selectVal > this.oldTimeRange[1]) {
                    this.timeRange = this.oldTimeRange;
                    return;
                }

                this.timeRange = [selectVal, this.oldTimeRange[1]];
                this.dateRangeStart = selectVal;

            } else {
                let selectVal = this.timeRange[1];
                if (selectVal == null) {
                    selectVal = this.timeRange[0];
                }
                if (this.oldTimeRange[0] == null) {
                    this.oldTimeRange[0] = selectVal;
                }

                if (selectVal < this.oldTimeRange[0]) {
                    this.timeRange = this.oldTimeRange;
                    return;
                }
                this.timeRange = [this.oldTimeRange[0], selectVal];
                this.dateRangeEnd = selectVal;

            }
            if (this.dateRangeStart != '' && this.dateRangeEnd != '') {
                this.disabledApplyBtn = false;
            } else {
                this.disabledApplyBtn = true;
            }
            this.changeDateRangeType();
            this.oldTimeRange = this.timeRange;
        },
        focusDateRange(type) {
            if (type == 0) {
                this.dataRangeEndBg = '';
                this.dataRangeStartBg = 'blue lighten-5';
            } else {
                this.dataRangeStartBg = '';
                this.dataRangeEndBg = 'blue lighten-5';
            }
        },
        changeDateRangeType() {
            if (this.selectDateRangeType == 0) {
                this.selectDateRangeType = 1;
                this.$nextTick(function () {
                    this.$refs.dateRangeEnd.focus();
                    this.dataRangeStartBg = '';
                    this.dataRangeEndBg = 'blue lighten-5';
                })
            } else {
                this.selectDateRangeType = 0;
                this.$nextTick(function () {
                    this.$refs.dateRangeStart.focus();
                    this.dataRangeEndBg = '';
                    this.dataRangeStartBg = 'blue lighten-5';
                })
            }
        },
    },
};
</script>
<style scoped></style>

