import servicesUtils from '../utils/service'

export const postCollectionServices = {
    async addPostToCollection(diaryPostId) {
        try {
            const url = `${servicesUtils.resourceUrl('postCollectionUpdate')}`
                .replace(':diaryPostId', diaryPostId);
            const res = await servicesUtils.post(url);
            return res.data;
        } catch (err) {
            console.log(`failed in addPostToCollection, err: ${err}`)
            throw err
        }
    },
    async removePostFromCollection(diaryPostId) {
        try {
            const url = `${servicesUtils.resourceUrl('postCollectionUpdate')}`
                .replace(':diaryPostId', diaryPostId);
            const res = await servicesUtils.delete(url);
            return res.data;
        } catch (err) {
            console.log(`failed in removePostFromCollection, err: ${err}`)
            throw err
        }
    },
    async getPostCollectionList(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('postCollectionList')}`
                .replace(':diaryHomeworkId', homeworkId)
                + '?'
                + servicesUtils.queryParams(payload);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getPostCollectionList, err: ${err}`)
            throw err
        }
    }
}