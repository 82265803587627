<template>
    <v-row class="vh-100 align-center align-self-center">
        <v-col>
            <v-progress-linear color="amber darken-3" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
    </v-row>
</template>

<script>
import { loginServices } from "@/services/login.js";
import { clearStorage } from '@/utils/storageManager';

export default {
    name: "Logout",
    async created() {
        //API logout
        await loginServices.logout();

        //清除localStorage
        clearStorage();
        // localStorage.removeItem('userName');
        // localStorage.removeItem('payfunctionMap');
        // localStorage.removeItem('ebookUserName');
        
        //清除sessionStorage
        sessionStorage.clear();
        // sessionStorage.removeItem('isHomeworkLock');
        // sessionStorage.removeItem('planName');
        // sessionStorage.removeItem('studentLimit');
        // sessionStorage.removeItem('locale');
        // sessionStorage.removeItem('mode');
        // sessionStorage.removeItem('teamModeAvailable');

        let isStudent = false;
        if (location.origin.replace('https://', '').replace('http://').indexOf('ebook') == 0) {
            isStudent = true;
        }
        //OAuth logout
        if (!isStudent) {
            location.href = "/logout";
        } else {
            location.href = process.env.VUE_APP_LOGOUT_EBOOK_URL;
        }
    },
}
</script>

<style scoped>
.vh-100 {
    height: 100vh;
}
</style>