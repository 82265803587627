<template>
    <v-container>
        <v-overlay absolute opacity="0.8">
            <v-card-title>
                {{ $t('chargePlan.areaLockMessage') }}
            </v-card-title>
            <!-- <v-card-actions>
                <v-btn color="deep-orange accent-2" elevation="0" dark
                    class="mx-auto normal-case">
                    <v-icon small class="mr-2">mdi-shimmer</v-icon>
                    <span class="d-none d-sm-block" @click="viewPlanPage"> {{ $t('chargePlan.areaLockViewPlan') }}</span>
                </v-btn>
            </v-card-actions> -->
        </v-overlay>
    </v-container>
</template>

<script>
export default {
    name: "LockBlock",
    methods: {
        viewPlanPage() {
            window.open('https://www.mangax.co/mangachat/pricing/', '_blank');
        }
    },
};
</script>

<style scoped></style>