<template>
    <v-dialog v-model="value" persistent width="600">
        <v-card>
            <v-card-title>
                <span v-if="isSample" class="text-h6"> {{ $t('quest.main.invitation_code') }}</span>
                <span v-else class="text-h6">{{ $t('info.diary.student_invite_code') }}</span>
                <span class="text-h6 ml-2" style="color:#D84315">
                    {{ title }}
                </span>
                <v-spacer></v-spacer>
                <v-btn icon @click="show = false"
                    class="normal-case">
                    <v-icon>mdi-close-thick</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-subtitle v-if="studentCode" class="mt-4 text-h4 text-center" color="grey darken-2">
                {{ studentCode }}
            </v-card-subtitle>
            <v-card-text v-if="studentCodeExpire" class="text-center" color="grey darken-1">
                {{ studentCodeExpire }} {{ $t('info.diary.expire') }}
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="isSample" class="mx-auto mb-4 normal-case"
                    dark color="deep-orange accent-2" @click="generateStudentCode">
                    {{ $t('quest.main.generate_invitation_code') }}
                </v-btn>
                <v-btn v-else class="mx-auto mb-4 normal-case" dark
                    color="deep-orange accent-2" @click="generateStudentCode">
                    {{ $t('info.diary.generate_code') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import fileDownload from "js-file-download";
import { diaryServices } from "@/services/diary.js";

import { mapState } from "vuex";
export default {
    name: "teacherInvitationDialog",
    components: {},
    props: {
        value: Boolean,
        title: String,
        isSample: Boolean,
    },
    data() {
        return {
            homeworkId: '',
            studentCode: '',
            studentCodeExpire: '',
            tab: 'tabCode',
        };
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        const codeResult = await diaryServices.checkHomeworkInviteCode(this.homeworkId);
        if (new Date() < new Date(codeResult.data.expire_time)) {
            this.studentCode = codeResult.data.invite_code;
            this.studentCodeExpire = codeResult.data.expire_time;
        } else {
            this.studentCode = null;
            this.studentCodeExpire = null;
        }
        this.value = false;
    },
    watch: {
    },
    computed: {
        ...mapState(["homeworkName"]),
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    mounted() {

    },
    methods: {
        async generateStudentCode() {
            const result = await diaryServices.getHomeworkInviteCode(this.homeworkId);
            this.studentCode = result.data.invite_code;
            this.studentCodeExpire = result.data.expire_time;
        },
    },
};
</script>
<style scoped></style>

