import servicesUtils from '../utils/service'

export const diaryServices = {

    async getDiaryFunctionPrivilege() {
        try {

            const url = `${servicesUtils.resourceUrl('getDiaryFunctionPrivilege')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to getDiaryFunctionPrivilege err: ${err}`)
            throw err
        }
    },
    async createHomework(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('createHomework')}`.replace(':id', homeworkId != null ? homeworkId : '')
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to createHomework: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },
    async getHomeworkDetail(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkDetail')}`
                .replace(':id', homeworkId);
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to getHomeworkDetail err: ${err}`)
            throw err
        }
    },
    async getHomeworkStudentName(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkStudentName')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getHomeworkStudentName err: ${err}`)
            throw err
        }
    },
    async getHomeworkName(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkName')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getHomeworkName err: ${err}`)
            throw err
        }
    },
    async checkHomeworkInviteCode(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('checkHomewrokInviteCode')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.get(url);
            return data
        } catch (err) {
            console.log(`failed to get checkHomeworkInviteCode err: ${err}`)
            throw err
        }
    },
    async getHomeworkInviteCode(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomewrokInviteCode')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.get(url);
            return data
        } catch (err) {
            console.log(`failed to get getHomeworkInviteCode err: ${err}`)
            throw err
        }
    },
    async getHomeworkTeacherInviteCode(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('checkHomeworkTeacherInviteCode')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.get(url);
            return data
        } catch (err) {
            console.log(`failed to get getHomeworkTeacherInviteCode err: ${err}`)
            throw err
        }
    },
    async getHomeworkTeacherInvitation(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkTeacherInvitation')}`
                .replace(':diaryHomeworkId', homeworkId);
            const res = await fetch(url, {
                method: 'get',
                responseType: 'blob',
                credentials: 'include'
            });
            return res;
        } catch (err) {
            console.log(`failed to get getHomeworkTeacherInvitation err: ${err}`)
            throw err
        }
    },
    async sendHomeworkTeacherInvitation(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkTeacherInvitation')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.post(url, payload);
            return data
        } catch (err) {
            console.log(`failed to get sendHomeworkTeacherInvitation err: ${err}`)
            throw err
        }
    },
    async getHomeworkTeachers(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkTeachers')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getHomeworkTeachers err: ${err}`)
            throw err
        }
    },
    async getBadMoodStudents(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('getBadMoodStudents')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getBadMoodStudents err: ${err}`)
            throw err
        }
    },
    async acceptHomeworkPrivilegeUser(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('acceptHomeworkPrivilege')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to get acceptHomeworkPrivilegeUser err: ${err}`)
            throw err
        }
    },
    async rejectHomeworkPrivilegeUser(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('rejectHomeworkPrivilege')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to get rejectHomeworkPrivilegeUser err: ${err}`)
            throw err
        }
    },
    async deleteHomeworkPrivilegeUser(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('deleteHomeworkPrivilege')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to get deleteHomeworkPrivilegeUser err: ${err}`)
            throw err
        }
    },
    async getDiaryHomework() {
        try {
            const url = `${servicesUtils.resourceUrl('getDiaryHomework')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getDiaryHomework err: ${err}`)
            throw err
        }
    },
    async getMyDiaryHomeworkList() {
        try {
            const url = `${servicesUtils.resourceUrl('getMyHomeworkList')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getMyDiaryHomeworkList err: ${err}`)
            throw err
        }
    },
    async copyDiaryHomework(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('copyDiaryHomework')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.post(url);
            return data;
        } catch (err) {
            console.log(`failed to get copyDiaryHomework err: ${err}`)
            throw err
        }
    },
    async archiveDiaryHomework(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('archiveDiaryHomework')}`
                .replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.post(url);
            return data;
        } catch (err) {
            console.log(`failed to get archiveDiaryHomework err: ${err}`)
            throw err
        }
    },
    async getJoinedDiaryHomework() {
        try {
            const url = `${servicesUtils.resourceUrl('getJoinedDiaryHomework')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getDiaryHomework err: ${err}`)
            throw err
        }
    },
    async joinDiaryHomework(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('joinDiaryHomework')}`;
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to get joinDiaryHomework err: ${err}`)
            throw err
        }
    },
    async getArchivedDiaryHomework() {
        try {
            const url = `${servicesUtils.resourceUrl('getArchivedDiaryHomework')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getArchivedDiaryHomework err: ${err}`)
            throw err
        }
    },
    async getDiaryHomeworkCase(query) {
        try {
            const url = `${servicesUtils.resourceUrl('getDiaryHomeworkCase')}`
                + '?'
                + servicesUtils.queryParams(query);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getDiaryHomeworkCase err: ${err}`)
            throw err
        }
    },
    async getSampleQuest(diaryHomeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('getSampleQuest')}`
                .replace(':diaryHomeworkId', diaryHomeworkId);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getDiarySampleQuest err: ${err}`)
            throw err
        }
    },
    async addSampleQuest(diaryHomeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getSampleQuest')}`
                .replace(':diaryHomeworkId', diaryHomeworkId);
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to get getDiarySampleQuest err: ${err}`)
            throw err
        }
    },
    async getSampleQuestDetail(diaryHomeworkId, questId) {
        try {
            const url = `${servicesUtils.resourceUrl('getSampleQuestDetail')}`
                .replace(':diaryHomeworkId', diaryHomeworkId)
                .replace(':questId', questId);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getSampleQuestDetail err: ${err}`)
            throw err
        }
    },
    async deleteSampleQuest(diaryHomeworkId, questId) {
        try {
            const url = `${servicesUtils.resourceUrl('getSampleQuestDetail')}`
                .replace(':diaryHomeworkId', diaryHomeworkId)
                .replace(':questId', questId);
            const data = await servicesUtils.delete(url);
            return data;
        } catch (err) {
            console.log(`failed to delete SampleQuest err: ${err}`)
            throw err
        }
    },
    async takeCourseBook(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('takeCourseBook')}`;
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed to get takeCourseBook err: ${err}`)
            throw err
        }
    },
    async getQusetDiary() {
        try {
            const url = `${servicesUtils.resourceUrl('getQusetDiary')}`;
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getQusetDiary err: ${err}`)
            throw err
        }
    },
    async checkJoinedHomework(id) {
        try {
            const url = `${servicesUtils.resourceUrl('checkJoinedHomework')}`
                .replace(':diaryHomeworkId', id);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed in checkJoinedHomework with err: ${err}`)
            throw err
        }
    },

    async getPublishSampleHomework() {
        try {
            const url = `${servicesUtils.resourceUrl('getPublishSampleHomework')}`
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getPublishSampleHomework err: ${err}`)
            throw err
        }
    },

    async getPublishSampleHomeworkWithSample() {
        try {
            const url = `${servicesUtils.resourceUrl('getPublishSampleHomeworkWithSample')}`
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getPublishSampleHomeworkWithSample err: ${err}`)
            throw err
        }
    },

    async getHomeworkResource(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkResource')}`.replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getHomeworkResource err: ${err}`)
            throw err
        }
    },

    async getPublishSampleHomeworkResources(homeworkId) {
        try {
            const url = `${servicesUtils.resourceUrl('getPublishSampleHomeworkResources')}`.replace(':diaryHomeworkId', homeworkId);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getPublishSampleHomeworkResources err: ${err}`)
            throw err
        }
    },
    async takePublishSampleHomeworkPlan(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('takePublishSampleHomeworkPlan')}`
                .replace(':id', id);
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed in takePublishSampleHomeworkPlan with err: ${err}`)
            throw err
        }
    },
    async applyInvitationCode(code) {
        try {
            const url = `${servicesUtils.resourceUrl('applyInvitationCode')}`
                .replace(':code', code);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed in applyInvitationCode with err: ${err}`)
            throw err
        }
    }
    
}