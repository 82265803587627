<template>
    <div id="app">
        <component :is="layout">
            <router-view v-if="isRouterAlive" />
            <vue-toastr ref="toastr"></vue-toastr>
        </component>
    </div>
</template>

<script>
import SnackMsg from '@/components/console/diary/component/SnackMsg';
import VueToastr from '@/plugins/toastr'
import { getItem } from '@/utils/storageManager';

export default {
    name: "App",
    components: {
        SnackMsg,
        VueToastr
    },
    provide() {
        return {
            reload: this.reload
        }
    },
    mounted() {
        this.$root.SnackMsg = this.$refs.SnackMsg
    },
    data() {
        return {
            defaultLayout: 'Default',
            isRouterAlive: true,
            lang: 'en',
        }
    },
    computed: {
        layout() {
            return (this.$route.meta.layout || this.defaultLayout) + 'Layout'
        }
    },
    async created() {

        let locale = getItem("locale");
        if (typeof locale == 'undefined' || locale == null || locale == '') {
            locale = this.getCookie('lang');
        }

        if (typeof locale == 'undefined' || locale == null || locale == '') {
            const localeName = navigator.language.toLocaleLowerCase().split('-')[0];
            locale = localeName == 'en' || localeName == 'zh' ? localeName : 'en'
        }
        this.$i18n.locale = locale;
        this.requestNotificationPermission();
    },
    methods: {
        reload() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            })
        },
        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        requestNotificationPermission() {
            if ('Notification' in window) {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        // You can now show notifications
                        console.log('Notification permission granted');
                    } else {
                        console.log('Notification permission denied');
                    }
                });
            } else {
                console.log('Notifications not supported in this browser');
            }
        },
    },
}
</script>

<style>
#app {
    max-width: 100%;
    /* margin: 0 auto;
    padding: 2rem; */

    font-weight: normal;
    background-color: var(--v-background-lighten5);
    /*--v-background-base*/
}

.page-title {
    border-bottom: 2px solid #20780c;
}

.main-content {
    min-height: calc(100vh - 297px);
}

.main-content.error-page {
    min-height: 58vh;
    margin-left: auto !important;
    margin-right: auto !important;
}

.w-100 {
    width: 100%;
}

.normal-case {
    text-transform: none !important;
}


/* @media (min-width: 1024px) {
    body {
        display: flex;
        place-items: center;
    }

    #app {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 2rem;
    }

    .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }

    nav {
        text-align: left;
        margin-left: -1rem;
        font-size: 1rem;

        padding: 1rem 0;
        margin-top: 1rem;
    }
} */
</style>