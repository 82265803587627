<template>
    <div class="main-content error-page row no-gutters justify-center mt-4">
        <div class="col-xs-12 align-center justify-center row no-gutters text-left px-5">
            <v-alert
            outlined
            type="warning"
            prominent
            border="left"
            >
            <span class="text-h5">{{ $t('error_page.title') }}</span><br>
            <span class="text-h6">{{ $t('error_page.contact') }}</span><br>
            <span>{{ $t('diary_main.phone') + ' +886-979-357-131'}}</span><br>
            <span>{{ $t('diary_main.time') }}</span><br>
            <span>{{ $t('diary_main.line') + ' ' }}</span><span><a href="https://lin.ee/v446Bup" target="_blank">https://lin.ee/v446Bup</a></span><br>
            <span>{{ $t('diary_main.mail') + ' '  }}</span><a href="mailto: mangaxtechnology@gmail.com" target="_blank">mangaxtechnology@gmail.com</a>
            </v-alert>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ErrorPage'
}
</script>