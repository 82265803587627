<template>
    <v-row class="vh-100 align-center align-self-center">
        <v-col>
            <v-progress-linear color="amber darken-3" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
    </v-row>
</template>

<script>
import { loginServices } from "@/services/login.js";
import { clearStorage } from '@/utils/storageManager.js';
import { userServices } from "@/services/user.js";

export default {
    name: "Logout",
    async created() {
        //API logout
        const token = localStorage.getItem('notifyToken');
        if (token) {
            await userServices.removeNotifyToken({ token: token });
        } 
        await loginServices.logout();

        //清除localStorage
        clearStorage();

        //清除sessionStorage
        sessionStorage.clear();

        ///OAuth logout
        // loginServices.oauthLogout();
        // location.href = process.env.VUE_APP_LOGOUT_URL;
        location.href = '/index';
    },
}
</script>

<style scoped>
.vh-100 {
    height: 100vh;
}
</style>