<template>
    <v-container fluid>
        <div class="text-h5">{{ $t('notification_list.title') }}</div>
        <v-tabs v-model="tab" @change="changeType">
            <v-tabs-slider color="deep-orange arken-1"></v-tabs-slider>
            <v-tab class="grey--text text--darken-2 subtitle-1">{{ $t('notification_list.all') }}</v-tab>
            <v-tab class="grey--text text--darken-2 subtitle-1">{{ $t('notification_list.journal') }}</v-tab>
            <v-tab class="grey--text text--darken-2 subtitle-1">{{ $t('notification_list.comment') }}</v-tab>
            <v-spacer />
            <v-btn class="mr-6 normal-case align-self-center" outlined @click="clear()" color="#555555" :disabled="loading" :loading="loading">{{ $t('notification_list.read') }}</v-btn>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-list :tile="false" nav dense>
                    <v-list-item v-for="(message, index) in messages" :key="index"
                        @click="gotoMessageUrl(message)">
                        <v-list-item-content v-if="message.status == 1"
                            style="background-color: #fefaf2;">
                            <v-row>
                                <v-col cols="2" sm="1">
                                    <v-list-item-avatar class="mx-4">
                                        <v-img :src=message.img></v-img>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="10" sm="11" class="my-auto">
                                    <v-list-item-title class="text-left">
                                        {{ message.msg }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                        class="text-left gray--text body-2">
                                        {{ message.during_time }}
                                    </v-list-item-subtitle>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                        <v-list-item-content v-else>
                            <v-row>
                                <v-col cols="2" sm="1">
                                    <v-list-item-avatar class="mx-4">
                                        <v-img :src=message.img></v-img>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="10" sm="11" class="my-auto">
                                    <v-list-item-title class="text-left">
                                        {{ message.msg }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                        class="text-left gray--text body-2">
                                        {{ message.during_time }}
                                    </v-list-item-subtitle>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="haveMoreMessage" @click="loadMessage">
                        <v-list-item-title class="text-center">
                            {{ $t('notification_list.load') }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-tab-item>
            <v-tab-item>
                <v-list :tile="false" nav dense>
                    <v-list-item v-for="(message, index) in journalMessages" :key="index"
                        @click="gotoMessageUrl(message)">
                        <v-list-item-content v-if="message.status == 1"
                            style="background-color: #fefaf2;">
                            <v-row>
                                <v-col cols="2" sm="1">
                                    <v-list-item-avatar class="mx-4">
                                        <v-img :src=message.img></v-img>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="10" sm="11" class="my-auto">
                                    <v-list-item-title class="text-left">
                                        {{ message.msg }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                        class="text-left gray--text body-2">
                                        {{ message.during_time }}
                                    </v-list-item-subtitle>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                        <v-list-item-content v-else>
                            <v-row>
                                <v-col cols="2" sm="1">
                                    <v-list-item-avatar class="mx-4">
                                        <v-img :src=message.img></v-img>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="10" sm="11" class="my-auto">
                                    <v-list-item-title class="text-left">
                                        {{ message.msg }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                        class="text-left gray--text body-2">
                                        {{ message.during_time }}
                                    </v-list-item-subtitle>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="haveMoreJournalMessage" @click="loadJournalMessage">
                        <v-list-item-title class="text-center">
                            {{ $t('notification_list.load') }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-tab-item>
            <v-tab-item>
                <v-list :tile="false" nav dense>
                    <v-list-item v-for="(message, index) in commentMessages" :key="index"
                        @click="gotoMessageUrl(message)">
                        <v-list-item-content v-if="message.status == 1"
                            style="background-color: #fefaf2;">
                            <v-row>
                                <v-col cols="2" sm="1">
                                    <v-list-item-avatar class="mx-4">
                                        <v-img :src=message.img></v-img>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="10" sm="11" class="my-auto">
                                    <v-list-item-title class="text-left">
                                        {{ message.msg }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                        class="text-left gray--text body-2">
                                        {{ message.during_time }}
                                    </v-list-item-subtitle>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                        <v-list-item-content v-else>
                            <v-row>
                                <v-col cols="2" sm="1">
                                    <v-list-item-avatar class="mx-4">
                                        <v-img :src=message.img></v-img>
                                    </v-list-item-avatar>
                                </v-col>
                                <v-col cols="10" sm="11" class="my-auto">
                                    <v-list-item-title class="text-left">
                                        {{ message.msg }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle
                                        class="text-left gray--text body-2">
                                        {{ message.during_time }}
                                    </v-list-item-subtitle>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="haveMoreCommentMessage" @click="loadCommentMessage">
                        <v-list-item-title class="text-center">
                            {{ $t('notification_list.load') }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-tab-item>
        </v-tabs-items>
        <v-overlay :value="dataLoading">
            <v-progress-circular
                indeterminate
                size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>
import { notificationServices } from "@/services/notification.js";
export default {
    name: 'notificationList',
    data() {
        return {
            tab: null,
            dataLoading: false,
            messages: [],
            currentMessagePage: 1,
            journalMessages: [],
            currentJournalMessagePage: 1,
            commentMessages: [],
            currentCommentMessagePage: 1,
            selectedTypeTab: '',
            haveMoreMessage: true,
            haveMoreJournalMessage: true,
            haveMoreCommentMessage: true,
            loading: false,
        }
    },
    async created() {
        await this.loadMessage();
    },
    methods: {
        async gotoMessageUrl(item) {
            await notificationServices.notificationRead(item.id);
            if (item.redirect_url) {
                this.$router.push(item.redirect_url);
            }
        },
        async loadMessage() {
            if (this.dataLoading) {
                return;
            }
            this.dataLoading = true;
            const payload = {
                page: this.currentMessagePage,
                type: ''
            }
            const result = await notificationServices.getIndex(payload);
            for (let item of result.dataList) {
                this.messages.push(item);
            }
            if (this.currentMessagePage < result.total_page) {
                this.currentMessagePage = this.currentMessagePage + 1;
            } else {
                this.haveMoreMessage = false;
            }
            this.dataLoading = false;
        },
        async loadJournalMessage() {
            this.dataLoading = true;
            const payload = {
                page: this.currentJournalMessagePage,
                type: 'd'
            }
            const result = await notificationServices.getIndex(payload);
            for (let item of result.dataList) {
                this.journalMessages.push(item);
            }
            if (this.currentJournalMessagePage < result.total_page) {
                this.currentJournalMessagePage = this.currentJournalMessagePage + 1;
            } else {
                this.haveMoreJournalMessage = false;
            }
            this.dataLoading = false;
        },
        async loadCommentMessage() {
            this.dataLoading = true;
            const payload = {
                page: this.currentCommentMessagePage,
                type: 'c'
            }
            const result = await notificationServices.getIndex(payload);
            for (let item of result.dataList) {
                this.commentMessages.push(item);
            }
            if (this.currentCommentMessagePage < result.total_page) {
                this.currentCommentMessagePage = this.currentCommentMessagePage + 1;
            } else {
                this.haveMoreCommentMessage = false;
            }
            this.dataLoading = false;
        },
        async changeType() {
            switch (this.tab) {
                case 0:
                    if (this.messages.length == 0) {
                        await this.loadMessage();
                    }
                    break;
                case 1:
                    if (this.journalMessages.length == 0) {
                        await this.loadJournalMessage();
                    }
                    break;
                case 2:
                    if (this.commentMessages.length == 0) {
                        await this.loadCommentMessage();
                    }
                    break;
            }
        },
        async clear() {
            this.loading = true;
            await notificationServices.clear();
            this.messages = [];
            this.journalMessages = [];
            this.commentMessages = [];
            this.currentMessagePage = 1;
            this.currentJournalMessagePage = 1;
            this.currentCommentMessagePage = 1;
            this.haveMoreMessage = true;
            this.haveMoreJournalMessage = true;
            this.haveMoreCommentMessage = true;
            this.loading = false;
            window.location.reload();
        }
    },
}
</script>
<style scoped></style>