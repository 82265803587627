<template>
    <v-container>
        <v-row class="vh-100 align-center align-self-center">
            <v-col>
                <v-progress-linear color="amber darken-3" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { loginServices } from '@/services/login.js';
import { registerServices } from '@/services/register.js';
import { getItem, setItem } from '@/utils/storageManager.js';

export default {
    name: "oauth3rdRedirect",
    components: {},
    data() {
        return {
            code: '',
            overlay: true,
            platform: '',
        };
    },
    async created() {
        this.code = this.$route.query.code;
        this.platform = this.$route.params.platform;
        this.getUserInfo();
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    },
    methods: {
        async getUserInfo() {
            let redirect_uri = process.env.VUE_APP_GOOGLE_REDIRECT_URL;
            console.log('platform', this.platform)
            if (this.platform == 'openid') {
                redirect_uri = process.env.VUE_APP_OPENID_REDIRECT_URL;
            }
            const loginData = {
                code: this.code,
                redirect_uri: redirect_uri,
            }
            const res = await loginServices.oAuthLogin3rd(this.platform, loginData);
            if (res.success) {
                const data = res.data;
                const payload = {
                    account: data.email,
                    tpcId: data.id,
                    tpcType: data.platform,
                    nickname: data.name,
                    avatar: data.avatar,
                }
                if (data.binding == 1) {
                    const bindingPayload = {
                        email: payload.account,
                        tpcType: payload.tpcType,
                        tpcId: payload.tpcId
                    }
                    const result = await registerServices.bind(bindingPayload);
                    if (result.data.returnCode === '-010202') {
                        setItem('binding', '-2');
                    } else if (result.data.returnCode === '-010201') {
                        setItem('binding', '-1');
                    } else {
                        setItem('binding', '1');
                    }
                    let redirect_path = sessionStorage.getItem('redirect_path');
                    if (!redirect_path) {
                        redirect_path = '/diary';
                    }
                    sessionStorage.removeItem('redirect_path');
                    location.href = redirect_path;
                } else if (data.isNewUser) {
                    ///register
                    this.register(payload);
                } else {
                    const payload = {
                        email: data.email,
                        tpcType: data.platform,
                        tpcId: data.id
                    }
                    this.login(payload);
                }
            }
        },
        async register(payload) {
            let locale = getItem('locale');
            if (!locale) {
                locale = 'en';
            }
            payload.locale = locale;
            const registerResult = await registerServices.register(payload);
            const loginPayload = {
                email: payload.account,
                tpcType: payload.tpcType,
                tpcId: payload.tpcId
            }
            if (registerResult.data.returnCode == '01000') {

                this.login(loginPayload);
            } else if (registerResult.data.returnCode == '-010200') {
                if (confirm(this.$t('register.error.google_duplicate'))) {
                    const bindingPayload = {
                        email: payload.account,
                        tpcType: payload.tpcType,
                        tpcId: payload.tpcId
                    }
                    const bindingResult = await registerServices.bind(bindingPayload);
                    if (bindingResult.data.returnCode == '') {
                        this.login(loginPayload);
                    } else {
                        this.$router.push('/login');
                    }
                } else {
                    this.$router.push('/login');
                }
            } else {
                this.$router.push('/login');
            }
        },
        async login(payload) {
            const result = await loginServices.accountLogin(payload);
            const res = result.data;
            this.loading = false;
            if (res.success) {
                const toRedirectPath = sessionStorage.getItem('redirect_path');
                if (!toRedirectPath) {
                    sessionStorage.setItem('redirect_path', '/diary');
                }
                location.href = `/login/check`;
            }
        },
    },
};
</script>

<style scoped>
.vh-100 {
    height: 100vh;
}
</style>