<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols>
                <v-card class="fill-height" flat>
                    <v-card-actions class="ml-0 pl-0">
                        <v-btn text class="px-0 normal-case"
                            @click="$router.go(-1)"
                            v-if="showBackButton">
                            <v-icon>mdi-arrow-left-thick</v-icon>
                            {{ $t('button.back') }}
                        </v-btn>
                    </v-card-actions>
                    <v-card-title class="px-2 grey--text text--darken-3">
                        {{ $t('homeworkQuestListEdit.questions_selection') }}
                    </v-card-title>
                    <v-row no-gutters class="mx-2">
                        <v-col cols="12" sm>
                            <div>{{ $t('homeworkQuestListEdit.available') }}</div>
                            <v-card outlined>
                                <v-card-text class="ma-2 pa-0 black--text">
                                    {{ `${questItems.length} ${$t('homeworkQuestListEdit.questions')}` }}
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-list>
                                    <v-list-item-group v-model="unSelectedQuestCurrent"
                                        class="fix-height-container">
                                        <v-list-item
                                            v-for="(item, i) in unSelectedQuestItems"
                                            :key="i">
                                            <v-list-item-content>
                                                <v-list-item-title v-text="item.title"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="auto" class="px-7 d-flex align-center">
                            <div class="d-block mx-auto">
                                <v-btn outlined block class="my-1 normal-case" :disabled="addDisabled"
                                    @click="addQuest">{{ `${$t('homeworkQuestListEdit.add')}\>`
                                    }}</v-btn>
                                <v-btn outlined block class="my-1 normal-case" :disabled="removeDisabled"
                                    @click="removeQuest">{{ `&lt;${$t('homeworkQuestListEdit.remove')}`
                                    }}</v-btn>
                                <v-btn outlined block class="my-1 normal-case"
                                    :disabled="selectedQuestItems.length == maxQuestSelection"
                                    @click="addAll">
                                    {{ `${$t('homeworkQuestListEdit.add_all')} \>\>` }}</v-btn>

                                <v-btn outlined block class="my-1 normal-case"
                                    :disabled="selectedQuestItems.length == 0"
                                    @click="removeAll">
                                    {{ `&lt;&lt; ${$t('homeworkQuestListEdit.remove_all')}`
                                    }}</v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" sm>
                            <div>{{ $t('homeworkQuestListEdit.assigned') }}</div>
                            <v-card outlined>
                                <v-card-text class="ma-2 pa-0 black--text">
                                    {{ `${selectedQuestItems.length}/${maxQuestSelection}
                                                                        ${$t('homeworkQuestListEdit.journals')}` }}
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-list overflow-x-auto>
                                    <v-list-item-group v-model="selectedQuestCurrent"
                                        class="fix-height-container">
                                        <draggable :list="selectedQuestItems" group="quest"
                                            @start="drag = true"
                                            @end="drag = false"
                                            @change="dragUpdated"
                                            class="fix-height-container">
                                            <v-list-item
                                                v-for="(item, i) in selectedQuestItems"
                                                :key="i">
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.title"></v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </draggable>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="auto" col-sm class="mt-2 mt-md-0  pl-0 pl-md-2 quest-col-width">
                <v-card v-if="currentQuest" class="pa-2 fill-height" outlined style="max-height: calc(100vh - 155px)">
                    <v-card-title>
                        {{ currentQuest.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-h6"> {{ $t('homeworkQuestListEdit.description') }} </div>
                        {{ currentQuest.description ? currentQuest.description :
                            $t('homeworkQuestListEdit.no_description') }}
                    </v-card-text>
                    <!-- <v-card-text v-for="(item, i) in currentQuest.contents"
                        :key="i">
                        <v-chip outlined>
                            {{ getTypeLabel(item.type) }}
                        </v-chip>
                        <div class="ma-2">
                            {{ item.title }}
                        </div>
                    </v-card-text> -->
                </v-card>
                <v-card v-else class="pa-2 fill-height" outlined>
                    <v-card-title>
                        {{ $t('homeworkQuestListEdit.no_selection') }}
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <div class="text-right mt-3">
            <v-btn color="#DE714B" class="white--text normal-case" @click="complete">
                {{ $t('info.diary.button.done') }}</v-btn>
        </div>
        <v-dialog v-model="showWarning" persistent width="400">
            <v-card class="py-4">
                <v-card-title class="my-2">
                    {{ $t('homeworkQuestListEdit.warning_title') }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="showWarning = false" class="normal-case">
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="dataLoading">
            <v-progress-circular
                indeterminate
                size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import { diaryQuestServices } from '@/services/diaryQuest.js';
import { contestServices } from '@/services/contest.js';

export default {
    name: 'homeworkQuestEdit',
    components: {
        draggable,
    },
    data() {
        return {
            homeworkId: '',
            questItems: [],
            unSelectedQuestCurrent: null,
            selectedQuestCurrent: null,
            currentQuest: null,
            addDisabled: true,
            removeDisabled: true,
            selectedQuestItems: [],
            addAllDiabled: true,
            removeAllDisabled: true,
            maxQuestSelection: 3,
            showWarning: false,
            dataLoading: false,
            showBackButton: true,
        }
    },
    methods: {
        addQuest() {
            this.currentQuest.selected = true;
            this.selectedQuestItems.push(this.currentQuest);
            this.unSelectedQuestCurrent = null;
            this.selectedQuestCurrent = this.selectedQuestItems.length - 1;
        },
        removeQuest() {
            this.currentQuest.selected = false;
            this.selectedQuestCurrent = null;
            this.unSelectedQuestCurrent = this.unSelectedQuestItems.findIndex(item => item.id === this.currentQuest.id);
            this.selectedQuestItems = this.selectedQuestItems.filter(item => item.id !== this.currentQuest.id);
        },
        addAll() {
            do {
                this.currentQuest = this.unSelectedQuestItems[0];
                this.addQuest();
            } while (this.selectedQuestItems.length < this.maxQuestSelection);
        },
        removeAll() {
            for (let item of this.selectedQuestItems) {
                let questItem = this.questItems.find(quest => quest.id === item.id);
                questItem.selected = false;
            }
            this.selectedQuestItems = [];
            this.selectedQuestCurrent = null;
            this.unSelectedQuestCurrent = 0;
            this.currentQuest = this.unSelectedQuestItems[0];
        },
        dragUpdated(detail) {
            this.selectedQuestCurrent = detail.moved.newIndex;
            this.currentQuest = this.questItems.find(item => item.id === detail.moved.element.id);
        },
        async complete() {
            if (this.selectedQuestItems.length === 0) {
                this.showWarning = true;
                return;
            }
            this.dataLoading = true;
            let items = [];
            this.selectedQuestItems.forEach((item, index) => {
                items.push({
                    quest_id: item.id,
                    order: index + 1,
                })
            })
            const payload = {
                quests: JSON.stringify(items)
            }
            await diaryQuestServices.addHomeworkQuestSelection(this.homeworkId, payload);
            this.dataLoading = false;
            if (this.$router.history.current.name === 'homeworkQuestList') {
                this.$router.go(-1);
            } else {
                this.$router.push(`/diary/homework/${this.homeworkId}/questList`);
            }
        },
        getTypeLabel(type) {
            return this.$t(`quest.main.${type}_frame`);
        }
    },
    async created() {
        this.dataLoading = true;
        this.homeworkId = this.$route.params.id;
        const result = await diaryQuestServices.getHomeworkQuestSelection(this.homeworkId);
        this.questItems = result.data.list;
        const contestHomeworkInfo = await contestServices.getHomeworkInfo(this.homeworkId);
        this.maxQuestSelection = contestHomeworkInfo.data.contest.quest_number;
        if (this.$route.query.new == 1) {
            this.showBackButton = false;
            if (contestHomeworkInfo.data.team.quest_pick_type == 1) {
                const maxLength = this.maxQuestSelection;
                let indexs = [];
                do {
                    const index = Math.floor(Math.random() * this.questItems.length);
                    if (!indexs.includes(index)) {
                        indexs.push(index);
                        this.questItems[index].selected = true;
                        this.selectedQuestItems.push(this.questItems[index]);
                    }
                } while (indexs.length < maxLength);
            }
        } else {
            this.selectedQuestItems = this.questItems.filter(item => item.selected);
            this.selectedQuestItems = this.selectedQuestItems.sort((a, b) => a.displayOrder - b.displayOrder);
        }
        this.dataLoading = false;
    },
    computed: {
        unSelectedQuestItems() {
            return this.questItems.filter(item => !item.selected)
        },
    },
    watch: {
        selectedQuestCurrent(val) {
            if (val === null) return;
            const id = this.selectedQuestItems[val].id;
            this.currentQuest = this.questItems.find(item => item.id === id);
            this.unSelectedQuestCurrent = null;
            this.addDisabled = true;
            this.removeDisabled = false;
        },
        unSelectedQuestCurrent(val) {
            if (val === null) return;
            const id = this.unSelectedQuestItems[val].id;
            this.currentQuest = this.questItems.find(item => item.id === id);
            this.selectedQuestCurrent = null;
            this.removeDisabled = true;
            if (this.selectedQuestItems.length < this.maxQuestSelection) {
                this.addDisabled = false;
            } else {
                this.addDisabled = true;
            }
        },
    }
}
</script>

<style scoped>
.fill-height {
    height: 100%;
}

.fix-height-container {
    height: calc(100vh - 350px);
    overflow-y: auto;
}

.quest-col-width {
    width: 330px;
}

@media (max-width: 575.98px) {
    .fix-height-container {
        height: 20vh;
    }

    .quest-col-width {
        width: 96%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>