<template>
    <v-snackbar
        v-model="snackBar.on"
        :timeout="snackBar.timeout"
        :color="snackBar.type"
        right
    >
        {{ snackBar.text }}
    </v-snackbar>
</template>

<script>
export default {
    name: "snackbar",
    components: {},
    props: [],
    data() {
        return {
            snackBar: {
                on: false,
                text: "My timeout is set to 2000.",
                timeout: 2000,
                type: "info",
            },
        };
    },
    async created() {
        
    },
    watch: {
    },
    mounted() {

    },
    methods: {

        showAlertMsg(type, msg) {
            this.snackBar.type = type;
            this.snackBar.text = msg;
            this.snackBar.on = true;
        },
    },
};
</script>
<style scoped>
</style>

