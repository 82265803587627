<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-card flat>
            <PayFunctionArea v-bind:isPay="payfunctionMap[functionId]"></PayFunctionArea>
            <div class="text-right">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs, value }">
                        <v-btn plain v-bind="attrs" v-on="on" class="normal-case">
                            {{ $t('report.query_condition.query_day.title') }}：{{ dateRangeText }}
                            <v-icon v-if="!value" right>
                                mdi-menu-down
                            </v-icon>
                            <v-icon v-if="value" right>
                                mdi-menu-up
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-for="(item, index) in dateRangeItems" :key="index">
                            <v-list-item-title>
                                <template v-if="item.value != -1">
                                    <v-btn plain block active-class="blue lighten-5" v-model="item.active"
                                        @click="selectDate(item.value)" class="normal-case">
                                        {{ item.title }}
                                    </v-btn>
                                </template>
                                <template v-if="item.value == -1">
                                    <v-btn plain block active-class="blue lighten-5" v-model="item.active"
                                        @click="openDataPickerMenu = true" class="normal-case">
                                        {{ item.title }}
                                    </v-btn>
                                </template>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <v-tabs :vertical="tabDirection" v-model="selectedChartTab" @change="changeChart">
                <v-tab class="justify-start">
                    <v-icon left>
                        mdi-account
                    </v-icon>
                    <span class="d-none d-sm-block">{{ $t('report.category.trend.title') }}</span>
                </v-tab>
                <v-tab class="justify-start">
                    <v-icon left>
                        mdi-chart-pie
                    </v-icon>
                    <span class="d-none d-sm-block">{{ $t('report.category.emotion4Category.title') }}</span>
                </v-tab>
                <v-tab class="justify-start">
                    <v-icon left>
                        mdi-chart-bar
                    </v-icon>
                    <span class="d-none d-sm-block">{{ $t('report.category.emotionDistribution.title') }}</span>
                </v-tab>
                <v-tab class="justify-start">
                    <v-icon left>
                        mdi-walk
                    </v-icon>
                    <span class="d-none d-sm-block">{{ $t('report.category.behaviorAnalysis.title') }}</span>
                </v-tab>

                <v-tab-item class="">
                    <v-card outlined>
                        <v-row class="no-gutters pa-5">
                            <v-col class="px-1 mb-3">
                                <v-row>
                                    <v-col cols="12" sm="6" md="4" lg="4">
                                        {{ $t('report.category.trend.query_condition.interval.title') }}:
                                        <v-radio-group v-model="calDateRange" row class="mt-0">
                                            <v-radio v-for="item in calDateRangeItems" :key="item.value" :label="item.title"
                                                :value="item.value"></v-radio>
                                        </v-radio-group>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="4" lg="4" v-if="diaryList.length > 1">
                                        {{ $t('report.category.trend.query_condition.student.title') }}：
                                        <v-select v-model="selectDiary" :items="diaryList"
                                            :label="this.$t('report.category.trend.query_condition.student.title')"
                                            item-text="studentName" item-value="diaryId" hide-details single-line dense
                                            :loading="isLoading" @change="addStudentTrendData"></v-select>
                                    </v-col>
                                </v-row>
                                <hr>
                                <div class="text-right mt-2">
                                    <v-btn class="normal-case"
                                        @click="saveAsImage('trendPrintArea', $t('report.category.trend.title'))"
                                        color="info" small outlined>{{ $t('button.downloadChart') }}</v-btn>
                                </div>
                                <div class="mt-4" ref="trendPrintArea">
                                    <canvas v-if="loaded" ref="trendCanvas" id="trendCanvas"></canvas>
                                </div>
                                <div class="grey lighten-4 pa-5 rounded-lg mt-6">
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.trend.description.p1.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.trend.description.p1.content') }}
                                    </div>
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.trend.description.p2.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.trend.description.p2.content') }}
                                    </div>
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.trend.description.p3.title') }}</span>
                                    <div class="mb-3">{{ $t('report.category.trend.description.p3.content') }}</div>
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.trend.description.p4.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.trend.description.p4.content') }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="col-12 col-md-4 col-lg-3 px-1"
                                v-if="queryCondition != '' || postDataList.length != 0">
                                <v-card outlined>
                                    <v-app-bar flat class="grey lighten-5">

                                        <v-toolbar-title class="text-subtitle-2 pl-0">
                                            {{ queryCondition }}<br />{{ queryDate }}
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>

                                        <v-btn x-small icon @click="closePostDataArea"
                                            class="normal-case">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-app-bar>

                                    <v-list-item v-for="item in postDataList" :key="item.postId"
                                        @click="dialogPostId = item.postId; postDialogOpenCount += 1">
                                        <v-list-item-avatar>
                                            <v-img alt="avatar" :src="item.avatarUrl"></v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <b>{{ item.userName }}</b> {{ item.title }}
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                <span v-for="(emo, i) in item.emotions" :key="i">
                                                    {{ emotionMap[emo] }}
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-card-text class="text-center mt-3" v-if="postDataList.length == 0">
                                        {{ $t('general.msg.no_data') }}
                                    </v-card-text>
                                    <div class="pa-2">
                                        <v-btn block color="secondary" :loading="isPostDataLoading"
                                            v-if="isShowPostDataMoreBtn" @click="readEmotionPostData"
                                            class="normal-case">{{ $t('button.more') }}</v-btn>
                                    </div>
                                </v-card>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item class="">
                    <v-card outlined :loading="isLoading">
                        <v-row class="no-gutters pa-5">
                            <v-col class="px-1 mb-3">
                                <div class="text-right mt-2">
                                    <v-btn class="normal-case"
                                        @click="saveAsImage('emotion4PrintArea', $t('report.category.emotion4Category.title'))"
                                        color="info" small outlined>{{ $t('button.downloadChart') }}</v-btn>
                                </div>
                                <v-row class="no-gutters" align="center" ref="emotion4PrintArea">
                                    <v-col class="col-12 col-sm-3 col-md-3 col-lg-3r">
                                        <div v-for="item in emotionMainTypeList" :key="item.id" class="mb-6">
                                            <div class="subtitle-2 text-center px-3 py-2 my-1" :class="item.cssClass">
                                                {{ item.title }}
                                            </div>
                                            <div class="body-2">{{ item.emotions }}</div>
                                        </div>
                                    </v-col>
                                    <v-col class="col-12 col-sm">
                                        <v-alert class="mx-5" outlined type="secondary" prominent border="left"
                                            v-if="noChartData">
                                            {{ $t('general.msg.no_data') }}
                                        </v-alert>
                                        <canvas v-if="loaded && !noChartData" ref="emotion4CategoryCanvas"></canvas>
                                    </v-col>
                                </v-row>
                                <div class="grey lighten-4 pa-5 rounded-lg mt-6">
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.emotion4Category.description.p1.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.emotion4Category.description.p1.content') }}
                                    </div>
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.emotion4Category.description.p2.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.emotion4Category.description.p2.content') }}
                                    </div>
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.emotion4Category.description.p3.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.emotion4Category.description.p3.content') }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="col-12 col-md-4 col-lg-3 px-1"
                                v-if="queryCondition != '' || postDataList.length != 0">
                                <v-card outlined>
                                    <v-app-bar flat class="grey lighten-5">

                                        <v-toolbar-title class="text-subtitle-2 pl-0">
                                            {{ queryCondition }}
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>

                                        <v-btn class="normal-case"
                                            x-small icon @click="closePostDataArea">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-app-bar>
                                    <v-list-item v-for="item in postDataList" :key="item.postId"
                                        @click="dialogPostId = item.postId; postDialogOpenCount += 1">
                                        <v-list-item-avatar>
                                            <v-img alt="avatar" :src="item.avatarUrl"></v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <b>{{ item.userName }}</b> {{ item.title }}
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                <span v-for="(emo, i) in item.emotions" :key="i">
                                                    {{ emotionMap[emo] }}
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-card-text class="text-center mt-3" v-if="postDataList.length == 0">
                                        {{ $t('general.msg.no_data') }}
                                    </v-card-text>
                                    <div class="pa-2">
                                        <v-btn block color="secondary" :loading="isPostDataLoading"
                                            v-if="isShowPostDataMoreBtn" @click="readEmotionPostData"
                                            class="normal-case">
                                            {{ $t('button.more') }}</v-btn>
                                    </div>
                                </v-card>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item class="">
                    <v-card outlined :loading="isLoading">
                        <v-row class="no-gutters pa-5">
                            <v-col class="px-1 mb-3">
                                <div class="text-right mt-2">
                                    <v-btn class="normal-case"
                                        @click="saveAsImage('distributionPrintArea', $t('report.category.emotionDistribution.title'))"
                                        color="info" small outlined>{{ $t('button.downloadChart') }}</v-btn>
                                </div>
                                <v-row class="no-gutters" align="center" ref="distributionPrintArea">
                                    <v-col class="col-12 col-sm-3 col-md-3 col-lg-3">
                                        <v-row>
                                            <v-col class="col-6 col-sm-12 col-md-12 col-lg-12">
                                                <div
                                                    class="green lighten-2 subtitle-2 white--text text-center px-3 py-2 my-1">
                                                    {{ $t('report.category.emotionDistribution.dataset.positive_rank.title')
                                                    }}
                                                </div>

                                                <v-simple-table dense>
                                                    <tbody>
                                                        <tr v-for="item in positiveEmoRank" :key="item.id">
                                                            <td>{{ item.title }}</td>
                                                            <td class="text-right">{{ item.value }}%</td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                            </v-col>
                                            <v-col>
                                                <div
                                                    class="deep-orange lighten-2 white--text subtitle-2 text-center px-3 py-2 my-1">
                                                    {{ $t('report.category.emotionDistribution.dataset.negative_rank.title')
                                                    }}
                                                </div>

                                                <v-simple-table dense>
                                                    <tbody>
                                                        <tr v-for="item in negativeEmoRank" :key="item.id">
                                                            <td>{{ item.title }}</td>
                                                            <td class="text-right">{{ item.value }}%</td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="col-12 col-sm px-3">
                                        <v-alert class="mx-5" outlined type="secondary" prominent border="left"
                                            v-if="noChartData">
                                            {{ $t('general.msg.no_data') }}
                                        </v-alert>
                                        <canvas v-if="loaded && !noChartData" ref="emotionsCanvas"></canvas>
                                    </v-col>
                                </v-row>
                                <div class="grey lighten-4 pa-5 rounded-lg mt-6">
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.emotionDistribution.description.p1.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.emotionDistribution.description.p1.content') }}
                                    </div>
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.emotionDistribution.description.p2.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.emotionDistribution.description.p2.content') }}
                                    </div>
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.emotionDistribution.description.p3.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.emotionDistribution.description.p3.content') }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="col-12 col-md-4 col-lg-3 px-1"
                                v-if="queryCondition != '' || postDataList.length != 0">
                                <v-card outlined>
                                    <v-app-bar flat class="grey lighten-5">

                                        <v-toolbar-title class="text-subtitle-2 pl-0">
                                            {{ queryCondition }}
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>

                                        <v-btn x-small icon @click="closePostDataArea"
                                            class="normal-case">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-app-bar>
                                    <v-list-item v-for="item in postDataList" :key="item.postId"
                                        @click="dialogPostId = item.postId; postDialogOpenCount += 1">
                                        <v-list-item-avatar>
                                            <v-img alt="avatar" :src="item.avatarUrl"></v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <b>{{ item.userName }}</b> {{ item.title }}
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                <span v-for="(emo, i) in item.emotions" :key="i">
                                                    {{ emotionMap[emo] }}
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-card-text class="text-center mt-3" v-if="postDataList.length == 0">
                                        {{ $t('general.msg.no_data') }}
                                    </v-card-text>
                                    <div class="pa-2">
                                        <v-btn block color="secondary" :loading="isPostDataLoading"
                                            class="normal-case"
                                            v-if="isShowPostDataMoreBtn" @click="readEmotionPostData">{{ $t('button.more')
                                            }}</v-btn>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>

                <v-tab-item class="">
                    <v-card outlined :loading="isLoading">
                        <v-row class="no-gutters pa-5">
                            <v-col class="col">
                                <div class="text-right mt-2">
                                    <v-btn class="normal-case"
                                        @click="saveAsImage('behaviorPrintArea', $t('report.category.behaviorAnalysis.title'))"
                                        color="info" small outlined>{{ $t('button.downloadChart') }}</v-btn>
                                </div>
                                <v-row class="no-gutters" align="center" ref="behaviorPrintArea">
                                    <v-col class="col-12 col-sm-4 col-md-3 col-lg-3">
                                        <v-row>
                                            <v-col class="col-6 col-sm-12 col-md-12 col-lg-12">
                                                <div
                                                    class="blue lighten-1 subtitle-2 white--text text-center px-3 py-2 my-1">
                                                    {{ $t('report.category.behaviorAnalysis.dataset.introversion.title') }}
                                                    {{
                                                        behaviorInnerPercent }}%
                                                </div>
                                                <v-simple-table dense>
                                                    <tbody>
                                                        <tr v-for="item in behaviorInnerList" :key="item.id">
                                                            <td>{{ item.title }}</td>
                                                            <td class="text-right">{{ item.value }}%</td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>

                                            </v-col>
                                            <v-col class="col-6 col-sm-12 col-md-12 col-lg-12">
                                                <div
                                                    class="pink accent-1 white--text subtitle-2 text-center px-3 py-2 my-1">
                                                    {{ $t('report.category.behaviorAnalysis.dataset.extroversion.title') }}
                                                    {{
                                                        behaviorOuterPercent }}%
                                                </div>

                                                <v-simple-table dense>
                                                    <tbody>
                                                        <tr v-for="item in behaviorOuterList" :key="item.id">
                                                            <td>{{ item.title }}</td>
                                                            <td class="text-right">{{ item.value }}%</td>
                                                        </tr>
                                                    </tbody>
                                                </v-simple-table>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="col-12 col-sm">
                                        <v-alert class="mx-5" outlined type="secondary" prominent border="left"
                                            v-if="noChartData">
                                            {{ $t('general.msg.no_data') }}
                                        </v-alert>
                                        <canvas v-if="loaded && !noChartData" ref="behaviorsCanvas"></canvas>
                                    </v-col>
                                </v-row>
                                <div class="grey lighten-4 pa-5 rounded-lg mt-6">
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.behaviorAnalysis.description.p1.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.behaviorAnalysis.description.p1.content') }}
                                    </div>
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.behaviorAnalysis.description.p2.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.behaviorAnalysis.description.p2.content') }}
                                    </div>
                                    <span class="orange--text text--darken-3">{{
                                        $t('report.category.behaviorAnalysis.description.p3.title') }}</span>
                                    <div class="mb-3">
                                        {{ $t('report.category.behaviorAnalysis.description.p3.content') }}
                                    </div>
                                </div>
                            </v-col>
                            <v-col class="col-12 col-md-4 col-lg-3 px-1"
                                v-if="queryCondition != '' || postDataList.length != 0">
                                <v-card outlined>
                                    <v-app-bar flat class="grey lighten-5">

                                        <v-toolbar-title class="text-subtitle-2 pl-0">
                                            {{ queryCondition }}
                                        </v-toolbar-title>

                                        <v-spacer></v-spacer>

                                        <v-btn class="normal-case"
                                            x-small icon @click="closePostDataArea">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-app-bar>
                                    <v-list-item v-for="item in postDataList" :key="item.postId"
                                        @click="dialogPostId = item.postId; postDialogOpenCount += 1">
                                        <v-list-item-avatar>
                                            <v-img alt="avatar" :src="item.avatarUrl"></v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <b>{{ item.userName }}</b> {{ item.title }}
                                            </v-list-item-title>

                                            <v-list-item-subtitle>
                                                <span v-for="(emo, i) in item.emotions" :key="i">
                                                    {{ emotionMap[emo] }}
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-card-text class="text-center mt-3" v-if="postDataList.length == 0">
                                        {{ $t('general.msg.no_data') }}
                                    </v-card-text>
                                    <div class="pa-2">
                                        <v-btn class="normal-case"
                                            block color="secondary" :loading="isPostDataLoading"
                                            v-if="isShowPostDataMoreBtn" @click="readEmotionPostData">{{ $t('button.more')
                                            }}</v-btn>
                                    </div>
                                </v-card>

                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </v-card>
        <v-dialog ref="dialog" v-model="openDataPickerMenu" persistent width="330px">
            <v-card>

                <div class="pt-4 pb-2 px-5">
                    <v-card-text class="pa-0 mb-1"><span class="subtitle-1">{{
                        $t('report.query_condition.query_day.value.custom.title') }}</span></v-card-text>
                    <v-row class="no-gutters" align="center">
                        <v-col>
                            <v-btn x-small color="primary" outlined class="mb-3 normal-case"
                                @click="setStartDateAsCreateDate">{{
                                    $t('report.query_condition.query_day.value.custom.btn.homework_create_date') }}</v-btn>
                            <v-text-field ref="dateRangeStart" :background-color="dataRangeStartBg" v-model="dateRangeStart"
                                :rules="dateRangeStartRule"
                                :label="$t('report.query_condition.query_day.value.custom.value.start_date.title')" dense
                                @click="selectDateRangeType = 0" @change="changeDateRange"
                                @focus="focusDateRange(0)"></v-text-field>
                        </v-col>
                        <div class="px-2">-</div>
                        <v-col class="text-right">
                            <v-btn x-small color="primary" outlined class="mb-3 normal-case"
                                @click="setStartDateAsLastPostDate"
                                :disabled="lastPostDate == null">{{
                                    $t('report.query_condition.query_day.value.custom.btn.last_post_date') }}</v-btn>
                            <v-text-field ref="dateRangeEnd" :background-color="dataRangeEndBg" v-model="dateRangeEnd"
                                :rules="dateRangeEndRule"
                                :label="$t('report.query_condition.query_day.value.custom.value.end_date.title')" dense
                                @click="selectDateRangeType = 1" @change="changeDateRange"
                                @focus="focusDateRange(1)"></v-text-field>
                        </v-col>
                    </v-row>
                    <div class="text-center">
                        <v-date-picker v-model="timeRange" locale="zh-cn" :min="homeworkCreateDate" :max="today" no-title
                            scrollable show-adjacent-months @click:date="changeDate" range>

                        </v-date-picker>
                    </div>
                </div>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="normal-case"
                        text color="primary" @click="openDataPickerMenu = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn class="normal-case"
                        text color="primary" @click="changeCustomDate">
                        {{ $t('button.apply') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <PostDialog v-if="dialogPostId != null" v-bind:postDialogOpenCount="postDialogOpenCount"
            v-bind:postId="dialogPostId" v-bind:homeworkId="homeworkId"></PostDialog>
    </v-container>
</template>

<script>

import { diaryStatisticServices } from "@/services/diaryStatistic.js";
import { diaryServices } from "@/services/diary.js";
import PostDialog from '@/components/console/diary/component/PostDialog.vue'

import html2canvas from 'html2canvas';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js';
Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle,
    ChartDataLabels,
);
import PayFunctionArea from '@/components/console/diary/component/PayFunctionArea.vue';
import { mapState } from "vuex";
import { getItem } from '@/utils/storageManager';
// Chart.defaults.set(
//     'plugins.tooltip', {
//     enabled: false
// });
export default {
    name: "report",
    components: { PayFunctionArea, PostDialog },
    data() {
        return {
            functionId: 'f8',
            payfunctionMap: JSON.parse(getItem('payfunctionMap')),
            dialogPostId: null,
            postDialogOpenCount: 0,
            breadcrumbsItems: [],
            buttonLoading: false,
            loaded: false,
            isLoading: false,
            homeworkId: null,
            homeworkCreateDate: null,
            lastPostDate: null,
            today: null,
            studentDiaryId: '',
            tabDirection: true,
            selectDiary: '',
            diaryList: [{
                diaryId: '',
                studentName: this.$t('report.category.trend.query_condition.student.value.all'),
            }],
            dateRangeText: this.$t('report.query_condition.query_day.value.15'),
            dateRangeItems: [
                { title: this.$t('report.query_condition.query_day.value.15'), value: 15, active: 1 },
                { title: this.$t('report.query_condition.query_day.value.30'), value: 30, active: 0 },
                { title: this.$t('report.query_condition.query_day.value.60'), value: 60, active: 0 },
                { title: this.$t('report.query_condition.query_day.value.90'), value: 90, active: 0 },
                { title: this.$t('report.query_condition.query_day.value.custom.title'), value: -1, active: 0 },
            ],
            openDataPickerMenu: false,
            selectDateRangeType: '0',
            dateRangleValue: [],
            dateRangeStart: '',
            dateRangeStartRule: [
                (v) => (/^((19|2[0-9])[0-9]{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v)) || this.$t('report.query_condition.query_day.value.custom.value.start_date.error.1'),
                (v) => !(this.dateRangeEnd == null || (this.dateRangeEnd != '' && v > this.dateRangeEnd)) || this.$t('report.query_condition.query_day.value.custom.value.start_date.error.2'),
            ],
            dataRangeStartBg: '',
            dateRangeEnd: '',
            dateRangeEndRule: [
                (v) => /^((19|2[0-9])[0-9]{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || this.$t('report.query_condition.query_day.value.custom.value.end_date.error.1'),
                (v) => !(this.dateRangeStart == null || (this.dateRangeStart != '' && v < this.dateRangeStart)) || this.$t('report.query_condition.query_day.value.custom.value.end_date.error.2'),
            ],
            dataRangeEndBg: '',
            oldTimeRange: [],
            timeRange: [],
            calDateRange: '7d',
            calDateRangeItems: [
                { title: this.$t('report.category.trend.query_condition.interval.value.monthly'), value: '1m', active: 1 },
                { title: this.$t('report.category.trend.query_condition.interval.value.weekly'), value: '7d', active: 0 },
                // { title: '每3天', value: '3d', active: 0 },
            ],
            selectedChartTab: 0,
            displayChart: null,
            noChartData: false,
            emotionMainTypeList: [
                { id: 'j', title: this.$t('emotion.category.joy.title'), cssClass: 'yellow darken-2 grey--text text--lighten-5', color: '#FBC02D', emotions: this.$t('emotion.category.joy.elements') },
                { id: 'a', title: this.$t('emotion.category.angry.title'), cssClass: 'red lighten-2 grey--text text--lighten-5', color: '#E57373', emotions: this.$t('emotion.category.angry.elements') },
                { id: 's', title: this.$t('emotion.category.sad.title'), cssClass: 'indigo lighten-2 grey--text text--lighten-5', color: '#7986CB', emotions: this.$t('emotion.category.sad.elements') },
                { id: 'f', title: this.$t('emotion.category.fear.title'), cssClass: 'grey grey--text text--lighten-5', color: '#9E9E9E', emotions: this.$t('emotion.category.fear.elements') },
            ],
            emotionList: ['3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', '11', '12', '13', '14', '15', '16', '17',],
            emotionMap: {
                '3': this.$t('emotion.type.3'),
                '4': this.$t('emotion.type.4'),
                '5': this.$t('emotion.type.5'),
                '6': this.$t('emotion.type.6'),
                '7': this.$t('emotion.type.7'),
                '8': this.$t('emotion.type.8'),
                '9': this.$t('emotion.type.9'),
                'A': this.$t('emotion.type.A'),
                'B': this.$t('emotion.type.B'),
                'C': this.$t('emotion.type.C'),
                'D': this.$t('emotion.type.D'),
                'E': this.$t('emotion.type.E'),
                'F': this.$t('emotion.type.F'),
                '11': this.$t('emotion.type.11'),
                '12': this.$t('emotion.type.12'),
                '13': this.$t('emotion.type.13'),
                '14': this.$t('emotion.type.14'),
                '15': this.$t('emotion.type.15'),
                '16': this.$t('emotion.type.16'),
                '17': this.$t('emotion.type.17'),
            },
            positiveEmoRank: [],
            negativeEmoRank: [],
            behaviorInnerVal: 0,
            behaviorOuterVal: 0,
            behaviorList: [
                { type: 'o', id: '1', title: this.$t('behavior.type.1'), value: 0 },
                { type: 'o', id: '2', title: this.$t('behavior.type.2'), value: 0 },
                { type: 'o', id: '3', title: this.$t('behavior.type.3'), value: 0 },
                { type: 'o', id: '6', title: this.$t('behavior.type.6'), value: 0 },
                { type: 'o', id: '7', title: this.$t('behavior.type.7'), value: 0 },
                { type: 'o', id: '9', title: this.$t('behavior.type.9'), value: 0 },
                { type: 'i', id: '4', title: this.$t('behavior.type.4'), value: 0 },
                { type: 'i', id: '5', title: this.$t('behavior.type.5'), value: 0 },
                { type: 'i', id: '8', title: this.$t('behavior.type.8'), value: 0 },
                { type: 'i', id: '10', title: this.$t('behavior.type.10'), value: 0 },
            ],
            chartPostCountList: {},
            postDataPayload: {},
            postDataList: [],
            isPostDataLoading: false,
            isShowPostDataMoreBtn: false,
            queryCondition: '',
            queryDate: '',
        };
    },
    async created() {
        Chart.defaults.font.size = 13;
        this.homeworkId = this.$route.params.id;
        const userId = this.$route.params.uid;

        const diaryHomeworkNameRes = await diaryServices.getHomeworkName(this.homeworkId, {});
        if (diaryHomeworkNameRes.data.return_code != null) {
            location.href = '/login';
        }
        this.breadcrumbsItems.push(
            {
                text: this.$t('index.title'),
                disabled: false,
                href: `/diary`,
            },
            {
                text: this.homeworkName,
                disabled: false,
                href: `/diary/homework/${this.homeworkId}`,
            },
            {
                text: this.$t('menu.diary_data_report'),
                disabled: true
            }
        );
        if (!this.payfunctionMap[this.functionId]) {
            return;
        }
        this.homeworkCreateDate = diaryHomeworkNameRes.data.create_date;
        this.lastPostDate = diaryHomeworkNameRes.data.last_post_date;
        if (this.lastPostDate != null) {
            this.lastPostDate = new Date(this.lastPostDate).toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
        }
        this.today = new Date().toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
        this.loaded = true;

        const dateRangeItem = this.dateRangeItems.find(item => item.active == 1);
        this.selectDate(dateRangeItem.value);

        const diaryStudentRes = await diaryServices.getHomeworkStudentName(this.homeworkId, {});
        const diaryStudentList = diaryStudentRes.data;
        if (typeof userId != 'undefined') {
            this.diaryList = [];
            const diaryInfo = diaryStudentList.find(item => item.user_id == userId);
            if (diaryInfo == null) {
                location.href = '/404';
            }
            this.studentDiaryId = diaryInfo.diary_id;
            this.diaryList.push({
                diaryId: diaryInfo.diary_id,
                studentName: diaryInfo.student_name,
            });
            this.breadcrumbsItems.push({
                text: diaryInfo.student_name,
                disabled: true,
                href: ``,
            });
            this.selectDiary = diaryInfo.diary_id;
        } else {
            diaryStudentList.forEach(student => {
                this.diaryList.push({
                    diaryId: student.diary_id,
                    studentName: student.student_name,
                })
            });
        }
        // this.breadcrumbsItems.push({
        //     text: this.$t('report.title'),
        //     disabled: true,
        //     href: ``,
        // });

        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    mounted() {
    },
    computed: {
        ...mapState(["homeworkName"]),
        behaviorInnerList() {
            return this.behaviorList.filter(item => item.type == 'i');
        },
        behaviorOuterList() {
            return this.behaviorList.filter(item => item.type == 'o');
        },
        behaviorInnerPercent() {
            return this.behaviorInnerVal == 0 ? 0 : Math.round(this.behaviorInnerVal / (this.behaviorInnerVal + this.behaviorOuterVal) * 100)
        },
        behaviorOuterPercent() {
            return this.behaviorOuterVal == 0 ? 0 : Math.round(this.behaviorOuterVal / (this.behaviorInnerVal + this.behaviorOuterVal) * 100)
        },
    },
    watch: {
        openDataPickerMenu() {
            if (!this.openDataPickerMenu) return;
            this.selectDateRangeType = 0;
            this.$nextTick(function () {
                this.$refs.dateRangeStart.focus();
                this.dataRangeStartBg = 'blue lighten-5';
            })
        },
        calDateRange() {
            this.changeChart();
        },
        homeworkName() {
            this.breadcrumbsItems[1].text = this.homeworkName;
        },
    },
    methods: {
        handleResize() {
            const widowInnerWidth = window.innerWidth;
            if (widowInnerWidth >= 1264) {
                this.tabDirection = true;
            } else {
                this.tabDirection = false;
            }
        },
        async changeChart() {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            const tablInd = this.selectedChartTab;

            this.queryCondition = '';
            this.postDataList = [];

            this.noChartData = false;
            if (tablInd == 0) {
                await this.readTrendChart();
                if (this.selectDiary != '') {
                    await this.addStudentTrendData();
                }
            } else if (tablInd == 1) {
                await this.readEmotion4Chart();
            } else if (tablInd == 2) {
                await this.readEmotionsChart();
            } else if (tablInd == 3) {
                await this.readBehaviorsChart();
            }
            this.isLoading = false;
        },
        async readTrendChart() {
            let payload = {
                start_date: this.timeRange[0],
                end_date: this.timeRange[1],
                day_interval: this.calDateRange,
            }
            const res = await diaryStatisticServices.getTrendData(this.homeworkId, payload);
            const respData = res.data;

            const calDateRange = this.calDateRangeItems.find(item => item.value == this.calDateRange);

            let currentDate = new Date(payload.start_date);
            let endDate = new Date(payload.end_date);

            let pdataList = [];
            let ndataList = [];
            let labelList = [];
            let dayInterval = 1;
            if (payload.day_interval.indexOf('d') != -1) {
                dayInterval = parseInt(payload.day_interval.replace('d', ''));
            } else {
                currentDate.setDate(1);
                endDate.setDate(1);
            }
            do {
                let date = '';
                if (payload.day_interval.indexOf('d') != -1) {
                    date = currentDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')
                } else {
                    date = currentDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit' }).replace(/\//g, '-')
                }

                let dateData = respData[date];
                if (dateData == null) {
                    dateData = {};
                }
                let nvalue = dateData['n'] == null ? 0 : dateData['n'];
                let pvalue = dateData['p'] == null ? 0 : dateData['p'];
                let totalValue = nvalue + pvalue;
                let pPercentage = (totalValue == 0) ? 0 : Math.round(pvalue / totalValue * 100);
                let nPercentage = (totalValue == 0) ? 0 : Math.round(nvalue / totalValue * 100);
                pdataList.push(pPercentage);
                ndataList.push(nPercentage);
                labelList.push(date);
                if (payload.day_interval.indexOf('d') != -1) {
                    currentDate.setDate(currentDate.getDate() + dayInterval);
                } else {
                    currentDate.setMonth(currentDate.getMonth() + dayInterval);
                }
            } while (currentDate <= endDate);

            const canvas = this.$refs.trendCanvas;

            if (this.displayChart != null) {
                this.displayChart.destroy();
            }

            this.displayChart = new Chart(canvas, {
                type: 'line',
                data: {
                    labels: labelList,
                    datasets: [{
                        //
                        label: this.$t('report.category.trend.dataset.overall_positive.title'),
                        data: pdataList,
                        borderColor: '#80c58d',
                        pointBorderColor: 'black',
                        pointBackgroundColor: '#80c58d',
                        pointBorderWidth: 1,

                    }, {
                        label: this.$t('report.category.trend.dataset.overall_negative.title'),
                        data: ndataList,
                        borderColor: '#f07f54',
                        pointBorderColor: 'black',
                        pointBackgroundColor: '#f07f54',
                        pointBorderWidth: 1,

                    }]
                },
                options: {
                    //aspectRatio: 2,
                    responsive: true,
                    legend: {
                    },
                    plugins: {
                        datalabels: {
                            formatter: function () {
                                return '';
                            }
                        }, tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return `${tooltipItem.formattedValue}%`;
                                },
                            }
                        }
                    },
                    scales: {
                        x: {
                            title: {
                                display: true,
                                text: calDateRange.title,
                            },
                        },
                        y: {
                            max: 100,
                            min: 0,
                            ticks: {
                                callback: function (value) { return value + "%" }
                            },
                            title: {
                                display: true,
                                text: this.$t('report.category.trend.unit_type.y.title'),
                            },
                        }
                    },
                    tooltips: {
                        mode: 'index',
                        callbacks: {

                            label: function (tooltipItem, data) {
                                //get the concerned dataset
                                var dataset = data.datasets[tooltipItem.datasetIndex];
                                //calculate the total of this data set

                                //get the current items value
                                var currentValue = dataset.data[tooltipItem.index];
                                return dataset.label + ' : ' + currentValue + "%";

                            }
                        }
                    },
                    interaction: {
                        mode: 'nearest', // 或 'x'
                    },
                    onClick: async (e, elements) => {
                        if (elements.length > 0) {
                            const chartElement = elements[0];
                            const datasetIndex = chartElement.datasetIndex;
                            const index = chartElement.index;

                            let emotionType = datasetIndex == '1' ? 'n' : 'p';
                            let queryEndDate;
                            let queryStartDate = new Date(labelList[index]);
                            if (index < labelList.length - 1) {
                                queryEndDate = new Date(labelList[index + 1]);
                                queryEndDate = queryEndDate.setDate(queryEndDate.getDate() - 1);
                                queryEndDate = new Date(queryEndDate);
                            } else {
                                queryEndDate = new Date(endDate);
                            }
                            queryEndDate = queryEndDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
                            queryStartDate = queryStartDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');

                            this.queryCondition = chartElement.element.$datalabels[0].$context.dataset.label
                            this.queryDate = queryStartDate;
                            this.postDataPayload = {
                                startDate: queryStartDate,
                                endDate: queryEndDate,
                                emotionType: emotionType,
                                diaryId: datasetIndex == '2' ? this.selectDiary : '',
                                page: 0,
                            }
                            this.readEmotionPostData();

                        }
                    }
                }
            });

        },
        async addStudentTrendData() {
            let currentDatasets = this.displayChart.data.datasets;
            if (this.selectDiary == '' || currentDatasets.length > 2) {
                currentDatasets.pop();

                if (this.selectDiary == '') {
                    this.displayChart.update();
                    return;
                }

            }
            let payload = {
                start_date: this.timeRange[0],
                end_date: this.timeRange[1],
                day_interval: this.calDateRange,
                diary_id: this.selectDiary,
            }
            const res = await diaryStatisticServices.getTrendData(this.homeworkId, payload);
            const respData = res.data;

            let currentDate = new Date(payload.start_date);
            let endDate = new Date(payload.end_date);

            let pdataList = [];
            let ndataList = [];
            let labelList = [];

            let dayInterval = 1;
            if (payload.day_interval.indexOf('d') != -1) {
                dayInterval = parseInt(payload.day_interval.replace('d', ''));
            } else {
                currentDate.setDate(1);
                endDate.setDate(1);
            }
            do {
                let date = '';
                if (payload.day_interval.indexOf('d') != -1) {
                    date = currentDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')
                } else {
                    date = currentDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit' }).replace(/\//g, '-')
                }

                let dateData = respData[date];
                if (dateData == null) {
                    dateData = {};
                }
                let nvalue = dateData['n'] == null ? 0 : dateData['n'];
                let pvalue = dateData['p'] == null ? 0 : dateData['p'];
                let totalValue = nvalue + pvalue;
                let pPercentage = (totalValue == 0) ? 0 : Math.round(pvalue / totalValue * 100);
                let nPercentage = (totalValue == 0) ? 0 : Math.round(nvalue / totalValue * 100);
                pdataList.push(pPercentage);
                ndataList.push(nPercentage);
                labelList.push(date);
                if (payload.day_interval.indexOf('d') != -1) {
                    currentDate.setDate(currentDate.getDate() + dayInterval);
                } else {
                    currentDate.setMonth(currentDate.getMonth() + dayInterval);
                }
            } while (currentDate <= endDate);
            //

            const selectDiaryObj = this.diaryList.find(item => item.diaryId === this.selectDiary);
            const dataset = {
                label: `${selectDiaryObj.studentName}` + this.$t('report.category.trend.dataset.positive.title'),
                data: pdataList,
                borderColor: '#444444',
                pointBorderColor: 'black',
                pointBackgroundColor: '#039BE5',
                pointBorderWidth: 1,
            }

            currentDatasets.push(dataset);
            this.displayChart.update();
        },
        async readEmotion4Chart() {
            let payload = {
                start_date: this.timeRange[0],
                end_date: this.timeRange[1],
                diary_id: this.studentDiaryId,
            }
            const res = await diaryStatisticServices.getEmotion4Data(this.homeworkId, payload);
            const respData = res.data;

            let dataList = [];
            let labelList = [];
            let colorList = [];

            const totalCount = parseInt(respData['all']);
            if (totalCount == 0) {
                this.noChartData = true;
                return;
            }
            let postCountList = [];
            this.emotionMainTypeList.forEach(emotion => {
                let data = respData[emotion.id];
                let value = 0;
                let postCount = 0;
                if (data != null) {
                    value = +(Math.round((data.count / totalCount * 100) + "e+2") + "e-2");
                    postCount = data.post_count;
                }
                dataList.push(value);
                postCountList.push(postCount);
                labelList.push(emotion.title);
                colorList.push(emotion.color);
            });

            const canvas = this.$refs.emotion4CategoryCanvas;
            if (this.displayChart != null) {
                this.displayChart.destroy();
            }

            const unitType = this.$t('report.unit_type.diary');

            this.displayChart = new Chart(canvas, {
                type: 'pie',
                data: {
                    labels: labelList,
                    datasets: [{
                        data: dataList,
                        backgroundColor: colorList,
                    }]
                },
                options: {
                    aspectRatio: 1.5,
                    responsive: true,
                    legend: {
                        display: false
                    },
                    title: {
                        display: false
                    },
                    plugins: {
                        datalabels: {
                            formatter: function (value) {

                                if (value == '0') {
                                    return '';
                                } else {
                                    return value + '%';
                                }
                            },
                            color: '#000',
                        }, tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    const selectInd = tooltipItem.dataIndex;
                                    let postCount = unitType.replace('${num}', postCountList[selectInd]);
                                    return `${tooltipItem.formattedValue}% (${postCount})`;
                                },
                            }
                        }
                    },
                    tooltips: {
                        mode: 'index',
                        callbacks: {

                        }
                    },
                    hover: {
                        mode: 'index'
                    },
                    onClick: async (e, elements) => {
                        if (elements.length > 0) {
                            const chartElement = elements[0];
                            const index = chartElement.index;

                            let emotionType = 'f';
                            if (index == '0') {
                                emotionType = 'j';
                            } else if (index == '1') {
                                emotionType = 'a';
                            } else if (index == '2') {
                                emotionType = 's';
                            }

                            let queryStartDate = new Date(this.timeRange[0]);
                            let queryEndDate = new Date(this.timeRange[1]);

                            queryEndDate = queryEndDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
                            queryStartDate = queryStartDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');

                            this.queryCondition = labelList[index];
                            this.queryDate = queryStartDate;
                            this.postDataPayload = {
                                startDate: queryStartDate,
                                endDate: queryEndDate,
                                emotionType: emotionType,
                                diaryId: this.studentDiaryId,
                                page: 0,
                            }
                            this.readEmotionPostData();

                        }
                    }
                }
            });

        },
        async readEmotionsChart() {
            let payload = {
                start_date: this.timeRange[0],
                end_date: this.timeRange[1],
                diary_id: this.studentDiaryId,
            }
            const res = await diaryStatisticServices.getEmotionsData(this.homeworkId, payload);
            const respData = res.data;
            const totalCount = parseInt(respData['all']);

            if (totalCount == 0) {
                this.noChartData = true;
                return;
            }

            let dataList = [];
            let labelList = [];
            let backgroundList = [];
            let borderList = [];


            let pRankList = [];
            let nRankList = [];

            let postCountList = [];
            this.emotionList.forEach(emotionId => {
                let data = respData[emotionId];
                let value = 0;
                let postCount = 0;
                let color = '';
                let emotionTitle = this.emotionMap[emotionId];
                if (data != null) {
                    value = +(Math.round((data.count / totalCount * 100) + "e+2") + "e-2");
                    postCount = data.post_count;
                    if (data.type == 'p') {
                        color = '#81C784';
                        pRankList.push({
                            id: emotionId,
                            title: emotionTitle,
                            value: value,
                        })
                    } else {
                        color = '#FF8A65';
                        nRankList.push({
                            id: emotionId,
                            title: emotionTitle,
                            value: value,
                        })
                    }
                }
                postCountList.push(postCount);
                dataList.push(value);
                labelList.push(emotionTitle);
                backgroundList.push(color);
                borderList.push(color);
            });
            pRankList.sort(function (x, y) {
                return y.value - x.value;
            });

            nRankList.sort(function (x, y) {
                return y.value - x.value;
            });
            this.positiveEmoRank = pRankList.splice(0, pRankList.length > 3 ? 3 : pRankList.length);
            this.negativeEmoRank = nRankList.splice(0, nRankList.length > 3 ? 3 : nRankList.length);

            const canvas = this.$refs.emotionsCanvas;
            if (this.displayChart != null) {
                this.displayChart.destroy();
            }
            const unitType = this.$t('report.unit_type.diary');

            this.displayChart = new Chart(canvas, {
                type: 'bar',
                data: {
                    labels: labelList,
                    datasets: [{
                        //
                        label: this.$t('report.category.emotionDistribution.dataset.all_emotion.title'),
                        data: dataList,
                        backgroundColor: backgroundList,
                        borderColor: borderList,
                        borderWidth: 1
                    }]
                },
                options: {
                    aspectRatio: 2,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        title: {
                            display: false
                        },
                        datalabels: {
                            formatter: () => {
                                return '';
                            },
                            color: function (context) {
                                let index = context.dataIndex;
                                var c = [
                                    '#fff',
                                    '#fff',
                                    '#fff',
                                    '#fff'
                                ];
                                return c[index];
                            }
                        }, tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    const selectInd = tooltipItem.dataIndex;
                                    let postCount = unitType.replace('${num}', postCountList[selectInd]);
                                    return `${tooltipItem.formattedValue}% (${postCount})`;
                                },
                            }
                        }
                    },
                    tooltips: {
                        mode: 'index',
                        callbacks: {
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                maxRotation: 100,
                                minRotation: 100
                            }
                        },
                        y: {
                            max: 100,
                            min: 0,
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) { return value + "%" }
                            },
                            title: {
                                display: true,
                                text: this.$t('report.category.emotionDistribution.unit_type.y.title'),
                                //align: 'end'
                            },
                        }
                    },
                    hover: {
                        mode: 'index'
                    },

                    onClick: async (e, elements) => {
                        if (elements.length > 0) {
                            const chartElement = elements[0];
                            const index = chartElement.index;

                            let emotionType = this.emotionList[index];

                            let queryStartDate = new Date(this.timeRange[0]);
                            let queryEndDate = new Date(this.timeRange[1]);

                            queryEndDate = queryEndDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
                            queryStartDate = queryStartDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');

                            this.queryCondition = this.emotionMap[emotionType];

                            this.postDataPayload = {
                                startDate: queryStartDate,
                                endDate: queryEndDate,
                                emotionType: emotionType,
                                diaryId: this.studentDiaryId,
                                page: 0,
                            }
                            this.readEmotionPostData();

                        }
                    }
                }
            });

        },
        async readBehaviorsChart() {
            let payload = {
                start_date: this.timeRange[0],
                end_date: this.timeRange[1],
                diary_id: this.studentDiaryId,
            }
            const res = await diaryStatisticServices.getBehaviorsData(this.homeworkId, payload);
            const respData = res.data;
            const totalCount = parseInt(respData['all']);

            if (totalCount == 0) {
                this.noChartData = true;
                return;
            }
            let dataList = [];
            let labelList = [];
            let backgroundList = [];
            let borderList = [];

            let maxValue = 0;
            this.behaviorInnerVal = 0;
            this.behaviorOuterVal = 0;

            let postCountList = [];
            this.behaviorList.forEach(item => {
                let data = respData[item.id];
                let value = 0;
                let postCount = 0;
                if (data != null) {
                    postCount = data.post_count;
                    value = +(Math.round((data.count / totalCount * 100) + "e+2") + "e-2");
                }
                postCountList.push(postCount);
                dataList.push(value);
                labelList.push(item.title);

                if (item.type == 'i') {
                    this.behaviorInnerVal += value;
                    backgroundList.push('#b2dfdb');
                    borderList.push('#b2dfdb');
                } else {
                    this.behaviorOuterVal += value;
                    backgroundList.push('#ffca28');
                    borderList.push('#b2dfdb');
                }

                item.value = value;

                if (maxValue < value) {
                    maxValue = value;
                }
            });


            const canvas = this.$refs.behaviorsCanvas;
            if (this.displayChart != null) {
                this.displayChart.destroy();
            }

            const unitType = this.$t('report.unit_type.diary');

            this.displayChart = new Chart(canvas, {
                type: 'radar',
                data: {
                    labels: labelList,
                    datasets: [{
                        //
                        label: null,//this.$t('report.category.behaviorAnalysis.dataset.behavior.title'),
                        data: dataList,
                        backgroundColor: function (context) {
                            const chartArea = context.chart.chartArea;
                            if (!chartArea) {
                                // This case happens on initial chart load
                                return;
                            }
                            const chartWidth = chartArea.right - chartArea.left;
                            const chartHeight = chartArea.bottom - chartArea.top;

                            const centerX = (chartArea.left + chartArea.right) / 2;
                            const centerY = (chartArea.top + chartArea.bottom) / 2;

                            const ctx = context.chart.ctx;

                            var gradient = ctx.createConicGradient(-1.0472, centerX, centerY);

                            gradient.addColorStop(0, '#FF80AB');
                            gradient.addColorStop(.62, '#FF80AB');
                            gradient.addColorStop(.62, '#42A5F5');
                            gradient.addColorStop(.92, '#42A5F5');
                            gradient.addColorStop(.92, '#FF80AB');

                            // Set the fill style and draw a rectangle
                            ctx.fillStyle = gradient;
                            ctx.fillRect(chartArea.left, chartArea.top, chartWidth, chartHeight);
                            return gradient;
                        },
                        borderColor: '#888',
                        borderWidth: 1,
                        pointBackgroundColor: '#fff',
                    }]
                },
                options: {
                    aspectRatio: 1.5,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false,
                        },
                        title: {
                            display: false
                        },
                        datalabels: {
                            formatter: () => {
                                return '';
                            },
                        }, tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    const selectInd = tooltipItem.dataIndex;
                                    let postCount = unitType.replace('${num}', postCountList[selectInd]);
                                    return `${tooltipItem.formattedValue}% (${postCount})`;
                                },
                            }
                        },
                    },
                    scales: {
                        r: {
                            angleLines: {
                                display: false
                            },
                            suggestedMin: 0,
                            suggestedMax: Math.round(maxValue) + (maxValue > 95 ? 0 : 5),
                            pointLabels: {
                                font: {
                                    size: 13,
                                },
                            },
                        },
                    },
                    hover: {
                        mode: 'index'
                    },

                    onClick: async (e, elements) => {
                        if (elements.length > 0) {
                            const chartElement = elements[0];
                            const index = chartElement.index;
                            const behavior = this.behaviorList[index];

                            let queryStartDate = new Date(this.timeRange[0]);
                            let queryEndDate = new Date(this.timeRange[1]);

                            queryEndDate = queryEndDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
                            queryStartDate = queryStartDate.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');

                            this.queryCondition = behavior.title;

                            this.postDataPayload = {
                                startDate: queryStartDate,
                                endDate: queryEndDate,
                                behaviorId: behavior.id,
                                diaryId: this.studentDiaryId,
                                page: 0,
                            }
                            this.readEmotionPostData();

                        }
                    }
                }
            });

        },
        async readEmotionPostData() {
            if (this.isPostDataLoading) {
                return;
            }

            const tablInd = this.selectedChartTab;
            let payload = {};
            //行為的參數不一樣
            if (tablInd == 3) {
                payload = {
                    start_date: this.postDataPayload.startDate,
                    end_date: this.postDataPayload.endDate,
                    diary_id: this.postDataPayload.diaryId,
                    selected_id: this.postDataPayload.behaviorId,
                    page: this.postDataPayload.page + 1,
                }
            } else {
                payload = {
                    start_date: this.postDataPayload.startDate,
                    end_date: this.postDataPayload.endDate,
                    diary_id: this.postDataPayload.diaryId,
                    selected_id: this.postDataPayload.emotionType,
                    page: this.postDataPayload.page + 1,
                }
            }
            this.isPostDataLoading = true;

            let res;
            if (tablInd == 0) {
                res = await diaryStatisticServices.getTrendPostList(this.homeworkId, payload);
            } else if (tablInd == 1) {
                res = await diaryStatisticServices.getEmotion4PostList(this.homeworkId, payload);
            } else if (tablInd == 2) {
                res = await diaryStatisticServices.getEmotionsDataPostList(this.homeworkId, payload);
            } else if (tablInd == 3) {
                res = await diaryStatisticServices.getBehaviorsPostList(this.homeworkId, payload);
            }

            if (this.postDataPayload.page == 0) {
                this.postDataList = [];
            }
            if (res.data != null) {
                res.data.forEach(item => {
                    this.postDataList.push({
                        postId: item.post_id,
                        avatarUrl: item.avatar_url,
                        title: item.title,
                        userName: item.user_name,
                        emotions: item.emotions
                    })
                });
                if (res.data.length < 5) {
                    this.isShowPostDataMoreBtn = false;
                } else {
                    this.isShowPostDataMoreBtn = true;
                }
                this.postDataPayload.page += 1;

                if (tablInd == 3) {
                    this.displayChart.update();
                }

            } else {
                this.isShowPostDataMoreBtn = false;
            }
            this.isPostDataLoading = false;
        },

        changeCustomDate() {
            this.dateRangeItems.forEach(item => {
                item.active = 0;
                if (item.value == '-1') {
                    item.active = 1;
                    this.dateRangeText = this.dateRangeStart + ' - ' + this.dateRangeEnd
                }
            });
            this.openDataPickerMenu = false;
            this.changeChart();
        },
        selectDate(value) {
            this.dateRangeItems.forEach(item => {
                item.active = 0;
                if (item.value == value) {
                    item.active = 1;
                    this.dateRangeText = item.title
                }
            });
            //不是自訂的話
            if (value != -1) {
                let dateEnd = new Date(new Date().toDateString());
                let dateStart = new Date();
                dateStart.setDate(dateEnd.getDate() - value);
                this.dateRangeStart = dateStart.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
                this.dateRangeEnd = dateEnd.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
                this.timeRange = [this.dateRangeStart, this.dateRangeEnd];
                this.oldTimeRange = this.timeRange;

                this.changeChart();
            }
        },
        setStartDateAsCreateDate() {
            this.dateRangeStart = this.homeworkCreateDate;
            this.changeDateRange();
            this.selectDateRangeType = 0;
        },
        setStartDateAsLastPostDate() {
            this.dateRangeEnd = this.lastPostDate;
            this.changeDateRange();
            this.selectDateRangeType = 1;
        },
        changeDateRange() {
            if (this.dateRangeEnd == '') {
                this.timeRange = [this.dateRangeStart, this.dateRangeStart];
            } else if (this.dateRangeStart == '') {
                this.timeRange = [this.dateRangeEnd, this.dateRangeEnd];
            } else {
                if (this.dateRangeStart > this.dateRangeEnd) {
                    return;
                }
                this.timeRange = [this.dateRangeStart, this.dateRangeEnd];
            }
            this.oldTimeRange = this.timeRange;
        },
        changeDate() {
            if (this.selectDateRangeType == 0) {
                let selectVal = this.timeRange[0];
                if (selectVal == null) {
                    selectVal = this.timeRange[1];
                }
                if (this.oldTimeRange[1] == null) {
                    this.oldTimeRange[1] = selectVal;
                }
                if (selectVal > this.oldTimeRange[1]) {
                    this.timeRange = this.oldTimeRange;
                    return;
                }

                this.timeRange = [selectVal, this.oldTimeRange[1]];
                this.dateRangeStart = selectVal;

            } else {
                let selectVal = this.timeRange[1];
                if (selectVal == null) {
                    selectVal = this.timeRange[0];
                }
                if (this.oldTimeRange[0] == null) {
                    this.oldTimeRange[0] = selectVal;
                }

                if (selectVal < this.oldTimeRange[0]) {
                    this.timeRange = this.oldTimeRange;
                    return;
                }
                this.timeRange = [this.oldTimeRange[0], selectVal];
                this.dateRangeEnd = selectVal;

            }
            this.changeDateRangeType();
            this.oldTimeRange = this.timeRange;
        },
        focusDateRange(type) {
            if (type == 0) {
                this.dataRangeEndBg = '';
                this.dataRangeStartBg = 'blue lighten-5';
            } else {
                this.dataRangeStartBg = '';
                this.dataRangeEndBg = 'blue lighten-5';
            }
        },
        changeDateRangeType() {
            if (this.selectDateRangeType == 0) {
                this.selectDateRangeType = 1;
                this.$nextTick(function () {
                    this.$refs.dateRangeEnd.focus();
                    this.dataRangeStartBg = '';
                    this.dataRangeEndBg = 'blue lighten-5';
                })
            } else {
                this.selectDateRangeType = 0;
                this.$nextTick(function () {
                    this.$refs.dateRangeStart.focus();
                    this.dataRangeEndBg = '';
                    this.dataRangeStartBg = 'blue lighten-5';
                })
            }
        },
        closePostDataArea() {
            this.queryCondition = '';
            this.postDataList = [];
            window.setTimeout(() => {
                this.displayChart.update();
            }, 100);

        },
        saveAsImage(targetCanvas, filename) {
            const shareContent = this.$refs[targetCanvas]
            var width = shareContent.offsetWidth;
            var height = shareContent.offsetHeight;
            html2canvas(shareContent, {
                x: -10,
                y: -10,
                width: width + 20,
                height: height + 20,
                backgroundColor: '#ffffff',
                useCORS: true
            }).then(canvas => {
                const url = canvas.toDataURL('image/png');

                let eleLink = document.createElement('a')
                eleLink.download = filename;
                eleLink.href = url;
                eleLink.click();
                eleLink.remove();
            })
        },
    },
};
</script>
<style scoped>
canvas {
    max-width: 100% !important;
}
</style>
