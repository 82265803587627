<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-3" large></v-breadcrumbs>
        <v-row>
            <v-col cols="12" md="8" lg="8" order="2" order-md="1">
                <v-toolbar v-if="postList.length != 0" dense color="elevation-0" class="px-0">
                    <DatePicker v-bind:homeworkCreateDate="homeworkCreateDate" v-bind:lastPostDate="lastPostDate"
                        @queryData="filtDate"></DatePicker>
                </v-toolbar>
                <v-card v-if="postList.length == 0 && !isPostLoad" class="text-center pa-5" outlined>{{
            $t('general.msg.no_diary_data') }}</v-card>
                <Post v-for="postItem in postList" v-bind:postItem="postItem"
                    v-bind:commentList="commentMap[postItem.diary_post_id]" v-bind:homeworkId="homeworkId"
                    v-bind:key="postItem.id" />
                <v-skeleton-loader v-if="isPostLoad"
                    type="list-item-avatar, divider, list-item-three-line, card-heading, image"></v-skeleton-loader>
                <v-sheet class="text-center" v-if="hasMorePost">
                    <v-btn class="normal-case"
                        outlined @click="getPostPagingList">{{ $t('button.load_more') }}</v-btn>
                </v-sheet>
            </v-col>
            <v-col cols="12" md="4" lg="4" order="1" order-md="2">
                <v-row v-if="!isJoinedHomework" class="no-gutters">
                    <v-col cols="12" sm class="button-column button-column-left">
                        <v-btn small outlined block color="secondary" class="mb-1 normal-case"
                            @click="openStudentInvitationDialog = true">{{ $t('info.button.invite_student') }}</v-btn>
                    </v-col>
                    <v-col cols="12" sm class="button-column button-column-right">
                        <v-btn small outlined block color="secondary" class="mb-1 normal-case"
                            @click="openTeacherInvitationDialog = true">{{ $t('info.button.invite_teacher') }}</v-btn>
                    </v-col>
                </v-row>
                <v-card v-if="showUCPWaring" outlined class="px-2 pb-4 mt-3">
                    <div class="px-4 py-1 subtitle-1">{{ $t('info.diary.ucp_waring.title') }}</div>
                    <v-divider></v-divider>
                    <v-card-subtitle>{{ warningText }}</v-card-subtitle>
                </v-card>
                <v-card outlined class="px-2 pb-4 mt-3">
                    <div class="px-4 py-1 subtitle-1">{{ $t('general.diary.overview') }}</div>
                    <v-divider></v-divider>
                    <v-card-subtitle>{{ $t('general.diary.recent_submit') }}</v-card-subtitle>
                    <div v-if="recentPostUserList.length == 0" class="text-center">{{ $t('general.diary.no_data') }}
                    </div>
                    <v-row class="no-gutters">
                        <v-col class="cursor-pointer text-center mb-2" v-for="(item, i) in recentPostUserList" :key="i"
                            cols="3" sm="2" md="4" lg="3" @click="toStudentPage(item.creator_id)">
                            <v-badge :content="item.count" color="grey" offset-x="10" offset-y="10">
                                <v-avatar color="grey lighten-2">
                                    <img :src="item.avatar_url" :alt="item.user_name">
                                </v-avatar>
                            </v-badge>
                            <v-card-subtitle class="pa-0">{{ item.user_name }}</v-card-subtitle>

                        </v-col>
                    </v-row>
                </v-card>
                <BadMood class="mt-2"></BadMood>
            </v-col>
        </v-row>
        <TeacherInvitationDialog v-model="openTeacherInvitationDialog"></TeacherInvitationDialog>
        <StudentInvitationDialog v-model="openStudentInvitationDialog"></StudentInvitationDialog>
    </v-container>
</template>

<script>
import Post from '@/components/console/diary/component/Post.vue'
import TeacherInvitationDialog from '@/components/console/diary/component/TeacherInvitationDialog.vue';
import StudentInvitationDialog from '@/components/console/diary/component/StudentInvitationDialog.vue';
import BadMood from '@/components/console/diary/component/BadMood.vue';
import DatePicker from '@/components/console/diary/component/DatePicker.vue'
import { diaryServices } from "@/services/diary.js";
import { diaryPostServices } from "@/services/diaryPost.js";
import { userPayServices } from '@/services/userPay.js';
import { mapState } from "vuex";
export default {
    name: "news",
    components: { Post, DatePicker, TeacherInvitationDialog, StudentInvitationDialog, BadMood },
    data() {
        return {
            breadcrumbsItems: [],
            buttonLoading: false,
            loaded: false,
            isLoading: false,
            homeworkId: 1,
            postDataPayload: {},
            postList: [],
            commentMap: {},
            recentPostUserList: [],
            openedPostItem: {},
            dialog: false,
            isPostLoad: true,
            hasMorePost: false,
            homeworkCreateDate: null,
            lastPostDate: null,
            sortOptions: [],
            dialogPostId: null,
            openTeacherInvitationDialog: false,
            openStudentInvitationDialog: false,
            isJoinedHomework: true,
            showUCPWaring: false,
            warningText: '',
        };
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        const diaryHomeworkNameRes = await diaryServices.getHomeworkName(this.homeworkId, {});
        this.breadcrumbsItems.push({
            text: this.$t('index.title'),
            disabled: false,
            href: `/diary`,
        }, {
            text: this.homeworkName,
            disabled: false,
            href: `/diary/homework/${this.homeworkId}`,
        }, {
            text: this.$t('menu.diary_news'),
            disabled: true,
            href: ``,
        });

        if (diaryHomeworkNameRes.data.diary_publish_num == 0) {
            this.showUCPWaring = true;
            const ucpCount = await userPayServices.getUnsoldUserChargePlanCount();
            if (ucpCount == 0) {
                this.warningText = this.$t('info.diary.ucp_waring.text_sold_out');
            } else {
                this.warningText = this.$t('info.diary.ucp_waring.text_zero_publish');
            }
        }

        const joinedHomeworkCheck = await diaryServices.checkJoinedHomework(this.homeworkId);
        this.isJoinedHomework = joinedHomeworkCheck.data.is_joined;

        this.homeworkCreateDate = diaryHomeworkNameRes.data.create_date;
        this.lastPostDate = diaryHomeworkNameRes.data.last_post_date;
        if (this.lastPostDate != null) {
            this.lastPostDate = new Date(this.lastPostDate).toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-');
        }

        this.postDataPayload = {
            startDate: '',
            endDate: '',
            page: 0
        }
        await this.getPostPagingList();

        const recentRes = await diaryPostServices.getHomeworkRecentPostUser(this.homeworkId, {});
        recentRes.data.forEach(item => {
            this.recentPostUserList.push(item);
        });
    },
    mounted() {
    },
    computed: {
        ...mapState(["homeworkName"]),
    },
    watch: {
        homeworkName() {
            this.breadcrumbsItems[1].text = this.homeworkName;
        },
    },
    methods: {
        async filtDate(timeRange) {
            this.postList = [];
            this.postDataPayload = {
                startDate: timeRange[0],
                endDate: timeRange[1],
                page: 0
            }
            this.getPostPagingList();
        },
        async getPostPagingList() {
            //記錄一下scrollbar位置，等等要停在這
            const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

            this.hasMorePost = false;
            this.isPostLoad = true;
            let payload = {
                start_date: this.postDataPayload.startDate,
                end_date: this.postDataPayload.endDate,
                page: this.postDataPayload.page + 1,
            }
            const res = await diaryPostServices.getHomeworkPostPagingData(this.homeworkId, payload);

            if (res.data != null) {
                this.isPostLoad = false;

                for (const [key, value] of Object.entries(res.data.post_comment_map)) {
                    let commentList = [];
                    value.forEach(item => {
                        commentList.push(item);
                    });
                    this.commentMap[key] = commentList;
                }

                res.data.post_list.forEach(item => {
                    this.postList.push(item);
                });

                console.log('postsList', this.postList);

                this.postDataPayload.page = payload.page;
                if (res.data.post_list.length == 0) {
                    this.hasMorePost = false;
                } else {
                    this.hasMorePost = true;

                    //scrollbar要停在這
                    window.scrollTo(0, scrollPosition + 200);
                }
            }
        },
        toStudentPage(studentId) {
            this.$router.push(`/diary/homework/${this.homeworkId}/student/${studentId}`);
        },
    },
}
</script>
<style scoped>
.v-toolbar__content {
    padding: 0 !important;
}

@media (min-width: 960px) {

    /* Adjust this breakpoint based on your theme's md breakpoint */
    .button-column-left {
        margin-right: 8px;
    }

    .button-column-right {
        margin-left: 8px;
    }
}

/* For smaller screens, keep vertical margins but no horizontal padding */
.button-column {
    margin-bottom: 8px;
    /* Maintain vertical spacing */
}
</style>
