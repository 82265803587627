import servicesUtils from '../utils/service'

export const ebookServices = {
    async checkBookReadyToPrint(diaryPrintId) {
        let isPostReady = true;
        let isCoverReady = false;
        const postRes = await this.getEBookPageList(diaryPrintId, {});
        postRes.data.forEach(page => {
            if (page.thumbnail_url == null) {
                isPostReady = false;
            } else if (page.type == 1) {
                isCoverReady = true;
            }
        });
        return isPostReady && isCoverReady;
    },
    async getEBook(homeworkId, userId) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBook')}`.replace(':diaryHomeworkId', homeworkId).replace(':userId', userId)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEBook err: ${err}`)
            throw err
        }
    },
    async getStudentEBook(diaryId) {
        try {
            const url = `${servicesUtils.resourceUrl('getStudentEBook')}`
                .replace(':diaryId', diaryId);
            const data = await servicesUtils.get(url);
            return data
        } catch (err) {
            console.log(`failed to get getStudentEBook err: ${err}`)
            throw err
        }
    },
    async getEBookPageList(printId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookPageList')}`.replace(':diaryPrintId', printId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEBookPageList err: ${err}`)
            throw err
        }
    },
    async getEBookPageTemplate(diaryPrintTemplateId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookPageTemplate')}`.replace(':diaryPrintTemplateId', diaryPrintTemplateId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEBookPageTemplate err: ${err}`)
            throw err
        }
    },

    async getEBookTemplateList(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookTemplateList')}` + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEBookTemplateList err: ${err}`)
            throw err
        }
    },

    async updateEBookTemplate(diaryPrintTemplateId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('saveEBookPageTemplate')}`.replace(':diaryPrintTemplateId', diaryPrintTemplateId)
            const data = await servicesUtils.postFormData(url, payload)
            return data
        } catch (err) {
            console.log(`failed to updateEBookTemplate: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },

    async saveEBookTemplateImage(diaryPrintId, payload) {
        try {
            const formData = new FormData();
            for (let key in payload) {
                formData.append(key, payload[key]);
            }
            const url = `${servicesUtils.resourceUrl('saveEBookPageTemplateImage')}`.replace(':diaryPrintId', diaryPrintId)
            const data = await servicesUtils.postFormData(url, payload)
            return data
        } catch (err) {
            console.log(`failed to updateEBookTemplate: ${JSON.stringify(payload)}, err: ${err}`)
            console.log('error:', err)
            throw err
        }
    },
    async getEBookPDF(printId) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookPDF')}`.replace(':diaryPrintId', printId)
            const data = await servicesUtils.getBinary(url)
            return data
        } catch (err) {
            console.log(`failed to get getEBookPDF err: ${err}`)
            throw err
        }
    },
    async generateEBookPDF(printId) {
        try {
            const url = `${servicesUtils.resourceUrl('generateEBookPDF')}`.replace(':diaryPrintId', printId)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get generateEBookPDF err: ${err}`)
            throw err
        }
    },
    async getDiaryPosts(diaryPrintId) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookDiaryPosts')}`.replace(':diaryPrintId', diaryPrintId)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to getDiaryPosts with diaryPrintId: ${diaryPrintId} , err: ${err}`)
            throw err
        }
    },
    async getEBookSelectedPosts(diaryPrintId) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookSelectedPosts')}`.replace(':diaryPrintId', diaryPrintId)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to getDiaryPosts with diaryPrintId: ${diaryPrintId} , err: ${err}`)
            throw err
        }
    },
    async getPostDetail(postId) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookPostDetail')}`.replace(':id', postId)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to getPostDetail with postId: ${postId} , err: ${err}`)
            throw err
        }
    },
    async updatePostsSelection(diaryPrintId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('updateEBookPosts')}`.replace(':diaryPrintId', diaryPrintId)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to updatePostsSelection: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },
    async addEBookPage(diaryPrintId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('addEBookPage')}`.replace(':diaryPrintId', diaryPrintId)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to addEBookPage: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },
    async getEBookBasicInfo(diaryPrintId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookBasicInfo')}`.replace(':diaryPrintId', diaryPrintId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getEBookBasicInfo err: ${err}`)
            throw err
        }
    },
    async saveEBookBasicInfo(diaryPrintId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('saveEBookBasicInfo')}`.replace(':diaryPrintId', diaryPrintId)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to saveEBookBasicInfo: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },
    async getEBookLink(diaryPrintId) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookLink')}`
                .replace(':diaryPrintId', diaryPrintId);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get getEBookLink err: ${err}`);
            throw err;
        }
    },
    async createEBookLink(diaryPrintId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookLink')}`
                .replace(':diaryPrintId', diaryPrintId);
            const data = await servicesUtils.post(url, payload);
            return data;
        } catch (err) {
            console.log(`failed in createEBookLink err: ${err}`);
            throw err;
        }
    },
    async revokeEBookLink(diaryPrintId) {
        try {
            const url = `${servicesUtils.resourceUrl('getEBookLink')}`
                .replace(':diaryPrintId', diaryPrintId);
            const data = await servicesUtils.delete(url);
            return data;
        } catch (err) {
            console.log(`failed in revokeEBookLink err: ${err}`);
            throw err;
        }
    },
}