import servicesUtils from '../utils/service'

export const diaryPostServices = {
    async getHomeworkRecentPostUser(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkRecentPostUser')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getHomeworkRecentPostUser err: ${err}`)
            throw err
        }
    },

    async getHomeworkPost(postId) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkPost')}`.replace(':id', postId)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getHomeworkPost err: ${err}`)
            throw err
        }
    },
    async getHomeworkPostPagingData(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getHomeworkPostPagingData')}`.replace(':id', homeworkId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getHomeworkPostPagingData err: ${err}`)
            throw err
        }
    },
    async getCommentList(postId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getDiaryPostComment')}`.replace(':id', postId) + '?' + servicesUtils.queryParams(payload)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getCommentList err: ${err}`)
            throw err
        }
    },
    async createComment(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('createDiaryPostComment')}`.replace(':id', id)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to createComment: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },

    async updateComment(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('updateDiaryPostComment')}`.replace(':id', id)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to updateComment: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },

    async deleteComment(id) {
        try {
            const url = `${servicesUtils.resourceUrl('deleteDiaryPostComment')}`.replace(':id', id)
            const data = await servicesUtils.delete(url)
            return data
        } catch (err) {
            console.log(`failed to deleteComment: ${id}, err: ${err}`)
            throw err
        }
    },
    async sendBackDiaryPost(postId) {
        try {
            const url = `${servicesUtils.resourceUrl('sendBackDiaryPost')}`
                .replace(':diaryPostId', postId);
            const data = await servicesUtils.post(url);
            return data;
        } catch (err) {
            console.log(`failed to sendBackPost: ${postId}, err: ${err}`);
            throw err;
        }
    },
    async getAIStrategy(postId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getAIStrategy')}`
                .replace(':id', postId)
                + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to getAIStrategy: ${postId}, err: ${err}`);
            throw err;
        }
    },
    async getPostImage(postId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('getDiaryPostImage')}`
                .replace(':diaryPostId', postId);
            const data = await servicesUtils.post(url, payload);
            return data
        } catch (err) {
            console.log(`failed to get getPostImage err: ${err}`)
            throw err
        }
    },
}