<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <v-card outlined>
            <v-row>
                <v-col>
                    <v-img :src="studentInfo.avatar_url" class="role-thumbnail  mx-auto my-2">
                    </v-img>
                </v-col>
                <v-col class="my-2">
                    <v-card-text>
                        <v-spacer></v-spacer>
                        <v-row>
                            {{ $t('student_detail.name') + '：' }} {{ studentInfo.nickname }}
                        </v-row>
                        <v-row>
                            {{ $t('student_detail.mail') + '：' }} {{ studentInfo.email }}
                        </v-row>
                        <v-row v-if="!isJoinedHomework">
                            <v-btn depressed color="deep-orange darken-1" class="mt-1 mr-2 white--text normal-case" small
                                target="_blank"
                                :href="`/diary/homework/${this.homeWorkId}/student/${this.studentId}/ebook`">
                                {{ $t('book.create') }}
                            </v-btn>
                            <v-btn class="mt-1 normal-case" outlined small @click="displayAchiveDialog">
                                {{ $t('student_detail.archive') }}
                            </v-btn>
                        </v-row>
                        <v-spacer></v-spacer>
                    </v-card-text>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </v-card>

        <v-tabs v-model="tab">
            <v-tabs-slider color="deep-orange arken-1"></v-tabs-slider>
            <v-tab href="#tabDiary" class="grey--text text--darken-2 subtitle-1">
                {{ $t('student_detail.tab.diary') }}
            </v-tab>
            <v-tab :href="`/diary/homework/${homeWorkId}/student/${studentId}/report`"
                class="grey--text text--darken-2 subtitle-1">
                {{ $t('student_detail.tab.report') }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" class="mt-2">
            <v-tab-item value="tabDiary">
                <v-subheader>{{ formatePosts() }}</v-subheader>
                <v-data-table :headers="diaryHeaders" :items="diaryPosts" hide-default-footer disable-sort
                    :server-items-length="totalPosts" :loading="loading" :page.sync="diaryPostPage"
                    :options.sync="diaryPostOptions" :items-per-page="10">
                    <template v-slot:item.unread="{ item }">
                        <v-icon v-if="item.unread" color="deep-orange darken-4">
                            mdi-circle-medium </v-icon>
                    </template>

                    <template v-slot:item.star="{ item }">
                        <div class="checkbox-container">
                            <v-checkbox v-model="item.star" :on-icon="'mdi-star'" :off-icon="'mdi-star-outline'"
                                color="deep-orange darken-4" @change="addOrRemoveStarPost(item)"></v-checkbox>
                        </div>
                    </template>

                    <template #item.time_location="{ item }">
                        <span>{{ `${item.location}/${item.happenTime}` }}</span>
                    </template>

                    <template v-slot:item.score="{ item }">
                        <span v-if="item.score < 1">{{ $t('post.scoring') }}</span>
                        <span v-else>{{ item.score }}</span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-btn small class="mr-2 mt-1 normal-case" outlined
                            @click="dialogPostId = item.post_id; postDialogOpenCount += 1">
                            <v-icon small class="mr-1"> mdi-eye </v-icon>
                            {{ $t('button.view_post') }}
                        </v-btn>
                    </template>
                </v-data-table>
                <div class="text-center my-4">
                    <v-pagination v-model="diaryPostPage" :length="diaryPostPageCount" circle color="orange accent-2">
                    </v-pagination>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog v-model="archvieDialog" width="600" persistent>
            <v-card class="pb-2">
                <v-card-title>
                    {{ $t('student_detail.archive_dialog.title') }}
                </v-card-title>
                <v-card-text>
                    {{ $t('student_detail.archive_dialog.text') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2 normal-case" outlined small @click="archvieDialog = false" :disabled="archiving">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" small @click="archiveStudent"
                        class="normal-case"
                        :loading="archiving" :disabled="archiving">
                        {{ $t('button.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="archvieSuccessDialog" width="600" persistent>
            <v-card class="pb-2">
                <v-card-title>
                    {{ $t('student_detail.archive_success_dialog.title') }}
                </v-card-title>
                <v-card-text>
                    {{ $t('student_detail.archive_success_dialog.text') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" small @click="gotoNews"
                        class="normal-case">
                        {{ $t('button.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <PostDialog v-if="dialogPostId != null" v-bind:postDialogOpenCount="postDialogOpenCount"
            v-bind:postId="dialogPostId" v-bind:homeworkId="homeWorkId"></PostDialog>
    </v-container>
</template>

<script>
import { studentServices } from '@/services/student.js';
import { postCollectionServices } from '@/services/diaryPostCollection.js'
import { diaryServices } from '@/services/diary.js';

import PostDialog from '@/components/console/diary/component/PostDialog.vue'
import { mapState } from "vuex";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export default {
    name: "StudentDetail",
    components: { PostDialog },
    data() {
        return {
            homeWorkId: '',
            studentId: '',
            studentInfo: {},
            diaryHeaders: [
                {
                    text: '',
                    sortable: false,
                    value: 'unread',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.collection'),
                    sortable: false,
                    value: 'star',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.diary_date'),
                    sortable: false,
                    value: 'diary_date',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.post_title'),
                    sortable: false,
                    value: 'title',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.location_time'),
                    sortable: false,
                    value: 'time_location',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.score'),
                    sortable: false,
                    value: 'score',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.create_date'),
                    sortable: false,
                    value: 'create_at',
                    align: 'center',
                    class: 'grey lighten-4',
                },
                {
                    text: this.$t('table.function'),
                    sortable: false,
                    value: 'actions',
                    align: 'center',
                    class: 'grey lighten-4',
                }

            ],
            diaryPosts: [],
            tab: null,
            diaryPostPage: 1,
            diaryPostPageCount: 1,
            totalPosts: 0,
            loading: false,
            diaryPostOptions: {},
            breadcrumbsItems: [],
            dialogPostId: null,
            postDialogOpenCount: 0,
            archvieDialog: false,
            archiving: false,
            archvieSuccessDialog: false,
            isJoinedHomework: true,
        };
    },
    async created() {
        this.homeWorkId = this.$route.params.id;
        this.studentId = this.$route.params.sid;

        const result = await studentServices.getStudentInfo(this.studentId);
        this.studentInfo = result;
        this.diaryPosts = [];

        const joinedHomeworkCheck = await diaryServices.checkJoinedHomework(this.homeWorkId);
        this.isJoinedHomework = joinedHomeworkCheck.data.is_joined;

        this.breadcrumbsItems.push({
            text: this.$t('index.title'),
            disabled: false,
            href: `/diary`,
        }, {
            text: this.homeworkName,
            disabled: false,
            href: `/diary/homework/${this.homeWorkId}`,
        }, {
            text: this.studentInfo.nickname,
            disabled: true,
            //href: `/diary/homework/${this.homeWorkId}/student/${this.studentId}`,
        }
        );
    },
    computed: {
        ...mapState(["homeworkName"]),
    },
    methods: {
        getDiaryPostData() {
            this.loading = true;
            this.getDiaryPostList().then(data => {
                this.diaryPosts = data.items;
                this.totalPosts = data.total;
                this.diaryPostPageCount = data.pageCount;
                this.loading = false;
                this.diaryPostPage = data.page;
            })
        },
        async getDiaryPostList() {
            const { page } = this.diaryPostOptions;
            const query = {
                page: page,
                diaryHomeworkId: this.homeWorkId,
                studentId: this.studentId,
            };
            const res = await studentServices.getStudentPosts(query);
            dayjs.extend(utc);
            dayjs.extend(timezone);
            const tz = dayjs.tz.guess();
            const items = res.list.map(item => {
                return {
                    ...item,
                    create_at: dayjs.utc(item.create_at).tz(tz).format('YYYY-MM-DD HH:mm'),
                    diary_date: dayjs.utc(item.diary_date).tz(tz).format('YYYY-MM-DD')
                }
            });
            const total = res.total;
            const pageCount = res.totalPage;
            return { items, total, pageCount, page }
        },
        async addOrRemoveStarPost(post) {
            if (post.star) {
                await postCollectionServices.addPostToCollection(post.post_id);
            } else {
                await postCollectionServices.removePostFromCollection(post.post_id);
            }
        },
        formatePosts() {
            return this.$t('unit_type.post_total').replace('${num}', this.totalPosts);
        },
        displayAchiveDialog() {
            this.archvieDialog = true;
        },
        async archiveStudent() {
            this.archiving = true;
            const payload = {
                studentId: this.studentId
            }
            const res = await studentServices.archiveStudentFromHomework(
                this.homeWorkId, payload);
            this.archiving = false;
            this.archvieDialog = false;
            if (res.result) {
                this.archvieSuccessDialog = true;
            }
        },
        gotoNews() {
            this.archvieSuccessDialog = false;
            // goto homework news page.
            this.$router.push(`/diary/homework/${this.homeWorkId}`);
        },
    },
    watch: {
        diaryPostOptions: {
            handler() {
                this.getDiaryPostData()
            },
            deep: true,
        },
        homeworkName() {
            this.breadcrumbsItems[1].text = this.homeworkName;
        },
    },
};
</script>

<style scoped>
.role-thumbnail {
    width: 100px;
    align-items: center;
}

.checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>