<template>
    <v-container fluid>
        <v-row class="book-procedure pa-0">
            <v-col v-for="(step, index) in bookProcedure" :key="step.id" cols="6" sm class="steps"
                :class="step.id == currentStep ? 'active' : ''" @click="goToStep(step.id)"
                :disabled="step.id == 4 && !isReadyToPrint">
                {{ index + 1 }}. {{ step.title }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import { ebookServices } from "@/services/ebook.js";
import { getItem, setItem } from '@/utils/storageManager';

export default {
    name: 'steps',
    components: {},
    data() {
        return {
            currentStep: 3,
            bookProcedure: [{
                id: 1,
                title: this.$t('book.menu.choose_post'),
                link: '/ebook/:diaryPrintId/posts',
            }, {
                id: 2,
                title: this.$t('book.menu.basic_info'),
                link: '/ebook/:diaryPrintId/basicinfo',
            }, {
                id: 3,
                title: this.$t('book.menu.content'),
                link: '/ebook/:diaryPrintId/list',
            }, {
                id: 4,
                title: this.$t('book.menu.print'),
                link: '/ebook/:diaryPrintId/download',
            }

            ],
            diaryPrintId: '',
            isReadyToPrint: false,
        }
    },
    watch: {

    },
    async created() {
        this.diaryPrintId = this.$route.params.diaryPrintId;
        const currentURI = window.location.pathname;
        
        this.bookProcedure.forEach(step => {
            step.link = step.link.replace(':diaryPrintId', this.diaryPrintId);
            if (currentURI == step.link) {
                this.currentStep = step.id;
            }
        });
        let ebookStorage = getItem('ebookReady');
        if (ebookStorage == null) {
            ebookStorage = '{}';
        }
        let ebookStorageJSON = JSON.parse(ebookStorage);
        this.isReadyToPrint = ebookStorageJSON[this.diaryPrintId];
        if (!this.isReadyToPrint) {
            this.isReadyToPrint = await ebookServices.checkBookReadyToPrint(this.diaryPrintId);
            ebookStorageJSON[this.diaryPrintId] = this.isReadyToPrint;
            setItem('ebookReady', JSON.stringify(ebookStorageJSON));
        }
    },
    methods: {
        goToStep(stepId) {
            if (stepId == 4 && !this.isReadyToPrint) {
                return;
            }
            let step = this.bookProcedure.find(step => step.id == stepId);
            if (step.id != this.currentStep) {
                this.$router.push(step.link);
            }
        },
        
    },

}
</script>
<style scoped>

.book-procedure > .steps {
    background-color: #eee;
    padding: 10px 0px;
    text-align: center;
    cursor: pointer;
}
.book-procedure > .steps:not(:last-child) {
    border-right: 1px solid #b9b9b9;
}
.book-procedure > .steps.active {
    background-color: #fff;
    color: #DE714B;
}

.book-procedure > .steps[disabled] {
    background-color: #f5f5f5;
    color: #c8c8c8;
    cursor: not-allowed;
}
</style>
