<template>
    <v-card class="mb-2" outlined>
        <v-card-actions class="pa-0">
            <v-list-item class="grow">
                <v-list-item-avatar color="grey lighten-2">
                    <v-img class="elevation-6" alt="" :src="postItem.avatar_url"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title v-if="clickable" class="cursor-pointer" @click="toStudentPage">{{
                        postItem.user_name }}</v-list-item-title>
                    <v-list-item-title v-else>{{ postItem.user_name }}</v-list-item-title>
                    <br />
                    <v-list-item-subtitle>{{ postItem.diary_date }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-row align="center" justify="end" v-if="showMenu">
                    <v-menu offset-y bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="normal-case"
                                plain v-bind="attrs" v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-btn text class="normal-case"
                                    @click="payfunctionMap['f13'] != true ? (showPayMsgDialog = true) : openReviewMode()">
                                    <v-list-item-title><v-icon small class="mr-1">mdi-pencil</v-icon>{{
                        $t('post.function.revise') }}<PayFunction
                                            v-bind:isPay="payfunctionMap['f13']"></PayFunction></v-list-item-title>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="normal-case"
                                    text @click="payfunctionMap['f4'] != true ? (showPayMsgDialog = true) : addCase()">
                                    <v-list-item-title><v-icon small class="mr-1">mdi-pin</v-icon>{{
                        $t('post.function.follow') }}<PayFunction
                                            v-bind:isPay="payfunctionMap['f4']"></PayFunction></v-list-item-title>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="normal-case"
                                    v-if="postItem.diary_post_collection_id == null" text
                                    @click="payfunctionMap['f5'] != true ? (showPayMsgDialog = true) : addToCollection()"
                                    :loading="updatingCollectionStatus">
                                    <v-list-item-title><v-icon small class="mr-1">mdi-star-outline</v-icon>{{
                        $t('post.function.add_collection') }}
                                        <PayFunction v-bind:isPay="payfunctionMap['f5']"></PayFunction>
                                    </v-list-item-title>
                                </v-btn>
                                <v-btn v-else text class="normal-case"
                                    @click="payfunctionMap['f5'] != true ? (showPayMsgDialog = true) : removeFromCollection()"
                                    :loading="updatingCollectionStatus">
                                    <v-list-item-title><v-icon small
                                            class="mr-1">mdi-star</v-icon>{{ $t('post.function.remove_collection') }}
                                        <PayFunction
                                            v-bind:isPay="payfunctionMap['f5']"></PayFunction>
                                    </v-list-item-title>
                                </v-btn>
                            </v-list-item>
                            <v-list-item>
                                <v-btn class="normal-case"
                                    text @click="payfunctionMap['f6'] != true ? (showPayMsgDialog = true) : sendBack()">
                                    <v-list-item-title><v-icon small class="mr-1">mdi-swap-horizontal</v-icon>{{
                        $t('post.function.send_back') }}
                                        <PayFunction v-bind:isPay="payfunctionMap['f6']"></PayFunction>
                                    </v-list-item-title>
                                </v-btn> </v-list-item>
                            <v-list-item>
                                <v-btn class="normal-case"
                                    text @click="openDownloadImageDialog()">
                                    <v-list-item-title><v-icon small
                                            class="mr-1">mdi-download</v-icon>{{ $t('post.function.download_image')
                                        }}</v-list-item-title>
                                </v-btn>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-row>
            </v-list-item>
        </v-card-actions>
        <v-card-text class="pt-0 text--primary">
            <span class="font-weight-medium subtitle-1 deep-orange--text text--darken-1 mr-1">{{
                        postItem.title }}</span>
            <span class="text-decoration-underline grey--text mr-1">
                {{ postItem.location }}
            </span>
            <span class="text-decoration-underline grey--text">
                {{ postItem.happen_time }}
            </span>
            <ol class="pl-3 m-0">
                <li v-for="msgItem in postItem.msgs" :key="msgItem.id">
                    {{ msgItem.message }}

                    <template v-if="msgItem.message_type == 'st'">
                        <span v-for="emo in msgItem.selected_emotions" :key="emo"
                            class="text-decoration-underline grey--text mr-1">{{ emo }}</span>
                    </template>

                    <span v-if="msgItem.behavior != null" class="text-decoration-underline grey--text mr-1">{{
                        msgItem.behavior }}</span>
                </li>
            </ol>
            <div v-if="postItem.score == null" class="text-small mb-2" style="text-decoration: underline;">
                {{ $t('post.scoring') }}
            </div>
            <div v-else class="text-small mb-2" style="text-decoration: underline;">
                {{ $t('post.score') }}{{ postItem.score }}
            </div>
        </v-card-text>
        <div class="grey lighten-5">
            <div class="img-content" :class="contentDisplay">
                <v-img class="my-1" v-for="msgItem in postItem.msgs" :key="msgItem.id" :src="msgItem.img_url"></v-img>
                <div class="show-content-btn" @click="contentDisplay = 'show'">{{ $t('post.expand') }}</div>
            </div>
        </div>
        <v-divider></v-divider>
        <div v-if="showComment">
            <div class="mt-2" v-for="comment in commentList" :key="comment.comment_id"
                v-on:mouseover="comment.editable ? (commentDisplayId = comment.comment_id) : ''"
                v-on:mouseleave="comment.editable ? (commentDisplayId = '') : ''">
                <div v-if="commentEditId != comment.comment_id">
                    <v-list-item class="grow feedback">
                        <v-list-item-avatar class="mx-0" color="grey lighten-3">
                            <v-img class="elevation-6" alt="" :src="comment.avatar_url"
                                v-if="comment.avatar_url"></v-img>
                            <v-icon v-if="!comment.avatar_url">mdi-emoticon-happy-outline</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content class="text-area ml-2">
                            <v-list-item-subtitle class="font-weight-medium blue--text">{{ comment.user_name
                                }}</v-list-item-subtitle>{{
                        comment.comment }}
                        </v-list-item-content>
                        <v-menu offset-y bottom left
                            v-if="commentDisplayId == comment.comment_id || commentEditOptionId == comment.comment_id"
                            v-model="commentEditOptionDisplay"><!-- -->

                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="px-0 mx-0 normal-case" small plain v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-btn class="normal-case"
                                        plain block @click="editComment(comment.comment_id)">
                                        {{ $t('post.edit') }}</v-btn>
                                </v-list-item>
                                <v-list-item>
                                    <v-btn class="normal-case"
                                        plain block @click="deleteComment(comment.comment_id)">
                                        {{ $t('post.delete') }}</v-btn>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item>
                    <div class="text-right body-2 px-4">
                        <span>{{ comment.create_date }}</span>
                    </div>
                </div>
                <div v-if="commentEditId == comment.comment_id">
                    <v-list-item class="grow">
                        <v-list-item-avatar class="mx-0 mb-0" color="grey darken-3">
                            <v-img class="elevation-6" alt="" :src="comment.avatar_url"></v-img>
                        </v-list-item-avatar>
                        <v-text-field v-model="comment.commentEdit" class="mx-2" background-color="light-blue lighten-5"
                            :placeholder="$t('post.comment')" filled hide-details rounded dense
                            @focus="showCanMsg = true">
                        </v-text-field>
                    </v-list-item>
                    <div class="text-right body-2 px-4">
                        <v-btn x-small plain class="text-decoration-underline normal-case"
                            @click="updateComment()">{{ $t('button.save') }}</v-btn>
                        <v-btn
                            x-small plain class="text-decoration-underline normal-case"
                            @click="cancelUpdateComment()">
                            {{ $t('button.cancel') }}</v-btn>
                    </div>
                </div>
            </div>
            <v-list-item class="grow">
                <v-list-item-avatar class="mx-0" color="grey darken-3">
                    <v-img class="elevation-6" alt=""
                        src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"></v-img>
                </v-list-item-avatar>
                <v-text-field v-model="commentMsg" class="mx-2" background-color="light-blue lighten-5"
                    :placeholder="$t('post.comment')"
                    filled hide-details rounded dense @focus="showCanMsg = true"></v-text-field>
                <v-btn depressed color="secondary" @click="createComment"
                    class="normal-case">
                    {{ $t('post.comment') }}
                </v-btn>
            </v-list-item>
            <div class="px-4 mb-2" v-if="showCanMsg">
                <v-sheet rounded="lg" color="grey lighten-3" class="px-2">
                    <v-chip-group column>
                        <v-chip v-for="canMsg in canMsgList" :key="canMsg" @click="canMsgClick(canMsg)">
                            #{{ canMsg }}
                        </v-chip>
                    </v-chip-group>
                </v-sheet>
            </div>
            <div class="px-4 mb-4">
                <v-btn class="mt-2 light-green lighten-4 normal-case" block rounded depressed
                    @click="payfunctionMap['f24'] != true ? (showPayMsgDialog = true) : (getAiStragegy())"
                    :loading="buttonLoading" :disabled="buttonLoading"><v-icon class="mr-1 yellow--text"
                        small>mdi-lightbulb</v-icon>{{ $t('post.ai_assisted') }}<PayFunction
                        v-bind:isPay="payfunctionMap['f24']"></PayFunction>
                </v-btn>
                <v-sheet class="mt-1" v-if="showAiStrategy">
                    <span class="body-2">{{ $t('post.ai_assisted_info') }}</span>

                    <v-list-item-group active-class="border" color="blue">
                        <v-list-item class="stragey-content mb-1" @click="strategyClick(strategy)"
                            v-for="(strategy, ind) in strategyList" :key="`strategy${ind}`">
                            <v-list-item-content>
                                {{ ind + 1 }}. {{ strategy }}
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-sheet>

            </div>
        </div>
        <v-dialog v-model="downloadImageDialog" width="600">
            <div v-if="selectImageType">
                <v-card dense>
                    <v-card-title>
                        <span class="text-h5">{{ $t('post.image_export.type') }}</span>
                        <v-spacer></v-spacer>
                        <v-btn class="normal-case" icon @click="downloadImageDialog = false">
                            <v-icon>mdi-close-thick</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-radio-group v-model="selectedImageType" mandatory>
                        <v-col>
                            <v-radio :label="$t('post.image_export.bamboo')" value="0">
                            </v-radio>
                        </v-col>
                        <v-col>
                            <v-radio :label="$t('post.image_export.block')"
                                value="2">
                            </v-radio>
                        </v-col>
                    </v-radio-group>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="light-green darken-2 white--text"
                            class="normal-case"
                            @click="downloadImage" :loading="imageLoading"
                            :disabled="imageLoading">
                            {{ $t('button.export') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </div>
            <div v-if="displayImage">
                <v-card dense v-model="displayImage">
                    <v-card-title>
                        <v-spacer></v-spacer>
                        <span class="text-h5 mx-auto">{{ $t('post.image_export.dowonload_and_share') }}</span>
                        <v-spacer></v-spacer>
                        <v-btn class="normal-case"
                            icon @click="downloadImageDialog = false, displayImage = false">
                            <v-icon>mdi-close-thick</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-img class="composed-image mx-auto" v-bind:src="'data:image/jpeg;base64,' + image64"></v-img>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn rounded @click="saveImageFile" color="yellow darken-4"
                            class="my-3 normal-case">
                            <v-icon>mdi-download-circle-outline</v-icon>
                            {{ $t('post.image_export.dowonload') }}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </div>
        </v-dialog>

        <v-dialog v-model="showPayMsgDialog" width="450">
            <v-card dense class="pt-1 pb-5">
                <v-sheet class="text-right">
                    <v-btn icon @click="showPayMsgDialog = false"
                        class="normal-case">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-sheet>
                <div class="text-center text-h6 mb-2">{{ $t('post.upgrade.title') }}</div>
                <v-card-text class="text-center">
                    {{ $t('post.upgrade.text') }}
                    <a href="mailto: mangaxtechnology@gmail.com" target="_blank">mangaxtechnology@gmail.com</a>
                </v-card-text>
                <!-- <div class="text-center">
                    <v-btn color="deep-orange lighten-1" class="white--text normal-case" depressed
                        href="https://www.mangax.co/mangachat/pricing/" target="_blank">
                        查看方案價格
                    </v-btn>
                </div> -->
            </v-card>
        </v-dialog>
        <v-dialog v-model="showImageErrorDialog" width="450">
            <v-card dense class="pt-1 pb-5">
                <v-sheet class="text-right">
                    <v-btn icon @click="showImageErrorDialog = false"
                        class="normal-case">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-sheet>
                <div class="text-center text-h6 mb-2">{{ $t('post.image_export.error_title') }}</div>
                <v-card-text class="text-center">
                    {{ $t('post.image_export.error_text') }}
                    <a href="mailto: mangaxtechnology@gmail.com" target="_blank">mangaxtechnology@gmail.com</a>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
// import { diaryPostServices } from '../../../../services/diaryPost';
import { diaryPostServices } from '@/services/diaryPost.js';
import { studentServices } from '@/services/student.js';
import { postCollectionServices } from '@/services/diaryPostCollection.js';
import PayFunction from '@/components/console/diary/component/PayFunction.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getItem } from '@/utils/storageManager';

export default {
    name: "post",
    components: { PayFunction },
    // props: ['postItem', 'commentList', 'homeworkId', 'showComment'],
    props: {
        postItem: {},
        commentList: {},
        homeworkId: {},
        showComment: {
            type: Boolean,
            default: true
        },
        showMenu: {
            type: Boolean,
            default: true
        },
        clickable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            showPayMsgDialog: false,
            payfunctionMap: {},
            postId: '',
            commentMsg: '',
            contentDisplay: '',
            commentDisplayId: '',
            commentEditOptionId: '',
            commentEditOptionDisplay: false,
            showCanMsg: false,
            commentEditId: '',
            canMsgList: [],
            canMsgListZH: ['有志者事竟成', '加油！', '問問同學', '太好了！', '很棒喔！', '沒問題的！', '給你一個讚', '失敗為成功之母', '繼續保持下去', '知錯能改，善莫大焉'],
            canMsgListEN: ["Where there's a will, there's a way.",
                'Keep it up!',
                'Ask your classmates.',
                'Great job!',
                'Well done!',
                'No problem!',
                'Thumbs up to you!',
                'Failure is the mother of success.',
                'Keep it up!',
                'Admitting and correcting mistakes is a great virtue.'],
            showAiStrategy: false,
            strategyList: [],
            buttonLoading: false,
            updatingCollectionStatus: false,
            downloadImageDialog: false,
            selectedImageType: '',
            imageLoading: false,
            composedImage: '',
            selectImageType: false,
            displayImage: false,
            composedImageUrl: '',
            image64: '',
            showImageErrorDialog: false,
            diaryDate: null,
        };
    },
    async created() {
        this.payfunctionMap = JSON.parse(getItem('payfunctionMap'));

        this.postId = this.postItem.diary_post_id;
        dayjs.extend(utc)
        dayjs.extend(timezone)
        const tz = dayjs.tz.guess()
        if (this.postItem.diary_date) {
            this.diaryDate = dayjs.utc(this.postItem.diary_date).tz(tz).format('YYYY-MM-DD');
        }

        if (getItem('locale').slice(0,2) == 'zh') {
            this.canMsgList = this.canMsgListZH;
        } else {
            this.canMsgList = this.canMsgListEN;
        }
    },
    watch: {
        commentEditOptionDisplay() {
            if (!this.commentEditOptionDisplay) {
                this.commentDisplayId = '';
                this.commentEditOptionId = '';
            } else {
                this.commentEditOptionId = this.commentDisplayId;
            }
        }
    },
    mounted() {

    },
    methods: {
        toStudentPage() {
            this.$router.push(`/diary/homework/${this.homeworkId}/student/${this.postItem.creator_id}`);
        },
        canMsgClick(msg) {
            this.commentMsg += msg;
        },
        editComment(commentId) {
            this.commentEditOptionDisplay = false;
            let comment = this.commentList.find(item => item.comment_id == commentId);
            comment.commentEdit = comment.comment;
            this.commentEditId = commentId;
        },
        cancelUpdateComment() {
            let comment = this.commentList.find(item => item.comment_id == this.commentEditId);
            comment.commentEdit = '';
            this.commentEditId = '';
            this.commentDisplayId = '';
            this.commentEditOptionId = '';
        },
        async getCommentList() {
            const res = await diaryPostServices.getCommentList(this.postId, {});
            this.commentList = [];
            const tz = dayjs.tz.guess()
            console.log('tz', tz);
            res.data.forEach(item => {
                item.create_date = dayjs.utc(item.create_date).tz(tz).format('YYYY-MM-DD HH:mm');
                this.commentList.push(item);
            });
        },
        async createComment() {
            if (!this.commentMsg) {
                return;
            }
            let payload = {
                comment: this.commentMsg
            };
            await diaryPostServices.createComment(this.postId, payload);
            this.commentMsg = '';
            await this.getCommentList();
        },
        async updateComment() {
            let comment = this.commentList.find(item => item.comment_id == this.commentEditId);

            let payload = {
                comment: comment.commentEdit
            };
            await diaryPostServices.updateComment(this.commentEditId, payload);
            await this.getCommentList();
        },
        async deleteComment(commentId) {
            await diaryPostServices.deleteComment(commentId);
            await this.getCommentList();
        },
        strategyClick(msg) {
            this.commentMsg = msg;
        },
        async getAiStragegy() {

            if (this.showAiStrategy) {
                return;
            }
            if (this.strategyList.length == 0) {
                this.buttonLoading = true;
                let locale = getItem('locale');
                let payload = {
                    locale: locale
                }
                const result = await diaryPostServices.getAIStrategy(this.postItem.diary_post_id, payload);
                for (let strategy of result.data.replys) {
                    this.strategyList.push(strategy);
                }
            }
            this.buttonLoading = false;
            this.showAiStrategy = true;
        },
        openReviewMode() {
            this.$router.push(`/diary/diaryPost/${this.postId}/revise`);
        },
        async addCase() {
            const studentId = this.postItem.creator_id;
            const homeworkIdNumber = parseInt(this.homeworkId);
            await studentServices.addStudentCase(homeworkIdNumber, studentId);
        },
        async addToCollection() {
            this.updatingCollectionStatus = true;
            const result = await postCollectionServices.addPostToCollection(this.postItem.diary_post_id);
            this.postItem.diary_post_collection_id = result.diary_post_colleciton_id;
            this.updatingCollectionStatus = false;
        },
        async sendBack() {
            await diaryPostServices.sendBackDiaryPost(this.postItem.diary_post_id);
        },
        openDownloadImageDialog() {
            this.selectImageType = true;
            this.downloadImageDialog = true;
        },
        async downloadImage() {
            this.imageLoading = true;
            const payload = {
                compose_type: this.selectedImageType
            }
            const result = await diaryPostServices.getPostImage(this.postItem.diary_post_id, payload);
            this.imageLoading = false;
            this.selectImageType = false;
            if (result.data.return_code == '-05001') {
                this.downloadImageDialog = false;
                this.showImageErrorDialog = true;
                return;
            }
            this.composedImageUrl = result.data.img_src;
            this.image64 = result.data.img_base64_src;
            this.displayImage = true;
        },
        async removeFromCollection() {
            this.updatingCollectionStatus = true;
            await postCollectionServices.removePostFromCollection(this.postItem.diary_post_id);
            this.postItem.diary_post_collection_id = null;
            this.updatingCollectionStatus = false;
        },
        saveImageFile() {
            const fileName = `${this.postItem.title}-${this.postItem.diary_date}.png`;
            const a = document.createElement("a");
            a.href = "data:image/png;base64," + this.image64;
            a.download = fileName;
            a.click();
        }
    },
};
</script>

<style scoped>
.img-content {
    max-width: 350px;
    height: 350px;
    overflow: hidden;
    position: relative;
    padding: 0 !important;
    margin: 0 auto;
}

.img-content.show {
    height: auto;
}

.img-content>.show-content-btn {
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    text-align: center;
    left: 0;
    padding: 5px;
    cursor: pointer;
}

.img-content.show>.show-content-btn {
    display: none;
}

.feedback>.text-area {
    background-color: #eeeeee;
    padding: 5px 20px;
    border-radius: 15px;
}

.stragey-content {
    border: 1px solid #b6b6b6;
    border-radius: 5px;
}

.composed-image {
    width: 500px;
}
</style>