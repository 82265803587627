<template>
    <v-card flat max-width="500">
        <p v-if="loading">Loading...</p>
        <v-list v-else>
            <template v-for="(item, index) in resources">
                <v-list-item :key="item.title">

                    <v-list-item-content>
                        <v-list-item-title><v-icon class="mr-1">{{ item.icon }}</v-icon> {{ item.title
                            }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <a :href="item.link" download>
                            <v-icon color="grey lighten-1">
                                mdi-download
                            </v-icon>
                        </a>
                    </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index < resources.length - 1" :key="index"></v-divider>
            </template>
        </v-list>
    </v-card>
</template>

<script>
export default {
    name: 'resource',
    components: {},
    props: {
        resourceRes: Array,
    },
    data() {
        return {
            resources: [],
            loading: true,
        }
    },

    async created() {
        if (!this.resourceRes) {
            this.loading = true;
        } else {
            this.loading = false;
            this.phareResource();
        }

    },
    computed: {
    },
    watch: {
        resourceRes(newVal) {
            if (newVal) {
                this.loading = false;
                this.phareResource();
            }
        },
    },
    methods: {
        phareResource() {
            this.resourceRes.forEach(resource => {
                let typeIcon = 'mdi-file-word';
                switch (resource.type) {
                    case 'xlsx':
                        typeIcon = 'mdi-file-excel';
                        break;
                    case 'audio':
                        typeIcon = 'mdi-volume-high';
                        break;
                    case 'video':
                        typeIcon = 'mdi-video'
                        break;
                    case 'pdf':
                        typeIcon = 'mdi-file-pdf-box'
                        break;
                    default:
                        typeIcon = 'mdi-file-word';

                }
                this.resources.push({
                    title: resource.title,
                    link: resource.link,
                    icon: typeIcon
                })
            });
        }
    },
}
</script>

<style scoped></style>
