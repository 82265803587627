import servicesUtils from '../utils/service'

export const ebookLoginServices = {
    async oauthLogin() {
        try {
            let redirectUrl = process.env.VUE_APP_LOGIN_EBOOK_REDIRECT_URL;
            if (location.origin.indexOf('ebook.mangachat') != -1) {
                redirectUrl = process.env.VUE_APP_LOGIN_EBOOK_STUDENT_REDIRECT_URL;
            }
            const url = `${servicesUtils.resourceUrl('loginLinkEBook')}?redirect_uri=${redirectUrl}`;
            const response = await servicesUtils.get(url);
            const oauthLink = response.link;
            location.href = oauthLink;
        } catch (err) {
            console.log(`failed to login err: ${err}`);
            throw err
        }
    },
    async getUserInfo(data) {
        const url = `${servicesUtils.resourceUrl('eBookUserInfo')}`;
        const response = await servicesUtils.post(url, data);
        return response.data;
    },
}