<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-3" large></v-breadcrumbs>
        <div class="text-h5">
            {{ $t('homeworkQuestList.page_title') }}
        </div>
        <v-row class="my-2 mx-1" v-if="!showNoQuestAlert">
            <v-select :items="orderList"
                item-text="text"
                item-value="value"
                outlined flat dense solo
                hide-details="auto"
                v-model="orderInput"
                class="my-auto"></v-select>
            <v-spacer></v-spacer>
            <v-btn outlined class="top-button-style my-auto normal-case"
                @click="editQuest" v-if="showQuestEditButton">
                <v-icon small class="mr-2" color="#555555">mdi-pencil</v-icon>
                {{ $t('homeworkQuestList.quest_edit') }}
            </v-btn>
        </v-row>
        <v-alert
            v-for="(item, index) in questItems"
            border="left"
            colored-border
            color="#DE714B"
            elevation="1"
            class="mx-1 my-5"
            :key="item.id">
            <v-row class="mx-2 py-0">
                <div>
                    <v-row class="text-h6 my-2 mx-2" color="#555555">
                        <v-avatar
                            color="#DE714B" size="20" class="white--text my-auto mr-2 body-2">{{ `${item.order ? item.order
                                : (index + 1)}` }}</v-avatar>
                        {{ item.title }}
                    </v-row>
                    <v-row class="text-body-1 my-2 mx-2" color="#616161">{{ item.date }}</v-row>
                    <v-row class="text-body-1 my-2 mx-2" color="#4F4F4F">
                        <span color="#4F4F4F" class="my-auto grey--text text--darken-3">
                            <v-icon color="#BDBDBD" small class="mb-1">mdi-check-bold</v-icon>
                            {{ `${$t('homeworkQuestList.complete_rate')} ${item.rate}%` }}
                        </span>
                        <span color="#4F4F4F" class="my-auto mx-4 grey--text text--darken-3">
                            <v-icon color="#BDBDBD" small class="mb-1">mdi-account</v-icon>
                            {{ `${$t('homeworkQuestList.complete_num')} ${item.counts}` }}
                        </span>
                        <span color="#4F4F4F" class="my-auto grey--text text--darken-3">
                            <v-icon color="#BDBDBD" small class="mb-1">mdi-bullseye</v-icon>
                            {{ `${$t('homeworkQuestList.acerage_score')} ${item.score_ave}` }}
                        </span>
                    </v-row>
                </div>
                <v-spacer></v-spacer>
                <v-btn outlined color="#DE714B" class="my-auto normal-case"
                    @click="goToDetails(item)">
                    {{ $t('homeworkQuestList.details') }}</v-btn>
            </v-row>
        </v-alert>
        <v-alert v-if="showNoQuestAlert" class="my-4"
            outlined text dense color="#BEE5EB">
            <span style="color: #4173B1;">
                參賽設定完成！題目設定為「參賽者自行選擇題目」，參賽者繳交的狀況將於比賽起始日後顯示。
            </span>
        </v-alert>
        <v-overlay absolute :value="dataLoading">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>
import { diaryServices } from "@/services/diary.js";
import { diaryQuestServices } from "@/services/diaryQuest.js";
import { contestServices } from '@/services/contest.js';

export default {
    name: "homeworkQuestList",
    data() {
        return {
            breadcrumbsItems: [],
            homeworkId: '',
            orderInput: 'asc',
            orderList: [],
            questOrderList: [
                {
                    value: 'asc',
                    text: this.$t('homeworkQuestList.order.asc'),
                },
                {
                    value: 'desc',
                    text: this.$t('homeworkQuestList.order.desc'),
                },
            ],
            timeOrderList: [
                {
                    value: 'asc',
                    text: this.$t('homeworkQuestList.order.time_asc'),
                },
                {
                    value: 'desc',
                    text: this.$t('homeworkQuestList.order.time_desc'),
                },
            ],
            questItems: [],
            showQuestEditButton: false,
            displayType: '',
            showNoQuestAlert: false,
            dataLoading: false,
        };
    },
    async created() {
        this.dataLoading = true;
        this.homeworkId = this.$route.params.id;

        const diaryHomeworkNameRes = await diaryServices.getHomeworkName(this.homeworkId, {});
        this.homeworkName = diaryHomeworkNameRes.data.name;
        this.breadcrumbsItems.push({
            text: this.$t('index.title'),
            disabled: false,
            href: `/diary`,
        }, {
            text: this.homeworkName,
            disabled: false,
            href: `/diary/homework/${this.homeworkId}`,
        }, {
            text: this.$t('homeworkQuestList.page_title'),
            disabled: true,
            href: ``,
        });
        const contestHomeworkInfo = await contestServices.getHomeworkInfo(this.homeworkId);
        if (contestHomeworkInfo.data.contest) {
            if ((contestHomeworkInfo.data.contest.quest_pick_type != 1) &&
                (new Date(contestHomeworkInfo.data.team.start_date) > new Date())) {
                this.showQuestEditButton = true;
            }
            if (contestHomeworkInfo.data.team.quest_pick_type == 3) {
                if ((new Date(contestHomeworkInfo.data.team.start_date) > new Date())) {
                    this.showNoQuestAlert = true;
                } else {
                    this.displayType = 'date';
                    const payload = {
                        type: 'date'
                    }
                    const result = await diaryQuestServices.getQuestInfo(
                        this.homeworkId, payload);
                    this.questItems = result.data.list;
                    this.orderList = this.timeOrderList;
                }
            } else {
                this.displayType = 'quest';
                const payload = {
                    type: 'quest'
                }
                const result = await diaryQuestServices.getQuestInfo(
                    this.homeworkId, payload);
                this.questItems = result.data.list;
                this.orderList = this.questOrderList;
            }
        }
        this.dataLoading = false;
    },
    methods: {
        editQuest() {
            this.$router.push(`/diary/homework/${this.homeworkId}/questList/edit`);
        },
        goToDetails(item) {
            if (this.displayType == 'date') {
                this.$router.push(`/diary/homework/${this.homeworkId}/questList/d_${item.date}`);
            } else {
                this.$router.push(`/diary/homework/${this.homeworkId}/questList/${item.id}`);
            }
        },
    },
    watch: {
        orderInput(value) {
            if (value == 'asc') {
                this.questItems = this.questItems.sort((a, b) => {
                    return a.order - b.order;
                });
            } else {
                this.questItems = this.questItems.sort((a, b) => {
                    return b.order - a.order;
                });
            }
        },
    },
}
</script>
<style scoped>
.top-button-style {
    color: #555555 !important;
    border-color: #BDBDBD !important;
}
</style>