<template>
    <v-container fluid>
        <v-tabs v-model="selectedTab" @change="changeSection()">
            <v-tabs-slider color="deep-orange arken-1"></v-tabs-slider>
            <v-tab
                class="grey--text text--darken-2 subtitle-1" @click="scrollToRef('sectionDiary')">
                {{ $t('main_menu.journals') }}
            </v-tab>
            <v-tab
                class="grey--text text--darken-2 subtitle-1" @click="scrollToRef('invitedDiary')">
                {{ $t('diary_main.joined_diary') }}
            </v-tab>
            <v-tab class="grey--text text--darken-2 subtitle-1" @click="scrollToRef('sectionCase')">
                {{ $t('diary_main.follow_up') }}
            </v-tab>
            <v-tab class="grey--text text--darken-2 subtitle-1" @click="scrollToRef('sectionCollections')">
                {{ $t('diary_main.collections') }}
            </v-tab>
            <v-tab class="grey--text text--darken-2 subtitle-1" @click="scrollToRef('sectionArchived')">
                {{ $t('diary_main.archived') }}
            </v-tab>
        </v-tabs>
        <v-divider class="my-2"></v-divider>
        <div ref="sectionDiary" class="my-5 grey--text text--darken-2 text-subtitle-1" v-text="$t('main_menu.journals')">
        </div>
        <v-card outlined>
            <v-row>
                <v-card-title class="ml-3 text-subtitle-2 font-weight-bold"> {{ planName }} </v-card-title>
                <v-divider vertical class="my-5"></v-divider>
                <!-- <v-card-title class="text-body-1 grey--text text--darken-3">
                    <v-icon color="deep-orange accent-2" class="mr-2">mdi-account-group</v-icon>
                    {{ formatInvitedNumber() }}
                </v-card-title> -->
                <v-spacer></v-spacer>
            </v-row>
            <v-divider></v-divider>
            <div v-if="planExpired">
                <v-card-subtitle class="text-center">
                    {{ $t('manage.msg.journalBlock') }}
                </v-card-subtitle>
            </div>
            <v-card-text v-else>
                <v-item-group class="mr-4">
                    <v-row>
                        <v-col
                            v-for="item in diaryHomeworks"
                            :key="item.id"
                            cols="12"
                            sm="4" md="3" lg="2">
                            <v-item v-if="item.type == 'create'">
                                <v-card outlined
                                    class="mx-auto text-center align-center dotted-outline"
                                    height="130px"
                                    @click="openCreateJournalDialog">
                                    <v-card-title>
                                        <v-icon medium color="grey darken-2" class="mx-auto mt-3">
                                            mdi-plus
                                        </v-icon>
                                    </v-card-title>
                                    <v-card-text class="text-body-2" style="color: #555555;">{{ item.name }}</v-card-text>
                                </v-card>
                            </v-item>
                            <v-item v-else>
                                <v-card outlined
                                    class="mx-auto text-center align-center card-outline"
                                    @click="gotoDiaryHomework(item)">
                                    <v-card-title class="my-0">
                                        <div v-if="item.diary_cover_img"  class="mt-3 mx-auto">
                                            <v-img
                                                :src="item.diary_cover_img"
                                                height="40px"
                                                width="40px"
                                            ></v-img>
                                        </div>
                                        <v-icon v-else class="mt-3 mx-auto" x-large
                                            :color="item.label_color_code ? item.label_color_code : '#6aa72c'">
                                            mdi-book-open
                                        </v-icon>

                                   
                                    </v-card-title>
                                    <div class="text-body-2" style="color: #555555;">{{ item.name }}</div>
                                    <v-card-actions>
                                        <v-menu offset-y bottom left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn append rounded elevation="0" block x-small
                                                    v-bind="attrs" v-on="on"
                                                    class="normal-case">
                                                    <v-spacer></v-spacer>
                                                    <v-icon small dark>
                                                        mdi-dots-horizontal
                                                    </v-icon></v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-btn text class="normal-case"
                                                        @click="gotoDiaryHomework(item)">
                                                        <v-list-item-title><v-icon small class="mr-1">mdi-eye</v-icon>
                                                            {{ $t('diary_main.diary_menu.view_diary') }}
                                                        </v-list-item-title>
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn text class="normal-case"
                                                        @click="openDiarySetting(item)">
                                                        <v-list-item-title><v-icon small class="mr-1">mdi-pencil</v-icon>
                                                            {{ $t('diary_main.diary_menu.edit') }}
                                                        </v-list-item-title>
                                                    </v-btn>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <v-list-item>
                                                    <v-btn text class="normal-case"
                                                        @click="duplicateDiarySetting(item)">
                                                        <v-list-item-title><v-icon small
                                                                class="mr-1">mdi-content-copy</v-icon>
                                                            {{ $t('diary_main.diary_menu.duplicate') }}
                                                        </v-list-item-title>
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn text class="normal-case"
                                                        @click="openArchvieDiaryDialog(item)">
                                                        <v-list-item-title><v-icon small class="mr-1">mdi-archive</v-icon>
                                                            {{ $t('diary_main.diary_menu.archvie') }}
                                                        </v-list-item-title>
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-card-actions>
                                </v-card>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>
            </v-card-text>
        </v-card>
        <div ref="invitedDiary" class="my-5 grey--text text--darken-2 text-subtitle-1"
            v-text="$t('diary_main.joined_diary')"></div>
        <v-card outlined>
            <v-card-text>
                <v-item-group class="mr-4">
                    <v-row>
                        <v-col
                            v-for="item in joinedDiaryHomeworks"
                            :key="item.keyId"
                            cols="12"
                            sm="4" md="3" lg="2">
                            <v-item v-if="item.type == 'join'">
                                <v-card outlined
                                    class="mx-auto text-center align-center dotted-outline"
                                    height="130px"
                                    @click="openJoinJournalDialog()">
                                    <v-card-title>
                                        <v-icon medium color="grey darken-2" class="mx-auto mt-3">
                                            mdi-plus
                                        </v-icon>
                                    </v-card-title>
                                    <v-card-text class="text-body-2" style="color: #555555;">{{ item.name }}</v-card-text>
                                </v-card>
                            </v-item>
                            <v-item v-else>
                                <v-card outlined
                                    class="mx-auto text-center align-center card-outline"
                                    :disabled="!item.id"
                                    @click="gotoDiaryHomework(item)">
                                    <v-card-title clas="my-0">
                                        <v-icon class="mt-3 mx-auto" x-large
                                            :color="item.label_color_code ? item.label_color_code : '#6aa72c'">
                                            mdi-book-open
                                        </v-icon>
                                    </v-card-title>
                                    <div class="text-body-2" style="color: #555555;">{{ item.name }}</div>
                                    <v-card-actions>
                                        <v-menu offset-y bottom left v-if="item.drp_status == '1'">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn append rounded elevation="0" block small
                                                    v-bind="attrs" v-on="on">
                                                    <v-spacer></v-spacer>
                                                    <v-icon small dark>
                                                        mdi-dots-horizontal
                                                    </v-icon></v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-btn text class="normal-case"
                                                        @click="gotoDiaryHomework(item)">
                                                        <v-list-item-title><v-icon small class="mr-1">mdi-eye</v-icon>
                                                            {{ $t('diary_main.diary_menu.view_diary') }}
                                                        </v-list-item-title>
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        <v-btn v-else disabled rounded elevation="0" block small
                                            class="normal-case">
                                            {{ $t('diary_main.join_pending') }}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>
            </v-card-text>
            <LockBlock v-if="planExpired"></LockBlock>
        </v-card>
        <div ref="sectionCase" class="my-5 grey--text text--darken-2 text-subtitle-1"
            v-text="$t('diary_main.follow_up')"></div>
        <CaseBlock class="px-0" v-bind:restrict="planExpired"></CaseBlock>
        <div ref="sectionCollections" class="my-5 grey--text text--darken-2 text-subtitle-1"
            v-text="$t('diary_main.collections')"></div>
        <CollectionBlock class="px-0" v-bind:restrict="planExpired"></CollectionBlock>
        <div ref="sectionArchived" class="my-5 grey--text text--darken-2 text-subtitle-1"
            v-text="$t('diary_main.archived')"></div>
        <v-card outlined>
            <v-card-text v-if="archivedDiaryHomeworks.length != 0">
                <v-item-group class="mr-4">
                    <v-row>
                        <v-col
                            v-for="item in archivedDiaryHomeworks"
                            :key="item.id"
                            cols="12"
                            sm="4" md="3" lg="2">
                            <v-item>
                                <v-card outlined
                                    class="mx-auto text-center align-center card-outline">
                                    <v-card-title clas="my-0">
                                        <v-icon class="mt-3 mx-auto" x-large
                                            color="grey lighten-1">
                                            mdi-book-open
                                        </v-icon>
                                    </v-card-title>
                                    <div class="text-body-2" style="color: #BDBDBD;">{{ item.name }}</div>

                                </v-card>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>

            </v-card-text>
            <v-card-text v-else color="grey lighten-1" class="text-center">
                {{ $t('diary_main.no_archived') }}
            </v-card-text>
        </v-card>
        <v-dialog v-model="diaryArchiveDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('diary_main.archive_dialog.title') }}
                </v-card-title>
                <v-card-text class="text-h6 text-center">
                    {{ $t('diary_main.archive_dialog.message') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn plain class="mb-2 normal-case"
                        @click="diaryArchiveDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" class="mb-2 normal-case"
                        :loading="loading" @click="archiveDiaryHomework">
                        {{ $t('button.confirm') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="joinDiaryDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('diary_main.join_dialog.title') }}
                </v-card-title>
                <v-card-text class="text-h6">
                    {{ $t('diary_main.join_dialog.message') }}
                </v-card-text>
                <v-card-text>
                    <v-form ref="inviteCodeForm" v-model="isInviteCodeValid" lazy-validation>
                        <v-text-field
                            :label="$t('diary_main.join_dialog.input')"
                            :placeholder="$t('diary_main.join_dialog.input')"
                            v-model="inviteCode"
                            :rules="inviteCodeRules"
                            outlined></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn plain class="mb-2 normal-case"
                        @click="joinDiaryDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="deep-orange lighten-1"
                        class="mb-2 white--text normal-case"
                        :loading="loading" @click="joinDiaryHomework()">
                        {{ $t('diary_main.join_dialog.join') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="alert" width="400">
            <v-card class="pt-3">
                <v-card-text class="text-h6 text-center">
                    {{ alertMessage }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 normal-case"
                        @click="alert = false">
                        OK
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createJournalDialog" width="400">
            <v-card class="pt-3">
                <v-card-title>
                    {{ $t('diary_main.create_journal.label') }}
                    <v-spacer></v-spacer>
                    <v-icon @click="createJournalDialog = false">mdi-close-thick</v-icon>
                </v-card-title>
                <v-card-text class="pb-5">
                    <v-row>
                        <v-col cols="6">
                            <v-card outlined
                                class="text-center align-center dotted-outline"
                                height="120px"
                                @click="createJournal">
                                <v-card-title>
                                    <v-icon x-large class="mx-auto">mdi-book-open-variant</v-icon>
                                </v-card-title>
                                <v-card-text class="text-body-2 text-center align-center"
                                    style="color: #555555;">
                                    {{ $t('diary_main.create_journal.dialog_new_journal') }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-card outlined
                                class="text-center align-center dotted-outline"
                                height="120px"
                                @click="openCreateFromSampleDialog">
                                <v-card-title>
                                    <v-icon x-large class="mx-auto">mdi-tools</v-icon>
                                </v-card-title>
                                <v-card-text class="text-body-2 text-center align-center"
                                    style="color: #555555;">
                                    {{ $t('diary_main.create_journal.dialog_sample_journal') }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
                    
                <v-card-text class="pb-5">
                    <v-divider class="my-2"></v-divider>
                    <div class="my-2">{{ $t('diary_main.create_from_publish_sample.title') }}</div>
                
                    <v-row>
                        <v-col cols="6">
                            <v-card outlined
                                class="text-center align-center dotted-outline"
                                height="120px"
                                @click="openCreateFromPublishSampleDialog">
                                <v-card-title>
                                    <div class="mx-auto">
                                        <v-img
                                            :src="publishSampleDiaryHomework.cover_img"
                                            height="40px"
                                            width="40px"
                                        ></v-img>
                                    </div>
                                </v-card-title>
                                <v-card-text class="text-body-2 text-center align-center"
                                    style="color: #555555;">
                                    {{ publishSampleDiaryHomework.name}}
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createFromSampleDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('diary_main.create_from_sample.title') }}
                </v-card-title>
                <v-card-text class="text-h6">
                    {{ $t('diary_main.create_from_sample.message') }}
                </v-card-text>
                <v-card-text>
                    <v-form ref="sampleCodeForm" v-model="isInviteCodeValid" lazy-validation>
                        <v-text-field
                            :label="$t('diary_main.create_from_sample.input')"
                            :placeholder="$t('diary_main.create_from_sample.input')"
                            v-model="sampleCode"
                            :rules="inviteCodeRules"
                            outlined></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn plain class="mb-2 normal-case"
                        @click="createFromSampleDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="deep-orange lighten-1"
                        class="mb-2 white--text normal-case"
                        :loading="loading" @click="createFromSample">
                        {{ $t('diary_main.create_from_sample.create') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createFromPublishSampleDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('diary_main.create_from_publish_sample.title') }} - {{ publishSampleDiaryHomework.name }}
                </v-card-title>
                <v-card-text class="pb-2">
                    <div class="text-subtitle-1">
                        {{ $t('diary_main.create_from_publish_sample.need_code.message') }}
                    </div>
                    <a :href="publishSampleDiaryHomework.info_url" class="text-subtitle-2 text--grey" target="_blank">
                        <v-icon class="mr-1" small>mdi-help-circle</v-icon>{{ $t('diary_main.create_from_publish_sample.need_code.info') }}
                    </a>
                </v-card-text>
                <v-card-text class="mt-2">
                    <v-form ref="planExchangeCodeForm" v-model="isPlanExchangeCodeValid" lazy-validation>
                        <v-text-field
                            :label="$t('diary_main.create_from_publish_sample.need_code.input')"
                            :placeholder="$t('diary_main.create_from_publish_sample.need_code.input')"
                            v-model="planExchangeCode"
                            :rules="planExchangeCodeRules"
                            outlined></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn plain class="mb-2 normal-case"
                        @click="createFromPublishSampleDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="deep-orange lighten-1"
                        class="mb-2 white--text normal-case"
                        :loading="loading" @click="createFromSampleWithPlanExchangeCode">
                        {{ $t('diary_main.create_from_publish_sample.need_code.button.validate') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="contestFinishedDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('diary_main.contest_end') }}
                </v-card-title>
                <v-card-text>
                    {{ $t('diary_main.contest_end_message') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="contestFinishedDialog = false"
                        class="normal-case">
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="inviteCodeErrorDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('diary_main.contest_code_invalid') }}
                </v-card-title>
                <v-card-text>
                    {{ `${inviteCodeErrorMessage}` + $t('diary_main.contest_issue_message') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="inviteCodeErrorDialog = false"
                        class="normal-case">
                        ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="planExpiredDialog" width="600">
            <v-card>
                <v-card-title>
                {{ $t('diary_main.plan_expired') }}
                </v-card-title>
                <v-card-subtitle class="my-2">
                    {{ $t('diary_main.plan_expired_message') }}
                </v-card-subtitle>
                <v-card-text>
                    <span>{{ $t('diary_main.phone') + ' +886-979-357-131'}}</span><br>
                    <span>{{ $t('diary_main.time') }}</span><br>
                    <span>{{ $t('diary_main.line') }}</span><span> <a href="https://lin.ee/v446Bup"
                            target="_blank">https://lin.ee/v446Bup</a></span><br>
                    <span>{{ $t('diary_main.mail') }}</span><a href="mailto: mangaxtechnology@gmail.com"
                        target="_blank">mangaxtechnology@gmail.com</a>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="planExpiredDialog = false"
                        class="normal-case">
                        {{ $t('diary_main.ok') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { diaryServices } from '@/services/diary.js';
import { contestServices } from '@/services/contest.js';
import CaseBlock from '@/components/console/diary/component/CaseBlock.vue';
import CollectionBlock from '@/components/console/diary/component/CollectionBlock.vue';
import LockBlock from '@/components/console/diary/component/LockBlcok.vue';

export default {
    name: 'diaryMain',
    components: {
        CaseBlock,
        CollectionBlock,
        LockBlock
    },
    data() {
        return {
            selectedTab: 0,
            planName: '',
            diaryLimitCount: 0,
            diaryUsageCount: 0,
            diaryHomeworks: [],
            diaryToArchive: '',
            diaryArchiveDialog: false,
            loading: false,
            joinedDiaryHomeworks: [],
            publishSampleDiaryHomework: {},
            joinDiaryDialog: false,
            inviteCode: '',
            inviteCodeRules: [(v) => !!v || this.$t('general.msg.must_filled')],
            isInviteCodeValid: true,
            planExchangeCode: '',
            planExchangeCodeRules: [
                (v) => !!v || this.$t('general.msg.must_filled'),
                (v) => (!!v && this.planExchangeCodeErrorMsg == '') || this.planExchangeCodeErrorMsg,
            ],
            planExchangeCodeErrorMsg: '',
            isPlanExchangeCodeValid: true,
            alertMessage: '',
            alert: false,
            archivedDiaryHomeworks: [],
            createJournalDialog: false,
            createFromSampleDialog: false,
            createFromPublishSampleDialog: false,
            sampleCode: '',
            contestFinishedDialog: false,
            inviteCodeErrorDialog: false,
            inviteCodeErrorMessage: '',
            planExpiredDialog: false,
            planExpired: false,
        }
    },
    watch: {
        planExchangeCode() {
            this.planExchangeCodeErrorMsg = '';
        },
    },
    async created() {
        const homeworkLock = sessionStorage.getItem('isHomeworkLock');
        this.planName = sessionStorage.getItem('planName');
        if (homeworkLock == 1) {
            this.planExpired = true;
            this.planExpiredDialog = true;
        }
        await this.fetchDiaryHomework();

        if (homeworkLock == 1) {
            this.diaryHomeworks = [];
            this.joinedDiaryHomeworks.push({
                id: -1,
                name: this.$t('diary_main.join_journal'),
                type: 'join'
            });
        } else {
            await this.fetchJoinedDiaryHomework();
            await this.fetchArchivedDiaryHomework();
        }
    },
    methods: {
        async fetchDiaryHomework() {
            const result = await diaryServices.getDiaryHomework();
            this.diaryLimitCount = result.data.diary_limit_count;
            this.diaryUsageCount = result.data.diary_usage_count;
            this.diaryHomeworks = result.data.data_list;
            this.diaryHomeworks.push({
                id: -1,
                name: this.$t('diary_main.create_journal.label'),
                type: 'create'
            });
        },
        async fetchJoinedDiaryHomework() {
            const result = await diaryServices.getJoinedDiaryHomework();
            let i = 0;
            for (let item of result.data.data_list) {
                this.joinedDiaryHomeworks.push({
                    diary_cover_color: item.diary_cover_color,
                    drp_status: item.drp_status,
                    id: item.id,
                    label_color_code: item.label_color_code,
                    name: item.name,
                    keyId: i
                });
                i++;
            }
            this.joinedDiaryHomeworks.push({
                id: -1,
                name: this.$t('diary_main.join_journal'),
                type: 'join'
            });
        },
        async fetchArchivedDiaryHomework() {
            const result = await diaryServices.getArchivedDiaryHomework();
            this.archivedDiaryHomeworks = result.data.data_list;
        },
        formatInvitedNumber() {
            return `${this.$t('diary_main.team_used_count')}`
                .replace('${diaryUsageCount}', this.diaryUsageCount)
                .replace('${diaryLimitCount}', this.diaryLimitCount);
        },
        async gotoDiaryHomework(diaryHomerwork) {
            if (!diaryHomerwork.id) {
                return;
            }
            const contestRes = await contestServices.checkHomeworkContest(diaryHomerwork.id);
            if (contestRes.data.is_contest_home_work) {
                this.$router.push(`/diary/homework/${diaryHomerwork.id}/questList`);
            } else {
                this.$router.push(`/diary/homework/${diaryHomerwork.id}/news`);
            }
        },
        async openDiarySetting(diaryHomerwork) {
            const contestRes = await contestServices.checkHomeworkContest(diaryHomerwork.id);
            if (contestRes.data.is_contest_home_work) {
                this.$router.push(`/contest/${diaryHomerwork.id}/edit`);
            } else {
                this.$router.push(`/diary/homework/${diaryHomerwork.id}/edit`);
            }
        },
        async duplicateDiarySetting(diaryHomerwork) {
            await diaryServices.copyDiaryHomework(diaryHomerwork.id);
            await this.fetchDiaryHomework();
        },
        openArchvieDiaryDialog(diaryHomerwork) {
            this.diaryToArchive = diaryHomerwork;
            this.diaryArchiveDialog = true;
        },
        async archiveDiaryHomework() {
            this.loading = true;
            await diaryServices.archiveDiaryHomework(this.diaryToArchive.id);
            await this.fetchDiaryHomework();
            await this.fetchArchivedDiaryHomework();
            this.loading = false;
            this.diaryArchiveDialog = false;
        },
        async openCreateJournalDialog() {
            const result = await diaryServices.getPublishSampleHomework();
            if (result.data.length != 0) {
                this.publishSampleDiaryHomework = {
                    name: result.data.name,
                    cover_img: result.data.cover_img,
                    info_url: result.data.info_url,
                    inviteCode: result.data.invite_code
                }
            }
            this.createJournalDialog = true;
        },
        openCreateFromSampleDialog() {
            this.createJournalDialog = false;
            this.createFromSampleDialog = true;
        },
        openCreateFromPublishSampleDialog() {
            this.createJournalDialog = false;
            this.createFromPublishSampleDialog = true;
        },
        createJournal() {
            this.$router.push(`/diary/homework/new`);
        },
        openJoinJournalDialog() {
            this.joinDiaryDialog = true;
        },
        async joinDiaryHomework() {
            if (this.$refs.inviteCodeForm.validate()) {
                this.loading = true;
                const payload = {
                    join_code: this.inviteCode
                };
                const result = await diaryServices.joinDiaryHomework(payload);
                this.loading = false;
                if (result.data.return_code == '-1') {
                    this.alertMessage = this.$t('diary_main.invitation_code_error');
                    this.alert = true;
                } else if (result.data.return_code == '-2') {
                    this.alertMessage = this.$t('diary_main.join_error');
                    this.alert = true;
                } else {
                    this.joinedDiaryHomeworks = [];
                    await this.fetchJoinedDiaryHomework();
                    this.joinDiaryDialog = false;
                }
            }
        },
        async createFromSample() {
            if (this.$refs.sampleCodeForm.validate()) {
                this.loading = true;
                if (this.sampleCode.indexOf('ct_') == -1) {
                    const payload = {
                        invite_code: this.sampleCode,
                    };
                    const dhId = await diaryServices.takeCourseBook(payload);
                    this.createFromSampleDialog = false;
                    this.loading = false;
                    this.$router.push('/diary/homework/:id/edit'.replace(':id', dhId.data.info.id));
                } else {
                    /// Get contest homework
                    const payload = {
                        code: this.sampleCode,
                    }
                    const codeVerifyResult = await contestServices.inviteCodeVerify(payload);
                    if (codeVerifyResult.data.code_available) {
                        this.createFromSampleDialog = false;
                        this.loading = false;
                        this.$router.push(`/contest/0000/edit?code=${this.sampleCode}`);
                    } else {
                        const data = codeVerifyResult.data;
                        const returnCode = data.return_code;
                        if (returnCode) {
                            switch (returnCode) {
                                case '-10002':
                                    this.contestFinishedDialog = true;
                                    this.createFromSampleDialog = false;
                                    this.loading = false;
                                    return;
                                case '-10003':
                                case '-10004':
                                    this.inviteCodeErrorDialog = true;
                                    if (data.message) {
                                        this.inviteCodeErrorMessage = `${data.message}，`;
                                    }
                                    this.createFromSampleDialog = false;
                                    this.loading = false;
                                    return;
                            }
                        } else {
                            this.inviteCodeErrorDialog = true;
                            this.inviteCodeErrorMessage = this.$t('diary_main.invitaion_code_error');
                            this.createFromSampleDialog = false;
                            this.loading = false;
                            return;
                        }
                    }
                }
            }
        },
        async createFromSampleWithPlanExchangeCode() {
            this.planExchangeCodeErrorMsg = '';
            if (this.$refs.planExchangeCodeForm.validate()) {
                this.loading = true;
                const payload = {
                    invite_code: this.publishSampleDiaryHomework.inviteCode,
                    plan_exchange_code: this.planExchangeCode,
                };
                const takeResult = await diaryServices.takeCourseBook(payload);
                const data = takeResult.data;
                const returnCode = data.return_code;
                
                if (returnCode) {
                    switch (returnCode) {
                        case '-01103':
                            this.planExchangeCodeErrorMsg = this.$t('diary_main.book.serial_num_error');
                            break;
                        case '-01104':
                            this.planExchangeCodeErrorMsg = this.$t('diary_main.book.serial_num_error');
                            break;
                        case '-01105':
                            this.planExchangeCodeErrorMsg = this.$t('diary_main.book.serial_num_used');
                            break;
                        default: break;
                    }
                    this.loading = false;
                    this.$refs.planExchangeCodeForm.validate();
                    return;
                }
                this.createFromSampleDialog = false;
                this.loading = false;
                this.$router.push('/diary/homework/:id/edit'.replace(':id', data.info.id));
                
            }
        },
        scrollToRef(refId) {
            const $elem = this.$refs[refId];
            const top = $elem.offsetTop;
            window.scrollTo(0, top);
        },
        // gotoManage() {
        //     window.open('https://stage.ws.mangachat.co/diary/manage', '_blank');
        // }
    },
}
</script>
<style scoped>
.dotted-outline {
    border: 2px dashed #BDBDBD;
    /* Change the color as needed */
}

.card-outline {
    border: none;
    /* border: 1px solid gray; */
    /* Change the color as needed */
}
</style>