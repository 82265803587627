<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" sm="12" md="12" class="pa-1">
                <v-card outlined>
                    <v-card-title>
                        <v-icon x-large>mdi-book-open-variant</v-icon>
                        <div class="ml-2">{{ title }}</div>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-actions>
                        <v-btn class="normal-case" small outlined
                            color="cyan darken-2" @click="openStudentInvitationDialog = true">{{
                                $t('info.button.invite_student') }}</v-btn>
                        <v-btn small outlined color="cyan darken-2" @click="openTeacherInvitationDialog = true"
                            class="ml-4 normal-case">{{ $t('info.button.invite_teacher') }}</v-btn>
                        <v-menu
                            :close-on-content-click="false"
                            offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text color="grey darken-2"
                                    class="text-decoration-underline normal-case"
                                    v-bind="attrs"
                                    v-on="on"
                                    :ref="privilegeTeachers">
                                    {{ formatTeachers() }}
                                </v-btn>
                            </template>
                            <span>
                                <v-list>
                                    <v-list-item class="mx-3" v-if="privilegeTeachers.length == 0">
                                        {{ $t('info.diary.no_teacher') }}
                                    </v-list-item>
                                    <v-list-item v-for="teacher in privilegeTeachers"
                                        :key="teacher.id">
                                        <v-list-item-avatar>
                                            <v-img
                                                class="role-thumbnail"
                                                :src="teacher.avatar_url"></v-img>
                                        </v-list-item-avatar>

                                        <v-list-item-content class="teacher-name">
                                            <v-list-item-title v-text="teacher.name"></v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <span v-if="teacher.status == 0">
                                                <v-btn small color="success"
                                                    class="mx-1 normal-case"
                                                    @click="acceptPrivilegeTeacher(teacher)">
                                                    接受
                                                </v-btn>
                                                <v-btn small outlined color="error"
                                                    class="mx-1 normal-case"
                                                    @click="rejectPrivilegeTeacher(teacher)">
                                                    拒絕
                                                </v-btn>
                                            </span>
                                            <span v-if="teacher.status == 1">
                                                <v-btn small outlined color="error"
                                                    class="mx-1 normal-case"
                                                    @click="openRemovePrivilegeDialog(teacher)">
                                                    移除
                                                </v-btn>
                                            </span>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </span>
                        </v-menu>
                        <v-spacer></v-spacer>
                        <v-btn small color="grey darken-2" dark @click="gotoRanking"
                            class="normal-case">
                            <v-icon small>mdi-cog</v-icon>
                            <span class="d-none d-sm-block">{{ $t('contest_info.ranking') }}</span>
                        </v-btn>
                        <v-btn small color="grey darken-2" dark
                            class="normal-case" @click="openDiarySetting">
                            <v-icon small>mdi-cog</v-icon>
                            <span class="d-none d-sm-block">{{ $t('info.button.diary_setting') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12" class="pa-1">
                <v-card outlined>
                    <v-card-title class="subtitle-1 py-2">
                        {{ $t('general.diary.description') }}
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-subtitle>
                        {{ description }}
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="6" class="pa-1">
                <v-card outlined>
                    <v-card-title class="subtitle-1 py-2">
                        <v-icon color="#E1957A" class="mr-2">mdi-flag</v-icon>
                        {{ $t('contest_homework_edit.info') }}
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-text :style="{ paddingTop: '8px', paddingBottom: '8px' }">
                        <span class="gray--text text-darken-1">{{ $t('contest_homework_edit.name') }}:</span>
                        <span class="black--text"> {{ teamName }}</span>
                    </v-card-text>
                    <v-card-text :style="{ paddingTop: '8px', paddingBottom: '8px' }">
                        <span class="gray--text text-darken-1">{{ $t('contest_homework_edit.school') }}:</span>
                        <span class="black--text"> {{ teamSchool }}</span>
                    </v-card-text>
                    <v-card-text :style="{ paddingTop: '8px', paddingBottom: '8px' }">
                        <span class="gray--text text-darken-1">{{ $t('contest_homework_edit.group') }}:</span>
                        <span class="black--text"> {{ ageGroup }}</span>
                    </v-card-text>
                    <v-card-title class="subtitle-1 py-2">
                        <v-icon color="#E1957A" class="mr-2">mdi-calendar</v-icon>
                        {{ $t('contest_homework_edit.time_table') }}
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-text :style="{ paddingTop: '8px', paddingBottom: '8px' }">
                        <span class="gray--text text-darken-1">{{ $t('contest_homework_edit.time_period') }}:</span>
                        <span class="black--text"> {{ contestPeriod }}</span>
                    </v-card-text>
                    <v-card-text :style="{ paddingTop: '8px', paddingBottom: '8px' }">
                        <span class="gray--text text-darken-1">{{ $t('contest_homework_edit.status.title') }}:</span>
                        <span class="black--text"> {{ contestStatus }}</span>
                        <span v-if="countdownDays != 0" :style="{ color: '#DE714B' }">{{ ` ${countdownDays} ` }}</span>
                        <span class="black--text" v-if="countdownDays != 0">{{ $t('contest_homework_edit.days') }}</span>
                    </v-card-text>
                    <v-card-text :style="{ paddingTop: '8px', paddingBottom: '8px' }">
                        <span class="gray--text text-darken-1">{{ $t('contest_homework_edit.current_score') }}:</span>
                        <span class="black--text mr-2"> {{ teamScores }}</span>
                        <span @click="gotoRanking" :style="{ color: '#DE714B' }">{{ $t('contest_homework_edit.view_ranking') }}</span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="6" class="pa-1">
                <v-card outlined class="fill-height d-flex flex-column">
                    <v-card-title class="subtitle-1 py-2">
                        <v-icon color="#E1957A" class="mr-2">mdi-compass</v-icon>
                        {{ $t('contest_homework_edit.instruction.title') }}
                        <v-chip color="#FFECE6" class="mx-2 py-2" v-if="isUnderContest">
                            <span :style="{ color: '#DE714B' }">{{ contestStatusTitle}}</span>
                        </v-chip>
                    </v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-text class="black--text">
                        <ol class="custom-list">
                            <li>{{ $t('contest_homework_edit.instruction.1') }}<span @click="gotoQuestList"
                                    :style="{ color: '#DE714B' }">{{ $t('menu.quest_list') }}</span>{{ $t('contest_homework_edit.instruction.2') }}
                            </li>
                            <li>{{ $t('contest_homework_edit.instruction.3') }}
                            </li>
                            <li> <span @click="gotoRanking" :style="{ color: '#DE714B' }">{{ $t('contest_homework_edit.view_ranking') }}</span>{{ $t('contest_homework_edit.instruction.4') }}
                            </li>
                        </ol>
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-text>
                        {{ $t('contest_homework_edit.customer_service') }}
                        <a href="https://lin.ee/v446Bup">
                            <div :style="{ color: '#DE714B' }" href="https://lin.ee/v446Bup">https://lin.ee/v446Bup</div>
                        </a>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <TeacherInvitationDialog v-model="openTeacherInvitationDialog"></TeacherInvitationDialog>
        <StudentInvitationDialog v-model="openStudentInvitationDialog"></StudentInvitationDialog>

        <v-dialog v-model="removePrivilegeTeacherDialog" width="600">
            <v-card>
                <v-card-title>
                    {{ $t('info.remove_dialog.title') }}
                </v-card-title>
                <v-card-text class="text-h6 text-center">
                    {{ formatDialogText() }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn plain class="mb-2 normal-case"
                        @click="removePrivilegeTeacherDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" class="mb-2 normal-case" :loading="loading"
                        @click="removePrivilegeTeacher">
                        {{ $t('button.confirm') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay absolute :value="dataLoading">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import { diaryServices } from "@/services/diary.js";
import { contestServices } from "@/services/contest.js";

import TeacherInvitationDialog from '@/components/console/diary/component/TeacherInvitationDialog.vue';
import StudentInvitationDialog from '@/components/console/diary/component/StudentInvitationDialog.vue';

import dateformat from "dateformat";

export default {
    name: 'diaryInfo',
    components: { TeacherInvitationDialog, StudentInvitationDialog },
    data() {
        return {
            homeworkId: '',
            title: '',
            teamMemberCount: 0,
            openTeacherInvitationDialog: false,
            openStudentInvitationDialog: false,
            buttonLoading: false,
            email: '',
            emailRules: [(v) => !!v || this.$t('info.email_rule'),
            (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('info.email_incorrect')],
            description: '',
            sendingMail: false,
            mailSend: false,
            privilegeTeachers: [],
            removePrivilegeTeacherDialog: false,
            teacherToRemove: '',
            loading: false,
            diaryType: '',
            isContestHomework: false,
            teamName: '',
            teamSchool: '',
            ageGroup: '',
            ageGroups: [
                { title: this.$t('contest_homework_edit.group_1'), value: 1 },
                { title: this.$t('contest_homework_edit.group_2'), value: 2 },
                { title: this.$t('contest_homework_edit.group_3'), value: 3 },
                { title: this.$t('contest_homework_edit.group_4'), value: 4 },
                { title: this.$t('contest_homework_edit.group_0'), value: 0 },
            ],
            contestPeriod: '',
            contestStatus: '',
            contestStatusTitle: '',
            countdownDays: 0,
            teamScores: '',
            isUnderContest: false,
            contestId: '',
            teamId: '',
            dataLoading: false,
        }
    },
    async created() {
        this.dataLoading = true;
        this.homeworkId = this.$route.params.id;
        const result = await diaryServices.getHomeworkDetail(this.homeworkId);
        if (result.success) {
            this.title = result.data.name;
            this.description = result.data.description;
            this.diaryType = result.data.share_type;
        }

        await this.getPrivilegeTeachers();
        const infoResult = await contestServices.getHomeworkInfo(this.homeworkId);
        this.teamName = infoResult.data.team.name;
        this.ageGroup = this.ageGroups.find(item => item.value == infoResult.data.contest.age_group).title;
        this.teamSchool = infoResult.data.team.school;
        this.contestId = infoResult.data.contest.id;
        this.teamId = infoResult.data.team.id;
        this.teamScores = infoResult.data.team.total_score;

        let startDate = '';
        let endDate = '';
        if (infoResult.data.contest.ans_day_type == 2) {
            if (infoResult.data.team && infoResult.data.team.start_date) {
                startDate = new Date(infoResult.data.team.start_date);
                endDate = new Date(infoResult.data.team.start_date)
                    .setDate(startDate.getDate() + infoResult.data.contest.ans_days);
                endDate = new Date(endDate).setSeconds(-1);
            }
        } else {
            startDate = new Date(infoResult.data.contest.start_date);
            endDate = new Date(infoResult.data.contest.end_date);
        }
        this.contestPeriod = `${dateformat(startDate, 'yyyy/mm/dd HH:MM:ss')}
                 - ${dateformat(endDate, 'yyyy/mm/dd HH:MM:ss')}`;

        const today = new Date();
        if (today < endDate && today > startDate) {
            this.countdownDays = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));
            this.isUnderContest = true;
            this.contestStatus = this.$t('contest_homework_edit.status.1.description');
            this.contestStatusTitle = this.$t('contest_homework_edit.status.1.title');
        } else if (today < startDate) {
            this.countdownDays = Math.ceil((startDate - today) / (1000 * 60 * 60 * 24));
            this.contestStatus = this.$t('contest_homework_edit.status.0.description');
            this.contestStatusTitle = this.$t('contest_homework_edit.status.0.title');
        } else {
            this.countdownDays = 0;
            this.contestStatus = this.$t('contest_homework_edit.status.2.description');
            this.contestStatusTitle = this.$t('contest_homework_edit.status.2.title');
        }
        this.dataLoading = false;
    },
    methods: {
        openDiarySetting() {
            this.$router.push(`/contest/${this.homeworkId}/edit?new=-1`);
        },
        formatTeachers() {
            return this.$t('info.diary.teacher_count')
                .replace('${num}', this.privilegeTeachers.length);
        },
        async getPrivilegeTeachers() {
            const teachers = await diaryServices.getHomeworkTeachers(this.homeworkId);
            this.privilegeTeachers = teachers.data.teachers;
        },
        async acceptPrivilegeTeacher(teacher) {
            const payload = {
                user_id: teacher.id,
            }
            const result = await diaryServices.acceptHomeworkPrivilegeUser(this.homeworkId, payload);
            if (result.success) {
                this.privilegeTeachers.find(item => item.id == teacher.id).status = 1;
            }
        },
        async rejectPrivilegeTeacher(teacher) {
            const payload = {
                user_id: teacher.id,
            }
            const result = await diaryServices.rejectHomeworkPrivilegeUser(this.homeworkId, payload);
            if (result.success) {
                this.privilegeTeachers = this.privilegeTeachers.filter(item => item.id != teacher.id);
            }
        },
        openRemovePrivilegeDialog(teacher) {
            this.teacherToRemove = teacher;
            this.removePrivilegeTeacherDialog = true;
        },
        async removePrivilegeTeacher() {
            this.loading = true;
            const payload = {
                user_id: this.teacherToRemove.id,
            }
            const result = await diaryServices.deleteHomeworkPrivilegeUser(this.homeworkId, payload);
            if (result.success) {
                this.privilegeTeachers = this.privilegeTeachers.filter(item => item.id != this.teacherToRemove.id);
            }
            this.loading = false;
            this.removePrivilegeTeacherDialog = false;
        },
        formatDialogText() {
            return this.$t('info.remove_dialog.text')
                .replace('${name}', this.teacherToRemove.name);
        },
        gotoQuestList() {
            this.$router.push(`/diary/homework/${this.homeworkId}/questList`);
        },
        gotoRanking() {
            this.$router.push(`/contest/${this.contestId}/ranking?&teamId=${this.teamId}`);
        },
    },
}
</script>

<style scoped>
.role-thumbnail {
    width: 50px;
    align-items: center;
}

.teacher-name {
    width: 150px;
}

.fill-height {
    height: 100%;
}
</style>