<template>
    <v-container fluid>
        <v-row>
            <v-col cols="4" sm="3" md="2" lg="1" class="my-auto">
                <v-avatar size="62">
                    <img :src='icon'>
                </v-avatar>
            </v-col>
            <v-col cols="8" sm="9" md="10" lg="11" class="my-auto">
                <v-row class="text-h5 mb-1">
                    {{ $t('invite_page.title') }}
                </v-row>
                <v-row>
                    {{ $t('invite_page.subtitle') }}
                </v-row>
            </v-col>
        </v-row>
        <v-card outlined class="my-4">
            <v-card-title>
                {{ $t('invite_page.process_title') }}
            </v-card-title>
            <v-row>
                <v-col cols="4" class="my-auto text-center">
                    <v-icon color="deep-orange darken-3"
                        class="custom-icon-size my-auto text-center">
                        mdi-share</v-icon>
                    <div style="color: #E64A19;" class="my-1">
                        {{ $t('invite_page.step.1') }}
                    </div>
                    <span v-html="$t('invite_page.step_description.1')"></span>
                </v-col>
                <v-col cols="4" class="my-auto text-center">
                    <v-icon color="deep-orange darken-3"
                        class="custom-icon-size my-auto text-center">
                        mdi-account</v-icon>
                    <div style="color: #E64A19;" class="my-1">
                        {{ $t('invite_page.step.2') }}
                    </div>
                    <span v-html="$t('invite_page.step_description.2')"></span>
                </v-col>
                <v-col cols="4" class="my-auto text-center">
                    <v-icon color="deep-orange darken-3"
                        class="custom-icon-size my-auto text-center">
                        mdi-gift</v-icon>
                    <div style="color: #E64A19;" class="my-1">
                        {{ $t('invite_page.step.3') }}
                    </div>
                    <span v-html="$t('invite_page.step_description.3')"></span>
                </v-col>
            </v-row>
            <v-card-title>
                {{ $t('invite_page.share_title') }}
            </v-card-title>
            <v-card-title class="text-body-1">
                <v-row>
                    <v-col cols="6">
                        {{ $t('invite_page.invitation_link') }}
                        <v-text-field
                            :value="inviteLink"
                            filled
                            readonly
                            append-icon="mdi-content-copy"
                            @click:append="copyLink"></v-text-field>
                    </v-col>
                    <v-col cols="1" class="my-auto text-center">
                        {{ $t('invite_page.or') }}
                    </v-col>
                    <v-col cols="5">
                        {{ $t('invite_page.invitation_code') }}
                        <v-text-field
                            :value="inviteCode"
                            filled
                            readonly
                            append-icon="mdi-content-copy"
                            @click:append="copyCode"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-title>
                {{ $t('invite_page.history') }}
            </v-card-title>
            <v-card-subtitle>
                {{ $t('invite_page.quota').replace('$()', goalCount) }}
            </v-card-subtitle>
            <v-data-table :headers="headers" :items="invitedFriends"
                :server-items-length="totalInvitedFriends" :loading="loading"
                :page.sync="invitedFriendsPage" :options.sync="invitedFriendsOptions"
                hide-default-footer class="mx-4"></v-data-table>
            <div class="text-center my-4">
                <v-pagination v-model="invitedFriendsPage" :length="invitedFriendsPageCount" circle color="orange accent-2">
                </v-pagination>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import iconImage from '@/assets/icon.png';
import { userServices } from '@/services/user.js';
export default {
    name: 'Invite',
    data() {
        return {
            icon: iconImage,
            inviteLink: '',
            inviteCode: '',
            headers: [{
                text: this.$t('invite_page.table.account'),
                value: 'account',
                align: 'left',
                class: 'grey lighten-4',
                filterable: false,
            }, {
                text: this.$t('invite_page.table.date'),
                value: 'create_date',
                align: 'left',
                class: 'grey lighten-4',
                filterable: false,
            }],
            invitedFriends: [],
            webLink: '',
            invitedFriendsPage: 1,
            invitedFriendsPageCount: 1,
            totalInvitedFriends: 0,
            loading: false,
            invitedFriendsOptions: {},
            inviteVoucherRequireCount: 3,
            goalCount: 0,
        }
    },
    async created() {
        this.webLink = process.env.VUE_APP_WEB_REF_URL;
        const inviteCode = await userServices.getMyInviteCode();
        this.inviteCode = inviteCode.data.invite_code;
        this.inviteLink = `${this.webLink}ri/${this.inviteCode}`;
    },
    watch: {
        invitedFriendsOptions: {
            handler() {
                this.getInvitedFriends();
            },
            deep: true,
        }
    },
    methods: {
        async copyCode() {
            try {
                let inviteStr = this.$t('invite_page.code_invitation')
                    .replace('$()', this.webLink);
                await navigator.clipboard.writeText(`${inviteStr} ${this.inviteCode}`);
                this.$toastr.Add({
                    msg: this.$t('invite_page.copied'),
                    clickClose: true,
                    timeout: 3000,
                    position: "toast-top-right",
                    classNames: ["animated", "bounceInRight"],
                });
            } catch (err) {
                console.error("Unable to copy text", err);
            }
        },
        async copyLink() {
            try {
                await navigator.clipboard.writeText(`${this.$t('invite_page.link_invitation')} ${this.webLink}ri/${this.inviteCode}`);
                this.$toastr.Add({
                    msg: this.$t('invite_page.copied'),
                    clickClose: true,
                    timeout: 3000,
                    position: "toast-top-right",
                    classNames: ["animated", "bounceInRight"],
                });
            } catch (err) {
                console.error("Unable to copy text", err);
            }
        },
        getInvitedFriends() {
            this.loading = true;
            this.getInvitedFriendsList().then(data => {
                this.invitedFriends = data.items;
                this.totalInvitedFriends = data.total;
                this.invitedFriendsPageCount = data.pageCount;
                this.loading = false;
                this.invitedFriendsPage = data.page;
            })
        },
        async getInvitedFriendsList() {
            const { page } = this.invitedFriendsOptions;
            const result = await userServices.getMyInvitedFriends(page);
            const items = result.data.list;
            const total = result.data.total;
            const pageCount = result.data.totalPage;
            this.goalCount = this.inviteVoucherRequireCount - (total % this.inviteVoucherRequireCount);
            return { items, total, pageCount, page };
        }
    }
}
</script>

<style scoped>
.custom-icon-size {
    font-size: 60px;
}
</style>