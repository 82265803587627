export const getters = {
    getBearerToken(state) {
        return state.bearerToken
    },
    getUser(state) {
        return state.user
    },
    getConsoleIndexUrl(state) {
        if (state.consoleIndexUrl) {
            return state.consoleIndexUrl
        }
        return '/';
    },
    getConsoleFuncList(state) {
        return state.consoleFuncList
    },
    getContestIndexUrl(state) {
        return state.contestIndexUrl
    },
    getContestFuncList(state) {
        return state.contestFuncList
    },
    getHasFinishFirstRound(state) {
        return state.hasFinishFirstRound
    },
    getHomeworkName(state) {
        console.log('store:', state.homeworkName)
        return state.homeworkName
    },
}