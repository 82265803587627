<template>
    <v-card outlined>
        <v-card-title class="subtitle-1 py-2">
            {{ $t('info.diary.mood_thermometer') }}
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-subtitle v-if="badMoodStudents.length == 0" class="text-center">
            <v-icon>mdi-thermometer</v-icon>
            {{ $t('info.diary.no_bad_mood_students') }}
        </v-card-subtitle>
        <v-container class="pa-2 mx-2">
            <v-row class="my-2 cursor-pointer" v-for="(item, i) in badMoodStudents" :key="i"
                @click="toStudentPage(item.student_info.userAccountId)">
                <v-col cols="2">
                    <img :src="getMoodImg(item.student_info.score)" :alt="item.student_info.user_name" class="mood">
                </v-col>
                <v-col cols="10" class="pa-2 my-2">
                    <v-row>
                        <span class="black--text">
                            {{ item.student_info.displayName }}</span>
                        <span class="gray--text text-caption ml-2 mt-1">
                            {{ item.post_info.title }}
                        </span>
                    </v-row>
                    <v-row>
                        <span class="red--text text-caption">
                            {{ `#${formatEmotions(item.post_info.emotions)}` }}
                        </span></v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { diaryServices } from "@/services/diary.js";
// import { emotion as emotionTable } from '@/locale/i18n/zh_tw.json';

export default {
    name: "teacherInvitationDialog",
    components: {},
    props: {
        value: Boolean,
    },
    data() {
        return {
            homeworkId: '',
            badMoodStudents: [],

        };
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        const result = await diaryServices.getBadMoodStudents(this.homeworkId);
        this.badMoodStudents = result.data;
    },
    watch: {
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        getMoodImg(score) {
            return require("@/assets/score/" + score + ".png");
        },
        formatEmotions(emotions) {
            if (Array.isArray(emotions) && typeof emotions[0] === 'string') {
                let result = '';
                for (let emotion of emotions) {
                    const a = this.$t(`emotion.type.${emotion}`);
                    if (a) {
                        if (result.length > 0) {
                            result += ', ';
                        }
                        result += a;
                    }
                }
                return result;
            }
            return emotions;
        },
        toStudentPage(studentId) {
            this.$router.push(`/diary/homework/${this.homeworkId}/student/${studentId}`);
        },

    },
};
</script>
<style scoped>
.mood {
    width: 30px;
    height: 30px;
}
</style>

