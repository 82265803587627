<template>
    <v-container>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import { userServices } from '@/services/user.js';
import { diaryServices } from '@/services/diary.js';
import { setItem } from '@/utils/storageManager.js';

export default {
    name: 'Mode',
    data() {
        return {
            loading: true,
        }
    },
    async created() {
        const changeModeResult = await userServices.changeMode();
        console.log('changeModeResult', changeModeResult);
        sessionStorage.setItem('mode', changeModeResult.data.mode);
        if (changeModeResult.data.isHomeworkLock) {
            sessionStorage.setItem('isHomeworkLock', changeModeResult.data.isHomeworkLock.isHomeworkLock);
            sessionStorage.setItem('planName', changeModeResult.data.isHomeworkLock.planName);
            sessionStorage.setItem('studentLimit', changeModeResult.data.isHomeworkLock.studentLimit);

            let payfunctionMap = {};
            const funcRes = await diaryServices.getDiaryFunctionPrivilege();
            funcRes.data.forEach(func => {
                payfunctionMap[`f${func['id']}`] = func['is_pay'];
            });
            setItem('payfunctionMap', JSON.stringify(payfunctionMap));

        }
        location.href = '/diary';
    },
    methods: {
        // Your methods here
    }
}
</script>

<style scoped>
/* Your CSS styles here */
</style>
