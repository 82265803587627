<template>
    <v-container fluid class="pt-0 px-0">
        <v-app-bar color="#EE7D31" flat
            class="white--text text-h5 ma-0">
            漫話英文日記比賽排行榜
        </v-app-bar>
        <v-card flat rounded="0" class="px-12 mt-2">
            <!-- <div v-if="teamAvailable"> -->
            <v-row>
                <v-col cols="8">
                    <div v-if="teamAvailable">
                        <v-row>
                            <v-card-title>
                                {{ teamName }}
                            </v-card-title>
                        </v-row>
                        <v-row>
                            <v-card-subtitle class="text-body-1 my-1 py-0">
                                <span color="#555555" class="my-auto grey--text text--darken-3">
                                    <v-icon color="#E1957A" class="mb-1">mdi-flag</v-icon>
                                    {{ `參賽組別： ${ageGroup}` }}
                                </span>
                                <span color="#555555" class="my-auto ml-4 grey--text text--darken-3">
                                    <v-icon color="#E1957A" class="mb-1">mdi-calendar</v-icon>
                                    {{ `參賽狀況：${teamStatus}` }}
                                </span>
                                <span v-if="countdownDays != 0" style="color: #E1957A;">
                                    {{ countdownDays }}
                                </span>
                                <span v-if="countdownDays != 0" color="#555555">
                                    {{ '天' }}
                                </span>
                            </v-card-subtitle>
                        </v-row>
                    </div>
                    <div v-else style="min-height: 80px;"></div>
                    <v-row>
                        <v-card-title><v-chip-group
                                active-class="chip_class"
                                mandatory>
                                <v-chip color="#F8F9FA" @click="changeTable('team')">參賽隊伍排名</v-chip>
                                <v-chip color="#F8F9FA" @click="changeTable('student')">隊伍內排名</v-chip>
                            </v-chip-group></v-card-title>
                    </v-row>
                    <div v-if="!teamAvailable" style="min-height: 80px;"></div>
                </v-col>
                <v-col cols="4" class="my-auto mx-auto">
                    <v-img max-height="150" contain :src="imgAddress()"
                        style="opacity: 40%;">
                    </v-img>
                </v-col>
            </v-row>
        </v-card>
        <div v-if="teamRanking">
            <v-data-table
                class="px-12 mt-2 no-selection-table"
                :headers="teamHeaders"
                :items="teams"
                :server-items-length="totalTeams" :loading="teamLoading"
                :page.sync="teamPage" :options.sync="teamOptions" :items-per-page="10" hide-default-footer
                :item-class="getRowClass" disable-sort>
                <template v-slot:item.ranking="{ item }">
                    <v-img v-if="item.ranking > 0 && item.ranking < 4"
                        :src="getRankImg(item.ranking)" class="rank-img  mx-auto my-auto">
                    </v-img>
                    <span v-else class="text-body-1">{{ item.ranking }}</span>
                </template>
                <template v-slot:item.status="{ item }">
                    <span v-if="item.status == -1" class="text-body-1">{{ '未開始' }}</span>
                    <span v-else-if="item.status == 1" class="text-body-1"
                        style="color:#6C757D;">{{ '已完賽' }}</span>
                    <span v-else class="text-body-1" style="color:#DE714B;">{{ '進行中' }}</span>
                </template>
                <template v-slot:item.avg_score="{ item }">
                    <v-avatar v-if="item.selected"
                        color="#E1957A" size="40" class="white--text font-weight-bold my-2 mr-2 body-2">{{ toFixed(item.avg_score)
                        }}</v-avatar>
                    <v-avatar v-else
                        color="#FFF8F5" size="40" class="black--text my-2 mr-2 body-2">{{ toFixed(item.avg_score) }}</v-avatar>
                </template>

            </v-data-table>
            <div class="text-center my-4">
                <v-pagination v-model="teamPage" :length="teamPageCount" circle color="orange accent-2">
                </v-pagination>
            </div>
        </div>
        <div v-if="studentRanking">
            <v-data-table
                class="px-12 mt-2 no-selection-table"
                :headers="studentHeaders"
                :items="students"
                :server-items-length="totalStudents" :loading="studentLoading"
                :page.sync="studentPage" :options.sync="studentOptions" :items-per-page="10" hide-default-footer
                :item-class="getRowClass" disable-sort>
                <template v-slot:item.ranking="{ item }">
                    <v-img v-if="item.ranking > 0 && item.ranking < 4"
                        :src="getRankImg(item.ranking)" class="rank-img  mx-auto my-auto">
                    </v-img>
                    <span v-else class="text-body-1">{{ item.ranking }}</span>
                </template>
                <template v-slot:item.user_name="{ item }" class="text-center">
                    <div class="d-flex">
                        <img :src="item.avatar_url" class="role-thumbnail my-2 mr-4">
                        <span class="text-body-1 my-auto"> {{ item.user_name }}</span>
                        <v-icon v-if="item.withBadge" color="#E1957A" class="my-auto ml-2">mdi-medal</v-icon>
                    </div>
                </template>
                <template v-slot:item.total_score="{ item }">
                    <v-avatar v-if="item.selected"
                        color="#E1957A" size="40" class="white--text font-weight-bold my-2 mr-2 body-2">{{ item.total_score
                        }}</v-avatar>
                    <v-avatar v-else
                        color="#FFF8F5" size="40" class="black--text my-2 mr-2 body-2">{{ item.total_score }}</v-avatar>
                </template>
            </v-data-table>
            <div class="text-center my-4">
                <v-pagination v-model="studentPage" :length="studentPageCount" circle color="orange accent-2">
                </v-pagination>
            </div>
        </div>
    </v-container>
</template>

<script>
import { contestServices } from '@/services/contest.js';
export default {
    name: 'ContestRanking',
    data() {
        return {
            teamName: '  ',
            ageGroup: '',
            ageGroups: [
                { title: '國小組', value: 1 },
                { title: '國中組', value: 2 },
                { title: '高中組', value: 3 },
                { title: '大專組', value: 4 },
                { title: '無限制', value: 0 },
            ],
            teamStatus: '',
            countdownDays: 0,
            teamAvailable: false,
            teamPage: 1,
            teamPageCount: 0,
            totalTeams: 0,
            teamLoading: false,
            teamOptions: {
                page: 1
            },
            teamHeaders: [
                {
                    text: '排名',
                    value: 'ranking',
                    align: 'center',
                    class: 'grey--text text--darken-2 text-body-2',
                },
                {
                    text: '隊伍名稱',
                    value: 'team_name',
                    align: 'left',
                    class: 'grey--text text--darken-2 text-body-2',
                },
                {
                    text: '參賽狀況',
                    value: 'status',
                    align: 'center',
                    class: 'grey--text text--darken-2 text-body-2',
                },
                {
                    text: '學校/單位',
                    value: 'school_name',
                    align: 'center',
                    class: 'grey--text text--darken-2 text-body-2',
                },
                {
                    text: '目前隊伍平均總分',
                    value: 'avg_score',
                    align: 'center',
                    class: 'grey--text text--darken-2 text-body-2',
                },
            ],
            teams: [],
            teamRanking: true,
            studentRanking: false,
            studentHeaders: [
                {
                    text: '排名',
                    value: 'ranking',
                    align: 'center',
                    class: 'grey--text text--darken-2 text-body-2',
                },
                {
                    text: '參賽者名稱',
                    value: 'user_name',
                    align: 'left',
                    class: 'grey--text text--darken-2 text-body-2',
                },
                {
                    text: '完成篇數',
                    value: 'total_posts',
                    align: 'center',
                    class: 'grey--text text--darken-2 text-body-2',
                },
                {
                    text: '總得分',
                    value: 'total_score',
                    align: 'center',
                    class: 'grey--text text--darken-2 text-body-2',
                },
            ],
            students: [],
            studentPage: 1,
            studentPageCount: 0,
            totalStudents: 0,
            studentLoading: false,
            studentOptions: {
                page: 1
            },
            contestId: 0,
            teamId: -1,
        }
    },
    created() {
        this.contestId = this.$route.params.id;
        if (this.$route.query.teamId) {
            this.teamId = this.$route.query.teamId;
            this.getTeamInfo();
        }
        if (this.$route.query.studentId) {
            this.userId = this.$route.query.studentId;
        }
    },
    methods: {
        toFixed(val) {
            return val.toFixed(2);
        },
        imgAddress() {
            return require("@/assets/contest_element.png");
        },
        getRankImg(rank) {
            return require(`@/assets/rank/crown${rank}.png`);
        },
        getTeamData() {
            this.teamLoading = true;
            this.getTeamList().then(data => {
                this.teams = data.items;
                this.totalTeams = data.total;
                this.teamPageCount = data.pageCount;
                this.teamLoading = false;
                this.teamPage = data.page;
                let team = this.teams.find(item => item.team_id == this.teamId);
                if (team) {
                    team['selected'] = true;
                }
            })
        },
        async getTeamList() {
            const { page } = this.teamOptions;
            const query = {
                page: page
            };
            const res = await contestServices.getTeamRanking(this.contestId, query);
            const items = res.data.list;
            const total = res.data.total;
            const pageCount = res.data.totalPage;
            return { items, total, pageCount, page }
        },
        getStudentData() {
            this.studentLoading = true;
            this.getStudentList().then(data => {
                this.students = data.items;
                this.totalStudents = data.total;
                this.studentPageCount = data.pageCount;
                this.studentLoading = false;
                this.studentPage = data.page;
                this.students.forEach(item => {
                    if (item.user_id == this.userId) {
                        item['selected'] = true;
                    }
                    if (item.total_posts >= 30) {
                        item['withBadge'] = true;
                    }
                })
            })
        },
        async getStudentList() {
            const { page } = this.studentOptions;
            const query = {
                page: page,
                teamId: this.teamId
            };
            const res = await contestServices.getStudentRanking(this.contestId, query);
            const items = res.data.list;
            const total = res.data.total;
            const pageCount = res.data.totalPage;
            return { items, total, pageCount, page: page }
        },
        changeTable(type) {
            if (type == 'team') {
                this.teamRanking = true;
                this.studentRanking = false;
                if (this.teams.length == 0) {
                    this.getTeamData();
                }
            } else {
                this.teamRanking = false;
                this.studentRanking = true;
                if (this.students.length == 0) {
                    this.getStudentData();
                }
            }
        },
        getRowClass(item) {
            let rowClass = '';
            if (item.selected) {
                rowClass = 'red-background';
            }
            return rowClass;
        },
        async getTeamInfo() {
            if (this.teamId) {
                const result = await contestServices.getTeamInfo(this.teamId);
                this.teamName = result.data.team.name;
                this.ageGroup = this.ageGroups.find(item => item.value == result.data.contest.age_group).title
                    || this.ageGroups[0].title;
                const today = new Date();
                const startDate = new Date(result.data.team.start_date);
                const endDate = new Date(result.data.team.start_date).setDate(startDate.getDate() + result.data.contest.ans_days);
                if (today < endDate && today > startDate) {
                    this.countdownDays = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));
                    this.teamStatus = '剩餘';
                } else if (today < startDate) {
                    this.countdownDays = Math.ceil((startDate - today) / (1000 * 60 * 60 * 24));
                    this.teamStatus = '尚未開賽，距離開始還有';
                } else {
                    this.countdownDays = 0;
                    this.teamStatus = '比賽已結束。';
                }
                this.teamAvailable = true;
            }
        }
    },
    watch: {
        teamOptions: {
            handler() {
                this.getTeamData()
            },
            deep: true,
        },
        studentOptions: {
            handler() {
                this.getStudentData()
            },
            deep: true,
        },
    }
}
</script>

<style scoped>
.chip_class {
    background-color: #FFF8F5 !important;
    color: black !important;
}

.role-thumbnail {
    width: 36px;
    align-items: center;
}

.rank-img {
    width: 40px;
    align-items: center;
}
::v-deep .red-background {
    background-color: #FCF1EE;
}
.no-selection-table>>>tr:hover {
    background: transparent !important;
}
</style>