import servicesUtils from '../utils/service'

export const registerServices = {
    async checkAccount(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('checkAccount')}`
                + '?' + servicesUtils.queryParams(payload);
            const data = await servicesUtils.get(url);
            return data;
        } catch (err) {
            console.log(`failed to get checkAccount err: ${err}`)
            throw err
        }
    },
    async register(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('register')}`
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to register: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },
    async bind(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('bind3rdAccount')}`
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to bind3rdAccount: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },
    async emailValidate(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('emailValidate')}`
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed in emailValidate: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    },
    async emailCodeValidate(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('emailCodeValidate')}`
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed in emailCodeValidate: ${JSON.stringify(payload)}, err: ${err}`)
            throw err
        }
    }
}