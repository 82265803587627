<template>
    <v-overlay absolute opacity="0.7" :value="!isPay">
        啟用功能，用更棒的方式管理日記本！
        <div class="text-center mt-3">
            <v-btn color="deep-orange lighten-1" class="white--text normal-case"
                depressed href="https://www.mangax.co/mangachat/pricing/" target="_blank">
                查看方案價格
            </v-btn>
        </div>
    </v-overlay>
</template>

<script>
export default {
    name: "payFunctionArea",
    components: {},
    props: ['isPay'],
    data() {
        return {
        };
    },
    async created() {
    },
    watch: {
    },
    mounted() {

    },
    methods: {
    },
};
</script>
<style scoped></style>

