<template>
    <v-container>
        <v-row style="height: 40vh;"></v-row>
        <v-row justify="center" align="center">
            <v-progress-linear color="amber darken-3" indeterminate rounded height="6"></v-progress-linear>
        </v-row>
    </v-container>
</template>

<script>
import { diaryServices } from '../../services/diary';
export default {
    name: 'InvitationCode',
    data() {
        return {
            code: '',
        };
    },
    async created() {
        this.code = this.$route.params.code;
        diaryServices.applyInvitationCode(this.code).then(res => {
            if (res.data.return_code == '1') {
                this.$router.push('/diary');
            } else {
                this.$toastr.Add({
                    msg: this.$t('invitation_code_error'),
                    type: "error",
                    timeout: 3000,
                    clickClose: true,
                    position: "toast-top-center",
                    onClosed: () => {
                        this.$router.push('/diary');
                    }
                });
            }
        });
    },
}
</script>