<template>
    <v-app>
        <router-view :key="$route.path"></router-view>
        <AppFooter />
    </v-app>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue";

export default {
    name: "AppPortal",

    components: {
        AppFooter,
    },

    data: () => ({

    }),
};
</script>

<style>

.main-bg {
    background-size: cover;
    background-position: bottom left;
}

.login-btn {
    box-shadow: 3px 3px 1px #f6d8b2!important;
}
</style>