<template>
    <v-dialog v-model="value" persistent width="600">
        <v-card>
            <v-card-title>
                <span class="text-h6">{{ $t('info.diary.teacher_invite_code') }}</span>
                <span class="text-h6 ml-2" style="color:#D84315">
                    {{ homeworkName }}
                </span>
                <v-spacer></v-spacer>
                <v-btn icon @click="show = false"
                    class="normal-case">
                    <v-icon>mdi-close-thick</v-icon>
                </v-btn>
            </v-card-title>
            <v-tabs v-model="tab" centered>
                <v-tabs-slider color="deep-orange arken-1"></v-tabs-slider>
                <v-tab href="#tabCode" class="grey--text text--darken-2 subtitle-1">
                    {{ $t('info.diary.share_code') }}
                </v-tab>
                <v-tab href="#tabMail" class="grey--text text--darken-2 subtitle-1">
                    {{ $t('info.diary.mail_inivte') }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="mt-2">
                <v-tab-item value="tabCode">
                    <v-card-text class="text-center mx-4" color="grey darken-1">
                        {{ $t('info.diary.share_instruction_1') }}
                        <a href="https://ws.mangachat.co">https://ws.mangachat.co</a>
                        {{ $t('info.diary.share_instruction_2') }}
                    </v-card-text>
                    <v-card-subtitle class="mt-4 text-h4 text-center" color="grey darken-2">
                        {{ teacherCode }}
                    </v-card-subtitle>
                    <v-card-actions>
                        <v-btn class="mx-auto mb-4 normal-case" dark
                            color="deep-orange accent-2" :loading="fileLoading"
                            :disabled="fileLoading" @click="downloadInvitation">
                            {{ fileDownloaded ? $t('info.diary.button.done') :
                                $t('info.diary.button.download_invitation')
                            }}
                        </v-btn>
                    </v-card-actions>
                </v-tab-item>
                <v-tab-item value="tabMail">
                    <v-card-text class="text-center mx-4" color="grey darken-1">
                        {{ $t('info.diary.mail_inivtation_description') }}
                    </v-card-text>
                    <v-form ref="emailForm">
                        <v-text-field :label="$t('info.diary.mail_address')" outlined class="mx-6" v-model="email"
                            :rules="emailRules">
                        </v-text-field>
                    </v-form>
                    <v-card-actions>
                        <v-btn class="mx-auto mb-4 normal-case" dark color="deep-orange accent-2" :loading="sendingMail"
                            :disabled="sendingMail" @click="sendInvitation">
                            {{ $t('info.diary.button.invite') }}
                        </v-btn>
                    </v-card-actions>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>

<script>
import fileDownload from "js-file-download";
import { diaryServices } from "@/services/diary.js";

import { mapState } from "vuex";
export default {
    name: "teacherInvitationDialog",
    components: {},
    props: {
        value: Boolean,
    },
    data() {
        return {
            homeworkId: '',
            title: '',
            teacherCode: '',
            fileLoading: false,
            fileDownloaded: false,
            tab: 'tabCode',
            sendingMail: false,
            // mailSend: false,
            email: '',
            emailRules: [(v) => !!v || this.$t('info.email_rule'),
            (v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('info.email_incorrect')],
        };
    },
    async created() {
        this.homeworkId = this.$route.params.id;
        const result = await diaryServices.getHomeworkTeacherInviteCode(this.homeworkId);
        this.teacherCode = result.data.teacher_invite_code;
        this.value = false;
    },
    watch: {
    },
    computed: {
        ...mapState(["homeworkName"]),
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
    },
    mounted() {

    },
    methods: {
        //...mapGetters(["getHomeworkName"]),
        async downloadInvitation() {
            if (this.fileDownloaded) {
                this.fileDownloaded = false;
                this.show = false;
            } else {
                // get invitation file
                try {
                    this.fileLoading = true;
                    const result = await diaryServices.getHomeworkTeacherInvitation(this.homeworkId);
                    if (result.status == 200) {
                        const blobFile = await result.blob();
                        const fileName = `${this.homeworkName}${this.$t('info.invitation_file_name')}.docx`;
                        fileDownload(blobFile, fileName);
                        this.fileDownloaded = true;
                    }
                    this.fileLoading = false;
                } catch (error) {
                    console.log(error);
                }
            }
        },
        async sendInvitation() {
            if (this.$refs.emailForm.validate()) {
                this.sendingMail = true;
                const payload = {
                    recipient: this.email
                }
                await diaryServices.sendHomeworkTeacherInvitation(this.homeworkId, payload);
                this.sendingMail = false;
                this.email = '';
                this.$toastr.Add({
                    msg: this.$t('info.diary.mail_inivtation_success'),
                    timeout: 3000
                });
                this.show = false;
            }
        },
    },
};
</script>
<style scoped></style>
