import servicesUtils from '../utils/service'

export const userPayServices = {
    async getMyOwnUserChargePlanList(query) {
        try {
            const url = `${servicesUtils.resourceUrl('getMyOwnChargePlanList')}`
                + '?'
                + servicesUtils.queryParams(query);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getMyOwnUserChargePlans, err: ${err}`)
            throw err
        }
    },
    async sold(id) {
        try {
            const url = `${servicesUtils.resourceUrl('soldUserChargePlan')}`.replace(':id', id)
            const data = await servicesUtils.post(url)
            return data
        } catch (err) {
            console.log(`failed to soldUserChargePlan, err: ${err}`)
            throw err
        }
    },
    async release(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('releaseUserChargePlan')}`.replace(':id', id)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to releaseUserChargePlan, err: ${err}`)
            throw err
        }
    },
    async addComment(id, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('userChargePlanAddComment')}`.replace(':id', id)
            const data = await servicesUtils.post(url, payload)
            return data
        } catch (err) {
            console.log(`failed to addComment, err: ${err}`)
            throw err
        }
    },
    
    async setNewCode(id) {
        try {
            const url = `${servicesUtils.resourceUrl('userChargePlanSetNewCode')}`.replace(':id', id)
            const data = await servicesUtils.post(url)
            return data
        } catch (err) {
            console.log(`failed to setNewCode, err: ${err}`)
            throw err
        }
    },
    async renew(id) {
        try {
            const url = `${servicesUtils.resourceUrl('renewUserChargePlan')}`.replace(':id', id)
            const data = await servicesUtils.post(url)
            return data
        } catch (err) {
            console.log(`failed to renew, err: ${err}`)
            throw err
        }
    },
    
    async getUnsoldUserChargePlanCount() {
        try {
            const url = `${servicesUtils.resourceUrl('getUnsoldUserChargePlanCount')}`;
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getUnsoldUserChargePlanCount, err: ${err}`)
            throw err
        }
    },

    async getHomeworkNonUseUserChargePlanCount(payload) {
        try {
            console.log()
            const url = `${servicesUtils.resourceUrl('getHomeworkNonUseUserChargePlanCount')}` + '?' + servicesUtils.queryParams(payload);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getHomeworkNonUseUserChargePlanCount, err: ${err}`)
            throw err
        }
    },
    
}