<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-3" large></v-breadcrumbs>
        <div class="main-content mx-auto">
            <div class="text-h6">{{ $t('contest_homework_edit.info') }}</div>
            <v-divider class="my-2"></v-divider>

            <div>
                <div class="text-body-1">{{ $t('contest_homework_edit.name') }}</div>
                <v-text-field outlined dense single-line disabled v-model="teamName"></v-text-field>
            </div>
            <div class="mb-2">
                <div class="text-body-1">{{ $t('contest_homework_edit.group') }}</div>
                <v-radio-group class="mt-0" dense hide-details="auto"
                    v-model="ageGroup" disabled row>
                    <v-radio v-for="item in ageGroups" :key="item.value" :label="item.title"
                        :value="item.value" hide-details="auto" color="#DE714B"></v-radio>
                </v-radio-group>
            </div>
            <div class="mb-2">
                <div class="text-body-1">{{ $t('contest_homework_edit.school') }}</div>
                <v-text-field outlined dense single-line disabled v-model="school"></v-text-field>
            </div>
            <div class="mb-2">
                <div class="text-body-1"></div>
            </div>
            <div class="text-body-1">{{ $t('contest_homework_edit.number') }}</div>
            <v-text-field outlined dense single-line disabled v-model="members"></v-text-field>
            <div class="text-h6">{{$t('contest_homework_edit.setting') }}</div>
            <v-divider class="my-2"></v-divider>

            <v-form ref="infoForm" v-model="valid" lazy-validation>
                <div class="mb-2">
                    <div class="text-body-1 my-2">{{ $t('contest_homework_edit.journal_name') }}</div>
                    <v-text-field :rules="homeworkNameRules"
                        outlined dense single-line v-model="homeworkName"></v-text-field>
                </div>
                <div class="mb-2">
                    <div class="text-body-1 my-2">{{ $t('contest_homework_edit.description') }}</div>
                    <v-text-field :rules="homeworkDescriptionRules"
                        outlined dense single-line v-model="homeworkDescription"></v-text-field>
                </div>
                <div class="mb-5">
                    <div class="text-body-1 my-2">{{ $t('contest_homework_edit.cover') }}</div>
                    <v-item-group class="d-flex" mandatory v-model="colorInput">
                        <v-item class="mr-2" v-for="item in colorList" :key="item.value" :value="item.value"
                            v-slot="{ active, toggle }">
                            <v-card :color="item.code" class="d-flex align-center text-center" dark height="30" width="30"
                                @click="toggle">
                                <v-scroll-y-transition>
                                    <v-icon v-if="active" class="mx-auto" label>mdi-check</v-icon>
                                </v-scroll-y-transition>
                            </v-card>
                        </v-item>
                    </v-item-group>
                </div>
                <div class="text-h6">{{ $t('contest_homework_edit.competitions_setting') }}</div>
                <v-divider class="my-2"></v-divider>
                <div class="mb-2">
                    <div class="text-body-1">{{ $t('contest_homework_edit.start_date') }}</div>
                    <div class="text-body-2" style="color: #828282;">
                        {{ getDateEditDetail() }}
                    </div>
                    <div class="text-body-2" style="color: #DE714B;">
                        {{ `${dateUpdateString}` }}
                    </div>
                    <v-menu
                        ref="dateMenu"
                        v-model="dateMenu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        :disabled="pickDateDisabled">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                :label="$t('edit.select_date')"
                                single-line
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                prepend-icon="mdi-calendar"
                                style="width: 200px;"
                                :rules="dateRules"
                                :disabled="pickDateDisabled"></v-text-field>
                        </template>
                        <!-- <v-date-picker
                        v-model="date"
                        :max="contestStartDateMax"
                        :min="contestStartDateMin"
                        :year-format="date => getYear(new Date(date).getFullYear())"
                        :header-date-format="customFormatHeader"
                        :month-format="getMonthFormat"
                        locale="zh-TW"
                        @input="dateMenu = false"></v-date-picker> -->
                        <v-date-picker
                            v-model="date"
                            :max="contestStartDateMax"
                            :min="contestStartDateMin"
                            locale="zh-TW"
                            @input="dateMenu = false"></v-date-picker>
                    </v-menu>
                </div>
            </v-form>
            <div class="mb-2" v-if="showQuestpick">
                <div class="text-body-1">{{ $t('contest_homework_edit.topic_setting') }}</div>
                <v-radio-group class="mt-0" dense hide-details="auto"
                    v-model="questPickType" row :disabled="questPickDisabled">
                    <v-radio v-for="item in questPickTypes" :key="item.value" :label="item.title"
                        :value="item.value" hide-details="auto" color="#DE714B"></v-radio>
                </v-radio-group>
            </div>
            <v-divider class="my-5"></v-divider>
            <v-row class="my-5 mx-1">
                <v-spacer></v-spacer>
                <v-btn outlined color="primary" class="mr-3 normal-case" v-if="showCancelButton"
                    @click="$router.go(-1)">{{ $t('button.cancel') }}</v-btn>
                <v-btn color="primary" @click="updateInfo"
                    class="normal-case">{{ $t('contest_homework_edit.ok') }}</v-btn>
            </v-row>
        </div>
        <v-overlay :value="dataLoading">
            <v-progress-circular
                indeterminate
                size="64"></v-progress-circular>
        </v-overlay>
        <v-dialog>
            <v-dialog v-model="updateErrorDialog" width="600">
                <v-card>
                    <v-card-title>
                        {{ $t('contest_homework_edit.retrieval_error') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('contest_homework_edit.retrieval_error_message') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="goToDiary()"
                            class="normal-case">
                            {{ $t('contest_homework_edit.ok') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
    </v-container>
</template>

<script>
import { contestServices } from '@/services/contest.js';

export default {
    name: 'contestHomeworkEdit',
    props: {
        info: Object
    },
    data() {
        return {
            breadcrumbsItems: [],
            teamName: '',
            ageGroups: [
                { title: this.$t('contest_homework_edit.group_1'), value: 1 },
                { title: this.$t('contest_homework_edit.group_2'), value: 2 },
                { title: this.$t('contest_homework_edit.group_3'), value: 3 },
                { title: this.$t('contest_homework_edit.group_4'), value: 4 },
                { title: this.$t('contest_homework_edit.group_0'), value: 0 },
            ],
            ageGroup: 1,
            questPickTypes: [
                { title: this.$t('contest_homework_edit.select_type_1'), value: 1 },
                { title: this.$t('contest_homework_edit.select_type_2'), value: 2 },
                { title: this.$t('contest_homework_edit.select_type_3'), value: 3 },
            ],
            questPickType: 1,
            showQuestpick: true,
            originalQuestPickType: null,
            questPickDisabled: true,
            school: '',
            members: null,
            homeworkName: '',
            homeworkNameRules: [(v) => !!v || this.$t('post_revise.comment_required')],
            homeworkDescription: '',
            homeworkDescriptionRules: [(v) => !!v || this.$t('post_revise.comment_required')],
            date: '',
            dateMenu: false,
            dateRules: [(v) => !!v || this.$t('post_revise.comment_required')],
            colorInput: '',
            colorList: [
                {
                    code: 'red',
                    value: '1',
                }, {
                    code: 'orange',
                    value: '2',
                }, {
                    code: 'yellow darken-1',
                    value: '3',
                }, {
                    code: 'green',
                    value: '4',
                }, {
                    code: 'blue',
                    value: '5',
                }, {
                    code: 'blue darken-4',
                    value: '6',
                }, {
                    code: 'deep-purple ',
                    value: '7',
                }
            ],
            homeworkId: '',
            dataLoading: false,
            valid: true,
            pickDateDisabled: false,
            answerDays: '',
            contestStartDateMax: '',
            contestStartDateMin: '',
            showCancelButton: true,
            isNewHomework: false,
            updateErrorDialog: false,
            needToChooseQuest: false,
            contestFinalDate: '',
            dateUpdateString: '',
        }
    },
    async created() {
        this.breadcrumbsItems.push(
            {
                text: this.$t('index.title'),
                disabled: false,
                href: `/diary`,
            },
            {
                text: this.$t('contest_homework_edit.setting'),
                disabled: true,
            }
        );
        this.dataLoading = true;
        this.homeworkId = this.$route.params.id;
        let infoResult;
        if (this.$route.query.code) {
            this.showCancelButton = false;
            this.isNewHomework = true;
            this.needToChooseQuest = true;
            const payload = {
                code: this.$route.query.code,
            }
            infoResult = await contestServices.inviteCodeVerify(payload);
        } else {
            infoResult = await contestServices.getHomeworkInfo(this.homeworkId);
        }
        this.teamName = infoResult.data.team.name;
        this.ageGroup = infoResult.data.contest.age_group;
        this.school = infoResult.data.team.school;
        this.members = infoResult.data.team.count;
        if (infoResult.data.contest.ans_day_type == 2) {
            if (infoResult.data.team && infoResult.data.team.start_date) {
                this.date = infoResult.data.team.start_date;
                if (new Date(this.date) < new Date()) {
                    this.pickDateDisabled = true;
                } else {
                    if (infoResult.data.team.start_date_update_available) {
                        this.dateUpdateString = this.$t('contest_homework_edit.update_available');
                    } else {
                        this.dateUpdateString = this.$t('contest_homework_edit.update_unavalible');
                        this.pickDateDisabled = true;
                    }
                }
            }
            this.contestStartDateMax = infoResult.data.contest.start_date_max;
            this.contestStartDateMin = infoResult.data.contest.start_date_min;
            this.answerDays = infoResult.data.contest.ans_days;

        } else {
            this.date = infoResult.data.contest.start_date;
            this.pickDateDisabled = true;
        }
        this.originalQuestPickType = infoResult.data.team.quest_pick_type;
        if (infoResult.data.contest.quest_pick_type != 2) {
            this.showQuestpick = false;
        } else {
            this.showQuestpick = true;
            this.questPickType = infoResult.data.team.quest_pick_type || 1;

            if (infoResult.data.team && infoResult.data.team.start_date) {
                this.date = infoResult.data.team.start_date;
                if (new Date(this.date) < new Date()) {
                    this.questPickDisabled = true;
                } else {
                    this.questPickDisabled = false;
                }
            } else {
                this.questPickDisabled = false;
            }
        }

        if (!this.isNewHomework) {
            this.homeworkName = infoResult.data.homework.name;
            this.colorInput = infoResult.data.homework.cover_color;
            this.homeworkDescription = infoResult.data.homework.description;
        }
        this.dataLoading = false;
    },
    methods: {
        // getYear(year) {
        //     return year - 1910;
        // },
        // formateDate(date) {
        //     if (!date) return null
        //     const [year, month, day] = date.split('-')
        //     return `${year - 1911}年 ${month}月 ${day}日`
        // },
        // customFormatHeader(date) {
        //     const [year, month] = date.split('-')
        //     if (month) {
        //         return `${year - 1911}年 ${month}月`
        //     }
        //     else {
        //         return `${year - 1911}年`
        //     }
        // },
        // getMonthFormat(isoDate) {
        //     const [year, month] = isoDate.split('-')
        //     return `${year - 1911}年 ${month}月`
        // },
        async updateInfo() {
            if (this.$refs.infoForm.validate()) {
                this.dataLoading = true;
                const inviteCodePayload = {
                    invite_code: this.$route.query.code
                }
                if (this.isNewHomework) {
                    const dhId = await contestServices.takeContestBook(inviteCodePayload);
                    if (dhId.data.homework_id) {
                        this.homeworkId = dhId.data.homework_id;
                    } else {
                        this.updateErrorDialog = true;
                        return;
                    }
                }
                const payload = {
                    homework_name: this.homeworkName,
                    homework_description: this.homeworkDescription,
                    homework_cover_color: this.colorInput,
                    start_date: this.date,
                    quest_pick_type: this.questPickType,
                }
                const result = await contestServices.updateHomeworkInfo(this.homeworkId, payload);
                this.dataLoading = false;
                if (result.data.return_code == '11000') {
                    if ((this.needToChooseQuest || this.originalQuestPickType
                        != this.questPickType) && this.questPickType == 2) {
                        this.$router.push('/diary/homework/:id/questList/edit?new=1'.replace(':id', this.homeworkId));
                    } else {
                        this.$router.push('/diary/homework/:id/questList'.replace(':id', this.homeworkId));
                    }
                }
            }
        },
        goToDiary() {
            this.$router.push('/diary');
        },
        getDateEditDetail() {
            return this.$t('contest_homework_edit.edit_time_message')
            .replace('{days}', this.answerDays)
            .replace('{date}', this.contestStartDateMax);
        },
    },
    computed: {
        // dateFormatted() {
        //     return this.date ? this.formateDate(this.date) : '';
        // },
    },
}
</script>

<style scoped>
.main-content {
    max-width: 650px;
}
</style>