<template>
    <v-container>
        <v-row style="height: 20vh;"></v-row>
        <v-row justify="center" align="center">
            <v-img :src="loadingImage" contain style="height: 40vh;"></v-img>
        </v-row>
        <v-row justify="center" class="mt-10">
            <v-progress-linear :color="loadingColor" indeterminate height="15"
                style="border-radius: 10px; width: 75vw;"></v-progress-linear>
        </v-row>
    </v-container>
</template>

<script>
import { ebookServices } from '@/services/ebook.js';
import { getItem, setItem } from '@/utils/storageManager';

export default {
    name: "getEbook",
    components: {},
    data() {
        return {
            isStudioUser: false,
            loadingColor: 'amber darken-3',
            loadingImage: require(`@/assets/ebook/loading_console.svg`)
        };
    },
    async created() {
        
        const homeWorkId = this.$route.params.id;
        const studentId = this.$route.params.sid;
        const diaryId = this.$route.params.did;

        let ebookRes;
        if (diaryId) {
            ebookRes = await ebookServices.getStudentEBook(diaryId);
            //const userName = localStorage.getItem('userName');
            this.isStudioUser = true;
            this.loadingColor = '#F3C250';
            this.loadingImage = require(`@/assets/ebook/loading_studio.svg`)
        } else {
            ebookRes = await ebookServices.getEBook(homeWorkId, studentId);
        }
        if (ebookRes.data.length != 0) {
            let ebookStorage = getItem('ebookReady');
            if (ebookStorage == null) {
                ebookStorage = '{}';
            }
            let ebookStorageJSON = JSON.parse(ebookStorage);
            delete ebookStorageJSON[ebookRes.data.id];
            setItem('ebookReady', JSON.stringify(ebookStorageJSON));

            setTimeout(() => {
                this.$router.push(`/ebook/${ebookRes.data.id}/posts`);
            }, 2000);
        }
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    },
    methods: {

    },
};
</script>

<style scoped></style>