<template>
    <div>
        <Steps />
        <v-container class="mt-4">
            <div class="text-h6">{{ $t('book.content.title') }}</div>
            <p>{{ $t('book.content.click_block') }}</p>
            <v-row>
                <v-col v-for="(post) in postList" :key="post.id" cols="12" sm="6" md="4" lg="3" class="pa-5 text-center">
                    <div class="page-content px-5 py-4" @mouseover="post.hover = true" @mouseleave="post.hover = false">

                        <v-img v-if="post.thumbnailUrl" lazy-src="https://picsum.photos/id/40/10/6" :src="post.thumbnailUrl"
                            class="border post-img"></v-img>
                        <div v-else class="d-flex no-data justify-center align-center">
                            <div>
                                <v-progress-circular indeterminate color="amber"></v-progress-circular>
                                <div class="mt-1 body-2">
                                    {{ $t('book.content.processing') }}
                                </div>
                            </div>
                        </div>
                        <div class="mt-1">{{ getPostTypeName(post.type) }}</div>

                        <v-overlay v-if="post.thumbnailUrl" absolute opacity="0.8" :value="post.hover">
                            <v-btn outlined color="white" @click="openPreviewImgDiary(post.id)">
                                <v-icon class="mr-1">mdi-eye</v-icon> {{ $t('book.content.view') }}
                            </v-btn>
                            <br />
                            <v-btn class="mt-2" outlined color="white" @click="goEdit(post.id)">
                                <v-icon class="mr-1">mdi-note-edit-outline</v-icon> {{ $t('book.content.edit') }}
                            </v-btn>
                        </v-overlay>
                    </div>
                </v-col>
            </v-row>
            <v-divider class="mt-5"></v-divider>
            <div class="d-flex my-5">
                <v-btn class="normal-case" outlined depressed @click="goPreStep()">
                    {{ $t('button.previous_step') }}</v-btn>
                <v-btn color="#DE714B" class="ml-auto white--text normal-case" depressed @click="goNextStep()"
                    :disabled="!isReadyToPrint">
                    {{ $t('button.next_step') }}</v-btn>
            </div>
        </v-container>
        <v-dialog v-model="showPreviewImgDialog" max-width="600px">
            <v-card class="pa-5">
                <v-img lazy-src="https://picsum.photos/id/40/10/6" :src="previewImg.src" class="border"></v-img>
                <v-card-actions class="justify-end mt-4">
                    <v-btn class="normal-case" text @click="showPreviewImgDialog = false">{{ $t('button.close') }}</v-btn>
                    <v-btn class="normal-case" color="primary" depressed @click="goEdit(previewImg.id)">{{ $t('book.content.edit') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ebookServices } from "@/services/ebook.js";
import Steps from "@/components/ebook/Steps.vue";
import { getItem, setItem } from '@/utils/storageManager';

export default {
    name: 'pageList',
    components: {
        Steps
    },
    data() {
        return {
            diaryPrintId: '',
            postList: [],
            showPreviewImgDialog: false,
            previewImg: {
                src: '',
                id: '',
            },
            isReadyToPrint: false,
        }
    },
    watch: {

    },
    async created() {
        this.diaryPrintId = this.$route.params.diaryPrintId;
        await this.getPageList();
    },
    methods: {
        async getPageList() {
            let hasGeneratring = false;
            let isCoverReady = false;
            const postRes = await ebookServices.getEBookPageList(this.diaryPrintId, {});
            
            postRes.data.forEach(page => {
                if (page.thumbnail_url == null) {
                    hasGeneratring = true;
                }
               
                let post = this.postList.find(post => post.id == page.id);
                
                if (post == null) {
                    this.postList.push({
                        'id': page.id,
                        'postId': page.post_id,
                        'thumbnailUrl': page.thumbnail_url ? `${page.thumbnail_url}?${this._uuid()}` : '',
                        'type': page.type,
                        'hover': false,
                    });
                } else if (post.thumbnailUrl == '' && page.thumbnail_url != null){
                    post.thumbnailUrl = page.thumbnail_url ? `${page.thumbnail_url}?${this._uuid()}` : '';
                    post.type = page.type;
                }
                if (page.type == 1 && page.thumbnail_url) {
                    isCoverReady = true;
                }
            });
            if (hasGeneratring) {
                setTimeout(() => {
                    this.getPageList();
                }, 5000);
            } else if (isCoverReady){
                this.isReadyToPrint = true;

                let ebookStorage = getItem('ebookReady');
                if (ebookStorage == null) {
                    ebookStorage = '{}';
                }
                let ebookStorageJSON = JSON.parse(ebookStorage);
                ebookStorageJSON[this.diaryPrintId] = true;
                setItem('ebookReady', JSON.stringify(ebookStorageJSON));
                
            }
        },
        getPostTypeName(type) {
            if (type == 1) {
                return this.$t('book.content.cover');
            } else if (type == 3) {
                return this.$t('book.content.author');
            } else if (type == 4) {
                return this.$t('book.content.epilogue');
            } else {
                return this.$t('book.basic_info.pages');
            }
        },
        openPreviewImgDiary(id) {
            let page = this.postList.find(post => post.id == id);
            this.previewImg.id = id;
            this.previewImg.src = page.thumbnailUrl.replace('thumbnail', 'output');
            this.showPreviewImgDialog = true;
        },
        goEdit(id) {
            this.$router.push(`/ebook/${this.diaryPrintId}/edit?qpage_id=${id}`);
        },
        _uuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) { var r = Math.random() * 16 | 0, v = c == 'x' ? r : r & 0x3 | 0x8; return v.toString(16); });
        },
        goPreStep() {
            this.$router.push(`/ebook/${this.diaryPrintId}/basicinfo`);
        },
        goNextStep() {
            if (this.isReadyToPrint) {
                this.$router.push(`/ebook/${this.diaryPrintId}/download`);
            }
                
        },
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    }
}
</script>
<style scoped>
.page-content {
    background-color: #eee;
    border: 1px solid #ddd;
    position: relative;
}

.page-content>.no-data, .page-content > .post-img {
    aspect-ratio: 558/394;
    background-color: #fff;
}
</style>
