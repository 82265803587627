<template>
    <v-app>
        <v-app-bar app elevate-on-scroll flat class="px-12">
            <v-toolbar-title>
                <v-btn text plain class="text-h6 pa-0 ma-0 normal-case font-weight-light"
                    color="grey darken-2"
                    @click=open(0)>{{ $t('index.mangax') }}</v-btn>
            </v-toolbar-title>
            <v-spacer />
            <div class="d-none d-md-flex">
                <v-menu offset-y v-model="mangachatMenu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            small
                            text
                            v-bind="attrs"
                            v-on="on"
                            color="grey darken-1"
                            class="normal-case">
                            <v-icon color="grey darken-1"
                                class="mr-2">mdi-view-carousel</v-icon>
                            {{ $t('index.mangachat.title') }}
                            <v-icon>{{ mangachatMenu ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in mangachatMenuItems"
                            :key="index">
                            <v-btn block text @click="openURL(item.url)"
                                color="grey darken-1"
                                class="normal-case"
                                :style="{ justifyContent: 'flex-start' }">
                                <v-icon left>{{ item.icon }}</v-icon>
                                {{ item.title }}</v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y v-model="teacherDashboardMenu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            small
                            text
                            v-bind="attrs"
                            v-on="on"
                            color="grey darken-1"
                            class="normal-case">
                            <v-icon class="mr-2">mdi-apps</v-icon>
                            {{ $t('index.teacher.title') }}
                            <v-icon>{{ teacherDashboardMenu ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in teacherDashboardMenuItems"
                            :key="index">
                            <v-btn block text @click="openURL(item.url)"
                                color="grey darken-1"
                                class="normal-case"
                                :style="{ justifyContent: 'flex-start' }">
                                <v-icon left>{{ item.icon }}</v-icon>
                                {{ item.title }}</v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-menu offset-y v-model="languageMenu">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            small
                            text
                            v-bind="attrs"
                            v-on="on"
                            color="grey darken-1"
                            class="normal-case">
                            <v-icon class="mr-2">mdi-view-carousel</v-icon>
                            {{ language }}
                            <v-icon>{{ languageMenu ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in languageMenuItems"
                            :key="index">
                            <v-btn
                                block text @click="changeLanguage(item.locale)"
                                color="grey darken-1"
                                class="normal-case"
                                :style="{ justifyContent: 'flex-start' }">
                                {{ item.title }}</v-btn>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <!-- Menu icon for mobile view -->
            <div class="d-md-none">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                            <v-icon>mdi-menu</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-menu offset-y v-model="mobileMangachatMenu">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        color="grey darken-1"
                                        class="normal-case">
                                        <v-icon color="grey darken-1"
                                            class="mr-2">mdi-view-carousel</v-icon>
                                        {{ $t('index.mangachat.title') }}
                                        <v-icon>{{ maobileMngachatMenu ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(item, index) in mangachatMenuItems"
                                        :key="index">
                                        <v-btn block text @click="openURL(item.url)"
                                            color="grey darken-1"
                                            class="normal-case"
                                            :style="{ justifyContent: 'flex-start' }">
                                            <v-icon left>{{ item.icon }}</v-icon>
                                            {{ item.title }}</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item>
                        <v-list-item>
                            <v-menu offset-y v-model="mobileTeacherDashboardMenu">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        color="grey darken-1"
                                        class="normal-case">
                                        <v-icon class="mr-2">mdi-apps</v-icon>
                                        {{ $t('index.teacher.title') }}
                                        <v-icon>{{ mobileTeacherDashboardMenu ? 'mdi-menu-up' : 'mdi-menu-down'
                                            }}</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(item, index) in teacherDashboardMenuItems"
                                        :key="index">
                                        <v-btn block text @click="openURL(item.url)"
                                            color="grey darken-1"
                                            class="normal-case"
                                            :style="{ justifyContent: 'flex-start' }">
                                            <v-icon left>{{ item.icon }}</v-icon>
                                            {{ item.title }}</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item>
                        <v-list-item>
                            <v-menu offset-y v-model="mobileLanguageMenu">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        text
                                        v-bind="attrs"
                                        v-on="on"
                                        color="grey darken-1"
                                        class="normal-case">
                                        <v-icon class="mr-2">mdi-view-carousel</v-icon>
                                        {{ language }}
                                        <v-icon>{{ mobileLanguageMenu ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="(item, index) in languageMenuItems"
                                        :key="index">
                                        <v-btn
                                            block text @click="changeLanguage(item.locale)"
                                            color="grey darken-1"
                                            class="normal-case"
                                            :style="{ justifyContent: 'flex-start' }">
                                            {{ item.title }}</v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>
        <div class="parallax-container">
            <v-parallax :src="image" height="480">
                <div class="overlay"></div>
                <v-layout justify-center column fill-height>
                    <v-container class="position-relative">
                        <v-row justify="center">
                            <v-col cols="12" sm="8" class="text-center">
                                <div class="text-h3 font-weight-bold my-4 text--white">
                                    {{ $t('index.teacher.title') }}
                                </div>
                                <div class="text-body-1 text--white">{{ $t('index.teacher.description') }}</div>
                            </v-col>
                        </v-row></v-container>
                </v-layout>
            </v-parallax>
        </div>
        <v-main class="main">
            <v-card class="mx-auto px-30 raised" style="width: 85vw;" rounded-lg elevation="12">
                <div style="height: 32px;"></div>
                <div class="custom-text-block text-subtitle-1 text-center font-weight-thin wide-margin" >
                    {{ $t('index.desc') }}
                </div>
                <div style="height: 32px;"></div>
                <v-card-actions>
                    <v-spacer />
                    <v-btn class="mb-10 normal-case" color="error" dark rounded :to="{ name: 'LoginCheckIndex' }">
                        {{ $t('index.btn.login') }}
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-main>
        <v-footer padless color="white">
            <v-col class="text-right font-weight-light text-body-2" cols="12" color="#444444">
                © 
                <a href="https://www.mangax.co/" target="_blank" rel="noopener noreferrer"
                style="color:#ff423d; text-decoration: none;">{{
                        $t('index.company_full')
                    }}</a>
                {{ new Date().getFullYear() + " " + $t('index.copyright') }}
            </v-col>
        </v-footer>
    </v-app>
</template>

<script>
import { setItem } from '@/utils/storageManager';

export default {
    name: "Index",
    data() {
        return {
            content: "",
            news: [],
            signUpNum: 0,
            practiceNum: 0,
            firstRoundNum: 0,
            compositionNum: 0,
            urls: {
                mangax: "https://www.mangax.co/",

            },
            url: [
                "https://www.mangax.co/",
            ],
            mangachatMenu: false,
            mobileMangachatMenu: false,
            mangachatMenuItems: [
                {
                    title: this.$t('index.mangachat.login'),
                    icon: "mdi-layers",
                    url: "https://studio.mangachat.co"
                },
                {
                    title: this.$t('index.mangachat.info'),
                    icon: "mdi-wrench",
                    url: "https://doc.mangachat.co"
                }
            ],
            teacherDashboardMenu: false,
            mobileTeacherDashboardMenu: false,
            teacherDashboardMenuItems: [
                {
                    title: this.$t('index.teacher.login'),
                    icon: "mdi-layers",
                    url: "https://ws.mangachat.co/login/check"
                }
            ],
            languageMenu: false,
            mobileLanguageMenu: false,
            languageMenuItems: [],
            language: '',
            image: require('@/assets/bg9.jpg')
        };
    },
    async created() {
        const currentLocale = this.$i18n.locale;
        console.log('currentLocale:', currentLocale)
        if (currentLocale === 'en') {
            this.languageMenuItems.push({
                title: "正體中文",
                locale: "zh",
            });
            this.language = 'English';
        } else {
            this.languageMenuItems.push({
                title: "English",
                locale: "en",
            });
            this.language = '正體中文';
        }
    },
    methods: {
        open(index) {
            if (index < this.url.length) {
                window.open(this.url[index], '_blank');
            }
        },
        openURL(url) {
            if (url) {
                window.open(url, '_blank');
            }
        },
        changeLanguage(locale) {
            setItem('locale', locale);
            window.location.reload(true);
        },
    },
};
</script>

<style scoped>
.parallax-container {
    position: relative;
}

.overlay-container {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(60deg, rgba(239, 154, 154, 0.56), rgba(239, 83, 80, 0.95));
    pointer-events: none;
    z-index: 0;
}

.position-relative {
    position: relative;
    z-index: 1;
}

.main {
    background: #FFFFFF;
    position: relative;
    z-index: 10;
}

.custom-text-block {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 96px;
    padding-right: 96px;
    line-height: 2rem;
  color: #333;
}

.raised {
    margin-top: -150px;
}

.wide-margin {
    margin-left: 32px;
    margin-right: 32px;
}
</style>