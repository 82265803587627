<template>
    <v-container fluid>
        <v-card flat>
            <v-card-text>
                <v-btn text class="pl-0 basic-text normal-case"
                    @click="$router.go(-1)">
                    <v-icon class="mr-2">mdi-arrow-left-thick</v-icon>
                    {{ $t('post_revise.back') }}
                </v-btn>
            </v-card-text>
            <v-card-text class="d-flex basic-text">
                {{ $t('post_revise.mode') + studentName }}
                <v-spacer></v-spacer>
                <v-btn v-if="postSendBacked" color="error" depressed
                    class="normal-case">
                    {{ $t('post_revise.sendBacked') }}
                </v-btn>
                <v-btn v-else outlined class="basic-text normal-case" small @click="sendBackPost">
                    <v-icon>mdi-swap-horizontal</v-icon>
                    {{ $t('post_revise.send_back') }}
                </v-btn>
            </v-card-text>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="3" class="pr-0 mr-0">
                        <v-card outlined class="square-corners fill-height">
                            <v-card-text>
                                {{ $t('post_revise.select') }}
                            </v-card-text>

                            <v-card v-for="item in journalBlocks"
                                :key="item.id"
                                outlined
                                class="square-corners custom-card"
                                @click="frameSelected(item)">
                                <v-card-text v-if="item.type == 'full'">
                                    <v-icon x-large style="width: 100px;">mdi-file-document-outline</v-icon>
                                    <span class="my-auto mx-1">
                                        {{ item.title }}
                                    </span>
                                </v-card-text>
                                <v-card-text v-else class="d-flex">
                                    <v-img :src="item.image"
                                        max-width="100"></v-img>
                                    <span class="my-auto mx-2">
                                        {{ item.title }}
                                    </span>
                                </v-card-text>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="8" lg="9" class="pl-0 ml-0">
                        <v-card outlined class="square-corners fill-height">
                            <v-card-text>
                                {{ currentBlockTitle }}
                            </v-card-text>
                            <v-card elevation="0" color="#F9F2F1" class="mx-4 my-2">
                                <div v-if="currentBlock.type == 'full'">
                                    <v-card-text class="text--primary">
                                        <span
                                            class="font-weight-medium subtitle-1 deep-orange--text text--darken-1 mr-1 my-1">{{
                                                currentBlock.journalTitle }}</span>
                                        <span class="text-decoration-underline grey--text mr-1 my-1">
                                            {{ currentBlock.location }}
                                        </span>
                                        <span class="text-decoration-underline grey--text my-1">
                                            {{ currentBlock.happen_time }}
                                        </span>
                                        <ol class="pl-3 mx-2 my-1">
                                            <li v-for="msgItem in currentBlock.msgs" :key="msgItem.id">
                                                {{ msgItem.message }}
                                            </li>
                                        </ol>
                                        <div class="text-small mb-2" style="text-decoration: underline;">{{ $t('post.score')
                                        }}{{ currentBlock.score }}</div>
                                    </v-card-text>
                                </div>
                                <div v-else>
                                    <v-card-text class="pl-3 mx-2 my-5 py-5 text-body-1"> {{ currentBlock.message
                                    }}</v-card-text>
                                </div>
                            </v-card>
                            <div v-if="comments.length == 0" class="my-4 text-center">增加些評語吧～～</div>
                            <div v-else>
                                <v-card-text v-for="item in comments"
                                    :key="item.id">

                                    <v-sheet color="#EEEEEE" rounded>
                                        <v-row class="mx-2 my-auto py-1">
                                            <div
                                                :class="getIconClass(item.type)">
                                                <v-icon v-if="item.type == 4"
                                                    color="black">{{ getIconName(item.type) }}</v-icon>
                                                <v-icon v-else color="white">{{ getIconName(item.type) }}</v-icon>
                                            </div>
                                            <span class="my-auto text-body-1">{{ item.comment }}</span>
                                            <v-spacer></v-spacer>
                                            <v-menu offset-y bottom left>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon class="my-auto normal-case" v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item>
                                                        <v-btn text blocked class="normal-case"
                                                            @click="openEditCommentDialog(item)">
                                                            <v-list-item-title>{{
                                                                $t('button.edit') }}
                                                            </v-list-item-title>
                                                        </v-btn>
                                                    </v-list-item>
                                                    <v-list-item>
                                                        <v-btn text blocked class="normal-case"
                                                            @click="openDeleteCommentDialog(item)">
                                                            <v-list-item-title>{{
                                                                $t('post.delete') }}
                                                            </v-list-item-title>
                                                        </v-btn>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </v-row>
                                    </v-sheet>
                                    <v-row class="mx-2 mt-2">
                                        <v-spacer></v-spacer>
                                        <span>{{ item.create_date }}</span>
                                    </v-row>
                                </v-card-text>
                            </div>
                            <v-divider class="mx-2"></v-divider>
                            <v-radio-group
                                v-model="selectedCommentType"
                                row mandatory
                                class="mx-5"
                                @change="getCommentLibrary">
                                <v-radio v-for="item in commentOptions"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.value"
                                    hide-details
                                    color="#F99015">
                                </v-radio>
                            </v-radio-group>
                            <v-form ref="commentForm">
                                <v-row class="mx-4 mb-0 pb-0">
                                    <v-text-field
                                        :label="$t('post_revise.comment_input_label')"
                                        v-model="comment"
                                        outlined dense single-line
                                        class="mr-5" :rules="commentRule"
                                        @input="commentEditChanged">
                                    </v-text-field>
                                    <v-btn color="#DE714B" class="white--text normal-case"
                                        @click="addComment">
                                        {{ $t('quest.edit.new') }}
                                    </v-btn>
                                </v-row>
                            </v-form>
                            <v-btn text class="mt-0 pt-0 normal-case" dense color="#1D6AA7"
                                :disabled="addCommentDisabled"
                                @click="addCommentLibrary">
                                {{ $t('post_revise.add_comment_library') }}
                            </v-btn>

                            <v-row class="body-2 mx-5 my-2">
                                <span class="text-body-1 my-auto">{{ $t('post_revise.comment_database') }}</span>
                                <v-spacer></v-spacer>
                                <v-btn v-if="editCommentLibrary" outlined
                                    class="normal-case"
                                    @click="editCommentLibrary = false">
                                    {{ $t('post_revise.edit_finish') }}
                                </v-btn>
                                <v-menu offset-y bottom left v-else>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon class="my-auto normal-case" v-bind="attrs" v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <v-btn text blocked class="normal-case"
                                                @click="enableEditCommentLibrary">
                                                <v-list-item-title>{{
                                                    $t('button.edit') }}
                                                </v-list-item-title>
                                            </v-btn>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-row>
                            <v-sheet class="mt-1">
                                <div class="pa-4 comment-data-area">
                                    <v-chip-group
                                        column v-if="editCommentLibrary">
                                        <div v-for="(commentData, ind) in commentDataList" :key="`strategy${ind}`">
                                            <v-chip color="#E0E0E0" v-if="commentData.is_default" :ripple="false">
                                                {{ '#' + commentData.comment }}
                                            </v-chip>
                                            <v-chip v-else close
                                                color="red"
                                                text-color="white"
                                                @click="openRemoveCommentLibrary(commentData)">
                                                {{ commentData.comment }}
                                            </v-chip>
                                        </div>
                                    </v-chip-group>
                                    <v-chip-group
                                        column v-else>
                                        <v-chip color="#E0E0E0" @click="commentDataSelected(commentData.comment)"
                                            v-for="(commentData, ind) in commentDataList" :key="`strategy${ind}`">
                                            {{ '#' + commentData.comment }}
                                        </v-chip>
                                    </v-chip-group>
                                </div>
                            </v-sheet>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-dialog v-model="editCommentDialog" persisted width="600">
                <v-card>
                    <v-card-title>
                        {{ $t('post_revise.edit_comment.title') }}
                    </v-card-title>
                    <v-card-text>
                        <v-radio-group
                            v-model="selectedCommentTypeEdit"
                            row mandatory
                            class="mx-5">
                            <v-radio v-for="item in commentOptionsEdit"
                                :key="item.id"
                                :label="item.label"
                                :value="item.value"
                                hide-details
                                color="#F99015">
                            </v-radio>
                        </v-radio-group>
                    </v-card-text>
                    <v-card-text>
                        <v-form ref="commentEditForm">
                            <v-text-field
                                :label="$t('post_revise.comment_input_label')"
                                v-model="commentEdit"
                                outlined dense single-line
                                class="mr-5" :rules="commentRule">
                            </v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-divider class="mb-4"></v-divider>
                    <v-card-actions>
                        <v-row class="mb-2">
                            <v-spacer></v-spacer>
                            <v-btn text @click="editCommentDialog = false"
                                class="mx-2 normal-case">
                                {{ $t('button.cancel') }}
                            </v-btn>
                            <v-btn color="#DE714B" class="white--text mx-2 normal-case"
                                @click="editComment">
                                {{ $t('post_revise.edit_comment.save') }}
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="deletCommentDialog" persisted width="600">
                <v-card class="pt-1">
                    <v-card elevation="0" color="#F9D7DA" class="mx-5 my-4">
                        <v-card-text class="text-body-1">{{ deleteCommentMsg }}</v-card-text>
                    </v-card>
                    <v-card-text class="text-body-1">{{ $t('post_revise.delete_comment_message') }}</v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-row class="my-2">
                            <v-spacer></v-spacer>
                            <v-btn text @click="deletCommentDialog = false"
                                class="mx-2 normal-case">
                                {{ $t('button.cancel') }}
                            </v-btn>
                            <v-btn color="#DE714B" class="white--text mx-2 normal-case"
                                @click="deleteComment">
                                {{ $t('button.confirm') }}
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="deletLibraryCommentDialog" persisted width="600">
                <v-card class="pt-1">
                    <v-card-text class="text-body-1 my-2">{{ $t('post_revise.delete_library_comment_message')
                    }}</v-card-text>
                    <v-card elevation="0" color="#F9D7DA" class="mx-5 my-2">
                        <v-card-text class="text-body-1">{{ deleteCommentLibraryMsg }}</v-card-text>
                    </v-card>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-row class="my-2">
                            <v-spacer></v-spacer>
                            <v-btn text @click="deletLibraryCommentDialog = false"
                                class="mx-2 normal-case">
                                {{ $t('button.cancel') }}
                            </v-btn>
                            <v-btn color="#DE714B" class="white--text mx-2 normal-case"
                                @click="deleteLibraryComment">
                                {{ $t('button.confirm') }}
                            </v-btn>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-overlay :value="contentLoading">
                <v-progress-circular
                    indeterminate
                    size="64"></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-container>
</template>

<script>
import { diaryPostServices } from '@/services/diaryPost.js';
import { diaryPostReviseServices } from '@/services/diaryPostRevise.js';

export default {
    name: 'postRevise',
    data() {
        return {
            journalBlocks: [{
                id: '0',
                type: 'full',
                title: this.$t('post_revise.whole_post'),
                location: '',
                happen_time: '',
                journalTitle: '',
                score: 100,
                msgs: [],
            }],
            commentOptions: [],
            fullCommentOptions: [
                {
                    id: 1,
                    value: 1,
                    label: this.$t('post_revise.comment_type.error')
                },
                {
                    id: 2,
                    value: 2,
                    label: this.$t('post_revise.comment_type.advice')
                },
                {
                    id: 3,
                    value: 3,
                    label: this.$t('post_revise.comment_type.praise')
                },
                {
                    id: 4,
                    value: 4,
                    label: this.$t('post_revise.comment_type.other')
                },
            ],
            blockCommentOptions: [
                {
                    id: 1,
                    value: 1,
                    label: this.$t('post_revise.comment_type.error')
                },
                {
                    id: 2,
                    value: 2,
                    label: this.$t('post_revise.comment_type.advice')
                },
                {
                    id: 4,
                    value: 4,
                    label: this.$t('post_revise.comment_type.other')
                },
            ],
            selectedCommentType: 4,
            comment: '',
            commentRule: [
                v => !!v || this.$t('post_revise.comment_required'),
            ],
            addCommentDisabled: true,
            commentDataList: [],
            comments: [],
            postId: '',
            currentBlockTitle: '',
            currentBlock: {},
            contentLoading: false,
            editCommentDialog: false,
            selectedCommentTypeEdit: null,
            commentOptionsEdit: [],
            commentEdit: '',
            editCommentId: '',
            deletCommentDialog: false,
            deleteCommentMsg: '',
            deleteCommentId: '',
            editCommentLibrary: false,
            deletLibraryCommentDialog: false,
            deleteCommentLibraryId: '',
            deleteCommentLibraryMsg: '',
            postSendBacked: false,
            studentName: '',
        }
    },
    async created() {
        this.postId = this.$route.params.id;
        this.commentOptions = this.fullCommentOptions;
        this.dataLoading = true;
        await this.fetchPostDetail();
        await this.fetchComments();
        await this.getCommentLibrary();
        this.dataLoading = false;
    },
    methods: {
        async fetchPostDetail() {
            const res = await diaryPostServices.getHomeworkPost(this.postId);
            let order = 1;
            for (let msg of res.data.post.msgs) {
                this.journalBlocks.push({
                    id: msg.id,
                    type: 'block',
                    title: `${this.$t('post_revise.block')} ${order++}`,
                    image: msg.img_url,
                    message: msg.message
                })
                this.journalBlocks[0].msgs.push({
                    id: msg.id,
                    message: msg.message
                })
            }
            this.journalBlocks[0].journalTitle = res.data.post.title;
            this.journalBlocks[0].location = res.data.post.location;
            this.journalBlocks[0].happen_time = res.data.post.happen_time;
            this.journalBlocks[0].score = res.data.post.score;
            this.currentBlockTitle = this.journalBlocks[0].title;
            this.currentBlock = this.journalBlocks[0];
            this.studentName = res.data.post.user_name;
            if (res.data.post.status == 4) {
                this.postSendBacked = true;
            }
        },
        async fetchComments(msgId) {
            let payload = {};
            if (msgId && msgId.length > 0) {
                payload = {
                    msg_id: msgId
                }
            }
            const res = await diaryPostReviseServices.getReviseList(this.postId, payload);
            this.comments = res.data;
        },
        async frameSelected(item) {
            this.currentBlockTitle = item.title;
            this.currentBlock = item;
            let msgId = '';
            if (item.type != 'full') {
                msgId = item.id;
                this.commentOptions = this.blockCommentOptions;
            } else {
                this.commentOptions = this.fullCommentOptions;
            }
            await this.fetchComments(msgId);
        },
        commentDataSelected(comment) {
            this.comment = comment;
        },
        getIconClass(type) {
            return 'mx-2 my-auto icon-style-' + type;
        },
        getIconName(type) {
            switch (type) {
                case 1:
                    return 'mdi-close-thick'
                case 2:
                    return 'mdi-alert'
                case 3:
                    return 'mdi-check-bold'
                default:
                    return 'mdi-comment-outline'
            }
        },
        async addComment() {
            if (this.$refs.commentForm.validate()) {
                this.contentLoading = true;
                const msgId = this.currentBlock.id == '0' ? '' : this.currentBlock.id;
                const payload = {
                    msg_id: msgId,
                    comment: this.comment,
                    type: this.selectedCommentType,
                }
                await diaryPostReviseServices.addPostRevise(
                    this.postId, payload);
                await this.fetchComments(msgId);
                this.contentLoading = false;
            }
        },
        openEditCommentDialog(comment) {
            this.editCommentId = comment.id;
            this.selectedCommentTypeEdit = comment.type;
            this.commentEdit = comment.comment;
            if (this.currentBlock.type == 'full') {
                this.commentOptionsEdit = this.fullCommentOptions;
            } else {
                this.commentOptionsEdit = this.blockCommentOptions;
            }
            this.editCommentDialog = true;
        },
        openDeleteCommentDialog(item) {
            this.deleteCommentMsg = item.comment;
            this.deleteCommentId = item.id;
            this.deletCommentDialog = true;
        },
        async editComment() {
            if (this.$refs.commentEditForm.validate()) {
                this.contentLoading = true;
                const msgId = this.currentBlock.id == '0' ? '' : this.currentBlock.id;
                const payload = {
                    msg_id: msgId,
                    comment: this.commentEdit,
                    type: this.selectedCommentTypeEdit,
                }
                await diaryPostReviseServices.editPostRevise(
                    this.postId, this.editCommentId, payload);
                await this.fetchComments(msgId);
                this.editCommentDialog = false;
                this.contentLoading = false;
            }
        },
        async deleteComment() {
            if (this.deleteCommentId) {
                this.contentLoading = true;
                await diaryPostReviseServices.deletePostRevise(
                    this.postId, this.deleteCommentId);
                await this.fetchComments();
                this.deletCommentDialog = false;
                this.contentLoading = false;
            }
        },
        async getCommentLibrary() {
            const type = this.selectedCommentType;
            const payload = {
                revise_type: type
            };
            const comments = await diaryPostReviseServices.getReviseLibrary(payload);
            this.commentDataList = comments.data.comment_list;
        },
        enableEditCommentLibrary() {
            this.editCommentLibrary = true;
        },
        openRemoveCommentLibrary(commentData) {
            console.log('comment', commentData);
            this.deleteCommentLibraryId = commentData.id;
            this.deleteCommentLibraryMsg = commentData.comment;
            this.deletLibraryCommentDialog = true;
        },
        async deleteLibraryComment() {
            if (this.deleteCommentLibraryId) {
                console.log('here')
                this.contentLoading = true;
                await diaryPostReviseServices.deleteReviseLibraryComment(this.deleteCommentLibraryId);
                await this.getCommentLibrary();
                this.deletLibraryCommentDialog = false;
                this.contentLoading = false;
            } else {
                console.log('no id')
            }
        },
        async addCommentLibrary() {
            if (this.comment.length > 0) {
                this.contentLoading = true;
                const payload = {
                    comment: this.comment,
                    revise_type: this.selectedCommentType
                }
                await diaryPostReviseServices.addReviseLibraryComment(payload);
                await this.getCommentLibrary();
                this.contentLoading = false;
            }
        },
        commentEditChanged(value) {
            if (value.length > 0) {
                this.addCommentDisabled = false;
            } else {
                this.addCommentDisabled = true;
            }
        },
        async sendBackPost() {
            this.contentLoading = true;
            await diaryPostServices.sendBackDiaryPost(this.postId);
            this.journalBlocks = [{
                id: '0',
                type: 'full',
                title: this.$t('post_revise.whole_post'),
                location: '',
                happen_time: '',
                journalTitle: '',
                score: 100,
                msgs: [],
            }];
            await this.fetchPostDetail();
            this.contentLoading = false;
        },
    },

}
</script>
<style scoped>
.basic-text {
    font-size: 16px;
    color: #555555;
    font-weight: 500;
}

.square-corners {
    border-radius: 0 !important;
}

.fill-height {
    height: 100%;
}

.custom-card {
    border-left: none;
    border-right: none;
    border-width: 0.5px;
}

.comment-data-area {
    background-color: #EEEEEE;
    border-radius: 5px;
    margin: 20px;
}

.icon-style-1 {
    background-color: #EB5757;
    border-radius: 25px;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-style-2 {
    background-color: #FFC107;
    border-radius: 25px;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-style-3 {
    background-color: #007bff;
    border-radius: 25px;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-style-4 {
    background-color: white;
    border-radius: 25px;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .v-text-field__details {
    min-height: 0 !important;
    margin-bottom: 0px !important;
}
</style>

<!-- REVISE_TYPE_ERROR: 1,
	REVISE_TYPE_WARN: 2,
	REVISE_TYPE_GOOD: 3,
	REVISE_TYPE_MSG: 4, -->