<template>
    <div class="d-flex align-center" style="background-color: #DE714B; min-height: 100vh;">
        <v-container>
            <v-card width="500" class="mx-auto mt-10">
                <v-card-title class="mb-0 pb-0">
                    <v-spacer />
                    <div class="text-center">
                        MangaChat
                    </div>
                    <v-spacer />
                </v-card-title>
                <v-card-title class="mt-0 pt-0">
                    <v-spacer />
                    <div class="text-center" style="color: #DE714B;">
                        {{ $t('login.console_name') }}
                    </div>
                    <v-spacer />
                </v-card-title>
                <v-card-text>
                    <v-tabs
                        v-model="tab">
                        <v-tab>
                            {{ $t('login.login') }}
                        </v-tab>
                        <v-tab>
                            {{ $t('login.signup') }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card>
                                <v-card-subtitle>
                                    <v-text-field :placeholder="$t('login.account')"
                                        v-model="email"
                                        :rules="[rules.required]"
                                        hide-details outlined></v-text-field>
                                </v-card-subtitle>
                                <v-card-subtitle>
                                    <v-text-field :placeholder="$t('login.password')"
                                        hide-details="auto" outlined
                                        v-model="password"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="[rules.required, verified]"
                                        :type="showPassword ? 'text' : 'password'"
                                        :error-messages="errorMessage"
                                        @click:append="showPassword = !showPassword">
                                    </v-text-field>
                                </v-card-subtitle>
                                <v-row class="mr-3">
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="passwordDialog = true" class="normal-case">
                                        <v-icon small class="mr-1">
                                            mdi-help-circle-outline
                                        </v-icon>
                                        {{ $t('login.forgot_password') }}
                                    </v-btn>
                                </v-row>
                                <v-card-subtitle>
                                    <v-btn outlined block @click="login" class="normal-case">{{ $t('login.login')
                                        }}</v-btn>
                                </v-card-subtitle>
                                <v-card-subtitle>
                                    <v-btn outlined block @click="googleLogin" class="normal-case">
                                        <img class="google-icon mr-1"
                                            src="https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png">
                                        {{ $t('login.google_login') }}
                                    </v-btn>
                                </v-card-subtitle>
                                <v-card-subtitle>
                                    <v-btn outlined block @click="openIdlogin" class="normal-case">
                                        <img class="google-icon mr-1"
                                            :src="require('@/assets/logo-openid.png')">
                                        {{ $t('login.openid_login') }}
                                    </v-btn>
                                </v-card-subtitle>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card>
                                <v-form ref="registerForm">
                                    <v-card-subtitle>
                                        <v-text-field
                                            :placeholder="$t('login.account')"
                                            v-model="newEmail"
                                            :rules="[rules.required]"
                                            hide-details="auto" outlined
                                            :error-messages="accountErrorMessage"
                                            :success-messages="accountCheckedMessage"
                                            @change="checkAccount"></v-text-field>
                                    </v-card-subtitle>
                                    <v-card-subtitle>
                                        <v-text-field :placeholder="$t('login.password')"
                                            outlined
                                            v-model="newPassword"
                                            :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required]"
                                            :type="showNewPassword ? 'text' : 'password'"
                                            @click:append="showNewPassword = !showNewPassword"
                                            @change="checkPassword">
                                        </v-text-field>
                                        <v-text-field :placeholder="$t('login.passwor_repeat')"
                                            outlined
                                            v-model="passwordRepeat"
                                            :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                                            :rules="[rules.required]"
                                            :type="showPasswordRepeat ? 'text' : 'password'"
                                            :error-messages="passwordErrorMessage"
                                            @click:append="showPasswordRepeat = !showPasswordRepeat"
                                            @change="checkPassword">
                                        </v-text-field>
                                    </v-card-subtitle>
                                </v-form>
                                <v-card-subtitle>
                                    <v-btn outlined block @click="register"
                                        class="normal-case">{{ $t('login.signup') }}</v-btn>
                                </v-card-subtitle>
                                <v-card-subtitle>
                                    <v-btn outlined block @click="googleLogin" class="normal-case">
                                        <img class="google-icon mr-1"
                                            src="https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png">
                                        {{ $t('login.google_register') }}
                                    </v-btn>
                                </v-card-subtitle>
                                <v-card-subtitle>
                                    <v-btn outlined block @click="openIdlogin" class="normal-case">
                                        <img class="google-icon mr-1"
                                            :src="require('@/assets/logo-openid.png')">
                                        {{ $t('login.openid_login') }}
                                    </v-btn>
                                </v-card-subtitle>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
            <v-dialog v-model="passwordDialog" max-width="450">
                <v-card>
                    <v-card-title class="headline">{{ $t('login.forgot_password') }}</v-card-title>
                    <v-card-text>
                        <v-form ref="passwordEmailForm">
                            <v-text-field
                                v-model="passwordEmail"
                                :rules="[rules.required, rules.email]"
                                :placeholder="$t('login.forgot_password_email_placeholder')"
                                :error-messages="pwdEmailError"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn block @click="sendPWDMail()" class="normal-case white--text"
                            color="#DE714B">{{ $t('login.send') }}</v-btn>
                    </v-card-actions>
                    <!-- <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="noEmail()" class="normal-case">{{ $t('login.forgot_password_no_email')
                            }}</v-btn>
                    </v-card-actions> -->
                </v-card>
            </v-dialog>
            <v-overlay :value="loading">
                <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
            </v-overlay>
        </v-container>
    </div>
</template>

<script>
import { loginServices } from "@/services/login.js";
import { registerServices } from "@/services/register.js";
import { userServices } from "@/services/user.js";
import { getItem, clearStorage } from "@/utils/storageManager.js";
// import { getItem } from "@/utils/storageManager.js";

export default {
    name: "Login",
    components: {},
    data() {
        return {
            tab: null,
            showPassword: false,
            rules: {
                required: value => !!value || this.$t('post_revise.comment_required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || this.$t('info_setting.email_verify.invalid_email')
                },
            },
            email: '',
            password: '',
            errorMessage: '',
            verified: true,
            accountChecked: true,
            newEmail: '',
            newPassword: '',
            showNewPassword: false,
            passwordRepeat: '',
            showPasswordRepeat: false,
            passwordErrorMessage: '',
            accountErrorMessage: '',
            loading: false,
            passwordDialog: false,
            passwordEmail: '',
            pwdEmailError: '',
            accountCheckedMessage: '',
        };
    },

    async created() {
        const userName = getItem('userName');
        if (userName) {
            location.href = `/login/check`;
        } else {
            let redirect = sessionStorage.getItem('redirect_path');
            sessionStorage.clear();
            clearStorage();
            if (redirect) {
                sessionStorage.setItem('redirect_path', redirect);
            }
        }
    },
    methods: {
        async login() {
            this.loading = true;
            const payload = {
                email: this.email,
                password: this.password,
            };
            const result = await loginServices.accountLogin(payload);
            const res = result.data;
            this.loading = false;
            if (res.success) {
                const toRedirectPath = sessionStorage.getItem('redirect_path');
                if (!toRedirectPath) {
                    sessionStorage.setItem('redirect_path', '/diary');
                }
                location.href = `/login/check`;
            } else {
                this.errorMessage = this.$t('login.wrong_password');
                this.verified = false;
            }
        },
        async googleLogin() {
            const redirect_uri = process.env.VUE_APP_GOOGLE_REDIRECT_URL;
            const link = await loginServices.get3rdOAuthLink('google', redirect_uri);
            location.href = link;
        },
        async openIdlogin() {
            const redirect_uri = process.env.VUE_APP_OPENID_REDIRECT_URL;
            const link = await loginServices.get3rdOAuthLink('openid', redirect_uri);
            location.href = link;
        },
        async checkAccount() {
            if (this.newEmail) {
                this.accountCheckedMessage = null;
                this.accountErrorMessage = null;
                const qs = {
                    account: this.newEmail,
                }
                const checkResult = await registerServices.checkAccount(qs);
                if (checkResult.data.returnCode == '-01001') {
                    this.accountErrorMessage = this.$t('login.account_repeated');
                } else {
                    this.accountCheckedMessage = this.$t('login.account_available');
                }
            }
        },
        checkPassword() {
            if (this.passwordRepeat && this.newPassword && this.newPassword !== this.passwordRepeat) {
                this.passwordErrorMessage = this.$t('login.password_not_match');
            } else {
                this.passwordErrorMessage = null;
            }
        },
        async register() {
            if (this.$refs.registerForm.validate() && !this.accountErrorMessage && !this.passwordErrorMessage) {
                this.loading = true;
                let locale = getItem('locale');
                if (!locale) {
                    locale = 'en';
                }
                const payload = {
                    account: this.newEmail,
                    password: this.newPassword,
                    locale: locale,
                    nickname: "-1"
                }
                const signUpResult = await registerServices.register(payload);
                if (signUpResult.data.returnCode == '01000') {
                    this.email = this.newEmail;
                    this.password = this.newPassword;
                    this.login();
                }
                this.loading = false;
            }
        },
        async sendPWDMail() {
            if (!this.$refs.passwordEmailForm.validate()) {
                return;
            }
            this.pwdEmailError = '';
            const baseURL = process.env.VUE_APP_BASE_URL;
            const link = `${baseURL}pwd/reset/\${code}`
            const payload = {
                email: this.passwordEmail,
                resetLink: link
            }
            const sendEmailResult = await userServices.forgetPwd('email', payload);
            if (sendEmailResult.data.error) {
                this.pwdEmailError = this.$t(`login.forgot_password_error.${sendEmailResult.data.error}`)
            } else {
                this.passwordDialog = false;
                alert(this.$t('login.forgot_password_mail_send'));
            }
        },
        noEmail() {
            console.log('no email');
        },
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    },
}
</script>

<style scoped>
::v-deep .nopadding {
    padding: 0 !important;
}

.google-icon {
    width: 1rem;
}
</style>