<template>
    <v-icon small color="amber" v-if="isPay != true">mdi-star-shooting</v-icon>
</template>

<script>
export default {
    name: "payFunction",
    components: {},
    props: ['isPay'],
    data() {
        return {
        };
    },
    async created() {
    },
    watch: {
    },
    mounted() {

    },
    methods: {
    },
};
</script>
<style scoped>
</style>

