<template>
    <div>
        <Steps />
        <v-container>
            <v-row no-gutters>
                <v-col cols>
                    <v-card class="fill-height" flat>
                        <v-card-title class="px-2 grey--text text--darken-1">
                            {{ $t('book.choose_post.title') }}
                        </v-card-title>
                        <v-card-subtitle class="px-2 grey--text text--darken-3">
                            {{ $t('book.choose_post.source') + ` ${diaryName} - ${authorName}` }}
                        </v-card-subtitle>
                        <v-row no-gutters class="mx-2">
                            <v-col cols="12" sm>
                                <div>{{ $t('book.choose_post.list') }}</div>
                                <v-card outlined>
                                    <v-card-text class="ma-2 pa-0 black--text">
                                        {{ `${unSelectedPosts.length} ` + $t('book.choose_post.numbers') }}
                                        <!-- {{ `${questItems.length} ${$t('homeworkQuestListEdit.questions')}` }} -->
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-list>
                                        <v-list-item-group v-model="unSelectedPostCurrent" class="fix-height-container">
                                            <v-list-item v-for="(item, i) in unSelectedPosts" :key="i">
                                                <v-list-item-content>
                                                    <v-list-item-title v-text="item.title"></v-list-item-title>
                                                    <v-list-item-subtitle
                                                        v-text="item.diary_date"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="auto" class="px-7 d-flex align-center">
                                <div class="d-block mx-auto">
                                    <v-btn outlined block class="my-1 normal-case" :disabled="addDisabled"
                                        @click="addPost">{{ `${$t('homeworkQuestListEdit.add')}\>`
                                        }}</v-btn>
                                    <v-btn outlined block class="my-1 normal-case" :disabled="removeDisabled"
                                        @click="removePost">{{ `&lt;${$t('homeworkQuestListEdit.remove')}`
                                        }}</v-btn>
                                    <v-btn outlined block class="my-1 normal-case"
                                        :disabled="selectedPosts.length == maxPostSelection" @click="addAll">
                                        {{ `${$t('homeworkQuestListEdit.add_all')} \>\>` }}</v-btn>

                                    <v-btn outlined block class="my-1 normal-case" :disabled="selectedPosts.length == 0"
                                        @click="removeAll">
                                        {{ `&lt;&lt; ${$t('homeworkQuestListEdit.remove_all')}`
                                        }}</v-btn>
                                </div>
                            </v-col>
                            <v-col cols="12" sm>
                                <div>{{ $t('book.choose_post.book') }}</div>
                                <v-card outlined>
                                    <v-card-text class="ma-2 pa-0 black--text">
                                        {{ getSelectedPosts() }}
                                    </v-card-text>
                                    <v-divider></v-divider>
                                    <v-list overflow-x-auto>
                                        <v-list-item-group v-model="selectedPostCurrent" class="fix-height-container">
                                            <draggable :list="selectedPosts" group="quest" @start="drag = true"
                                                @end="drag = false" @change="dragUpdated" class="fix-height-container">
                                                <v-list-item v-for="(item, i) in selectedPosts" :key="i">
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="item.title"></v-list-item-title>
                                                        <v-list-item-subtitle
                                                            v-text="item.diary_date"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </draggable>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col v-if="currentPost" cols="auto" col-sm
                    class="mt-4 mt-md-2  pl-0 pl-md-2 quest-col-width fix-height-container-taller">
                    <Post v-bind:postItem="currentPost" v-bind:showComment="false" v-bind:showMenu="false"
                        v-bind:clickable="false" v-bind:key="currentPost.diary_post_id" />
                </v-col>
            </v-row>
            <v-divider class="mt-5" />
            <div class="text-right my-5">
                <v-btn color="#DE714B" class="ml-auto white--text normal-case" depressed :disabled="doneButtonDisabled"
                    @click="finishSelection()">
                    {{ $t('button.next_step') }}</v-btn>
            </div>

        </v-container>
        <v-dialog v-model="cancelDialog" max-width="500" persistent>
            <v-card>
                <v-card-title>
                    {{ $t('book.choose_post.msg_exit') }}
                </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined class="normal-case mr-3" color="grey" @click="cancelDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn color="error" @click="dialog = false">
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="isLoading">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import Post from '@/components/console/diary/component/Post.vue'
import draggable from 'vuedraggable';
import { ebookServices } from '@/services/ebook.js';
import Steps from '@/components/ebook/Steps.vue';
import { getItem } from '@/utils/storageManager';

export default {
    name: "PostsEdit",
    components: { draggable, Post, Steps },
    data() {
        return {
            diaryPrintId: null,
            diaryName: '',
            authorName: '',
            posts: [],
            selectedPosts: [],
            addDisabled: true,
            removeDisabled: true,
            maxPostSelection: 30,
            minPostSelection: 1,
            currentPost: null,
            currentPostComments: null,
            unSelectedPostCurrent: null,
            selectedPostCurrent: null,
            displayPostId: null,
            isLoading: false,
            cancelDialog: false,
            doneButtonDisabled: true,
        };
    },
    async created() {
        this.isLoading = true;
        this.diaryPrintId = this.$route.params.diaryPrintId;
        const result = await ebookServices.getDiaryPosts(this.diaryPrintId);
        this.diaryName = result.data.diaryName;
        // this.posts = result.data.postList;

        this.authorName = result.data.studentName;
        this.maxPostSelection = result.data.maxDiaryCount;
        this.minPostSelection = result.data.minDiaryCount;

        const selectedPostResult = await ebookServices.getEBookSelectedPosts(this.diaryPrintId);
        let selectedIds = [];
        for (let post of selectedPostResult.data) {
            if (post.type === '2' || post.type === '2L') {
                selectedIds.push(post.post_id);
            }
        }
        if (result.data.postList) {
            for (let post of result.data.postList) {
                if (selectedIds.includes(post.id)) {
                    post.selected = true;
                    this.selectedPosts.push(post);
                } else {
                    post.selected = false;
                }
                this.posts.push(post);
            }
        }
        this.selectedPosts.sort((a, b) => {
            let indexOfA = selectedIds.indexOf(a.id);
            let indexOfB = selectedIds.indexOf(b.id);

            if (indexOfA > indexOfB) {
                return 1;
            }
            if (indexOfA < indexOfB) {
                return -1;
            }
            return 0;
        });
        this.checkOK();
        this.isLoading = false;
    },
    methods: {
        addPost() {
            let item = this.unSelectedPosts[this.unSelectedPostCurrent];
            item.selected = true;
            this.selectedPosts.push(item);
            this.unSelectedPostCurrent = null;
            this.selectedPostCurrent = this.selectedPosts.length - 1;
            this.checkOK();
        },
        removePost() {
            let item = this.selectedPosts[this.selectedPostCurrent];
            item.selected = false;
            const targetId = item.id;
            this.selectedPosts = this.selectedPosts.filter(item => item.id !== targetId);
            this.selectedPostCurrent = null;
            this.unSelectedPostCurrent = this.unSelectedPosts.findIndex(item => item.id === targetId);
            this.checkOK();
        },
        addAll() {
            do {
                this.unSelectedPostCurrent = 0;
                this.addPost();
            } while (this.selectedPosts.length < this.maxPostSelection);

        },
        removeAll() {
            for (let post of this.selectedPosts) {
                let postItem = this.posts.find(item => item.id === post.id);
                postItem.selected = false;
            }
            this.selectedPosts = [];
            this.selectedPostCurrent = null;
            this.unSelectedPostCurrent = 0;
            const id = this.unSelectedPosts[0].id;
            this.getPost(id);
            this.checkOK();
        },
        dragUpdated(detail) {
            this.selectedPostCurrent = detail.moved.newIndex;
            const id = detail.moved.element.id;
            this.getPost(id);
        },
        async getPost(postId) {
            this.isLoading = true;
            const result = await ebookServices.getPostDetail(postId);
            if (result.success) {
                this.currentPost = result.data.post;
                this.currentPostComments = result.data.comment;
                this.displayPostId = postId;
            }
            this.isLoading = false;
        },
        checkOK() {
            if (this.selectedPosts.length >= this.minPostSelection) {
                this.doneButtonDisabled = false;
            } else {
                this.doneButtonDisabled = true;
            }
        },
        async finishSelection() {
            this.isLoading = true;
            let postIdStr = '';
            let postIds = [];
            for (let post of this.selectedPosts) {
                if (postIdStr !== '') postIdStr += ',';
                postIdStr += `${post.id}`;
                postIds.push(post.id);
            }

            console.log('postIds', postIdStr);
            const body = {
                diary_post_ids: postIdStr,
            }
            const result = await ebookServices.updatePostsSelection(this.diaryPrintId, body);
            if (result.success) {
                let ebookStorage = getItem('ebookReady');
                if (ebookStorage == null) {
                    ebookStorage = '{}';
                }
                let ebookStorageJSON = JSON.parse(ebookStorage);
                let isReadyToPrint = ebookStorageJSON[this.diaryPrintId];

                if (!isReadyToPrint || isReadyToPrint === 'false') {
                    this.$router.push(`/ebook/${this.diaryPrintId}/basicinfo`);
                } else {
                    this.$router.push(`/ebook/${this.diaryPrintId}/list`);
                }
            }
            console.log('finishSelection', result);
            this.isLoading = false;
        },
        getSelectedPosts() {
            //{{ `${selectedPosts.length}/${maxPostSelection}` + $t('book.choose_post.numbers') + `(最少 ${minPostSelection} 篇)` }}
            let str = this.$t('book.choose_post.min').replace('${minPostSelection}', this.minPostSelection);
            return this.selectedPosts.length + '/' + this.maxPostSelection + ' ' + str;
        }
    },
    computed: {
        unSelectedPosts() {
            return this.posts.filter(item => !item.selected)
        }
    },
    watch: {
        selectedPostCurrent(val) {
            if (val === null || val === undefined) {
                this.removeDisabled = true;
                if (this.unSelectedPostCurrent === null || this.unSelectedPostCurrent === undefined) {
                    this.currentPost = null;
                    this.displayPostId = null;
                }
                return;
            }
            const id = this.selectedPosts[val].id;
            this.unSelectedPostCurrent = null;

            if (!this.displayPostId || this.displayPostId !== id) {
                this.getPost(id);
            }
            this.addDisabled = true;
            this.removeDisabled = false;
        },
        unSelectedPostCurrent(val) {

            if (val === null || val === undefined) {
                this.addDisabled = true;
                if (this.selectedPostCurrent === null || this.selectedPostCurrent === undefined) {
                    this.currentPost = null;
                    this.displayPostId = null;
                }
                return;
            }
            const id = this.unSelectedPosts[val].id;
            this.selectedPostCurrent = null;

            if (!this.displayPostId || this.displayPostId !== id) {
                this.getPost(id);
            }
            this.removeDisabled = true;
            if (this.selectedPosts.length < this.maxPostSelection) {
                this.addDisabled = false;
            } else {
                this.addDisabled = true;
            }
        }
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    }
};
</script>

<style scoped>
.fill-height {
    height: 100%;
}

.fix-height-container {
    height: calc(100vh - 385px);
    overflow-y: auto;
}

.fix-height-container-taller {
    height: calc(100vh - 220px);
    overflow-y: auto;
}

.quest-col-width {
    width: 380px;
}

@media (max-width: 575.98px) {
    .fix-height-container {
        height: 20vh;
    }

    .quest-col-width {
        width: 96%;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>