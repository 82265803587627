<template>
    <v-container fluid>
        <v-breadcrumbs :items="breadcrumbsItems" class="pa-0 mb-2" large></v-breadcrumbs>
        <Resource v-bind:resourceRes="resources"></Resource>
    </v-container>
</template>

<script>
import { diaryServices } from "@/services/diary.js";
import Resource from '@/components/console/diary/component/Resource.vue';
import { mapState } from "vuex";

export default {
    name: 'diaryResource',
    components: {
        Resource
    },
    data() {
        return {
            breadcrumbsItems: [],
            homeworkId: '',
            title: '',
            resources: []
        }
    },
    async created() {
        this.homeworkId = this.$route.params.id;

        const diaryHomeworkNameRes = await diaryServices.getHomeworkName(this.homeworkId, {});
        if (diaryHomeworkNameRes.data.return_code != null) {
            location.href = '/login';
        }

        this.breadcrumbsItems.push({
            text: this.$t('index.title'),
            disabled: false,
            href: `/diary`,
        }, {
            text: this.homeworkName,
            disabled: false,
            href: `/diary/homework/${this.homeworkId}`,
        }, {
            text: this.$t('menu.diary_resource'),
            disabled: true,
            href: ``,
        });

        const resourceRes = await diaryServices.getHomeworkResource(this.homeworkId);
        this.resources = resourceRes.data;
    },
    computed: {
        ...mapState(["homeworkName"]),
    },
    watch: {
        homeworkName() {
            this.breadcrumbsItems[1].text = this.homeworkName;
        },
    },
    methods: {

    },
}
</script>

<style scoped></style>
