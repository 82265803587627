<template>
    <v-container fluid>
        <div id="invitedDiary" class="text-h6 grey--text text--darken-2">
            {{ $t('main_menu.quest') }}
        </div>
        <v-card>
            <v-card-text>
                <v-item-group class="mr-4">
                    <v-row>
                        <v-col
                            v-for="item in diarySamples"
                            :key="item.id"
                            cols="12"
                            sm="4" md="3" lg="2">
                            <v-item v-if="item.type == 'create'">
                                <v-card outlined
                                    class="mx-auto text-center align-center dotted-outline"
                                    height="130px"
                                    @click="openCreateSampleDialog">
                                    <v-card-title>
                                        <v-icon medium color="grey darken-2" class="mx-auto mt-3">
                                            mdi-plus
                                        </v-icon>
                                    </v-card-title>
                                    <v-card-text class="text-body-2" style="color: #555555;">{{ item.name }}</v-card-text>
                                </v-card>
                            </v-item>
                            <v-item v-else>
                                <v-card outlined
                                    class="mx-auto text-center align-center card-outline"
                                    @click="goToDiaryQuest(item)">
                                    <v-card-title clas="my-0">
                                        <v-icon class="mt-3 mx-auto" x-large
                                            :color="item.label_color_code ? item.label_color_code : '#6aa72c'">
                                            mdi-book-open
                                        </v-icon>
                                    </v-card-title>
                                    <div class="text-body-2" style="color: #555555;">{{ item.name }}</div>
                                    <v-card-actions>
                                        <v-menu offset-y bottom left>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn append rounded elevation="0" block x-small
                                                    class="normal-case"
                                                    v-bind="attrs" v-on="on">
                                                    <v-spacer></v-spacer>
                                                    <v-icon small dark>
                                                        mdi-dots-horizontal
                                                    </v-icon></v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item>
                                                    <v-btn text class="normal-case"
                                                        @click="goToDiaryQuest(item)">
                                                        <v-list-item-title><v-icon small class="mr-1">mdi-eye</v-icon>
                                                            {{ $t('diary_main.diary_menu.view_diary') }}
                                                        </v-list-item-title>
                                                    </v-btn>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-btn text class="normal-case"
                                                        @click="openDiarySetting(item)">
                                                        <v-list-item-title><v-icon small class="mr-1">mdi-pencil</v-icon>
                                                            {{ $t('diary_main.diary_menu.edit') }}
                                                        </v-list-item-title>
                                                    </v-btn>
                                                </v-list-item>
                                                <v-divider></v-divider>
                                                <v-list-item>
                                                    <v-btn text class="normal-case"
                                                        @click="openArchvieDiaryDialog(item)">
                                                        <v-list-item-title><v-icon small class="mr-1">mdi-archive</v-icon>
                                                            {{ $t('diary_main.diary_menu.archvie') }}
                                                        </v-list-item-title>
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-card-actions>
                                </v-card>
                            </v-item>
                        </v-col>
                    </v-row>
                </v-item-group>
            </v-card-text>
            <LockBlock v-if="planExpired"></LockBlock>
        </v-card>
    </v-container>
</template>

<script>
import { diaryServices } from '@/services/diary.js';
import LockBlock from '@/components/console/diary/component/LockBlcok.vue';
import { getItem } from '@/utils/storageManager';

export default {
    name: 'diarySample',
    components: { LockBlock },
    data() {
        return {
            diarySamples: [],
            diaryToArchive: '',
            diaryArchiveDialog: false,
            loading: false,
            planExpired: false,
        }
    },
    async created() {
        await this.fetchDiarySample();
        const planLevel = getItem('planLevel');
        if (planLevel == 0) {
            this.planExpired = true;
        }
    },
    methods: {
        async fetchDiarySample() {
            const result = await diaryServices.getQusetDiary();
            this.diarySamples = result.data.data_list;
            this.diarySamples.push({
                id: -1,
                name: this.$t('diary_sample.create_sample.label'),
                type: 'create'
            });
        },
        goToDiaryQuest(item) {
            this.$router.push(`/diary/homework/${item.id}/quest`);
        },
        openDiarySetting(diaryHomerwork) {
            this.$router.push(`/diarySample/${diaryHomerwork.id}/edit`);
        },
        openArchvieDiaryDialog(diaryHomerwork) {
            this.diaryToArchive = diaryHomerwork;
            this.diaryArchiveDialog = true;
        },
        async archiveDiaryHomework() {
            this.loading = true;
            await diaryServices.archiveDiaryHomework(this.diaryToArchive.id);
            await this.fetchDiaryHomework();
            await this.fetchArchivedDiaryHomework();
            this.loading = false;
        },
        openCreateSampleDialog() {
            this.$router.push(`/diarySample/new`);
        }
    },
}
</script>

<style scoped>
.dotted-outline {
    border: 2px dashed #BDBDBD;
}
</style>