import { render, staticRenderFns } from "./BookRedirect.vue?vue&type=template&id=c70cfd52&scoped=true&"
import script from "./BookRedirect.vue?vue&type=script&lang=js&"
export * from "./BookRedirect.vue?vue&type=script&lang=js&"
import style0 from "./BookRedirect.vue?vue&type=style&index=0&id=c70cfd52&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c70cfd52",
  null
  
)

export default component.exports