<template>
    <v-row class="vh-100 align-center align-self-center">
        <v-col>
            <v-progress-linear color="amber darken-3" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
    </v-row>
</template>
<script>
import { userServices } from "@/services/user.js";
import { diaryServices } from '@/services/diary.js';
// import { setItem, setSharedLangCookie, testCookie } from '@/utils/storageManager.js';
import { setItem, removeItem } from '@/utils/storageManager.js';

export default {
    name: 'Refresh',
    async created() {
        const res = await userServices.refreshUserInfo();
        const data = res.data;
        let toRedirectPath = sessionStorage.getItem('redirect_path');
        sessionStorage.removeItem('redirect_path');
        if (!toRedirectPath) {
            toRedirectPath = '/diary';
        }
        if (data.success) {
            setItem("userName", data.user.name);
            setItem('locale', data.user.locale);
            sessionStorage.setItem('isHomeworkLock', data.chargePlan.isHomeworkLock);
            sessionStorage.setItem('planName', data.chargePlan.planName);
            sessionStorage.setItem('studentLimit', data.chargePlan.studentLimit);
            sessionStorage.setItem('locale', data.user.locale);
            sessionStorage.setItem('mode', data.mode);
            // const overwrite = this.$route.query.overwrite;
            // setSharedLangCookie(data.user.locale, overwrite == 1 ? true : false);
            // console.log('overwrite in refresh', overwrite);
            // testCookie();
            
            let payfunctionMap = {};
            const funcRes = await diaryServices.getDiaryFunctionPrivilege();
            funcRes.data.forEach(func => {
                payfunctionMap[`f${func['id']}`] = func['is_pay'];
            });
            setItem('payfunctionMap', JSON.stringify(payfunctionMap));

            location.href = toRedirectPath;
        } else {
            removeItem('userName');
            location.href = '/login';
        }
    }
}
</script>
<style scoped>
.vh-100 {
    height: 100vh;
}
</style>