<template>
    <v-navigation-drawer id="core-navigation-drawer" v-model="drawer" :color="bgColor" app clipped
        :expand-on-hover="expandOnHover" :src="barImage" :permanent="$vuetify.breakpoint.mdAndUp" v-bind="$attrs">
        <v-list dense nav>
            <v-list-item link :href="item.url" v-for="item in menu" :key="item.title">
                <v-list-item-icon class="mr-4">
                    <v-icon color="white">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="subtitle-1 white--text">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
export default {
    name: "MainMenu",
    props: {
        expandOnHover: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        menu: [],
        bgColor: "deep-orange lighten-1",
    }),
    async created() {
        this.setMenu();
        this.setColor();
    },
    computed: {
        ...mapState(["barColor", "barImage", "homeworkName"]),

        drawer: {
            get() {
                return this.$store.state.drawer;
            },
            set(val) {
                this.$store.commit("SET_DRAWER", val);
            },
        },
    },
    methods: {
        ...mapGetters(["getConsoleIndexUrl", "getHomeworkName"]),
        ...mapMutations({
            setHomeworkName: "setHomeworkName",
        }),
        mapItem(item) {
            return {
                ...item,
                children: item.children
                    ? item.children.map(this.mapItem)
                    : undefined,
            };
        },
        setMenu() {
            const mode = sessionStorage.getItem('mode');
            this.menu = [];
            if (mode == 't') {
                this.menu.push({
                    icon: 'mdi-book-open-variant',
                    title: this.$t('main_menu.journals'),
                    url: `/diary`,
                },
                    {
                        icon: 'mdi-book-open-outline',
                        title: this.$t('main_menu.quest'),
                        url: `/diarySample`,
                    },
                    {
                        icon: 'mdi-account-group',
                        title: this.$t('main_menu.uploadUserData'),
                        url: `/student`,
                    },
                    // {
                    //     icon: 'mdi-television',
                    //     title: this.$t('main_menu.board'),
                    //     url: `/diary/board`,
                    // },
                    {
                        icon: 'mdi-account-cog',
                        title: this.$t('main_menu.team_management'),
                        url: `/team/my`,
                    }, {
                        icon: 'mdi-information',
                        title: this.$t('main_menu.userChargePlanHistory'),
                        url: `/my/ucp`,
                    });

            } else {
                this.menu.push({
                    icon: 'mdi-book-open-variant',
                    title: this.$t('main_menu.journals'),
                    url: `/diary`,
                },
                    {
                        icon: 'mdi-book-open-outline',
                        title: this.$t('main_menu.quest'),
                        url: `/diarySample`,
                    },
                    {
                        icon: 'mdi-account-group',
                        title: this.$t('main_menu.uploadUserData'),
                        url: `/student`,
                    },
                    // {
                    //     icon: 'mdi-television',
                    //     title: this.$t('main_menu.board'),
                    //     url: `/diary/board`,
                    // },
                     {
                        icon: 'mdi-bookshelf',
                        title: this.$t('menu.diary_resource'),
                        url: `/diarySample/resourcesBook`,
                    },
                    {
                        icon: 'mdi-ticket-account',
                        title: this.$t('main_menu.userChargePlanHistory'),
                        url: `/my/ucp`,
                    }
                    );
            }
        },
        setColor() {
            const mode = sessionStorage.getItem('mode');
            if (mode == 't') {
                this.bgColor = '#66b799';
            } else {
                this.bgColor = 'deep-orange lighten-1';
            }
        }
    },
};
</script>

<style scoped></style>
