<template>
    <v-footer
    padless
  >
    <v-card
      flat
      tile
      class="orange lighten-5 text-center w-100"
    >
      <v-card-text>
        <v-container>
            <v-row>
                
                
            </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="text-center">
         {{ $t('copyright') + ' © ' + new Date().getFullYear() + ' ' + $t('mangaX_tech')}}
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
    name: "AppFooter",
    components: {},
    data() {
        return {
           
        };
    },
};
</script>

<style scoped>
.line-qrcode {
    max-width: 100px;
}
.ocac-logo {
  width: 35px;
}
.ocac-logo-xs {
  width: 30px;
}
.main-unit {
  font-size: 1.15rem;
}
</style>