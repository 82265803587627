import servicesUtils from '../utils/service'

export const notificationServices = {
    async getIndex(query) {
        try {
            const url = `${servicesUtils.resourceUrl('getNotificationIndex')}`
                + '?'
                + servicesUtils.queryParams(query);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in notificationService.getIndex, err: ${err}`)
            throw err
        }
    },
    async notificationRead(id) {
        try {
            const url = `${servicesUtils.resourceUrl('notificationRead')}`
                .replace(':id', id);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in notificationService.getIndex, err: ${err}`)
            throw err
        }
    },
    async getUnreadNotificationCount() {
        try {
            const url = `${servicesUtils.resourceUrl('getUnreadNotification')}`;
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in notificationService.getIndex, err: ${err}`)
            throw err
        }
    },
    async clear() {
        try {
            const url = `${servicesUtils.resourceUrl('clearNotification')}`;
            const res = await servicesUtils.post(url);
            return res.data;
        } catch (err) {
            console.log(`failed in notificationService.getIndex, err: ${err}`)
            throw err
        }
    }
}