import servicesUtils from '../utils/service'

export const studentServices = {
    async getStudents(id, query) {
        try {
            const url = `${servicesUtils.resourceUrl('students')}`
                .replace(':id', id)
                + '?'
                + servicesUtils.queryParams(query);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getStudents, err: ${err}`)
            throw err
        }
    },
    async addStudentCase(homeworkId, studentId) {
        try {
            const url = `${servicesUtils.resourceUrl('studentCase')}`
                .replace(':homeworkId', homeworkId)
                .replace(':studentId', studentId);
            const res = await servicesUtils.post(url);
            return res.data;
        } catch (err) {
            console.log(`failed in addStudentCase, err: ${err}`)
            throw err
        }
    },
    async deleteStudentCase(homeworkId, studentId) {
        try {
            const url = `${servicesUtils.resourceUrl('studentCase')}`
                .replace(':homeworkId', homeworkId)
                .replace(':studentId', studentId);
            const res = await servicesUtils.delete(url);
            return res.data;
        } catch (err) {
            console.log(`failed in deleteStudentCase, err: ${err}`)
            throw err
        }
    },
    async getStudentInfo(studentId) {
        try {
            const url = `${servicesUtils.resourceUrl('studentDetail')}`
                .replace(':id', studentId);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getStudentInfo, err: ${err}`)
            throw err
        }
    },
    async getStudentPosts(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('studentPosts')}` + '?'
                + servicesUtils.queryParams(payload);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getStudentPosts err: ${err}`)
            throw err
        }
    },
    async getStudentCases(id, query) {
        try {
            const url = `${servicesUtils.resourceUrl('studentCaseList')}`
                .replace(':homeworkId', id)
                + '?'
                + servicesUtils.queryParams(query);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getStudentCases, err: ${err}`)
            throw err
        }
    },
    async getSendBackPosts(id, query) {
        try {
            const url = `${servicesUtils.resourceUrl('sendBackPosts')}`
                .replace(':homeworkId', id)
                + '?'
                + servicesUtils.queryParams(query);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getSendBackPosts, err: ${err}`)
            throw err
        }
    },
    async archiveStudentFromHomework(homeworkId, payload) {
        try {
            const url = `${servicesUtils.resourceUrl('archiveStudentFromHomework')}`
                .replace(':homeworkId', homeworkId);
            const res = await servicesUtils.post(url, payload);
            return res.data;
        } catch (err) {
            console.log(`failed in archiveStudentFromHomework, err: ${err}`)
            throw err
        }
    },
    async getAllStudentList(query) {
        try {
            const url = `${servicesUtils.resourceUrl('allStudentList')}`
                + '?'
                + servicesUtils.queryParams(query);
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getAllStudentList, err: ${err}`)
            throw err
        }
    },
    async uploadStudents(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('uploadStudents')}`;
            // const res = await servicesUtils.post(url, payload);
            const res = await servicesUtils.postFormData(url, payload);
            return res.data;
        } catch (err) {
            console.log(`failed in uploadStudents, err: ${err}`)
            throw err
        }
    },
    async uploadStudentsCheck(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('uploadStudentsCheck')}`;
            const res = await servicesUtils.post(url, payload);
            return res.data;
        } catch (err) {
            console.log(`failed in uploadStudentsCheck, err: ${err}`)
            throw err
        }
    },
    async getBasicInfo() {
        try {
            const url = `${servicesUtils.resourceUrl('getBasicInfo')}`;
            const res = await servicesUtils.get(url);
            return res.data;
        } catch (err) {
            console.log(`failed in getBasicInfo, err: ${err}`)
            throw err
        }
    },
    async uploadStudentsSubmit(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('uploadStudentsSubmit')}`;
            const res = await servicesUtils.post(url, payload);
            return res.data;
        } catch (err) {
            console.log(`failed in uploadStudentsSubmit, err: ${err}`)
            throw err
        }
    },
    async deleteStudent(payload) {
        try {
            const url = `${servicesUtils.resourceUrl('deleteStudent')}`;
            const res = await servicesUtils.post(url, payload);
            return res.data;
        } catch (err) {
            console.log(`failed in deleteStudent, err: ${err}`)
            throw err
        }
    },
}