<template>
    <v-navigation-drawer id="core-navigation-drawer" v-model="drawer" :color="bgColor" app clipped
        :expand-on-hover="expandOnHover" :src="barImage" :permanent="$vuetify.breakpoint.mdAndUp" v-bind="$attrs">
        <!-- <v-list dense nav class="grey darken-3">
            <v-list-item>

                <v-list-item-content class="pa-0 text-center">
                    <v-list-item-title style="line-height: unset"><v-btn text plain class="white--text text-h5 pa-0 ma-0"
                            :to="getConsoleIndexUrl">MangaChat</v-btn>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider class="mb-2" /> -->
        <v-list-item>
            <v-list-item-title class="text-h6 white--text text-center" style="line-height: unset">{{ homeworkName
            }}</v-list-item-title>
        </v-list-item>
        <v-divider color="white"></v-divider>
        <v-list dense nav>
            <template v-for="item in menu">
                <v-list-item link :href="item.url" v-if="item.children.length == 0" :key="item.title">
                    <v-list-item-icon class="mr-4">
                        <v-icon color="white">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="subtitle-1 white--text">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <template v-else>
                    <v-subheader :key="item.title" class="subtitle-2 white--text">
                        {{ item.title }}
                    </v-subheader>
                    <template v-for="childItem in item.children">
                        <v-list-item :key="childItem.id" v-if="childItem.display" link :to="childItem.url" >
                            <v-list-item-icon class="mr-4">
                                <v-icon color="white">{{ childItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1 white--text">
                                    {{ childItem.title }}
                                    <PayFunction v-bind:isPay="payfunctionMap[childItem.id]"></PayFunction>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </template>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
// import { sysFunctionServices } from "@/services/sysFunction.js";
import PayFunction from '@/components/console/diary/component/PayFunction.vue'
import { diaryServices } from '@/services/diary.js';
import { mapMutations, mapState, mapGetters } from "vuex";
import { contestServices } from "@/services/contest.js";
import { getItem } from '@/utils/storageManager';

export default {
    name: "Menu",
    props: {
        expandOnHover: {
            type: Boolean,
            default: false,
        },
    },
    components: { PayFunction },
    data: () => ({
        menu: [],
        //homeworkName: '',
        payfunctionMap: {},
        homeworkId: '',
        isContestHomework: false,
        bgColor: "deep-orange lighten-1",
    }),
    async created() {
        this.homeworkId = this.$route.params.id;
        const diaryHomeworkNameRes = await diaryServices.getHomeworkName(this.homeworkId, {});
        if (diaryHomeworkNameRes.data.return_code == '-01101') {
            this.$router.push("/404");
            return;
        }
        this.setHomeworkName(diaryHomeworkNameRes.data.name);

        this.payfunctionMap = JSON.parse(getItem('payfunctionMap'));

        this.menu.push({
            icon: '',
            title: this.$t('menu.diary_management'),
            children: [{
                icon: 'mdi-newspaper-variant-outline',
                title: this.$t('menu.diary_news'),
                url: `/diary/homework/${this.homeworkId}/news`,
                id: 'f2',
                display: true
            }, {
                icon: 'mdi-account-group',
                title: this.$t('menu.diary_student'),
                url: `/diary/homework/${this.homeworkId}/student`,
                id: 'f3',
                display: true
            }, {
                icon: 'mdi-pin',
                title: this.$t('menu.diary_case'),
                url: `/diary/homework/${this.homeworkId}/case`,
                id: 'f4',
                display: true
            }, {
                icon: 'mdi-star',
                title: this.$t('menu.diary_collection'),
                url: `/diary/homework/${this.homeworkId}/collection`,
                id: 'f5',
                display: true
            }, {
                icon: 'mdi-swap-horizontal',
                title: this.$t('menu.diary_returned'),
                url: `/diary/homework/${this.homeworkId}/sendback`,
                id: 'f6',
                display: true
            }, {
                icon: 'mdi-information',
                title: this.$t('menu.diary_info'),
                url: `/diary/homework/${this.homeworkId}/info`,
                id: 'f1',
                children: [],
                display: true
            }, {
                icon: 'mdi-bookshelf',
                title: this.$t('menu.diary_resource'),
                url: `/diary/homework/${this.homeworkId}/resource`,
                id: 'f9',
                children: [],
                display: diaryHomeworkNameRes.data.has_resources
            }],
        },
            {
                icon: '',
                title: this.$t('menu.diary_data'),
                children: [{
                    icon: 'mdi-file-export',
                    title: this.$t('menu.diary_data_export'),
                    url: `/diary/homework/${this.homeworkId}/export`,
                    id: 'f7',
                    display: true
                }, {
                    icon: 'mdi-chart-line',
                    title: this.$t('menu.diary_data_report'),
                    url: `/diary/homework/${this.homeworkId}/report`,
                    id: 'f8',
                    display: true
                }],
            },
            // {
            //     icon: '',
            //     title: '其他',
            //     children: [{
            //         icon: 'mdi-book-open-blank-variant',
            //         title: '匯出電子書',
            //         url: '',
            //     }, {
            //         icon: 'mdi-television',
            //         title: '心情留言板',
            //         url: '',
            //     }],
            // },
        );

        ///如果是比賽用的日記本，手動加入下列功能
        const contestRes = await contestServices.checkHomeworkContest(this.homeworkId);
        if (contestRes.data.is_contest_home_work) {
            this.isContestHomework = contestRes.data.is_contest_home_work;
            this.menu.unshift({
                icon: 'mdi-format-list-bulleted',
                title: this.$t('menu.quest_list'),
                url: `/diary/homework/${this.homeworkId}/questList`,
                id: 'c_quest_list',
                children: [],
            });
        }

        this.setColor();
    },
    computed: {
        ...mapState(["barColor", "barImage", "homeworkName"]),

        drawer: {
            get() {
                return this.$store.state.drawer;
            },
            set(val) {
                this.$store.commit("SET_DRAWER", val);
            },
        },
        computedItems() {
            return this.items.map(this.mapItem);
        },
        profile() {
            return {
                avatar: true,
            };
        },
    },
    methods: {
        ...mapGetters(["getConsoleIndexUrl", "getHomeworkName"]),
        ...mapMutations({
            setHomeworkName: "setHomeworkName",
        }),
        mapItem(item) {
            return {
                ...item,
                children: item.children
                    ? item.children.map(this.mapItem)
                    : undefined,
            };
        },
        parseMenu(m) {
            let menu = {
                id: m.id,
                url: m.url,
                title: m.name,
                description: m.description,
                displayIcon: m.displayIcon,
                status: m.status,
                children: [],
            };
            return menu;
        },
        setColor() {
            const mode = sessionStorage.getItem('mode');
            if (mode == 't') {
                this.bgColor = '#66b799';
            } else {
                this.bgColor = 'deep-orange lighten-1';
            }
        }
    },
};
</script>

<style scoped></style>
