<template>
    <v-container fluid>
        <v-card outlined>
            <v-card-title class="text-h6 grey lighten-3 ">
                {{ $t('user_setting.avatar') }}
            </v-card-title>
            <v-card-title class="mx-auto">
                <v-avatar v-if="userAvatar" size="120" class="mx-auto">
                    <v-img :src="userAvatar"></v-img>
                </v-avatar>
                <span v-else class="text-body-2 text=center">{{ $t('user_setting.no_avatar') }}</span>
            </v-card-title>
            <v-card-actions>
                <v-btn color="deep-orange lighten-1 white--text round" small
                    class="mx-auto normal-case"
                    @click="openEditPhotoDialog()">{{ $t('user_setting.edit') }}</v-btn>
            </v-card-actions>
            <v-card-title class="text-h6 grey lighten-3 my-3 py-5">
                <v-row class="mx-1">
                    <span>{{ $t('user_setting.profile') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn color="deep-orange lighten-1 white--text round" small
                        class="normal-case"
                        @click="openSettingPage">{{ $t('user_setting.edit') }}</v-btn>
                </v-row>
            </v-card-title>
            <v-row class="mx-2">
                <v-col cols="6" sm="3">{{ $t('user_setting.name') }}</v-col>
                <v-col cols="6" sm="9">{{ userName }}</v-col>
            </v-row>
            <v-row class="mx-2">
                <v-col cols="6" sm="3">{{ $t('user_setting.nick_name') }}</v-col>
                <v-col cols="6" sm="9">{{ nickName }}</v-col>
            </v-row>
            <v-row class="mx-2">
                <v-col cols="6" sm="3">{{ $t('user_setting.birthday') }}</v-col>
                <v-col cols="6" sm="9">{{ birthday }}</v-col>
            </v-row>
            <v-row class="mx-2">
                <v-col cols="6" sm="3">{{ $t('user_setting.gender') }}</v-col>
                <v-col cols="6" sm="9">{{ gender.text }}</v-col>
            </v-row>
            <v-card-title class="text-h6 grey lighten-3 my-3 py-5">
                <v-row class="mx-1">
                    <span>Email</span>
                    <v-spacer></v-spacer>
                    <v-btn color="deep-orange lighten-1 white--text round" small
                        class="normal-case"
                        @click="editEmail">{{ $t('user_setting.edit') }}</v-btn>
                </v-row>
            </v-card-title>
            <v-row class="mx-2">
                <v-col cols="6" sm="3">Email</v-col>
                <v-col cols="6" sm="9">{{ userEmail }}</v-col>
            </v-row>
            <v-card-title class="text-h6 grey lighten-3 my-3 py-5">
                <v-row class="mx-1">
                    <span>{{ $t('user_setting.account_title') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn color="deep-orange lighten-1 white--text round" small
                        class="normal-case"
                        @click="openAccountSetting()">{{ $t('user_setting.edit') }}</v-btn>
                </v-row>
            </v-card-title>
            <v-row class="mx-2">
                <v-col cols="6" sm="3">{{ $t('user_setting.account') }}</v-col>
                <v-col cols="6" sm="9">{{ userAccount }}</v-col>
            </v-row>
            <v-card-title class="text-h6 grey lighten-3 my-3 py-5">
                <v-row class="mx-1">
                    <span>{{ $t('user_setting.language') }}</span>
                    <v-spacer></v-spacer>
                    <v-btn color="deep-orange lighten-1 white--text round" small
                        class="normal-case"
                        @click="languageDialog = true">{{ $t('user_setting.edit') }}</v-btn>
                </v-row>
            </v-card-title>
            <v-row class="mx-2">
                <v-col cols="6" sm="3">{{ $t('user_setting.language') }}</v-col>
                <v-col cols="6" sm="9">{{ userLanguage }}</v-col>
            </v-row>
            <v-card-title class="text-h6">
                {{ $t('user_setting.plan') }}
            </v-card-title>
            <v-row class="mx-2">
                <v-col cols="6" sm="3">{{ $t('user_setting.plan_name') }}</v-col>
                <v-col cols="6" sm="9">{{ planName }}</v-col>
            </v-row>
            <v-row class="mx-2 mb-2">
                <v-col>{{ planDescription }}</v-col>
            </v-row>
        </v-card>
        <v-dialog v-model="infoDialog" persistent max-width="550px">
            <v-card>
                <v-card-title class="text-h6 grey lighten-3 mb-4">
                    {{ $t('user_setting.profile') }}
                    <v-spacer />
                    <v-btn class="normal-case text-right" text
                        @click="infoDialog = false">
                        <v-icon>mdi-close</v-icon>
                        {{ $t('button.cancel_edit') }}
                    </v-btn>
                </v-card-title>
                <v-card-subtitle class="pb-0">
                    <v-row class="mx-2">
                        <v-col cols="5" sm="2">{{ $t('user_setting.name') }}</v-col>
                        <v-col cols="7" sm="10">
                            <v-text-field cols="6" sm="9" v-model="userNameEdit"
                                outlined dense hide-details="auto">
                            </v-text-field>
                            <p class="text-body-1 mt-1">{{ $t('user_setting.name_details') }}</p>
                        </v-col>
                    </v-row>
                </v-card-subtitle>
                <v-divider class="my-2 mx-6" />
                <v-card-subtitle class="pt-2 pb-0">
                    <v-row class="mx-2">
                        <v-col cols="5" sm="2">{{ $t('user_setting.nick_name') }}</v-col>
                        <v-col cols="7" sm="10">
                            <v-text-field v-model="nickNameEdit"
                                outlined dense hide-details="auto">
                            </v-text-field>
                            <v-checkbox class="mt-1"
                                v-model="displayNickNameEdit"
                                :label="`${$t('user_setting.display_nick_name')}`"></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card-subtitle>
                <v-divider class="my-2 mx-6" />
                <v-card-subtitle class="pt-2">
                    <v-row class="mx-2">
                        <v-col cols="5" sm="2">{{ $t('user_setting.birthday') }}</v-col>
                        <v-col cols="3" sm="4">
                            <v-select v-model="birthdayYear" :items="yearOptions"
                                outlined dense hide-details="auto" />
                        </v-col>
                        <v-col cols="2" sm="3">
                            <v-select v-model="birthdayMonth" :items="monthOptions"
                                outlined dense hide-details="auto" />
                        </v-col>
                        <v-col cols="2" sm="3">
                            <v-select v-model="birthdayDay" :items="dayOptions"
                                outlined dense hide-details="auto" />
                        </v-col>
                    </v-row>
                </v-card-subtitle>
                <v-divider class="my-2 mx-6" />
                <v-card-subtitle class="pt-2">
                    <v-row class="mx-2">
                        <v-col cols="5" sm="2">{{ $t('user_setting.gender') }}</v-col>
                        <v-col cols="7" sm="10">
                            <v-select :items="genderOptions" v-model="genderEdit"
                                item-text="text" item-value="value" outlined dense hide-details="auto">
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-subtitle>
                <v-card-actions class="py-4">
                    <v-spacer />
                    <v-btn class="normal-case" rounded @click="saveProfileSetting">
                        {{ $t('button.save') }}
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="languageDialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="text-h6 grey lighten-3 mb-4">
                    {{ $t('user_setting.language') }}
                    <v-spacer />
                    <v-btn class="normal-case text-right" text @click="languageDialog = false">
                        <v-icon>mdi-close</v-icon>
                        {{ $t('button.cancel_edit') }}
                    </v-btn>
                </v-card-title>
                <v-card-subtitle class="pt-3">
                    <v-row class="mx-2">
                        <v-col cols="6" sm="3">{{ $t('user_setting.language') }}</v-col>
                        <v-col cols="6" sm="9">
                            <v-select :items="languageOptions" v-model="selectedLanguage"
                                item-text="text" item-value="value" outlined dense hide-details="auto">
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-subtitle>
                <v-card-actions class="py-4">
                    <v-spacer />
                    <v-btn class="normal-case" rounded @click="saveLanguage()">
                        {{ $t('button.save') }}
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="emailVerifyDialog">
            <v-card>
                <VerifyEmail @code-verified-success="emailVerify"
                    v-bind:userEmail="userFullEmail"
                    :key="verifyKey"></VerifyEmail>
            </v-card>
        </v-dialog>
        <v-dialog v-model="accountSettingDialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="text-h6 grey lighten-3 mb-4">
                    {{ $t('user_setting.account_title') }}
                    <v-spacer />
                    <v-btn class="normal-case text-right" text @click="accountSettingDialog = false">
                        <v-icon>mdi-close</v-icon>
                        {{ $t('button.cancel_edit') }}
                    </v-btn>
                </v-card-title>
                <div v-if="userAccount">
                    <v-card-subtitle class="pt-2 mt-2">
                        <v-row class="mx-2">
                            <v-col cols="6" sm="3">{{ $t('user_setting.account') }}</v-col>
                            <v-col cols="6" sm="9">
                                <span class="text-body-1">{{ userAccount }}</span>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-divider class="mb-4 mx-6" />
                    <v-card-subtitle>
                        <v-row class="mx-2">
                            <v-col cols="6" sm="3">{{ $t('login.password') }}</v-col>

                            <v-col cols="6" sm="9">
                                <v-form ref="passwordForm">
                                    <v-text-field :placeholder="$t('user_setting.new_password_placeholder')"
                                        outlined dense hide-details="auto"
                                        v-model="newPassword"
                                        :rules="[rules.required]"
                                        :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showNewPassword ? 'text' : 'password'"
                                        @click:append="showNewPassword = !showNewPassword"
                                        @change="checkPassword" class="mb-1">
                                    </v-text-field>
                                    <v-text-field :placeholder="$t('login.passwor_repeat')"
                                        outlined dense hide-details="auto"
                                        v-model="passwordRepeat"
                                        :rules="[rules.required]"
                                        :append-icon="showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="showPasswordRepeat ? 'text' : 'password'"
                                        :error-messages="passwordErrorMessage"
                                        @click:append="showPasswordRepeat = !showPasswordRepeat"
                                        @change="checkPassword">
                                    </v-text-field>
                                    <div v-if="isPasswordEmpty" class="red--text text-body-2 mt-1">{{
                    $t('user_setting.no_password') }}
                                    </div>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-divider class="mb-4 mx-6" />
                </div>
                <v-card-subtitle class="mx-5">
                    <v-textfield v-if="isGoogleBind" outlined readonly>
                        <img class="google-icon mr-1"
                            src="https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png">
                        {{ $t('account_setting.google_binded') }}
                    </v-textfield>
                    <v-btn v-else outlined block @click="bindGoogle" class="normal-case">
                        <img class="google-icon mr-1"
                            src="https://www.google.com/images/branding/googleg/1x/googleg_standard_color_128dp.png">
                        {{ $t('account_setting.bind_google') }}
                    </v-btn>
                </v-card-subtitle>
                <v-card-subtitle class="mx-5">
                    <v-textfield v-if="isOpenIdBind" outlined readonly>
                        <img class="google-icon mr-1"
                            :src="require('@/assets/logo-openid.png')">
                        {{ $t('account_setting.openid_binded') }}
                    </v-textfield>
                    <v-btn v-else outlined block @click="bindOpenId" class="normal-case">
                        <img class="google-icon mr-1"
                            :src="require('@/assets/logo-openid.png')">
                        {{ $t('account_setting.bind_openid') }}
                    </v-btn>
                </v-card-subtitle>
                <v-card-actions class="py-4">
                    <v-spacer />
                    <v-btn class="normal-case" rounded @click="savePWD()">
                        {{ $t('button.save') }}
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editPhotoDialog" max-width="400px" persistent>
            <v-card dense>
                <p class="pa-5 text-h5 text-center">{{ $t('info_setting.update_thumbnail') }}</p>
                <v-card-text>
                    <v-container>
                        <v-form ref="thumbnailForm" lazy-validation>
                            <div class="book-img-block mx-auto mb-2" v-if="editedThumbnail.thumbnailFiles"
                                :style="`width: ${thumbnailOption.autoCropWidth}px; height: ${thumbnailOption.autoCropHeight}px`">
                                <div ref="thumbnailBlock">
                                    <vueCropper ref="thumbnailCropper" :img="thumbnailOption.img"
                                        :mode="option.mode" :canScale="option.canScale" :output-size="option.size"
                                        :output-type="option.outputType" :info="false" :full="option.full"
                                        :fixed="option.fixed" :fixed-number="thumbnailOption.fixedNumber"
                                        :can-move="option.canMove" :can-move-box="option.canMoveBox"
                                        :fixed-box="option.fixedBox" :original="option.original"
                                        :auto-crop="option.autoCrop"
                                        :auto-crop-width="thumbnailOption.autoCropWidth"
                                        :auto-crop-height="thumbnailOption.autoCropHeight"
                                        :center-box="option.centerBox">
                                    </vueCropper>
                                </div>
                            </div>
                            <v-file-input v-model="editedThumbnail.thumbnailFiles"
                                accept="image/png, image/jpeg, image/jpg" prepend-icon="mdi-camera"
                                @change="$uploadImage(editedThumbnail.thumbnailFiles, thumbnailOption)"></v-file-input>
                        </v-form>
                        <v-slider v-model="scale" :max="20" :min="1" step="1" hide-details class="align-center"
                            @input="changeScale()"></v-slider>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn class="normal-case" rounded outlined :disabled="loading"
                        @click="editPhotoDialog = false">
                        {{ $t('button.cancel') }}
                    </v-btn>
                    <v-btn class="normal-case white--text" color="deep-orange"
                        :disabled="loading" :loading="loading" rounded @click="savePhoto()">
                        {{ $t('button.save') }}
                    </v-btn>
                    <v-spacer />
                </v-card-actions>
            </v-card>
        </v-dialog>
        <input type="file" accept="image/png, image/jpeg, image/jpg" ref="thumbnailFile"
            @change="handleFileChange" style="display: none;">
        <v-overlay :value="loading">
            <v-progress-circular indeterminate color="deep-orange"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>
import { loginServices } from "@/services/login";
import { userServices } from "@/services/user";
import VerifyEmail from "@/components/account/component/VerifyEmail.vue";
import { VueCropper } from 'vue-cropper';
import { getItem, removeItem } from '@/utils/storageManager';

export default {
    name: "UserSetting",
    components: { VerifyEmail, VueCropper },
    data() {
        return {
            userId: '',
            userAvatar: '',
            userName: '',
            nickName: '',
            gender: '',
            birthday: '',
            planName: '',
            planDescription: '',
            userEmail: '',
            userLanguage: '',
            userAccount: '',
            infoDialog: false,
            userNameEdit: '',
            nickNameEdit: '',
            displayNickName: false,
            displayNickNameEdit: false,
            birthdayYear: '',
            birthdayMonth: '',
            birthdayDay: '',
            genderEdit: '',
            genderOptions: [
                { value: 'm', text: this.$t('info_setting.male') },
                { value: 'f', text: this.$t('info_setting.female') },
            ],
            languageDialog: false,
            languageOptions: [
                { value: 'zh', text: '繁體中文' },
                { value: 'en', text: 'English' },
            ],
            selectedLanguage: '',
            locale: '',
            loading: false,
            emailVerifyDialog: false,
            userFullEmail: '',
            verifyKey: 0,
            accountSettingDialog: false,
            newPassword: '',
            showNewPassword: false,
            passwordErrorMessage: '',
            passwordRepeat: '',
            showPasswordRepeat: false,
            isGoogleBind: false,
            isOpenIdBind: false,
            rules: {
                required: value => !!value || this.$t('post_revise.comment_required'),
            },
            isPasswordEmpty: false,
            editPhotoDialog: false,
            thumbnail: {
                editOptionFab: false,
                imgUrl: '',
            },
            editedThumbnail: {
                imgUrl: '',
                thumbnailFiles: '',
                displayHeight: 0,
                displayWidth: 0,
            },
            thumbnailOption: {
                img: '',
                fixedNumber: [1, 1],
                autoCropHeight: 200,
                autoCropWidth: 200,
            },
            option: this.$cropperOption,
            scale: 1,
            previousScale: 1,
        }
    },
    async created() {
        await this.fetchUserInfo();
        this.option.mode = 'contain';
        const bindingResult = getItem('binding');
        if (bindingResult) {

            console.log('bindingResult', bindingResult)

            switch (bindingResult) {
                case '-2':
                    this.$toastr.Add({
                        msg: this.$t('user_setting.bind.duplicate'),
                        type: "error",
                        clickClose: true,
                        timeout: 3000,
                        position: "toast-top-right",
                        classNames: ["animated", "bounceInRight"],
                    });
                    break;
                case '-1':
                    this.$toastr.Add({
                        msg: this.$t('user_setting.bind.failed'),
                        type: "error",
                        clickClose: true,
                        timeout: 3000,
                        position: "toast-top-right",
                        classNames: ["animated", "bounceInRight"],
                    });
                    break;
                default:
                    this.$toastr.Add({
                        msg: this.$t('user_setting.bind.success'),
                        clickClose: true,
                        timeout: 3000,
                        position: "toast-top-right",
                        classNames: ["animated", "bounceInRight"],
                    });
                    break;
            }
            removeItem('binding');
        }
    },
    methods: {
        async fetchUserInfo() {
            const userInfo = await loginServices.getMyInfo();
            this.userName = userInfo.user.fullName ? userInfo.user.fullName : userInfo.user.name;
            this.nickName = userInfo.user.nickName ? userInfo.user.nickName : userInfo.user.name;
            this.gender = (userInfo.user.gender == null || userInfo.user.gender == '') ? '' : this.genderOptions.find(item => item.value === userInfo.user.gender);
            this.birthday = userInfo.user.birthday;
            this.userAvatar = userInfo.user.avatarUrl;
            this.planName = userInfo.chargePlan.planName;
            this.planDescription = this.$t('user_setting.plan_description')
                .replace('{expireDate}', userInfo.chargePlan.planEndDate);
            this.locale = userInfo.user.locale;
            this.userLanguage = this.languageOptions.find(item => item.value === this.locale)?.text;
            this.selectedLanguage = this.languageOptions.find(item => item.value === this.locale);
            this.userAccount = userInfo.user.account;
            this.userEmail = userInfo.user.email;
            this.displayNickName = userInfo.user.isNickNameDefault;
            this.isPasswordEmpty = userInfo.user.isPasswordEmpty;
        },
        openSettingPage() {
            this.userNameEdit = this.userName;
            this.nickNameEdit = this.nickName;
            if (this.birthday) {
                const birthdayArray = this.birthday.split('-');
                this.birthdayYear = Number(birthdayArray[0]);
                this.birthdayMonth = Number(birthdayArray[1]);
                this.birthdayDay = Number(birthdayArray[2]);
            }
            this.genderEdit = this.gender.value;
            this.displayNickNameEdit = this.displayNickName;
            this.infoDialog = true;
        },
        async saveProfileSetting() {
            this.loading = true;
            let newBirthday = `${this.birthdayYear}-${String(this.birthdayMonth).padStart(2, '0')}-${String(this.birthdayDay).padStart(2, '0')}`;
            if (newBirthday.length != 10) {
                newBirthday = '';
            }
            const payload = {
                name: this.userNameEdit,
                gender: this.genderEdit,
                birthday: newBirthday,
                nickname: this.nickNameEdit,
                isNickNameDefault: this.displayNickNameEdit,
            }
            await userServices.basicInfoSetting(payload);
            sessionStorage.setItem('redirect_path', this.$route.path);
            this.infoDialog = false;
            this.loading = false;
            location.href = '/refresh'
        },
        async saveLanguage() {
            if (this.selectedLanguage.value === this.locale ||
                this.selectedLanguage === this.locale) {
                this.languageDialog = false;
                return;
            } else {
                this.loading = true;
                await userServices.changeLanguage(this.selectedLanguage);
                sessionStorage.setItem('redirect_path', this.$route.path);
                this.languageDialog = false;
                this.loading = false;
                location.href = '/refresh?overwrite=1';
            }
        },
        async editEmail() {
            this.verifyKey++;
            const fullEmailResult = await userServices.getFullEmail();
            if (fullEmailResult.success == true) {
                this.userFullEmail = fullEmailResult.data;
            }
            this.emailVerifyDialog = true;
        },
        async savePWD() {
            if (!this.$refs.passwordForm.validate()) {
                return;
            }
            this.checkPassword();
            if (this.passwordErrorMessage) {
                return;
            }
            const keyResult = await userServices.getKey();
            if (keyResult.data.key) {
                const key = keyResult.data.key;
                const encryptedPassword = userServices.encryptData(this.newPassword, key);
                const payload = {
                    password: encryptedPassword
                }
                const saveResult = await userServices.changePwd(payload);
                if (saveResult.data.returnCode == '') {
                    this.$toastr.Add({
                        msg: this.$t('user_setting.password_changed'),
                        clickClose: true,
                        timeout: 3000,
                        position: "toast-top-right",
                        classNames: ["animated", "bounceInRight"],
                    });
                    this.accountSettingDialog = false;
                }
            }
        },
        getYear(year) {
            if (this.locale === 'en') {
                return year;
            } else {
                return '民國' + (year - 1911) + '年';
            }
        },
        getMonth(month) {
            if (this.locale === 'en') {
                return month;
            } else {
                return month + '月';
            }
        },
        getDay(day) {
            if (this.locale === 'en') {
                return day;
            } else {
                return day + '日';
            }
        },
        async emailVerify() {
            await this.fetchUserInfo();
            this.emailVerifyDialog = false;
        },
        checkPassword() {
            this.passwordErrorMessage = null;
            if (this.passwordRepeat && this.newPassword && this.newPassword !== this.passwordRepeat) {
                this.passwordErrorMessage = this.$t('login.password_not_match');
            } else {
                this.passwordErrorMessage = null;
            }
        },
        async openAccountSetting() {
            const bindingResult = await userServices.bindingCheck();
            if (bindingResult.data) {
                this.isGoogleBind = bindingResult.data.isGoogleBinding;
                this.isOpenIdBind = bindingResult.data.isOpenIdBinding;
            }
            this.accountSettingDialog = true;
        },
        async bindGoogle() {
            sessionStorage.setItem('redirect_path', this.$route.path);
            const redirect_uri = process.env.VUE_APP_GOOGLE_REDIRECT_URL;
            const link = await loginServices.get3rdOAuthBindLink('google', redirect_uri);
            location.href = link;
        },
        async bindOpenId() {
            sessionStorage.setItem('redirect_path', this.$route.path);
            const redirect_uri = process.env.VUE_APP_OPENID_REDIRECT_URL;
            const link = await loginServices.get3rdOAuthBindLink('openid', redirect_uri);
            location.href = link;
        },
        async savePhoto() {
            if (this.$refs.thumbnailForm.validate()) {
                this.loading = true;
                Promise.all([this.$getCropperData(this.$refs.thumbnailCropper)])
                    .then(async result => {
                        if (result[0].status == 1) {
                            const imageRes = await userServices.updateAvatar(
                                {
                                    file: result[0].data
                                }
                            );
                            this.userAvatar = imageRes.data.url;
                            this.loading = false;
                            this.editPhotoDialog = false;
                        }
                    });
            }
        },
        openEditPhotoDialog() {
            this.$refs.thumbnailFile.click();
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if (!file) {
                return;
            }
            this.editedThumbnail.thumbnailFiles = file;
            this.$uploadImage(file, this.thumbnailOption);
            this.editPhotoDialog = true;
        },
        reloadCropper() {
            this.$refs.thumbnailCropper.refresh();
        },
        changeScale() {
            const change = this.scale - this.previousScale;
            this.$refs.thumbnailCropper.changeScale(change);
            this.previousScale = this.scale;
        },
    },
    computed: {
        yearOptions() {
            const year = new Date().getFullYear();
            return Array.from({ length: 100 }, (v, k) => year - k).map((year) => {
                return { value: year, text: this.getYear(year) };
            });
        },
        monthOptions() {
            return Array.from({ length: 12 }, (v, k) => k + 1).map((month) => {
                return { value: month, text: this.getMonth(month) };
            });
        },
        dayOptions() {
            return Array.from({ length: new Date(this.birthdayYear, this.birthdayMonth, 0).getDate() }, (v, k) => k + 1).map((day) => {
                return { value: day, text: this.getDay(day) };
            });
        },
    }
}
</script>
<style scoped>
.google-icon {
    width: 1rem;
}


.book-img-block {
    width: 60px;
}

.book-img-block>div {
    width: 100%;
    height: 100%;
}

@media (min-width: 600px) {
    .book-img-block {
        width: 200px;
    }
}

@media (min-width: 960px) {
    .book-img-block {
        width: 250px;
    }
}

@media (min-width: 1264px) {

    .book-img-block {
        width: 300px;
    }
}
</style>