<template>
    <div>
        <Steps />
        <v-container class="mt-4">
            <v-row class="px-5 mb-2">
                <div class="text-h6">{{ $t('book.print.title') }}</div>
                <v-spacer />
                <div v-if="sharedLink != null" class="my-auto">
                    <v-icon small color="success">mdi-circle</v-icon>
                    {{ $t('book.print.share_status_on') }}
                </div>
                <div v-else class="my-auto">
                    <v-icon small>mdi-circle-outline</v-icon>
                    {{ $t('book.print.share_status_off') }}
                </div>
                <v-btn outlined class="ml-2 my-auto normal-case"
                    @click="sharedLink ? shareLinkDialog = true : createLinkDialog = true">
                    {{ $t('book.print.share') }}</v-btn>
            </v-row>
            <v-row class="px-5">{{ $t('book.print.desc') }}</v-row>
            <v-row class="pa-5">
                <v-col cols="12" sm class="show-area  mb-2">
                    <div class="d-flex">
                        <div class="align-self-center">
                            <v-btn text color="white" @click="changePage(-1)"><v-icon>mdi-chevron-left</v-icon></v-btn>
                        </div>
                        <div class="w-100">
                            <pdf v-if="pdfContent.isReady" :src="pdfContent.showSrc" :page="pdfContent.page"></pdf>
                        </div>
                        <div class="align-self-center">
                            <v-btn text color="white" @click="changePage(1)"><v-icon>mdi-chevron-right</v-icon></v-btn>
                        </div>
                    </div>
                    <v-row no-gutters class="mt-2">
                        <div class="align-self-center mr-3 white--text">
                            {{ pdfContent.page }} / {{ pdfContent.numPages }}
                        </div>
                        <v-col>
                            <v-slider hide-details hint="zoom" :max="pdfContent.numPages" :min="1" class="w-100"
                                v-model="pdfContent.page" color="white"></v-slider>
                        </v-col>
                    </v-row>
                </v-col>
                <div class="option-area px-3 mb-2">
                    <div class="text-h6">{{ $t('book.print.output') }}</div>
                    <v-btn block depressed color="deep-orange darken-1" class="white--text normal-case" :href="pdfContent.src"
                        target="_blank" download="ebook.pdf">{{ $t('book.print.download_pdf') }}</v-btn>
                    <v-divider class="my-3"></v-divider>
                    <div class="text-h6">{{ $t('book.print.print_book') }}</div>
                    <p>
                        {{ $t('book.print.print_book_hint') }}
                    </p>

                </div>
            </v-row>
        </v-container>
        <v-dialog v-model="createLinkDialog" max-width="450" persistent>
            <v-card>
                <v-card-title>{{ $t('book.print.share_link_dialog.title') }}
                    <v-spacer />
                    <v-card-actions>
                        <v-btn icon @click="createLinkDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card-title>
                <v-card-text>
                    {{ $t('book.print.share_link_dialog.desc') }}
                </v-card-text>
                <v-card-text class="pb-0">
                    {{ $t('book.print.share_link_dialog.password') }}
                </v-card-text>
                <v-card-text>
                    <v-text-field
                        v-model=sharedLinkPassword
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"></v-text-field>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn block color="deep-orange darken-1" class="white--text normal-case"
                        @click="generateLink()">{{ $t('book.print.share_link_dialog.generate_link') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="shareLinkDialog" max-width="450" persistent>
            <v-card>
                <v-card-title>{{ $t('book.print.share_link_dialog.title') }}
                    <v-spacer />
                    <v-card-actions>
                        <v-btn icon @click="shareLinkDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card-title>
                <v-card-text>
                    {{ $t('book.print.share_link_dialog.created') }}
                </v-card-text>
                <v-card-text>
                    <v-text-field
                        :value="sharedLink"
                        filled
                        readonly
                        append-icon="mdi-content-copy"
                        @click:append="copyLink"></v-text-field>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-btn block color="deep-orange darken-1" class="white--text normal-case"
                        @click="revokeLink()">{{ $t('book.print.share_link_dialog.stop') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="!pdfContent.isReady" opacity="1" class="text-center">
            <div class="text-h6">{{ $t('book.print.processing') }} {{ progressPercentage }}%</div>
            <v-progress-linear color="orange lighten-1" height="10" class="mt-5 progress-bar" indeterminate
                rounded>
            </v-progress-linear>
        </v-overlay>
    </div>
</template>

<script>
import { ebookServices } from "@/services/ebook.js";
import pdf from 'vue-pdf'
import Steps from "@/components/ebook/Steps.vue";

export default {
    name: 'pageList',
    components: {
        pdf,
        Steps
    },
    data() {
        return {
            currentStep: 4,
            bookProcedure: [{
                id: 1,
                title: this.$t('book.choose_post'),
                link: '',
            }, {
                id: 2,
                title: this.$t('book.menu.basic_info'),
                link: '',
            }, {
                id: 3,
                title: this.$t('book.menu.content'),
                link: `/ebook/${this.diaryPrintId}/list`,
            }, {
                id: 4,
                title: this.$t('book.menu.print'),
                link: `/ebook/${this.diaryPrintId}/download`,
            }

            ],
            diaryPrintId: '',

            pdfContent: {
                isReady: false,
                showSrc: null,
                src: null,
                page: 1,
                numPages: 0,
            },
            progressPercentage: 0,
            interval: 0,
            sharedLink: null,
            createLinkDialog: false,
            showPassword: false,
            sharedLinkPassword: '',
            shareLinkDialog: false,
        }
    },
    watch: {

    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
    async created() {
        this.diaryPrintId = this.$route.params.diaryPrintId;

        this.interval = setInterval(() => {
            console.log('this.progressPercentage:', this.progressPercentage)
            if (this.progressPercentage < 85) {
                this.progressPercentage += Math.round(Math.random() * 5) + 1;
            } else {
                this.progressPercentage = 90;
                clearInterval(this.interval);
            }
        }, 2000)

        await ebookServices.generateEBookPDF(this.diaryPrintId);
        clearInterval(this.interval);
        this.progressPercentage = 95;
        await this.getPDF();
        await this.getLink();
    },
    methods: {
        goToStep(stepId) {
            let step = this.bookProcedure.find(step => step.id == stepId);
            if (step.id != this.currentStep) {
                this.$router.push(step.link.replace('${diaryPrintId}', this.diaryPrintId));
            }
        },
        async getPDF() {
            const data = await ebookServices.getEBookPDF(this.diaryPrintId);
            var _blob = new Blob([data], {
                type: "application/pdf"
            });
            this.pdfContent.src = URL.createObjectURL(_blob);
            this.pdfContent.showSrc = pdf.createLoadingTask(this.pdfContent.src);

            this.pdfContent.isReady = true;

            this.pdfContent.showSrc.promise.then(pdf => {
                this.pdfContent.page = 1;
                this.pdfContent.numPages = pdf.numPages;
            });
        },
        changePage(direction) {
            if (direction == -1 && this.pdfContent.page == 1) {
                return;
            }
            if (direction == 1 && this.pdfContent.page == this.pdfContent.numPages) {
                return;
            }
            this.pdfContent.page += direction;
        },
        async getLink() {
            const result = await ebookServices.getEBookLink(this.diaryPrintId);
            if (result.data.url) {
                this.sharedLink = result.data.url;
            } else {
                this.sharedLink = null;
            }
        },
        async generateLink() {
            const payload = {
                password: this.sharedLinkPassword
            }
            const result = await ebookServices.createEBookLink(this.diaryPrintId,
                payload);
            this.createLinkDialog = false;
            if (result.data.url) {
                this.sharedLink = result.data.url;
                this.shareLinkDialog = true;
            }
        },
        async copyLink() {
            try {
                await navigator.clipboard
                    .writeText(`${this.sharedLink}`);
                this.$toastr.Add({
                    msg: this.$t('invite_page.copied'),
                    clickClose: true,
                    timeout: 3000,
                    position: "toast-top-right",
                    classNames: ["animated", "bounceInRight"],
                });
            } catch (err) {
                console.error("Unable to copy text", err);
            }
        },
        async revokeLink() {
            await ebookServices.revokeEBookLink(this.diaryPrintId);
            this.sharedLink = null;
            this.shareLinkDialog = false;
            this.$toastr.Add({
                msg: this.$t('book.print.share_link_stopped'),
                clickClose: true,
                timeout: 3000,
                position: "toast-top-right",
                classNames: ["animated", "bounceInRight"],
            });
        }
    },
    mounted() {
        const facebookChat = document.querySelector(".fb_reset");
        if (facebookChat)
            facebookChat.style.display = "none";
    }
}
</script>
<style scoped>
.progress-bar {
    width: 90vw;
    height: 20px;
}

.show-area {
    background-color: #616161;
}

.option-area {
    width: 100%;
}

@media (min-width: 767.9px) {
    .option-area {
        max-width: 250px;
    }
}
</style>
